import { Component, OnInit } from '@angular/core';
import { ResponseVO, ErrorModel } from '../../_models/response.model';
import { DataloadConfigurationService } from '../../_services/dataload-configuration.service';
import { TransfereService } from '../../_services/transferService.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthMessageService } from '../../_services/auth-message.service';
import { Variables } from '../../constants';
import { CommonFunctions } from '../../common';
import * as $ from 'jquery';// import Jquery here
import * as _ from "lodash";
import { LeftNavService } from 'src/app/_services/left-nav.service';

@Component({
  selector: 'app-data-load-configuration',
  templateUrl: './data-load-configuration.component.html',
  styleUrls: ['./data-load-configuration.component.less']
})
export class DataLoadConfigurationComponent implements OnInit {
  errorStrInUpload: string;
  isxlsx: boolean = true;
  downloadMode:string= 'data';
  successStrInUpload: string;
  errorDescriptionList = [];
  showLogs: boolean = false;
  isEditable:boolean;

  constructor(
    public  ngxSmartModalService: NgxSmartModalService,
    private authMessageService: AuthMessageService,
    private dataloadConfigurationService: DataloadConfigurationService,
    private leftNavData: LeftNavService
  ) { }

  templateList: any[];
  selectedTemplate: {};
  dataObj = { document: '' };
  showUploadSection: boolean = false;
  message: boolean = true;
  mainContent: boolean = true;

  ngOnInit() {
    this.getAllDataloadConfig(true);
    this.leftNavData.currentMessage.subscribe(message => {
      this.message = message;
      this.changeContainerSize();
    });
  }

  changeContainerSize() {
    if (this.message) {
      this.mainContent = true;
    } else {
      this.mainContent = false;
    }

  }

  openDownloadConfirmation(mode){
    this.downloadMode=mode;
    this.ngxSmartModalService.open('downloadConfirmationModal');
  }

  getAllDataloadConfig(reset) {
    this.dataloadConfigurationService.getAllDataloadConfig().subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        //this.isEditable =   response["responseData"]["isDataLoadConfigEditable"];
        this.templateList = response["responseData"]["dataloadConfigDetailsVOList"];
        this.templateList = _.orderBy(this.templateList, ['sequence'], ['asc']);
        if(reset){
          this.selectedTemplate = this.templateList[0];
          this.isEditable = this.selectedTemplate["isDataUploadVisible"];
        }
          
      }
    });
  }

  selectTemplate(template) {
    this.selectedTemplate = template;
    this.isEditable = template.isDataUploadVisible;
    this.showUploadSection = false;
    this.showLogs = false;
  }


  addDocument(documentData) {
    if (!this.dataObj.document) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select a file to upload';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      let formData = new FormData();
      // Add your values in here
      var documentFile = documentData.target[4].files[0];
      if (documentFile) {
        formData.append('templateDataFile', documentFile, documentFile.name);
        formData.append('dataStoreId', this.selectedTemplate['dataStoreId']);
        formData.append('sequence', this.selectedTemplate['sequence']);
        formData.append('configName', this.selectedTemplate['configName']);
      }
      this.dataloadConfigurationService.uploadData(formData)
      .subscribe((response) => {
        if(response['responseType'] == Variables.statusSuccess){
          //this.authMessageService.showSuccessPopup(response);
          this.showLogs = true;
          this.errorStrInUpload = response['responseData'].failure;
          this.successStrInUpload = response['responseData'].success;
          this.errorDescriptionList = response['responseData'].errorDescriptionList;
          this.getAllDataloadConfig(false);
        }
      });
    }

  }

  openUploadSection() {
    this.showUploadSection = true;
    this.removeFile('upload-document');
  }

  browseFiles(id) {
    setTimeout(function () {
      $('#' + id).click();
    });
  }

  downloadData() {
    this.dataloadConfigurationService
      .downloadData(this.selectedTemplate['dataStoreId'],this.isxlsx)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          var fileData = response['responseData'].fileData;
          var fileName = response['responseData'].fileName;
          if (fileData && fileName) {
            CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
          }
        }
        this.isxlsx = true;
      });
  }

  downloadTemplate() {
    this.dataloadConfigurationService
      .downloadTemplate(this.selectedTemplate['dataStoreId'],this.isxlsx)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          var fileData = response['responseData'].fileData;
          var fileName = response['responseData'].fileName;
          if (fileData && fileName) {
            CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
          }
        }
        this.isxlsx = true;
      });
  }

  setBrowseFile(id) {
    if (this.validateFile(id)) {
      var file = $('#' + id).val();
      var fileName = (<string>file).replace(/C:\\fakepath\\/i, '');
      this.dataObj.document = fileName;

    }
  }

  validateFile(id) {
    return true;
  }

  removeFile(id) {
    $('#' + id).val('');
    this.dataObj.document = '';
  }

}
