import { Component, OnInit } from '@angular/core';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { ActivatedRoute } from '@angular/router';
import { Variables } from '../../../../constants';

@Component({
  selector: 'product-fees-tab',
  templateUrl: './product-fees-tab.component.html',
  styleUrls: ['./product-fees-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductFeesTabComponent implements OnInit {
  productFeesData = {};
  productId: any;

  productAccountColumnDefs = [];
  productAccountFeeRowData = [];

  productAdviserColumnDefs = [];
  productAdviserFeeRowData = [];

  productAssetBasedFeeColumnDefs = [];
  productAssetBasedFeeRowData = [];

  productDeferredLoadFeeColumnDefs = [];
  productDeferredLoadFeeRowData = [];

  productExchangeFeeColumnDefs = [];
  productExchangeFeeRowData = [];

  productFrontLoadFeeColumnDefs = [];
  productFrontLoadFeeRowData = [];

  productFundOperatingExpenseVO:any = {};

  productPurchaseFeeColumnDefs = [];
  productPurchaseFeeRowData = [];

  productRecurringFrontLoadFeeColumnDefs = [];
  productRecurringFrontLoadFeeRowData = [];

  productRedemptionFeeColumnDefs = [];
  productRedemptionFeeRowData = [];
  
  gridApi: any;
  gridColumnApi: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private catalogProductInfoService: CatalogProductInfoService
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });

    this.productFrontLoadFeeColumnDefs = [
      { headerName: "Schedule ID", field: "frontScheduleId", width: 100 },
      { headerName: "Begin Amt.$", field: "frontBeginAmount", width: 100 },
      { headerName: "End Amt.$", field: "frontEndAmount", width: 100 },
      { headerName: "Stand. Amt.$", field: "frontStandardAmount", width: 100 },
      { headerName: "Min. Fee %", field: "frontMinimumFee", width: 100 },
      { headerName: "Max. Fee %", field: "frontMaximumFee", width: 100 }
    ];

    this.productRecurringFrontLoadFeeColumnDefs = [
      { headerName: "Schedule ID", field: "recurringScheduleId", width: 100 },
      { headerName: "Begin Amt.$", field: "recurringBeginAmount", width: 100 },
      { headerName: "End Amt.$", field: "recurringEndAmount", width: 100 },
      { headerName: "Stand. Amt.$", field: "recurringStandardAmount", width: 100 },
      { headerName: "Min. Fee %", field: "recurringMinimumFee", width: 100 },
      { headerName: "Max. Fee %", field: "recurringMaximumFee", width: 100 }
    ];

    this.productPurchaseFeeColumnDefs = [
      { headerName: "Schedule ID", field: "purchaseScheduleId", width: 100 },
      { headerName: "Begin Amt.$", field: "purchaseBeginAmount", width: 100 },
      { headerName: "End Amt.$", field: "purchaseEndAmount", width: 100 },
      { headerName: "Stand. Amt.$", field: "purchaseStandardAmount", width: 100 },
      { headerName: "Min. Fee %", field: "purchaseMinimumFee", width: 100 },
      { headerName: "Max. Fee %", field: "purchaseMaximumFee", width: 100 }
    ];

    this.productAdviserColumnDefs = [
      { headerName: "Schedule ID", field: "adviserScheduleId", width: 100 },
      { headerName: "Begin Amt.$", field: "adviserBeginAmount", width: 100 },
      { headerName: "End Amt.$", field: "adviserEndAmount", width: 100 },
      { headerName: "Stand. Amt.$", field: "adviserStandardAmount", width: 100 },
      { headerName: "Min. Fee %", field: "adviserMinimumFee", width: 100 },
      { headerName: "Max. Fee %", field: "adviserMaximumFee", width: 100 }
    ];

    this.productAssetBasedFeeColumnDefs = [
      { headerName: "Schedule ID", field: "assetScheduleId", width: 100 },
      { headerName: "Begin Amt.$", field: "assetStandardAmount", width: 100 },
      { headerName: "End Amt.$", field: "assetEndAmount", width: 100 },
      { headerName: "Stand. Amt.$", field: "adviserStandardAmount", width: 100 },
      { headerName: "Min. Fee %", field: "assetMinimumFee", width: 100 },
      { headerName: "Max. Fee %", field: "assetMaximumFee", width: 100 }
    ];

    this.productDeferredLoadFeeColumnDefs = [
      { headerName: "Begin Day", field: "deferredBeginDay", width: 100 },
      { headerName: "End Day", field: "deferredEndDay", width: 100 },
      { headerName: "Fee", field: "deferredPercentFee", width: 100 }
    ];

    this.productRedemptionFeeColumnDefs = [
      { headerName: "Begin Day", field: "redemptionBeginDay", width: 100 },
      { headerName: "End Day", field: "redemptionEndDay", width: 100 },
      { headerName: "Fee", field: "redemptionPercentFee", width: 100 }
    ];

    this.productExchangeFeeColumnDefs = [
      { headerName: "Begin Day", field: "exchangeBeginDay", width: 100 },
      { headerName: "End Day", field: "exchangeEndDay", width: 100 },
      { headerName: "Fee", field: "exchangePercentFee", width: 100 }
    ];

    this.productAccountColumnDefs = [
      { headerName: "Fee Amt.$", field: "feeAmount", width: 100 },
      { headerName: "Min. Amt.$", field: "minimumAmount", width: 100 },
      { headerName: "Max. Amt.$", field: "maximumAmount", width: 100 },
      { headerName: "Frequency", field: "frequency", width: 100 }
    ];

    this.getProductFeesData(this.productId);
  }

  getProductFeesData(productId) {
    this.catalogProductInfoService.getProductFeesInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productFeesData = response['responseData'];
          this.productAccountFeeRowData = this.productFeesData['productAccountFeeVO'];
          this.productAdviserFeeRowData = this.productFeesData['productAdviserFeeVO'];
          this.productAssetBasedFeeRowData = this.productFeesData['productAssetBasedFeeVO'];
          this.productDeferredLoadFeeRowData = this.productFeesData['productDeferredLoadFeeVO'];
          this.productExchangeFeeRowData = this.productFeesData['productExchangeFeeVO'];
          this.productFrontLoadFeeRowData = this.productFeesData['productFrontLoadFeeVO'];
          this.productFundOperatingExpenseVO = this.productFeesData['productFundOperatingExpenseVO'];
          this.productPurchaseFeeRowData = this.productFeesData['productPurchaseFeeVO'];
          this.productRecurringFrontLoadFeeRowData = this.productFeesData['productRecurringFrontLoadFeeVO'];
          this.productRedemptionFeeRowData = this.productFeesData['productRedemptionFeeVO'];
        }
      });
  }

  /* On grid ready */
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

}
