import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class PortfolioOverviewService {

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };

    constructor(private http: HttpClient) { }

    savePortfolioData(data) {
        return this.http.post(ApiUrls.savePortfolioDataAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deletePortfolioData(data) {
        return this.http.post(ApiUrls.deletePortfoliosAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveAccountData(data) {
        return this.http.post(ApiUrls.setAccountDataAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteAccountData(value) {
        return this.http.post(ApiUrls.deleteAccountDataAPI, value, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getOwnerListData(processInstanceId) {
        return this.http.get(ApiUrls.getOwnerListForAccountAPI + '?processInstanceId=' + processInstanceId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getConnectedPortfolioListData(accountId) {
        return this.http.get(ApiUrls.getConnectedPortfolioListAPI + '?accountId=' + accountId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveConnectedPortfolioListData(accountId, data) {
        return this.http.post(ApiUrls.saveConnectedPortfolioListAPI + '?accountId=' + accountId, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    includePortfoliosInWorkflow(data) {
        return this.http.post(ApiUrls.includePortfolioInWorkflowAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getHoldingsList(accountId, portfolioId) {
        return this.http.get(ApiUrls.getHoldingsListAPI + '?accountId=' + accountId + '&portfolioId=' + portfolioId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getEditHoldingsList(accountId) {
        return this.http.get(ApiUrls.getEditHoldingsListAPI + '?accountId=' + accountId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    downloadTemplateWorkflow(isxlsx) {
        return this.http.get(ApiUrls.downloadTemplateWorkflowAPI+ '?isXLS=' + isxlsx, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteHoldings(data) {
        return this.http.post(ApiUrls.deleteHoldingsAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveHoldings(data) {
        return this.http.post(ApiUrls.saveHoldingsAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    uploadData(formData) {
        const options = {} as any; // Set any options you like
        return this.http.post(ApiUrls.importHoldingsDataAPI, formData, options)
            .pipe(
                response => response
            );
    }

    resolveHoldings(data,sysCurrency,baseCurrency) {
        return this.http.post(ApiUrls.resolveHoldingsAPI + '?systemCurrency=' + sysCurrency + '&baseCurrency='+ baseCurrency , data, this.httpOptions)
            .pipe(
                response => response
            );
    }


}
