export class CatalogModel {
}

export class ProductListModel {
    dataStoreId: number;
    name: string;
    type: string = null;
    description: string;
    sortOrder: boolean;
    entitlement: string = null;
}

export class ProductsInfoModel {

}

export class ProductModel {
    dataStoreId: number;
    name: string;
    productId: string;
    shortName: string;
    productType: string = null;
    inceptionDate: string;
    style: string= null;
    primaryBenchmark: string= null;
    secondaryBenchmark: string= null;
    accreditedInvestorRequired: boolean = null;
    qualifiedInvestorRequired: boolean = null;
    residenceRequirementBenifits: string;
    assetClass: string;
    issueCountry: string= null;
    riskCountry: string= null;
    baseCurrency: string = 'USD';
    status: string= null;
    isActive: boolean = null;
    isPassive: boolean = null;
    isExternal: boolean = null;
    firmRanking: number;
    taxExempt: boolean = null;
    taxType: string= null;
    productClass: string;
    productSuperClass: string;
    productListsDataStoreId = [];
    productAssetAllocations = [];
    assetClassId : string = null;
    expenseRatio : number = null;
}

export class AssetAllocationModel{
    assetClassId: string;
    percentageOwnership: number;

}

export class CatalogDropdownData {
    productListType: any;
    productStatus: any;
    productType: any;
    taxType: any;
    primaryBenchmark: any;
    productStyle: any;
    secondaryBenchmark: any;
    issueCountry: any;
    riskCountry: any;
    assetClass: any;
}

