import { Component, OnInit, Input } from '@angular/core';
import { AnalysisStrategyComponent } from 'src/app/webPages/proposal-workflow/analysis-strategy/analysis-strategy.component';
@Component({
  selector: 'app-historical-statistices',
  templateUrl: './historical-statistices.component.html',
  styleUrls: ['./historical-statistices.component.less']
})
export class HistoricalStatisticesComponent implements OnInit {
  typesOfCharts: any;
  showAnalysisTypeChartData: any[];
  removeProposedGraph: boolean;
  strategy1: any;
  strategy2: any;
  title: any;
  constructor(
    private analysisStrategyComponent:AnalysisStrategyComponent
  ) { }
  @Input() inputAnalysisData;
  
  ngOnChanges(){
    this.strategy1=this.analysisStrategyComponent.selectedStrategy1['key'];
    this.strategy2=this.analysisStrategyComponent.selectedStrategy2['key'];
    if(this.inputAnalysisData){
      console.log(this.inputAnalysisData);
      this.title=this.inputAnalysisData.timePeriodMessage;
      this.removeProposedGraph=this.analysisStrategyComponent.flagForRemovingProposedColumn;

      if(this.inputAnalysisData){
        this.typesOfCharts = this.inputAnalysisData.statistics;
        this.convertIntoList(this.typesOfCharts);
  
      }
    }
  }

  ngOnInit() {
  }
  convertIntoList(Object) {
    this.showAnalysisTypeChartData = [];
    if (Object) {
      Object.forEach(element => {
        element.isHeader = true;
        this.showAnalysisTypeChartData.push(element);
        if (element.children) {
          element.children.forEach(item => {
            item.isHeader = false;
            item.parentId = element.id;
            item.assetClassName="- -"+item.assetClassName;
            this.showAnalysisTypeChartData.push(item);


          });
        }

      });
    }
  }
}
