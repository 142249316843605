
import { Component, OnInit, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BaseService } from "src/app/_services/base.service";
import { Variables } from "src/app/constants";
import { MaxCalculatorMainComponent } from '../../max-calculator-main/max-calculator-main.component';
import { StrategySelectionService } from 'src/app/_services/workflow-services/strategy-selection.service';
import { WorkflowBaseService } from 'src/app/_services/workflow-services/workflow-base.service'
import { StrategySelectionModel } from 'src/app/_models/strategy-selection.model';
import { StrategyCharacteristicModel } from 'src/app/_models/strategy-characteristic.model';
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { from, of } from 'rxjs';
import { AnalysisService } from '../../../../_services/workflow-services/analysis.service';
import { CommonFunctions } from '../../../../common';
import { variable } from '@angular/compiler/src/output/output_ast';
import { isNgTemplate } from '@angular/compiler';
import * as _ from "lodash";

@Component({
  selector: 'app-cal-strategy-selection',
  templateUrl: './cal-strategy-selection.component.html',
  styleUrls: ['./cal-strategy-selection.component.less']
})
export class CalStrategySelectionComponent implements OnInit {
  selectedStrategyOfPortfolio: any = [];
  strategyDropdownMenuList: any = [];
  portfolioDropdownMenuList: any = [];
  apiParams: any;
  strategyInfo = new StrategySelectionModel();
  strategyCharacteristicInfo = new StrategyCharacteristicModel();
  tempArr: any;
  assetClassData: any;
  portfolioObjectList: any = [];
  objectList: any = {};
  commonFunction = new CommonFunctions();
  portfolioDataList: any[];
  portfolioList: any;
  savedPofolioStrategyMap = {};
  totalCurrentAmount: any = 0;
  totalCurrentPerc: any = 0;
  totalProposedAmount: any = 0;
  totalProposedPerc: any = 0;
  totalDifferenceAmount: any = 0;
  totalDifferencePerc: any = 0;
  strategyData: any;
  selectedStrategyData: any;
  showSelectedStrategyData: any = [];
  lastSelectedStrategy= null;

  saveStrategyUpdateData: any = [];
  analyticsData;
  characteristicData: any;

  investmentTemplateList:any = [];
  selectedTemplate = null;
  templateName;
  investmentTemplateVO = {};
  templatePopupMode:string;



  constructor(public ngxSmartModalService: NgxSmartModalService,
    private baseService: BaseService,
    private workflowBaseService: WorkflowBaseService,
    private strategySelectionService: StrategySelectionService,
    private maxCalculatorMainComponent: MaxCalculatorMainComponent,
    private authMessageService: AuthMessageService,
    private analysisService: AnalysisService

  ) { }
  //@Input() public parentData;


  ngOnInit() {
    this.analyticsData = {
      parentComponent: "strategySelection",
      selectedStrategy: this.strategyInfo['proposed'],
      processDefinitionKey : this.maxCalculatorMainComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey
    }

    let requestObj = {
      "processInstanceId": this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "proposalSetup",
      "processDefinitionKey" : this.maxCalculatorMainComponent.workflowInfoData.processDefinitionKey
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData'].taskVariables && response['responseData'].taskVariables.strategySelectionAllocation) {
          this.savedPofolioStrategyMap = response['responseData'].taskVariables.strategySelectionAllocation.portfoioVsProposedPortfolioInfo;
        }
      }
    });

    this.getDropdownData();
    // setTimeout(()=>{
    // },500);
    //this.setTotalValueForPortFolioNstrategy();
    let wait = setInterval(() => {
      if ($('.strategy-total')[0].clientHeight) {
        clearInterval(wait)
        this.adjustHeightGridViewport(0);
      }
    })
  }

  openDeleteTemplateConfirmation(){
    if(!this.selectedTemplate){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select template';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }else{
      this.ngxSmartModalService.open("deleteTemplateConfirmationModal");
    }
  }

  deleteTemplateAfterConfirmation(){
    this.strategySelectionService.deleteStrategyTemplate(this.selectedTemplate).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
          this.selectedTemplate = null;
          this.getInvestmentTemplate(this.selectedTemplate);
      }
    });
  }

  saveInvestmentTemplate(){
    let duplicateInvestment = false;
    if(!this.investmentTemplateVO["name"]){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let investmentList = [];
    let totalPercentage = 0;

    this.strategyDropdownMenuList["assetClassDataForDropdown"].forEach(element => {
      if(element.isSelected){
        if(!element.percentage)
          element.percentage = 0;
        investmentList.push({
          name : element.name,
          percentage:element.percentage,
          assetId : element.objectId
        })
        totalPercentage += parseFloat(element.percentage);
      }

    });
    if(totalPercentage>100){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Total percentage cannot be greater than 100';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    

     let requestData ={
      name : this.investmentTemplateVO["name"],
      objectId : null,
      strategyTemplateData : investmentList

     }
     if(this.templatePopupMode == 'edit')
      requestData.objectId = this.investmentTemplateVO["objectId"];
    
    this.strategySelectionService.saveStrategyTemplate(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('investmentTemplatePopup').close();
          this.getInvestmentTemplate(this.selectedTemplate);
      }
    });
  }

  openTemplateModal(mode){
    
    if(mode == 'edit'){
      if(!this.selectedTemplate){
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select template';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      this.investmentTemplateVO = _.find(this.investmentTemplateList, { 'objectId': this.selectedTemplate });
      //this.templateName = this.investmentTemplateVO["name"];
    }else{
      this.investmentTemplateVO = {};
    }
    this.templatePopupMode = mode;
    this.strategyDropdownMenuList["assetClassDataForDropdown"].forEach(element => {
      element.percentage = 0;
      element.isSelected = false;
      if(mode == 'edit'){
        if(this.investmentTemplateVO['strategyTemplateData']){
          let exists = _.find(this.investmentTemplateVO['strategyTemplateData'], { 'assetId': element.objectId });_
          if(exists){
            element.isSelected = true;
            element.percentage = exists.percentage;
          }
        }
      }
      if(mode == 'clone'){
        if(this.showSelectedStrategyData){
          let exists = _.find(this.showSelectedStrategyData, { 'assetClassId': element.objectId });_
          if(exists && !exists.isHeader){
            element.isSelected = true;
            element.assetId = exists.assetClassId
            if(exists.proposedAmountPercentage)
             element.percentage = (parseFloat(exists.proposedAmountPercentage)).toFixed(2);
          }
        }
      }
      
    });
    this.ngxSmartModalService.open("investmentTemplatePopup");

  }

  onChangeTemplate(){
    this.getSelectedStrategy(null);
  }
  getInvestmentTemplate(template){
    this.selectedTemplate = template;

    this.strategySelectionService.getStrategyTemplate().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          console.log(response);
          this.investmentTemplateList = response["responseData"];
          if(this.selectedTemplate) 
          this.getSelectedStrategy(null);
           
      }
    });
  }

  char_count(str, letter) {
    var letter_Count = 0;
    for (var position = 0; position < str.length; position++) {
      if (str.charAt(position) == letter) {
        letter_Count += 1;
      }
    }
    return letter_Count;
  }


  handleKeyUp(evt, value) {

    setTimeout(() => {
      var sumForValidation = 0;
      for (let item of this.showSelectedStrategyData) {
        if (!(item.isHeader)) {
          //     item.proposedAmountValue=item.proposedAmountValue.replace(/,/g, "").replace('$', "");
          if (item.proposedAmountValueStr.includes('$')) {
            item.proposedAmountValue = item.proposedAmountValueStr.replace(/,/g, "").replace('$', "");
          } else {
            item.proposedAmountValueStr = this.convertToCommaSeperated(item.proposedAmountValueStr);
            item.proposedAmountValue = item.proposedAmountValueStr.replace(/,/g, "").replace('$', "");
          }
          sumForValidation += Number(item.proposedAmountValue);
        }
      }
      if (sumForValidation > this.totalCurrentAmount) {
        //evt.preventDeafault();
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please remove the currently entered amount because  total proposed amount is greater than total current amount ';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);

      }
      this.onChangeInProposedAmount();

    }, 100);
    // let c1=this.char_count(value,'$') ;
    // let c2=this.char_count(value,',')
    // let count$n = c1+c2;
    let value1 = value.replace(/,/g, "").replace('$', "");

    this.commonFunction.allowPositiveDecimalNumbers(evt, value1);
  }
  convertUnFormattedValueToFormatted(){
    for(let item of this.showSelectedStrategyData){
      if(!item.isHeader){
        if (item.proposedAmountPercentageStr.includes('%')) {
                 
        }else{
          item.proposedAmountPercentageStr=this.convertToPercSeperated(item.proposedAmountPercentageStr);
        }
      }
    }
  }

  handleKeyUpPerc(evt, value) {
    //this.onChangeInProposedPerc();
    setTimeout(() => {
      var sumForValidation = 0;
      for (let item of this.showSelectedStrategyData) {
        if (!(item.isHeader)) {
          if (item.proposedAmountPercentageStr.includes('%')) {
            item.proposedAmountPercentage = item.proposedAmountPercentageStr.replace('%', "");
          } else {
            // item.proposedAmountPercentageStr = this.convertToPercSeperated(item.proposedAmountPercentageStr)
            // item.proposedAmountPercentage = item.proposedAmountPercentageStr.replace('%', "");
          }
          item.proposedAmountPercentage = item.proposedAmountPercentageStr.replace('%', "");
          sumForValidation += Number(item.proposedAmountPercentage);
        }
      }
      // if (sumForValidation > this.totalCurrentPerc) {
      //   let errorObj = {};
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please update the currently entered percentage because due to the entered percentage total proposed percentage is becoming greater than total current percentage';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      // }
      this.onChangeInProposedPerc();

    }
      , 100);
    let value1 = value.replace('%', "");
    this.commonFunction.percentageValidator(evt, value1);

  }

  onChangeInProposedAmount() {
    for (let item of this.showSelectedStrategyData) {
      if (!(item.isHeader)) {

        item.proposedAmountPercentage = ((item.proposedAmountValue / this.totalCurrentAmount) * 100).toFixed(2);
        if(item.proposedAmountPercentage=='0.00'){
          item.proposedAmountPercentageStr=item.proposedAmountPercentage;
        }else{
        item.proposedAmountPercentageStr = this.convertToPercSeperated(item.proposedAmountPercentage);
        }     
      }

    }

    this.onChangeInProposedAmountAndPerc();
  }

  onChangeInProposedPerc() {
    for (let item of this.showSelectedStrategyData) {
      if (!(item.isHeader)) {
        if (item.proposedAmountPercentageStr == "") {
          item.proposedAmountValue = '0.00';
          item.proposedAmountValueStr = this.convertToCommaSeperated(item.proposedAmountValue);
          // item.proposedAmountPercentageStr='0.00%';

          //item.proposedAmountPercentage='0';
        } else {
          item.proposedAmountValue = ((item.proposedAmountPercentage / 100) * this.totalCurrentAmount).toFixed(2);
          item.proposedAmountValueStr = this.convertToCommaSeperated(item.proposedAmountValue);

        }
      }
      this.onChangeInProposedAmountAndPerc();
    }

  }

  onChangeInProposedAmountAndPerc() {

    var sumOfTotalProposedAmountValueHeader = 0;
    var sumOfTotalProposedAmountPercentageHeader = 0;
    var sumOfTotalDifferenceAmountValueHeader = 0;
    var sumOfTotalDifferenceAmountPercentageHeader = 0;
    for (let item of this.showSelectedStrategyData) {
      var parentId = item.assetClassId;
      if (item.isHeader) {
        var sumOfProposedAmountValueHeader = 0;
        var sumOfProposedAmountPercentageHeader = 0;
        for (let childItem of this.showSelectedStrategyData) {

          if (childItem.isParentId == parentId) {
            sumOfProposedAmountValueHeader += Number(childItem.proposedAmountValue);
            sumOfProposedAmountPercentageHeader += Number(childItem.proposedAmountPercentage);

          }
        }
        item.proposedAmountValue = (sumOfProposedAmountValueHeader).toFixed(2);
        item.proposedAmountValueStr = this.convertToCommaSeperated(item.proposedAmountValue)
        item.proposedAmountPercentage = (sumOfProposedAmountPercentageHeader).toFixed(2);
        item.proposedAmountPercentageStr = this.convertToPercSeperated(item.proposedAmountPercentage);
        item.differenceAmountValue = (Number(item.proposedAmountValue) - Number(item.currentAmountValue)).toFixed(2);
        item.differenceAmountPercentage = (Number(item.proposedAmountPercentage) - Number(item.currentAmountPercentage)).toFixed(2);
      } else {
        item.differenceAmountValue = (Number(item.proposedAmountValue) - Number(item.currentAmountValue)).toFixed(2);
        item.differenceAmountPercentage = (Number(item.proposedAmountPercentage) - Number(item.currentAmountPercentage)).toFixed(2);
      }

      if (item.isHeader) {
        sumOfTotalProposedAmountValueHeader += Number(item.proposedAmountValue);
        sumOfTotalProposedAmountPercentageHeader += Number(item.proposedAmountPercentage);
        sumOfTotalDifferenceAmountValueHeader += Number(item.differenceAmountValue);
        sumOfTotalDifferenceAmountPercentageHeader += Number(item.differenceAmountPercentage);
      }
    }
    this.totalProposedAmount = Number(sumOfTotalProposedAmountValueHeader).toFixed(2);
    this.totalProposedPerc = Number(sumOfTotalProposedAmountPercentageHeader).toFixed(2);
    this.totalDifferenceAmount = Number(sumOfTotalDifferenceAmountValueHeader).toFixed(2);
    this.totalDifferencePerc = Number(sumOfTotalDifferenceAmountPercentageHeader).toFixed(2);

  }



  analysis() {
    this.analyticsData.selectedStrategy = this.strategyInfo['proposed']['shortName'] || '';
    this.ngxSmartModalService.open("portfolioAnalytics");
  }
  getDropdownData() {
    this.apiParams = {
      processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      stepName: "proposalSetupStrategySelection"
    };
    this.workflowBaseService.getEnumDataBasedOnStep(this.apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.strategyDropdownMenuList = response['responseData'];
        this.strategyDropdownMenuList['assetClassDataForDropdown'] = [];
        if (this.strategyDropdownMenuList.assetClass) {
          this.strategyDropdownMenuList.assetClass.forEach(element => {
            element.isHeader = true;
            element.displayName = element.name;
            this.strategyDropdownMenuList['assetClassDataForDropdown'].push(element);
            if (element.assetSubClassVO) {
              element.assetSubClassVO.forEach(item => {
                item.isHeader = false;
                item.isParentId = element.objectId;
                item.displayName = '- - ' + item.name;
                this.strategyDropdownMenuList['assetClassDataForDropdown'].push(item);
              });
            }
          });
        }
        this.portfolioDataList = [];
        this.portfolioList = this.strategyDropdownMenuList['portfolioIdVsStrategies'];
        for (let key in this.portfolioList) {
          this.portfolioDataList.push(this.portfolioList[key]);

        }
        if (this.portfolioDataList.length) {
          this.strategyInfo.portfolio = this.portfolioDataList[0].portfolioOverviewVO.portfolioId;
          this.portfolioStrategy('form');
          this.getInvestmentTemplate(null);
        }
      }

    });
    //this.setTotalValueForPortFolioNstrategy();      


  }

  addAssetClass() {
    let assetClassAdd = this.strategyInfo.assetClass;
    //console.log(assetClassAdd);
    if(!assetClassAdd){

      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please Select Asset class';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;

    }
    assetClassAdd['assetClassId'] = assetClassAdd['objectId']//because that we are adding asset class it not contain assetClassId but it has objectId
    let checkAssetClassAdded = 1;
    for (let item of this.showSelectedStrategyData) {
      if (item.assetClassId == assetClassAdd['assetClassId']) {
        checkAssetClassAdded = 0;// this flag for checking existence of asset class which we are adding
      }
    }
    if (checkAssetClassAdded) {
      if (assetClassAdd) {
        this.convertAgainModifiedListIntoOriginalForm();
        this.showSelectedStrategyData = [];
        let checkFlag = 1;
        //console.log(this.saveStrategyUpdateData);
        for (let item of this.saveStrategyUpdateData) {
          if (item.assetClassId == assetClassAdd['isParentId']) {
            assetClassAdd['currentAmountValue'] = 0;
            assetClassAdd['currentAmountPercentage'] = 0;
            assetClassAdd['proposedAmountValue'] = 0;
            assetClassAdd['proposedAmountPercentage'] = 0;
            assetClassAdd['proposedAmountValueStr'] = 0;
            assetClassAdd['proposedAmountPercentageStr'] = 0;
            assetClassAdd['differenceAmountValue'] = 0;
            assetClassAdd['differenceAmountPercentage'] = 0;
            assetClassAdd['assetClassName'] = assetClassAdd['shortName'];
            item.strategySelectionInfoVOChild.push(assetClassAdd);
            checkFlag = 0;
            break;
          }
        }//if parent of the subchild does not exist in the current strategy
        if (checkFlag) {
          for (let item of this.strategyDropdownMenuList.assetClassDataForDropdown) {
            if ((item.objectId == assetClassAdd['isParentId']) && item.isHeader) {
              item['currentAmountValue'] = 0;
              item['currentAmountPercentage'] = 0;
              item['proposedAmountValue'] = 0;
              item['proposedAmountPercentage'] = 0;
              item['proposedAmountValueStr'] = 0;
              item['proposedAmountPercentageStr'] = 0;
              item['differenceAmountValue'] = 0;
              item['differenceAmountPercentage'] = 0;
              item['assetClassName'] = item['shortName'];
              item['assetClassId'] = item['objectId'];
              item['strategySelectionInfoVOChild'] = [];
              assetClassAdd['currentAmountValue'] = 0;
              assetClassAdd['currentAmountPercentage'] = 0;
              assetClassAdd['proposedAmountValue'] = 0;
              assetClassAdd['proposedAmountPercentage'] = 0;
              assetClassAdd['proposedAmountValueStr'] = 0;
              assetClassAdd['proposedAmountPercentageStr'] = 0;
              assetClassAdd['differenceAmountValue'] = 0;
              assetClassAdd['differenceAmountPercentage'] = 0;
              assetClassAdd['assetClassName'] = assetClassAdd['shortName'];
              item['strategySelectionInfoVOChild'].push(assetClassAdd);
              this.saveStrategyUpdateData.push(item);
              //console.log(this.saveStrategyUpdateData)
              //this.saveStrategyUpdateData.item.push(Obj);
              //console.log(this.saveStrategyUpdateData);


            }
          }
        }
        let successObj = {};
        successObj['message'] = 'Asset Class Added';
        this.authMessageService.showSuccessPopup(successObj);
      } else {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select any asset class for adding to the asset Class';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);

      }
      console.log(this.saveStrategyUpdateData);

      if (this.saveStrategyUpdateData) {
        this.saveStrategyUpdateData.forEach(element => {
          //element.isHeader = true;
          this.showSelectedStrategyData.push(element);
          if (element.strategySelectionInfoVOChild) {
            element.strategySelectionInfoVOChild.forEach(item => {
              // item.isHeader = false;
              item.isSelected = false;
              item.isParentId = element.assetClassId;
              this.showSelectedStrategyData.push(item);
            });
          }
        });
      }
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = "You can not add same asset class more than one times";
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }


  portfolioStrategy(isFrom) {
    let selectedPortfolio = this.strategyInfo.portfolio;

    for (let key in this.portfolioList) {
      if (key == selectedPortfolio) {
        this.selectedStrategyOfPortfolio = this.portfolioList[key].passedStrategies;
        if(this.portfolioList[key].failedStrategies){
          this.portfolioList[key].failedStrategies.forEach(element => {
            element.isFailed = true;
            this.selectedStrategyOfPortfolio.push(element);
          });
        }
       // this.strategyInfo.proposed = this.selectedStrategyOfPortfolio[0];
        //this.getSelectedStrategy("form");
        this.getSelectedStrategy(null);
        this.totalCurrentAmount = this.portfolioList[key].portfolioOverviewVO.investableAssetsStr;
        this.totalCurrentPerc = 100;
        break;
      } else {

        this.selectedStrategyOfPortfolio = [];
        this.showSelectedStrategyData = [];
        // this.setTotalValueForPortFolioNstrategy();
        this.totalCurrentAmount = 0;
        this.totalCurrentPerc = 0;
        this.totalDifferenceAmount = 0;
        this.totalDifferencePerc = 0;
        this.totalProposedAmount = 0;
        this.totalProposedPerc = 0;
      }
    }
    if (this.selectedStrategyOfPortfolio.length) {
      //this.strategyInfo.proposed = _.find(this.selectedStrategyOfPortfolio, { 'objectId': this.savedPofolioStrategyMap[selectedPortfolio].proposedAllocation.objectId });
      //this.getSelectedStrategy('form');
    } else {
      // this.strategyInfo.portfolio=null;
      // this.strategyInfo.proposed['objectId']=null;
      //this.setTotalValueForPortFolioNstrategy();
      this.strategyCharacteristicInfo = new StrategyCharacteristicModel();
    }



  }


  openStrategyChangeConfirmation(){
    if(!this.strategyInfo.proposed || this.strategyInfo.proposed=="null"){
    
      setTimeout(() => {
        this.revertStrategy();
      }, 500);
      
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select proposed strategy for this portfolio';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);

    }else{
      if(this.lastSelectedStrategy )
     this.ngxSmartModalService.open("changeConfirmationModal");
     else{
      this.lastSelectedStrategy = this.strategyInfo.proposed;
      this.getSelectedStrategy(null);
     }
    }
    
     

  }

  revertStrategy(){
    this.strategyInfo.proposed = this.lastSelectedStrategy;
  }

  changeAfterConfirmation(){
    this.lastSelectedStrategy = this.strategyInfo.proposed;
    this.getSelectedStrategy(null);
    this.ngxSmartModalService.close("changeConfirmationModal");
  }



  getSelectedStrategy(isFrom) {
    let proposedStrategy = this.strategyInfo.proposed;
    
   // if (proposedStrategy == "null") {
     if(false){
      this.showSelectedStrategyData = [];
      this.totalProposedAmount = 0;
      this.totalProposedPerc = 0;
      this.totalDifferenceAmount = 0;
      this.totalDifferencePerc = 0;
      this.strategyCharacteristicInfo = new StrategyCharacteristicModel();
    } else {
     
      this.apiParams = {
        processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
        portfolioId: this.strategyInfo.portfolio,
        strategyTemplateId : this.selectedTemplate

      };
      if(!proposedStrategy)
        proposedStrategy ={};
      this.strategySelectionService.getStrategyDataBasedOnPortfolio(proposedStrategy, this.apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.selectedStrategyData = response['responseData']['strategySelectionInfoVOChild'];
          
          if(response['responseData']['strategyId'])
           this.strategyInfo.proposed = _.find(this.selectedStrategyOfPortfolio, { 'objectId': response['responseData']['strategyId'] });
          else
          this.strategyInfo.proposed = null ;

          this.lastSelectedStrategy = this.strategyInfo.proposed;

          this.convertIntoList();
          if(!isFrom){
            this.savePortfolioStrategy();
          }
        }
      });

    }
  }

  reset() {
    this.getSelectedStrategy('form');
  }

  revert() {
    let selectedPortfolio = this.strategyInfo.portfolio
    this.apiParams = {
      processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      portfolioId: this.strategyInfo.portfolio
    };
    this.strategySelectionService.getDefaultDataBasedOnSelectedPortfolio(this.apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        // this.selectedStrategyData = response['responseData'];
        // this.portfolioDataList = [];
        // this.portfolioList = this.selectedStrategyData['portfolioIdVsStrategies'];
        // for (let key in this.portfolioList) {
        //   this.portfolioDataList.push(this.portfolioList[key]);

        // }

        // for (let key in this.portfolioList) {
        //   if (key == selectedPortfolio) {
        //     this.selectedStrategyOfPortfolio = this.portfolioList[key].passedStrategies;
        //     break;
        //   } else {
        //     this.selectedStrategyOfPortfolio = [];
        //     this.showSelectedStrategyData = [];
        //     this.totalCurrentAmount = 0;
        //     this.totalCurrentPerc = 0;
        //     this.totalDifferenceAmount = 0;
        //     this.totalDifferencePerc = 0;
        //     this.totalProposedAmount = 0;
        //     this.totalProposedPerc = 0;
        //   }
        // }
        this.getSelectedStrategy('form');
      }

    });

  }



  convertIntoList() {
    this.showSelectedStrategyData = [];

    if (this.selectedStrategyData) {
      this.selectedStrategyData.forEach(element => {
        element.isHeader = true;
        element.currentAmountValue = Number(element.currentAmountValue).toFixed(2);
        element.currentAmountPercentage = Number(element.currentAmountPercentage).toFixed(2);
        element.differenceAmountValue = Number(element.differenceAmountValue);
        element.differenceAmountPercentage = Number(element.differenceAmountPercentage).toFixed(2);
        element.proposedAmountValue = Number(element.proposedAmountValue);
        element.proposedAmountPercentage = Number(element.proposedAmountPercentage);
        this.showSelectedStrategyData.push(element);
        if (element.strategySelectionInfoVOChild) {
          element.strategySelectionInfoVOChild.forEach(item => {
            item.currentAmountValue = Number(item.currentAmountValue).toFixed(2);
            item.currentAmountPercentage = Number(item.currentAmountPercentage).toFixed(2);
            item.differenceAmountValue = Number(item.differenceAmountValue);
            item.differenceAmountPercentage = Number(item.differenceAmountPercentage).toFixed(2);
            item.proposedAmountValue = Number(item.proposedAmountValue);
            item.proposedAmountPercentage = Number(item.proposedAmountPercentage);
            item.isHeader = false;
            item.isSelected = false;
            item.isParentId = element.assetClassId;
            this.showSelectedStrategyData.push(item);
          });
        }
      });
    }
    this.totalCurrentAmount = 0;
    this.totalCurrentPerc = 0;
    this.totalDifferenceAmount = 0;
    this.totalDifferencePerc = 0;
    this.totalProposedAmount = 0;
    this.totalProposedPerc = 0;
    for (let item of this.showSelectedStrategyData) {
      if (item.isHeader) {
        this.totalCurrentAmount += Number(item.currentAmountValue);
        this.totalCurrentPerc += Number(item.currentAmountPercentage);
        this.totalProposedAmount += Number(item.proposedAmountValue);
        this.totalProposedPerc += Number(item.proposedAmountPercentage);
        this.totalDifferenceAmount += Number(item.differenceAmountValue);
        this.totalDifferencePerc += Number(item.differenceAmountPercentage);
      }
    }
    //console.log(this.showSelectedStrategyData);
  }

  removeRows() {
    this.convertAgainModifiedListIntoOriginalForm();
    this.showSelectedStrategyData = [];
    var flagForValidation = 1;
    var flagForValidation2 = 0;
    this.saveStrategyUpdateData.forEach(element => {
      element.strategySelectionInfoVOChild.forEach(item => {
        if (item.isSelected) {
          if (item.currentAmountValue == 0) {
            element.strategySelectionInfoVOChild.splice(element.strategySelectionInfoVOChild.indexOf(item), 1);
            flagForValidation = 0;
          } else {
            flagForValidation2 = 1;
          }
        }
      });
    });
    //console.log(this.saveStrategyUpdateData);
    if (flagForValidation == 1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any row(s) for removing';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (flagForValidation == 0) {
      let successObj = {};
      successObj['message'] = 'Asset Class Removed';
      this.authMessageService.showSuccessPopup(successObj);
    }
    if (flagForValidation2) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any row(s) for removing which do not have current ammount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    if (this.saveStrategyUpdateData) {
      this.saveStrategyUpdateData.forEach(element => {
        element.isHeader = true;
        this.showSelectedStrategyData.push(element);
        if (element.strategySelectionInfoVOChild) {
          element.strategySelectionInfoVOChild.forEach(item => {
            item.isHeader = false;
            item.isSelected = false;
            item.isParentId = element.assetClassId;
            this.showSelectedStrategyData.push(item);
          });
        }
      });
    }
    
    this.onChangeInProposedAmount();

    //for auto save
    if (flagForValidation == 0)
      this.savePortfolioStrategy();
    /* if (this.tempArr.length > 0) {
       this.ngxSmartModalService.open('deleteModal');
     } else {
       let errorObj = {};
       errorObj['errorCode'] = 'ERR0107';
       errorObj['message'] = 'Please select any row(s) for deleting';
       errorObj['errorDescription'] = '';
       this.authMessageService.showErrorPopup(errorObj);
     }*/
  }

  convertAgainModifiedListIntoOriginalForm() {
    this.saveStrategyUpdateData = [];
    for (let item of this.showSelectedStrategyData) {
      let parentObject = {};
      let Id;
      if (item.isHeader) {
        Id = item.assetClassId;
        parentObject = item;
        parentObject['strategySelectionInfoVOChild'] = [];
        for (let item1 of this.showSelectedStrategyData) {
          let childObject = {};
          if (item1.isParentId == Id) {
            childObject = item1;
            parentObject['strategySelectionInfoVOChild'].push(childObject);
          }
        }
        this.saveStrategyUpdateData.push(parentObject);
      }
    }
  }

  savePortfolioStrategy() {
    this.saveStrategyUpdateData = [];
    this.convertAgainModifiedListIntoOriginalForm();
    //console.log(this.saveStrategyUpdateData);
    if (parseFloat(this.totalProposedPerc) > 100.001) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Total proposed pecentage should not be greater than 100%';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      let objectBody = {};
      objectBody['processInstanceId'] = this.maxCalculatorMainComponent.workflowInfoData.processInstanceId;
      objectBody['portfolioId'] = this.strategyInfo.portfolio;
      objectBody['strategyId'] = this.strategyInfo.proposed['objectId'];
      objectBody['proposedAllocationVO'] = this.saveStrategyUpdateData;
      this.strategySelectionService.saveUpdatedStrategyInfo(objectBody).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          if(this.strategyInfo.proposed && this.strategyInfo.proposed != "null")
            this.setTotalValueForPortFolioNstrategy();//to get total Row data for particular portfolio and strategy
        }

      });
    }

  }

  adjustHeightGridViewport(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let analyse = $('.strategy-analytics')[0].clientHeight;
    let totalRow = $('.strategy-total')[0].clientHeight;
    let analyseH = $('.strategy-analytics')[0].clientHeight;

    // let chart =  $('.chart-container')[0].clientHeight;
    let margin = 110 + extraMargin;
    var total = header + pageheader + margin + stepHeader + analyse + totalRow + analyseH - 100;
    var ele = document.getElementsByTagName('tbody')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(80vh - " + total  + "px);")
    }
  }

  setTotalValueForPortFolioNstrategy() {
    let requestData = {
      processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      taskDefinitionKey: this.analyticsData.parentComponent,
      portfolioId: this.strategyInfo.portfolio,
      analysisType: "analysisPrimaryInfo",
      firstModelId: this.strategyInfo.proposed['objectId'],
      secondModelId: null,
      section : "strategySelection"
    }

    this.analysisService.getanalysisinfo(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.characteristicData = response['responseData'];
        this.characteristicData.forEach(element => {
          let element1 = element.assetClassName;
          switch (element1) {
            case "Expected Return":
              this.strategyCharacteristicInfo.expectedReturn = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentexpectedReturn = element.currentAllocation;
              break;
            case "After tax Expected Return":
              this.strategyCharacteristicInfo.afterTaxExpectedReturn = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentafterTaxExpectedReturn = element.currentAllocation;
              break;
            case "Standard Deviation":
              this.strategyCharacteristicInfo.standardDeviation = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentstandardDeviation = element.currentAllocation;
              break;
            case "After tax Yield":
              this.strategyCharacteristicInfo.afterTaxYield = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentafterTaxYield = element.currentAllocation;
              break;
            case "After tax Standard Deviation":
              this.strategyCharacteristicInfo.afterTaxStandardDeviation = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentafterTaxStandardDeviation = element.currentAllocation;
              break;
            case "Sharpe Ratio":
              this.strategyCharacteristicInfo.sharpRatio = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentsharpRatio = element.currentAllocation;
              break;
            case "After tax Sharpe Ratio":
              this.strategyCharacteristicInfo.afterTaxSharpRatio = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentafterTaxSharpRatio = element.currentAllocation;
              break;
            case "Yield":
              this.strategyCharacteristicInfo.yield = element.proposedAllocation;
              this.strategyCharacteristicInfo.currentyield = element.currentAllocation;
              break;
            default:
              break;
          }
        });
      }
    });
  }

  convertToCommaSeperated(row) {
    let value = row
    var partBeforeDecimal = '', partAfterDecimal = '';
    var index = value.indexOf('.')
    if (value == '$' || value == "") {
      row = '$0';
    } else {
      if (index > -1) {
        partBeforeDecimal = value.substr(0, index);
        partAfterDecimal = value.substr(index, value.length - index);
      } else {
        partBeforeDecimal = value;
      }

      if (partBeforeDecimal) {
        partBeforeDecimal = partBeforeDecimal.replace(/,/g, "").replace('$', "");
        row = '$' + (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
      }
    }
    return row;
  }

  convertToPercSeperated(rowData) {
    let value = rowData;
    if (value) {
      rowData = value + '%';
    }
    return rowData;
  }






}

