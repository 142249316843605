import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './webPages/login/login.component';
import { WelcomeComponent } from './webPages/welcome/welcome.component';
import { DashboardComponent } from './webPages/dashboard/dashboard.component';
import { SettingComponent } from './webPages/setting/setting.component';
import { ResetPasswordComponent } from './webPages/reset-password/reset-password.component';
import { PresentationComponent } from './webPages/presentation/presentation.component';
import { CatalogComponent } from './webPages/catalog/catalog.component';
import { DataLoadConfigurationComponent } from './webPages/data-load-configuration/data-load-configuration.component';
import { DocumentCenterComponent } from './webPages/document-center/document-center.component';
import { RelationshipsComponent } from './webPages/relationships/relationships.component';
import { UserManagementComponent } from './webPages/user-management/user-management.component';
import { AddEditRelationshipComponent } from './webPages/add-edit-relationship/add-edit-relationship.component';
import { ProposalWorkflowComponent } from './webPages/proposal-workflow/proposal-workflow.component';
import { RelationshipSelectionComponent } from './webPages/proposal-workflow/relationship-selection/relationship-selection.component';
import { RelationshipOverviewComponent } from './webPages/proposal-workflow/relationship-overview/relationship-overview.component';
import { ProductInfoComponent } from './webPages/catalog/product-info/product-info.component';
import { PortfolioOverviewComponent } from './webPages/proposal-workflow/portfolio-overview/portfolio-overview.component';
import { CashFlowObjectiveComponent } from './webPages/proposal-workflow/cash-flow-objective/cash-flow-objective.component';
import { StrategySelectionComponent } from './webPages/proposal-workflow/strategy-selection/strategy-selection.component';
import { PortfolioConstructionComponent } from './webPages/proposal-workflow/portfolio-construction/portfolio-construction.component';
import { SlideSelectionComponent } from './webPages/proposal-workflow/slide-selection/slide-selection.component';
import { CreatePresentationComponent } from './webPages/proposal-workflow/create-presentation/create-presentation.component';
import { PdfChartComponent } from './webPages/pdf-chart/pdf-chart.component';
import { MaxCalculatorMainComponent } from './webPages/max-calculator/max-calculator-main/max-calculator-main.component';
import { ProposalSetupComponent } from './webPages/max-calculator/proposal-setup/proposal-setup.component';
import { CalPortfolioConstructionComponent } from './webPages/max-calculator/cal-portfolio-construction/cal-portfolio-construction.component';
import { CalSlideSelectionComponent } from './webPages/max-calculator/cal-slide-selection/cal-slide-selection.component';
import { CalCreatePresentationComponent } from './webPages/max-calculator/cal-create-presentation/cal-create-presentation.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'welcomePage', component: WelcomeComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'setting', component: SettingComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'pdfChart' , component: PdfChartComponent},
  { path: 'presentation', component: PresentationComponent },
  { path: 'relationships', component: RelationshipsComponent },
  { path: 'catalog', component: CatalogComponent },
  { path: 'dataLoadConfiguration', component: DataLoadConfigurationComponent },
  { path: 'documentCenter', component: DocumentCenterComponent },
  { path: 'userManagement', component: UserManagementComponent },
  { path: 'add-edit-relationship', component: AddEditRelationshipComponent },
  { path: 'proposalWorkflow', component: ProposalWorkflowComponent,
    children :[
        { path: 'relationshipSelection', component: RelationshipSelectionComponent},
        { path: 'relationshipOverview', component: RelationshipOverviewComponent },
        { path: 'portfolioOverview', component: PortfolioOverviewComponent },
        { path: 'cashFlowObjectives', component: CashFlowObjectiveComponent },
        { path: 'strategySelection', component: StrategySelectionComponent },
        { path: 'portfolioConstruction', component: PortfolioConstructionComponent },
        { path: 'slideSelection', component: SlideSelectionComponent },
        { path: 'createPresentation', component: CreatePresentationComponent }
    ]
  },
  { path: 'maxCalculator', component: MaxCalculatorMainComponent,
    children :[
      { path: 'proposalSetup', component: ProposalSetupComponent},
      { path: 'portfolioConstruction', component: CalPortfolioConstructionComponent},
      { path: 'slideSelection', component: CalSlideSelectionComponent},
      { path: 'createPresentation', component: CalCreatePresentationComponent},
    ]
  },
  { path: 'productInfo/:id', component: ProductInfoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
