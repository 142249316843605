export class ResponseVO{
    errorCode            : string;
    errorDescription     : string;
    repsonseType         : string;
    message              : string;
    responseData         : object;
    messageList          :any;
}
  
export class ErrorModel{
    errorCode            : string = 'ERR0107';
    message              : string;
    errorDescription     : string;
    repsonseType         : string = 'ERROR';
}