import { MoneyModel } from "./money.model";

export class RelationshipDropdownData{
    relationType: any;
    ownerType: any;
    accountType: any;
    accountSubType: any;
    taxStatus:any;
    investmentObjective: any;
    accountStatus:any;
}

export class Relationship{
    relationshipId:string;
    clientId:string;
    name:string;
    shortName:string;
    clientType:string = null;
    legalEntitytType = null;
    accountType:string = null;  
    accountSubType:string =null;
    taxStatus:string=null;
    investmentObjective:string=null;
    accountStatus:string=null;
    isProspect:boolean = true;
    description:string;
    inceptionDate:any = new Date;
    note:string;
    currency:string = "USD";
    dataStoreId:Number;
    relationshipDataStoreId: Number;
}

export class Client {
    ownershipId:string;
    name:string;
    shortName:string;
    legalEntitytType:string = null;
    description:string;
    inceptionDate:any = new Date;
    dateOfBirth:any = null;
    note:string;
    currency:string = "USD";
    taxId:string;
    //isOpportunity:boolean = true;
    isPrimary:string=null;
    ownershipDataStoreId: Number;
    dataStoreId:Number;
    clientDataStoreId:Number;
    restrictValidationOnClientDate:boolean=true;
}

export class Account {
  accountId:string;
  name:string;
  shortName:string;
  accountType:string = null;
  accountSubType:string = null;
  isAccountOnBook:boolean = false;
  taxStatus:string = null;
  isQualified:string =null;
  description:string;
  accountSubTypeDataStoreId:number=null;
  inceptionDate:any = new Date;
  showOwnerDateOfBirth:any;
  investmentObjectiveDataStoreId:number=null;
  note:string;
  currency:string = 'USD';
  investmentObjective: string =null;
  carvedOutPercentage: number=0;
  dataStoreId: number;
  ownershipDataStoreId:string=null;
  accountTypeDataStoreId:number=null;  
  portfolioId:string; 
  dateOfBirth:any=null;
  totalMarketValueInMoney = new MoneyModel(); 
}

export class ListData {
    label: string;
    type: string;
    dataStoreId: number;
    entireData: any;
    children: any[];
    carvedOutPercentage:number;
  }
