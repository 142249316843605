import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { ApiUrls } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'subDomain'   : localStorage.getItem('subDomain'),
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };

constructor(private http: HttpClient) { }


addUser(request){
  return this.http.post(ApiUrls.createUserAPI, request , this.httpOptions )
  .pipe(
      response => response
  );
}

editUser(request){
  return this.http.put(ApiUrls.updateUserAPI, request , this.httpOptions )
  .pipe(
      response => response
  );
}

getAllRoles(){
  return this.http.get(ApiUrls.getAllRolesAPI , this.httpOptions )
  .pipe(
      response => response
  );
}

userVarificationMail(userId){
  return this.http.get(ApiUrls.userVarificationMailAPI +"?userId="+userId, this.httpOptions )
  .pipe(
      response => response
  );
}

generateTempPassword(){
  return this.http.get(ApiUrls.tempPasswordAPI , this.httpOptions )
  .pipe(
      response => response
  );
}

deleteUser(filterInfoVO){
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('token') || ''
    }),
    body: filterInfoVO
  };
  return this.http.delete(ApiUrls.deleteUserAPI, options)
  .pipe(
      response => response
  );
}






}
