import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TransfereService } from '../../_services/transferService.service';
import { Relationship, Client, Account, ListData, RelationshipDropdownData } from '../../_models/relationship.model';
import { ResponseVO, ErrorModel } from '../../_models/response.model';
import { RelationshipsService } from '../../_services/relationships.service';
import { Variables } from '../../constants';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AuthMessageService } from '../../_services/auth-message.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FilterModel } from 'src/app/_models/filter.model';
import { SearchSort } from './../../_models/search-sort.model';
import * as _ from "lodash";
import * as $ from 'jquery';
import { BaseService } from '../../_services/base.service';
import { CommonFunctions } from '../../common';


@Component({
  selector: 'app-add-edit-relationship',
  templateUrl: './add-edit-relationship.component.html',
  styleUrls: ['./add-edit-relationship.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class AddEditRelationshipComponent implements OnInit {
  relationship = new Relationship();
  client = new Client();
  userId;
  account = new Account();
  relationShipDropdowns = new RelationshipDropdownData();
  public filterVO = new FilterModel();
  showMarketValueInputBox: boolean = false;
  showOwnerDateOfBirth: boolean = false;
  commonFunction = new CommonFunctions();
  restrictValidationOnClientDate: boolean = true;
  currentSelectedRelationship: any;
  constructor(
    private router: Router,
    private transferService: TransfereService,
    private relationshipsService: RelationshipsService,
    private datePipe: DatePipe,
    private authMessageService: AuthMessageService,
    private baseService: BaseService
  ) { }
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  passedFrom: string;
  listData = new ListData();
  files: any[];

  entityTypes = Variables.entityTypes;
  inceptionDateRelationship: Date;
  inceptionDateClient: Date;
  inceptionDateAccount: Date;
  selectedType: string = "Relationship"
  rowData: any[];
  currentFormType = this.entityTypes.relationship;
  selectedFiles: any[];
  editingRelationId: number;
  showBackButton: boolean = false;
  private isEditType: boolean = false
  private clientVO = {
    event: {},
    data: [],
    type: ''
  }
  private accountVO = {
    event: {},
    data: [],
    type: ''
  }
  ngOnInit() {
    this.dpConfig.containerClass = "theme-blue";
    
    this.getDropdownsData();
    this.IntialCallForHierarchy();

  }

  IntialCallForHierarchy(){
    this.passedFrom = this.transferService.getData();
    this.selectedFiles = this.transferService.getData();
   this.currentSelectedRelationship=this.selectedFiles;


    if (!this.selectedFiles) {
      this.isEditType = false
      this.selectedFiles = JSON.parse(localStorage.getItem('dataItem'));
      if (!this.selectedFiles) {
        this.selectedFiles = [];
      } else {
        if (this.selectedFiles[0].isFrom) {
          this.getRelationshipData(this.selectedFiles[0].data.relationshipId);
          this.showBackButton = true;
        } else
          this.getLatestData(this.selectedFiles[0].data);
      }

    } else {
      this.isEditType = true
      setTimeout(() => {
        this.setListForEdit(this.selectedFiles[0]);
      }, 100);
    }
  }

  /* Goto workflow */
  gotoWorkflow() {
    this.router.navigate(['/proposalWorkflow']);
  }

  backToRelationship() {
    this.transferService.clearData();
    this.router.navigate(['/relationships']);
  }

  getRelationshipData(relationshipId) {
    let searchSortColumn = new SearchSort();
    searchSortColumn.fieldName = 'clientHierarchyId';
    searchSortColumn.searchedValue[0] = relationshipId;
    this.filterVO.searchParamsInfoVO.push(searchSortColumn);
    this.relationshipsService
      .getRelationshipsData(this.filterVO)
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          var rowDataList = this.prepareData(response["responseData"]["result"]);
          console.log(rowDataList)
          this.selectedFiles = rowDataList;
          this.setListForEdit(this.selectedFiles[0]);
        }
      });
  }

  prepareData(dataSet) {
    var rowData = [];
    for (let entry of dataSet) {
      let singleData = {};
      singleData["data"] = entry;
      singleData["data"]["id"] = entry.clientId;
      singleData["data"]["type"] = entry.objectType;
      if (entry.children) {
        singleData["children"] = [];
        entry.children.forEach(element => {
          var ele = { "data": element, "children": [] };
          ele["data"]["id"] = element.ownershipId
          ele["data"]["type"] = element.objectType;
          if (element.children) {
            element.children.forEach(innerElement => {
              var innerEle = { "data": innerElement, "children": [] }
              innerEle["data"]["id"] = innerElement.accountId;
              innerEle["data"]["type"] = innerElement.objectType;
              ele["children"].push(innerEle);
            });
          }
          singleData["children"].push(ele);
        });
      }
      rowData.push(singleData);
    }
    return rowData;
  }

  getDropdownsData() {
    this.baseService.getEnumDataBasedOnModule("relationships").subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var retData = response['responseData'];
        this.relationShipDropdowns.accountType = retData.accountType;
        this.relationShipDropdowns.taxStatus = retData.accountTaxStatus;
        this.relationShipDropdowns.ownerType = retData.ownerType;
        this.relationShipDropdowns.relationType = retData.relationshipType;
        this.relationShipDropdowns.accountStatus = retData.accountStatus;
        this.relationShipDropdowns.investmentObjective = retData.investmentObjective;
      }
    });
  }


  submitFormDetails() {
    if (this.currentFormType == this.entityTypes.relationship) {
      if (!this.relationship.shortName)
        this.relationship.shortName = this.relationship.name;
      if (this.validateFormData(this.relationship, this.entityTypes.relationship, compareDateObj)) {
        let tempDate = this.relationship.inceptionDate;
        this.relationship.inceptionDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
        let toCreateOwnership = false;
        this.relationshipsService.setRelationshipsData(this.relationship, toCreateOwnership).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.relationship.relationshipId = response['responseData']['clientId'];
            this.relationship.clientId = response['responseData']['clientId'];
            this.relationship.dataStoreId = response['responseData']['dataStoreId'];
            this.relationship.relationshipDataStoreId = response['responseData']['dataStoreId'];
            this.setRelationDataInList(this.relationship);
            this.setDataInList(this.relationship);
            if (this.files[0].entireData) {
              this.files[0].entireData.objectType = "Relationship";
              this.getLatestData(this.files[0].entireData);
            }
            else{
              this.getLatestData(response['responseData']);
            }
            //this.IntialCallForHierarchy();
          }
        });
      }
    } else if (this.currentFormType == this.entityTypes.client) {
      if (!this.client.shortName)
        this.client.shortName = this.client.name;

      let tempDate = this.client.inceptionDate;
      let tempDate1 = this.client.dateOfBirth;
      let compareDateObj = this.getCompareDate(tempDate, tempDate1);
      // var currentDay=this.datePipe.transform(tempDate,'dd');
      // var dobDay=this.datePipe.transform(tempDate1,'dd');
      if (this.validateFormData(this.client, this.entityTypes.client, compareDateObj)) {
        //let tempDate = this.client.inceptionDate;
        this.client.inceptionDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
        //let tempDate2 = this.client.dateOfBirth;
        this.client.dateOfBirth = this.datePipe.transform(tempDate1, 'MM/dd/yyyy');
        if (!this.isEditType) {
          if (this.client.dateOfBirth) {

            this.account.dateOfBirth = tempDate1
          } else {

            this.account.dateOfBirth = null;
          }
        } else {
          
          this.client['children'].forEach(element => {
            element.dateOfBirth=this.client.dateOfBirth;
            
          });
        }
        this.relationshipsService.setClientData(this.client).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.client.ownershipId = response['responseData']['ownershipId'];
            this.client.dataStoreId = response['responseData']['dataStoreId'];
            this.client.ownershipDataStoreId = response['responseData']['dataStoreId'];
            if (this.isEditType) {
              this.selectedFiles = this.clientVO.data;
              this.selectedType = this.clientVO.type
              this.nodeSelect(this.clientVO.event);
              // this.setClientDataInList(this.client);
              // this.getLatestData(this.currentSelectedRelationship[0]);
            }
            else {
              this.setClientDataInList(this.client);
              this.getLatestData(this.files[0].entireData.data);
            }
           // this.IntialCallForHierarchy();
          }
        });

        // }else{
        //   var errorObj = new ErrorModel();
        //   errorObj['errorCode'] = 'ERR0107';
        //   errorObj['message'] = 'Client/Owner has Account whose Tax Status is Tax Deferred.';
        //   errorObj['errorDescription'] = '';
        //   this.authMessageService.showErrorPopup(errorObj);
        //   return false;
        // }
      }
    } else if (this.currentFormType == this.entityTypes.account) {
      if (!this.account.shortName)
        this.account.shortName = this.account.name
      let tempDate = this.account.inceptionDate;
      let tempDate1 = this.account.dateOfBirth;
      var compareDateObj = this.getCompareDate(tempDate, tempDate1);
      // var currentDay=this.datePipe.transform(tempDate,'dd');
      // var dobDay=this.datePipe.transform(tempDate1,'dd');
      if (this.validateFormData(this.account, this.entityTypes.account, compareDateObj)) {

        this.account.inceptionDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
        this.account.dateOfBirth = this.datePipe.transform(tempDate1, 'MM/dd/yyyy');
        this.relationshipsService.setAccountData(this.account).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.account.dataStoreId = response['responseData']['dataStoreId'];
            this.account.accountId = response['responseData']['accountId'];
            if (this.isEditType) {
              this.selectedFiles = this.accountVO.data;
              this.selectedType = this.accountVO.type;
              this.nodeSelect(this.accountVO.event);
              this.setAccountDataInList(this.account, this.client);
              this.getLatestData(this.files[0].entireData.data);
            }
            else {
              this.setAccountDataInList(this.account, this.client);
              this.getLatestData(this.files[0].entireData.data);
            }
          }
          //this.IntialCallForHierarchy();
        });
        //this.IntialCallForHierarchy();
      }
    }
  }

  getCompareDate(currentDate, currentDate1) {
    var currentDay = this.datePipe.transform(currentDate, 'dd');
    var currentMonth = this.datePipe.transform(currentDate, 'MM');
    var currentYear = this.datePipe.transform(currentDate, 'yyyy');
    var dobDay = this.datePipe.transform(currentDate1, 'dd');
    var dobMonth = this.datePipe.transform(currentDate1, 'MM');
    var dobYear = this.datePipe.transform(currentDate1, 'yyyy');
    if (dobYear < currentYear) {
      return true;
    } else if (dobYear == currentYear) {
      if (dobMonth < currentMonth) {
        return true;
      } else if (dobMonth == currentMonth) {
        if (dobDay < currentDay) {
          return true;
        } else {
          return false;
        }

      } else if (dobMonth > currentMonth) {
        return false
      }

    } else if (dobYear > currentYear) {
      return false
    }


  }

  validateFormData(data, formType, compareDateObj) {
    if (formType == this.entityTypes.relationship) {
      if (!data.name) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!data.shortName) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter short name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }


      if (!data.clientType || data.clientType == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter client type';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      // if (!data.inceptionDate) {
      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please enter inception date';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      //} 
      if (!data.currency || data.currency == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter currency';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
    } else if (formType == this.entityTypes.client) {
      if (!data.name) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!data.shortName) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter short name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!compareDateObj) {

        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Date of Birth should be before Inception Date';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!data.restrictValidationOnClientDate) {
        if (!data.dateOfBirth) {
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Date of Birth is mandatory as Client/Owner has Account whose Tax Status is Tax Deferred.';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          return false;
        }

      }

      if (!data.legalEntitytType || data.legalEntitytType == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter ownership type';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      // if (!data.inceptionDate) {
      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please enter inception date';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      // }
      // if (!data.dateOfBirth) {
      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please enter inception date';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      // }
      // if (!data.taxId) {
      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please enter tax id';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      // }
      if (!data.currency || data.currency == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter currency';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
    } else if (formType == this.entityTypes.account) {
      if (!data.name) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!data.shortName) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter short name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!data.accountType || data.accountType == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter account type';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }

      if (!data.accountSubType || data.accountSubType == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter account sub type';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (!compareDateObj) {

        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Date of Birth should before inception date';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      if (data.accountType && data.accountType != "ASSET_ACCOUNT") {
        if (!data.totalMarketValueInMoney.decimalValue) {
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please enter market value';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          return false;
        }
      }
      if (this.showOwnerDateOfBirth) {
        if (!data.dateOfBirth) {
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please enter owner date of birth';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          return false;
        }
      }
      if (!data.taxStatus || data.taxStatus == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter tax status';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      // if (!data.investmentObjective) {
      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please enter investment objective';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      // }
      if (!data.currency || data.currency == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter currency';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      // if (!data.inceptionDate) {
      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Please enter inception date';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      // }
      if (data.isQualified == null || data.isQualified == "null") {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter account Qualified Status';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      var taxableAccObj = _.find(this.relationShipDropdowns.taxStatus, { display: 'Taxable' });
      if (taxableAccObj && taxableAccObj.code == data.taxStatus && data.isQualified == 'true') {

        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Taxable Account cannot be qualified';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      // if (taxableAccObj && taxableAccObj.code != data.taxStatus && data.isQualified == 'false') {

      //   var errorObj = new ErrorModel();
      //   errorObj['errorCode'] = 'ERR0107';
      //   errorObj['message'] = 'Non Taxable or tax exempt Account should be qualified';
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      //   return false;
      // }
    }

    return true;
  }


  nodeSelect(event) {
    if (this.selectedFiles["type"] == "relationship") {
      this.currentFormType = this.entityTypes.relationship;
      if (this.selectedFiles['entireData'])
        this.relationship = this.selectedFiles['entireData']['data'];
      this.client.clientDataStoreId = this.selectedFiles["dataStoreId"];

    } else if (this.selectedFiles["type"] == "client") {
      this.currentFormType = this.entityTypes.client;
      this.client = this.selectedFiles['entireData']['data'];
      this.client.restrictValidationOnClientDate = this.checkAccountTaxStatus(this.client);

      this.account.ownershipDataStoreId = this.selectedFiles["dataStoreId"];
      this.clientVO['event'] = event;
      this.clientVO['data'] = this.selectedFiles;
      this.clientVO['type'] = this.entityTypes.client;
    } else if (this.selectedFiles["type"] == "account") {
      this.currentFormType = this.entityTypes.account;
      this.account = this.selectedFiles['entireData']['data'];
      //this.setAccountTypeDropdown(this.account.accountTypeDataStoreId);
      this.accountVO['event'] = event;
      this.accountVO['data'] = this.selectedFiles;
      this.accountVO['type'] = this.entityTypes.account;
      let accountTypeObj = {
        target: {
          value: this.account.accountType
        }
      }
      this.onAccountTypeBlur(accountTypeObj);
    }

  }
  addNewRelationship() {
    this.currentFormType = this.entityTypes.relationship;
  }
  addNewClient() {
    if (!this.selectedFiles["dataStoreId"]) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please create relationship first';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.currentFormType = this.entityTypes.client;
      let relationId = this.selectedFiles["dataStoreId"];
      this.client = new Client();
      this.client.clientDataStoreId = relationId;
      this.isEditType = false;
    }

  }
  addNewAccount() {
    if (!this.account.ownershipDataStoreId) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please create client/owner first';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.currentFormType = this.entityTypes.account;
      let clientId = this.account.ownershipDataStoreId;
      this.account = new Account();
      this.account.dateOfBirth = this.client.dateOfBirth;
      this.account.ownershipDataStoreId = clientId;
      this.isEditType = false;
    }
  }

  onAccountTypeBlur(event) {

    this.setAccountTypeDropdown(event.target.value);

    let selectedAccountType = event.target.value;
    setTimeout(() => {
      if (selectedAccountType == 'LIABILITY' || selectedAccountType == 'OPERATIONAL_CASH') {
        this.showMarketValueInputBox = true;
        this.account.carvedOutPercentage = 100;
        this.account.totalMarketValueInMoney.currency = 'USD';
        document.getElementById('account-carve-out-percentage-id')['disabled'] = true;
      } else {
        this.showMarketValueInputBox = false;
        this.account.carvedOutPercentage = 0;
        this.account.totalMarketValueInMoney.decimalValue = 0;
        this.account.totalMarketValueInMoney.currency = '';
        document.getElementById('account-carve-out-percentage-id')['disabled'] = false;
      }
    }, 200);
  }

  onTaxStatusTypeBlur(event) {
    let selectedTaxStatusType = event.target.value;
    if (selectedTaxStatusType == "TAX_DEFERRED") {
      this.showOwnerDateOfBirth = true;
    } else {
      this.showOwnerDateOfBirth = false;
    }
  }

  setAccountTypeDropdown(value: any) {
    var accountType = _.find(this.relationShipDropdowns.accountType, { 'code': value });
    if (!_.isUndefined(accountType)) {
      this.relationShipDropdowns.accountSubType = accountType.accountSubTypeVO;
    }
    else {
      this.relationShipDropdowns.accountSubType = [];
    }
  }

  setDataInList(value) {
    this.listData.label = value.name;
    this.listData.type = this.entityTypes.relationship;
    this.listData.dataStoreId = value.relationshipDataStoreId;
    this.files = [this.listData];

  }

  setRelationDataInList(value) {
    this.listData.label = value.name;
    this.listData.type = this.entityTypes.relationship;
    this.listData.dataStoreId = value.relationshipDataStoreId
    this.listData.children = [];
    this.listData.entireData = value;
    this.files = [];
    this.files.push(this.listData);

  }

  setClientDataInList(clientData) {
    let clientInList = new ListData();
    clientInList.label = clientData.name;
    clientInList.type = this.entityTypes.client;
    clientInList.dataStoreId = clientData.ownershipDataStoreId;
    clientInList.children = [];
    this.listData.children.push(clientInList);
    this.files = [];
    this.files.push(this.listData);

  }
  setAccountDataInList(accountData, clientData) {
    let accountInList = new ListData();
    accountInList.label = accountData.name;
    accountInList.type = this.entityTypes.account;
    accountInList.dataStoreId = accountData.dataStoreId;
    accountInList.carvedOutPercentage = accountData.carvedOutPercentage;
    this.listData.children.forEach(element => {
      if (element.dataStoreId == accountData.ownershipDataStoreId) {
        element.children.push(accountInList);
      }
    });
    this.files = [];
    this.files.push(this.listData);
  }

  getLatestData(relationshipData) {
    var hierarchyLatestData = {
      data: relationshipData,
      children: []
    };
    var dataStoreId = relationshipData.dataStoreId;
    var relationshipId = relationshipData.relationshipId || relationshipData.clientId;
    this.relationshipsService.getLegalEntityListData(dataStoreId, relationshipId).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        response['responseData'].forEach(element => {
          var dataObj = {
            data: element,
            children: []
          };
          element.children.forEach(innerElement => {
            var innerDataObj = {
              data: innerElement,
              children: []
            };
            dataObj.children.push(innerDataObj);
          });
          hierarchyLatestData.children.push(dataObj);
        });
        this.files = [];
        this.setListForEdit(hierarchyLatestData);
      }
    });
  }

  setListForEdit(info) {
    this.relationship = info.data;
    if (info.data.objectType == "Relationship") {
      this.listData.label = info.data.name;
      this.listData.type = 'relationship';
      this.listData.dataStoreId = info.data.dataStoreId;
      this.editingRelationId = info.data;
      this.listData.entireData = info;
      this.listData.children = [];
      info.children.forEach(element => {
        let clientData = new ListData();
        clientData.label = element.data.name;
        clientData.type = 'client';
        clientData.entireData = element;
        clientData.dataStoreId = element.data.dataStoreId;
        clientData.children = [];
        this.listData.children.push(clientData);
        element.children.forEach(innerelement => {
          let accountData = new ListData();
          accountData.label = innerelement.data.name;
          accountData.type = 'account';
          accountData.entireData = innerelement;
          accountData.dataStoreId = innerelement.data.dataStoreId;
          clientData.children.push(accountData);
        });
      });
      this.files = [this.listData];
    }
    setTimeout(() => {
      $(".ui-treenode-content:first").click();
    });
  }

  checkAccountTaxStatus(info) {
    let c = 0;
    if (info.children.length > 0) {
      info.children.forEach(element => {
        if (element.taxStatus == 'TAX_DEFERRED') {
          c += 1;
        }
      });
    }
    if (c > 0) {
      return false;
    } else {
      return true;
    }
  }
}

