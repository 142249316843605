import { Observable, Observer } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class SharedService {
    currentUrl: any;
    routeChange: Observable<any>;
    routeChangeObserver: any;
    activateRouteChange:Observable<any>;
    activateRouteObserver:any
  
    constructor() {
      this.routeChange = new Observable((observer:Observer<any>) =>{
        this.routeChangeObserver = observer;
      });
      this.activateRouteChange = new Observable((observer:Observer<any>) =>{
        this.activateRouteObserver = observer;
      });

    }
  
    setCurrentUrl(data:any) {
      this.currentUrl = data;
      this.routeChangeObserver.next(this.currentUrl);
      if(this.activateRouteObserver)
        this.activateRouteObserver.next(this.currentUrl);
    }
  }