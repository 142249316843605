import {Component, ViewChild, ElementRef} from '@angular/core';

import {GridFilterSortingService} from '../../_services/grid-filter-sorting.service';


@Component({
  selector: 'app-custom-grid-header',
  templateUrl: './custom-grid-header.component.html',
  styleUrls: ['./custom-grid-header.component.less']
})
export class CustomGridHeaderComponent {

    params: any;

    private columnFilterData;

    constructor( 
        private gridFilterSortingService: GridFilterSortingService
    ){}

    @ViewChild('menuButton', {read: ElementRef}) public menuButton;

    agInit(params): void {
        this.params = params;
    }

    onMenuClicked() {
        this.params.showColumnMenu(this.menuButton.nativeElement);
    };

    sortingRequest(){
        this.columnFilterData = this.params.column.colDef.searchSortData;
        if(this.columnFilterData){
            let sortingOrder = this.columnFilterData['sortingOrder'];
            var allColumnDefs = this.params.columnApi.columnController.columnDefs;
            allColumnDefs.forEach(element => {
                if(element.searchSortData){
                    element.searchSortData.sortingOrder = '';
                }
            });
            if(sortingOrder == ''){
                this.columnFilterData['sortingOrder'] = 'ASC';
            } else if(sortingOrder == 'ASC'){
                this.columnFilterData['sortingOrder'] = 'DESC';
            } else if(sortingOrder == 'DESC'){
                this.columnFilterData['sortingOrder'] = '';
            }
            this.gridFilterSortingService.setSortingData(this.columnFilterData);
        }
        
    }
}
