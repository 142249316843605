import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
@Component({
  selector: 'app-market-downturn',
  templateUrl: './market-downturn.component.html',
  styleUrls: ['./market-downturn.component.less']
})
export class MarketDownturnComponent implements OnInit {
  marketTableResponse: any;
  marketChartResponse: any;
  marketTableHeader: any = [];
  marketTableRow: any = [];
  periodDisplay: any = [];

  constructor() { }
  @Input() inputAnalysisData;
  chartOption: EChartOption;

  ngOnChanges() {
    this.marketChartResponse = [];
    this.marketTableHeader = [];
    this.marketTableRow = [];
    this.marketTableResponse = [];
    this.periodDisplay = [];
    if (this.inputAnalysisData) {
      this.marketTableResponse = this.inputAnalysisData['downtrunAnanlysisTableData'];
      this.marketChartResponse = this.inputAnalysisData['downtrunAnalysisChartData'];
    }
    for (let i = 0; i < this.marketTableResponse.length; i++) {
      this.marketTableHeader.push(this.marketTableResponse[i].crisisName);
      this.periodDisplay.push(this.marketTableResponse[i].periodDisplay);

    }

    for (let i = 0; i < this.marketChartResponse.length; i++) {
      var singleRowObject = {};
      singleRowObject['key'] = this.marketChartResponse[i].seriesId;
      singleRowObject['value'] = this.marketChartResponse[i].values;
      this.marketTableRow.push(singleRowObject);
    }
    this.setDataToChart();
  }

  setDataToChart() {
    var series = [];
    var legendData = [];
    for (let i = 0; i < this.marketChartResponse.length; i++) {
      var seriesData = {};
      legendData.push(this.marketChartResponse[i].seriesId);
      seriesData['name'] = this.marketChartResponse[i].seriesId;
      seriesData['type'] = 'bar';
      seriesData['data'] = this.marketChartResponse[i].values;
      series.push(seriesData);
    }
    // this.xAxis['type'] = "\"category\"";
    // this.xAxis['data'] = this.marketTableHeader;
    // this.yAxis['type'] = "\"value\"";
    // this.yAxis['data'] = [];
    // this.legend['data'] = legendData;
    // this.legend['orient'] = "\"vertical\"";
    // this.legend['right'] = 10;
    // this.chartOption = {};
    // this.chartOption['legend'] = this.legend;
    // this.chartOption['series'] = this.series;
    // this.chartOption['xAxis'] = this.xAxis;
    // this.chartOption['yAxis'] = this.yAxis;
    // console.log(this.chartOption);
    this.chartOption = {
      toolbox: {
        show: true
      },

      legend: {
        data: legendData,
        orient: 'vertical', 
        right:true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },

      xAxis: {

        type: 'category',
        data: this.marketTableHeader,
        boundaryGap:true,
        axisLabel:{
          align:'center',
          interval:0, 
          rotate:18
         }
       
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      series: series
    }
  }



  ngOnInit() {

  }

}
