import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.less']
})
export class ProductInfoComponent implements OnInit {


  tabView = 'productOverview';

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activeRoute.params.subscribe(params => { });
  }

  ngOnInit() {
    // if (localStorage.getItem('productDetailTabView')) {
    //   this.tabView = localStorage.getItem('productDetailTabView');
    // }

  }

  backToProduct(): void {
    this.router.navigate(['/catalog']);
  }

  onTabSelect(evt): void {
    this.tabView = evt.id;
    //localStorage.setItem('productDetailTabView', this.tabView);
  }

  ngOnDestroy() {

  }




}
