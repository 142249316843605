import { Injectable } from '@angular/core';
import { ApiUrls } from '../../constants';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PortfolioConstructionService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
};

  constructor(private http: HttpClient) { }

  getTacticalStrategies(processInstanceId,portfolioId,templateId) {
    if(templateId == "null")
        templateId = null;
    return this.http.get(ApiUrls.getTacticalStrategiesAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId+"&templateId="+templateId, this.httpOptions)
        .pipe(
            response => response
        );
}

removeInvestment(processInstanceId,portfolioId,investmentList) {
    return this.http.post(ApiUrls.removeInvestmentAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId,investmentList, this.httpOptions)
        .pipe(
            response => response
        );
}

  addProductInAsset(requestData) {
    return this.http.post(ApiUrls.addProductInAssetAPI,requestData, this.httpOptions)
        .pipe(
            response => response
        );
  }

  getInvestmentTemplate() {
    return this.http.post(ApiUrls.getInvestmentTemplateAPI,"", this.httpOptions)
        .pipe(
            response => response
        );
  }

  deleteInvestmentTemplate(templateId) {
    return this.http.delete(ApiUrls.investmentTemplateAPI+"?templateId="+templateId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  saveInvestmentTemplate(requestData) {
    return this.http.post(ApiUrls.saveInvestmentTemplateAPI,requestData, this.httpOptions)
        .pipe(
            response => response
        );
  }

  getBuySellSummaryData(requestData) {
    return this.http.post(ApiUrls.getBuySellSummarDataAPI,requestData, this.httpOptions)
        .pipe(
            response => response
        );
  }

  saveLockedHoldinData(processInstanceId) {
    return this.http.post(ApiUrls.saveLockedHoldingDataAPI+"?processInstanceId="+processInstanceId , this.httpOptions)
        .pipe(
            response => response
        );
  }

  validatePortfolioAllocation(processInstanceId) {
    return this.http.get(ApiUrls.validatePortfolioAllocation+"?processInstanceId="+processInstanceId , this.httpOptions)
        .pipe(
            response => response
        );
  }

  getAccountList(processInstanceId,portfolioId) {
    return this.http.get(ApiUrls.getAccountListAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  getAccountProductList(processInstanceId,portfolioId,accountId) {
    return this.http.get(ApiUrls.getAccountProductListAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId +"&accountId="+accountId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  getUnAssignedPosition(processInstanceId,portfolioId) {
    return this.http.get(ApiUrls.getUnAssignedPositionAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  assignProduct(processInstanceId,portfolioId,accountId,requestData) {
    return this.http.post(ApiUrls.assignProductAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId+"&accountId="+accountId ,requestData, this.httpOptions)
        .pipe(
            response => response
        );
  }

  unAssignProduct(processInstanceId,portfolioId,accountId,requestData) {
    return this.http.post(ApiUrls.unAssignProductAPI+"?processInstanceId="+processInstanceId +"&portfolioId="+portfolioId+"&accountId="+accountId ,requestData, this.httpOptions)
        .pipe(
            response => response
        );
  }

  saveUpdatedTacticalStrategies(data) {
    return this.http.post(ApiUrls.saveUpdatedTacticalStrategiesAPI , data, this.httpOptions)
        .pipe(
            response => response
        );
}

createOpenAccount(data) {
    return this.http.post(ApiUrls.createOpenAccountAPI , data, this.httpOptions)
        .pipe(
            response => response
        );
}
 
}
