import { Component, OnInit,ViewChild } from '@angular/core';
import { LeftNavService } from "src/app/_services/left-nav.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { FilterModel } from "../../_models/filter.model";
import { UserInfoModel } from "../../_models/user.model";
import { Variables } from "src/app/constants";
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { ErrorModel } from "../../_models/response.model";
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CommonFunctions } from '../../common';
import { GridComponent } from "../../shared/grid/grid.component";
import { UserManagementService } from "src/app/_services/user-management.service";
import { BaseService } from "src/app/_services/base.service";

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class UserManagementComponent implements OnInit {

  constructor(
    private leftNavData: LeftNavService,
    public ngxSmartModalService: NgxSmartModalService,
    private authMessageService: AuthMessageService,
    private datePipe: DatePipe,
    private userManagementService:UserManagementService,
    private baseService:BaseService
  ) {

    this.componentReference = { componentParent: this };
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);
   }
  gridName: string;
  minDate;
  detailExpandCollaspe = true;
  maxDate;
  userCode = localStorage.getItem("userCode");
  modalTitle : string;
  filterVO = new FilterModel();
  rowData: any[];
  commonFunction = new CommonFunctions();
  colName: string;
  selectedRows: any[];
  userModel = new UserInfoModel();
  message:boolean = true;
  mainContent: boolean = true;
  componentReference = {};
  roleList = [];
  genderList = ["Male","Female","Other"];
  permissionList = [];
  sections = [];
  showPassword:boolean =false;
  @ViewChild(GridComponent) gridComponentData: GridComponent;
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  ngOnInit() {
    this.dpConfig.containerClass = "theme-blue";
    this.leftNavData.currentMessage.subscribe(message =>{
      this.message = message;
      this.changeContainerSize();
    });
    this.getAllRoles();
    this.getDropdownsData();
  }

  showHidePassword(){
    this.showPassword = !this.showPassword;
  }

  getDropdownsData() {
    this.baseService.getEnumDataBasedOnModule("usermanagement").subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var retData = response['responseData'];
        console.log(retData);
        retData.userModuleEditInfoList.forEach(element => {

          this.sections.push({
              moduleId : element.moduleId,
              moduleName : element.moduleName,
              isExpanded : true
          });

          if(element.childUserModuleEditInfoList){
            element.childUserModuleEditInfoList.forEach(element2 => {
              element2.parentModule = element.moduleId;
              this.permissionList.push(element2);
              if(element2.childUserModuleEditInfoList){
                element2.childUserModuleEditInfoList.forEach(element3 => {
                  element3.parentModule = element.moduleId;
                  this.permissionList.push(element3);
                });
              }
            });
          }
        });
      }
    });
  }

  addUser() {
    this.userModel = new UserInfoModel();
    this.modalTitle = 'Add User';
    this.detailExpandCollaspe = true;
    this.userModel['modulesWithPermissionList']=[];
    this.permissionList.forEach(module=>{
      module.modulePermissionVO = {};
      module.userModuleActivityCanEdit.forEach(element => {
        module.modulePermissionVO[element] =false;
      });
      this.userModel.modulesWithPermissionList.push(module)
    })
    this.ngxSmartModalService.open('addModal');
    //this.compileService.compile(AddEditDocumentPopup,(c)=>{c.instance.compId = "test"});
  }

  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {
    this.editUser(rowData);
  }

  editUser(rowData) {
    this.userModel=Object.assign({},rowData);
    this.detailExpandCollaspe = true;
    if(this.userModel.trialPeriodEndDate){
      this.userModel.trialDaysRemaining = Math.floor((Date.parse(this.userModel.trialPeriodEndDate)-new Date().getTime())/86400000)+1
    }
    if(!this.userModel.contactInfoVO.emailVOList){
      this.userModel.contactInfoVO.emailVOList =  [{
          isPrimary :true,
          value : null,
          type :"home"
        }]
      }
      if(!this.userModel.contactInfoVO.phoneNumberVOList){
        this.userModel.contactInfoVO.phoneNumberVOList =  [{
            isPrimary :true,
            value : null,
            type :"home",
            countryCode :null
          }]
        } 

      if(!this.userModel.contactInfoVO.addressVOList){
        this.userModel.contactInfoVO.addressVOList =  [{
          type : "Home",
          addressLine1 : "",
          addressLine2 : "",
          city : "",
          state : "",
          country : "",
          zipCode : "",
          isPrimary : true
          }]
        } 
    
    if(this.userModel.modulesWithPermissionList.length){
      this.userModel.modulesWithPermissionList.forEach(module=>{
        let modObj = this.permissionList.find(item=>{
          return item.moduleId == module.moduleId
        })
        if(modObj){
          module.moduleName = modObj.moduleName;
          module.parentModule = modObj.parentModule;
          module.userModuleActivityVO = modObj.userModuleActivityVO;
          Object.keys(module.modulePermissionVO).forEach((key) => {
            if(modObj.userModuleActivityCanEdit.indexOf(key)<0){
              delete module.modulePermissionVO[key];
            }
          });
        }
      })
      // to add module not coming from backend in user listing api
      this.permissionList.forEach(module=>{
        let modObj = this.userModel.modulesWithPermissionList.find(item=>{
          return item.moduleId == module.moduleId
        })
        if(!modObj){
          module.modulePermissionVO = {};
          module.userModuleActivityCanEdit.forEach(element => {
          module.modulePermissionVO[element] =false;
        });
        this.userModel.modulesWithPermissionList.push(module)
        }
        
      })
    }
    
    let role = this.roleList.find(item=>{
      return item.roleId == this.userModel.role.roleId
    })
    this.userModel.role = role;
    this.modalTitle = 'Edit User';
    this.ngxSmartModalService.open('addModal');
  }

  saveUserModel(){
    if(this.validateForm(this.userModel)){
      if(this.modalTitle == "Add User")
        this.addUserModel();
      else
        this.editUserModel();
    }
   }
  
   addUserModel(){
    this.userModel.dob = this.datePipe.transform(this.userModel.dob, 'MM/dd/yyyy');
    this.userModel.trialPeriodEndDate = this.datePipe.transform(this.userModel.trialPeriodEndDate, 'MM/dd/yyyy');
    this.userManagementService.addUser(this.userModel)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('addModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
   }

   getAllRoles(){
    
    this.userManagementService.getAllRoles()
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
        this.roleList = response["responseData"];
        }
      });
   }

   generateTempPassword(){
    setTimeout(() => {
      if(this.userModel.isAutoGenerated){
        this.userManagementService.generateTempPassword()
        .subscribe(response => {
          if (response['responseType'] == Variables.statusSuccess) {
            
          this.userModel.password = response["responseData"];
          
          }
        });
      }else{
        this.userModel.password= null;
      }
    }, 200);
    
    
   }

   editUserModel(){
    this.userModel.dob = this.datePipe.transform(this.userModel.dob, 'MM/dd/yyyy');
    this.userModel.trialPeriodEndDate = this.datePipe.transform(this.userModel.trialPeriodEndDate, 'MM/dd/yyyy');
    this.userManagementService.editUser(this.userModel)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('addModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
   }

  validateForm(data){
    if (!data.firstName) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter First Name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.lastName ) {
        var errorObj = new ErrorModel();
        errorObj['message'] = 'Please enter Last Name';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
    }
    if (!data.userId) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter User Id';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.contactInfoVO.emailVOList[0].value ) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter Email';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!CommonFunctions.validateEmail(data.contactInfoVO.emailVOList[0].value) ) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter valid Email';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
  if (!data.password&& this.modalTitle =="Add User") {
    var errorObj = new ErrorModel();
    errorObj['message'] = 'Please enter Password';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
    return false;
  }
  // if (!data.dob) {
  //   var errorObj = new ErrorModel();
  //   errorObj['message'] = 'Please enter Date of Birth';
  //   errorObj['errorDescription'] = '';
  //   this.authMessageService.showErrorPopup(errorObj);
  //   return false;
  // }
  if (!data.role || data.role == "null") {
    var errorObj = new ErrorModel();
    errorObj['message'] = 'Please select user Role';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
    return false;
  }
  // if (!data.gender || data.gender == "null") {
  //   var errorObj = new ErrorModel();
  //   errorObj['message'] = 'Please select Gender ';
  //   errorObj['errorDescription'] = '';
  //   this.authMessageService.showErrorPopup(errorObj);
  //   return false;
  // }
  if (data.contactInfoVO.phoneNumberVOList[0].value &&  !data.contactInfoVO.phoneNumberVOList[0].countryCode) {
    var errorObj = new ErrorModel();
    errorObj['message'] = 'Please enter Country Code for the provided Phone Number';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
    return false;
  }
    return true;
  }

  changeInTrialPeriod(){
    if(this.userModel.trialPeriodEndDate){
      this.userModel.trialDaysRemaining = Math.floor((this.userModel.trialPeriodEndDate.getTime()-new Date().getTime())/86400000)+2;
    }
  }

  deleteUser() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length) {
      let shouldProceed = true;
      this.selectedRows.forEach(item=>{
        if(item.isTerminated)
          shouldProceed =false;
      })
      if(shouldProceed)
        this.ngxSmartModalService.open('deleteModal');
      else {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Terminated user cannot be deleted';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
        }
      
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any user for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteUserAfterConfirmation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    this.filterVO = this.gridComponentData.filterVO;
    this.filterVO.dataStoreIdList = [];
    this.selectedRows.forEach(element => {
      this.filterVO.dataStoreIdList.push(element.dataStoreId);
    });
    this.userManagementService.deleteUser(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }

  verifyUser() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if(this.selectedRows && !this.selectedRows.length){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any user for verification';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if(this.selectedRows.length > 1){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select only one user for verification';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if(this.selectedRows[0].isTerminated){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'User is terminated';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if(this.selectedRows[0].isUserVerify){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'User is already verified';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    this.userManagementService.userVarificationMail(this.selectedRows[0].userId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }

  


  changeContainerSize(){
    if(this.message){
      this.mainContent = true;
    }else{
      this.mainContent = false;
    }

  }
}
