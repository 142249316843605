import { Component, OnInit, Input, Renderer2, ApplicationRef, ViewChild } from "@angular/core";

import { NgxSmartModalService } from "ngx-smart-modal";
import { FilterModel } from "../../_models/filter.model";
import { Variables } from "src/app/constants";
import { DocumentCenterService } from "src/app/_services/document-center.service";
import { DocumentCenterModel } from "src/app/_models/document-center.model";
import { UploadFileService } from "src/app/_services/upload-file.service";
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { CommonFunctions } from '../../common';
import { CustomCompileService } from "../../_services/custom-compile.service";
import { GridComponent } from "../../shared/grid/grid.component";
import { LeftNavService } from "src/app/_services/left-nav.service";

declare var _: any;
@Component({
  selector: "app-document-center",
  templateUrl: "./document-center.component.html",
  styleUrls: ["./document-center.component.less"]
})
export class DocumentCenterComponent implements OnInit {
  @Input()
  gridName: string;
  filterVO = new FilterModel();
  cols: any[];
  rowData: any[];
  colName: string;
  selectedRows: any[];
  selectedColumns: any[];
  document = new DocumentCenterModel();
  itemsPerPage = Variables.itemsPerPageArr;
  rowsPerPage: number;
  totalItems: number;
  selectedFile: File = null;
  modalTitle: string;
  componentReference = {};

  @ViewChild(GridComponent) gridComponentData: GridComponent;

  constructor(
    public documentCenterService: DocumentCenterService,
    public ngxSmartModalService: NgxSmartModalService,
    public renderer: Renderer2,
    private authMessageService: AuthMessageService,
    public uploadService: UploadFileService,
    public compileService: CustomCompileService,
    private appRef: ApplicationRef,
    private leftNavData: LeftNavService
  ) {
    compileService.configure(this.appRef);
    this.componentReference = { componentParent: this };
  }
  message:boolean = true;
  mainContent: boolean = true;
  ngOnInit() {
    this.leftNavData.currentMessage.subscribe(message =>{
      this.message = message;
      this.changeContainerSize();
    });
  }

  changeContainerSize(){
    if(this.message){
      this.mainContent = true;
    }else{
      this.mainContent = false;
    }

  }

  showFilter(filterName: string) {
    this.colName = filterName;
    this.ngxSmartModalService.getModal("filter").open();
  }

  addDoc() {
    this.document = new DocumentCenterModel();
    this.modalTitle = 'Add Document';
    this.ngxSmartModalService.open('addModal');
    //this.compileService.compile(AddEditDocumentPopup,(c)=>{c.instance.compId = "test"});
  }

  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {
    this.editDoc(rowData);
  }

  editDoc(rowData) {
    this.document=Object.assign({},rowData);
    this.modalTitle = 'Edit Document';
    this.ngxSmartModalService.open('addModal');
  }

  deleteDoc() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length) {
      this.ngxSmartModalService.open('deleteModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteDocumentAfterConfirmation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    this.filterVO = this.gridComponentData.filterVO;
    this.filterVO.dataStoreIdList = [];
    this.selectedRows.forEach(element => {
      this.filterVO.dataStoreIdList.push(element.dataStoreId);
    });
    this.documentCenterService.deleteDocuments(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }

  validateData(data) {
    if (CommonFunctions.isEmptyString(data.documentName)) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter document name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.document && this.modalTitle != 'Edit Document') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select document';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }

  addDocument(documentData) {
    if (this.validateData(this.document)) {
      let formData = new FormData();
      // Add your values in here
      var documentName = documentData.target[1].value;
      var documentDescription = documentData.target[2].value;
      var documentFile = documentData.target[3].files[0];
      var dataStoreId = this.document.dataStoreId || '';
      formData.append('documentName', documentName);
      formData.append('description', documentDescription);
      if (documentFile) {
        formData.append('document', documentFile, documentFile.name);
      }
      formData.append('dataStoreId', dataStoreId);
      this.documentCenterService.saveDocumentsData(formData)
        .subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.ngxSmartModalService.getModal('addModal').close();
            this.gridComponentData.getGridDataAfterUpdatingRecords();
          }
        });
    }
  }

  fileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  downloadDocument() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length) {
      var dataStoreIdList = [];
      this.selectedRows.forEach(element => {
        dataStoreIdList.push(element.dataStoreId);
      });
      this.downloadDocumentData(dataStoreIdList);
    }
    else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for downloading file';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  downloadDocumentData(dataStoreIdList) {
    this.documentCenterService
      .downloadDocumentData(dataStoreIdList)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          var fileData = response['responseData'].fileData;
          var fileName = response['responseData'].fileName;
          if (fileData && fileName) {
            CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
          }
        }
      });
  }

}
