import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class StrategySelectionService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
};

  constructor(private http: HttpClient) { }
     

  getStrategyDataBasedOnPortfolio(data,apiParams){
    this.httpOptions['params'] = apiParams;
    return this.http.post(ApiUrls.getStrategyDataAPI,data,this.httpOptions)
    .pipe(
        response => response
    );
  }
  
  getDefaultDataBasedOnSelectedPortfolio(apiParams){
    this.httpOptions['params'] = apiParams;
    return this.http.get(ApiUrls.getDataOnResetAPI,this.httpOptions)
    .pipe(
         response =>response
    );
  }

  saveUpdatedStrategyInfo(data){
    return this.http.post(ApiUrls.saveUpdatedStrategyDataAPI,data,this.httpOptions)
    .pipe(
         response =>response
    );
  }

  getStrategyTemplate() {
    this.httpOptions['params'] = null;
    return this.http.get(ApiUrls.getStrategyTemplateAPI, this.httpOptions)
        .pipe(
            response => response
        );
  }

  deleteStrategyTemplate(templateId) {
    return this.http.delete(ApiUrls.strategyTemplateAPI+"?templateId="+templateId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  saveStrategyTemplate(requestData) {
    return this.http.post(ApiUrls.strategyTemplateAPI,requestData, this.httpOptions)
        .pipe(
            response => response
        );
  }
 
}
