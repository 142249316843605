import {Component} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'download-icon',
    template: `<div class="float-left w-100" [ngClass]="{'download-printHide':hideDownLoadIcon}">
                    <fa name="download" class="cursor" (click)="downloadRowReport()"></fa>
              </div>`,
    styles   : []
})
export class DownloadIconRowTemplate implements ICellRendererAngularComp {
    params:any;
    hideDownLoadIcon : boolean = false;

    agInit(params:any):void {
        this.params = params;
        if(this.params.node.data.gridName=="presentation" && this.params.node.data.progressStatus=="In Progress"){
            this.hideDownLoadIcon = true;
        }
    }

    public downloadRowReport() {
        this.params.context.componentParent.downloadRowData(this.params.node.data);
    }

    refresh(): boolean {
        return false;
    }
}