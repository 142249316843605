import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class CatalogProductInfoService {

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };

    constructor(private http: HttpClient) { }

    getProductOverviewInfoData(productId) {
        return this.http.get(ApiUrls.getProductOveriewInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getProductDetailsInfoData(productId) {
        return this.http.get(ApiUrls.getProductDetailsInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getProductCharactersticsInfoData(productId) {
        return this.http.get(ApiUrls.getProductCharactersticsInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getProductManagementInfoData(productId) {
        return this.http.get(ApiUrls.getProductManagementInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getProductPerformanceInfoData(productId) {
        return this.http.get(ApiUrls.getProductPerformanceInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getProductFeesInfoData(productId) {
        return this.http.get(ApiUrls.getProductFeesInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getProductHoldingsInfoData(productId) {
        return this.http.get(ApiUrls.getProductHoldingInfoDataAPI + '?productDetailsDataStoreId=' + productId, this.httpOptions)
            .pipe(
                response => response
            );
    }

}
