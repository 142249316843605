export class InvestorInfo {
  investorName: string;
  investorType: string;
  entityType: string;
  advisoryTeam: string;
  organizationTeam: string;
  primary: string=null;
  secondary: string=null;
  addressType: string;
  address: string;
  city: string;
  stateProvince: string;
  postalZIP: string;
  countryRegion: string;
  estimatedNetWorth = new ValueVsCurrency;
  estimatedInvestableAssets = new ValueVsCurrency;
  baseCurrency: string = "USD";
  email: string;
  phone: string;
  primaryInternetAddressVO = new InternetAddressVO;
  primaryPostalAddressVO = new PostalAddressVO;
  primaryTeleCommunicationsVO = new TeleCommunicationsVO;
  primaryDataStoreId: number;
  primaryEntityId: string=null;
  secondaryDataStoreId: number;
  secondaryEntityId: string=null;
  secondaryInternetAddressVO = new InternetAddressVO;
  secondaryPostalAddressVO = new PostalAddressVO;
  secondaryTeleCommunicationsVO = new TeleCommunicationsVO;
}


export class ValueVsCurrency {
  currency: string;
  decimalValue: number;
}

export class InternetAddressVO {
  address: string;
  dataStoreId: number;
  internetAddressTypeCode: string;
  isPrimary: boolean;
}

export class TeleCommunicationsVO {
  dataStoreId: number;
  description: string;
  isPrimary: boolean;
  telecommunicationsNumber: string;
  telecommunicationsTypeCode: string;
}

export class PostalAddressVO {
  address: string;
  dataStoreId: number;
  addressTypeCode: string;
  isPrimary: boolean;
  city: string;
  countryInfoCode: string;
  description: string;
  landmark: string;
  stateInfoCode: string;
  zipcode: string;
}