import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { Variables } from '../../../../constants';

@Component({
  selector: 'product-details-tab',
  templateUrl: './product-details-tab.component.html',
  styleUrls: ['./product-details-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductDetailsTabComponent implements OnInit {

  productId: string;
  productDetailsData:any = {};
  productDetailsVO :any= {};
  productSubscriptionInformationVO :any= {};

  constructor(
    private activeRoute: ActivatedRoute,
    private catalogProductInfoService: CatalogProductInfoService
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });
    this.getProductDetailsData(this.productId);
  }

  getProductDetailsData(productId) {
    this.catalogProductInfoService.getProductDetailsInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productDetailsData = response['responseData'];
          this.productDetailsVO = this.productDetailsData['productDetailsVO'];
          this.productSubscriptionInformationVO = this.productDetailsData['productSubscriptionInformationVO'];
        }
      });
  }

}
