import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-calc-non-qualified',
  templateUrl: './tax-calc-non-qualified.component.html',
  styleUrls: ['./tax-calc-non-qualified.component.less']
})
export class TaxCalcNonQualifiedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
