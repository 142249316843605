import { ErrorModel } from '../app/_models/response.model';
import { AuthMessageService } from "../app/_services/auth-message.service";


export class CommonFunctions {
    static downloadUsingUrl: any;
    static downloadFileUsingByteCode(fileName, fileData) {
        var fileDataWithArrayBuffer = this.base64ToArrayBuffer(fileData);
        var blob = new Blob([fileDataWithArrayBuffer]);
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove();
        }, 100)

    }

    /* function for generating preview link for pdfs */
    static previewPdfUsingByteStream(fileData){
    var fileDataWithArrayBuffer = this.base64ToArrayBuffer(fileData);
    var blob = new Blob([fileDataWithArrayBuffer], {type: 'application/pdf'});
    var url = window.URL.createObjectURL(blob);
    window.open(url,'knskf');
      setTimeout(function(){
          window.URL.revokeObjectURL(url);
    }, 100);
  }

    /* Convert Base 64 file to Array Buffer */
    static base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    /* Return true if valid email else returns false */
    static validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email);
    }


    static isEmptyString(str) {
        if (str == undefined) {
            return true;
        } else if (str == 0) {
            return false;
        } else {
            //Check for blank,empty or string that contains only white spaces
            return str === null || str.toString().match(/^ *$/) !== null;
        }
    }

    allowOnlyNumbers(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {

            evt.preventDefault();
        }
    }

    allowOnlyNumbersWithoutZero(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {

            evt.preventDefault();
        }
        if (value == null || value == "") {
            if (charCode == 48) {
                evt.preventDefault();
            }
        }
    }

    allowOnlyNumbersToNthDigit(evt, value, n) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {

            evt.preventDefault();
        }
        var current = String.fromCharCode(charCode);
        if (value) {
            var newvalue = value.toString().concat(current);
            if (newvalue.toString().length > n) {
                evt.preventDefault();

            }
        }

    }

    allowOnlyNumbersToNthValue(evt, value, n) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        }
        var current = String.fromCharCode(charCode);
        if (value) {
            var newvalue = value.toString().concat(current);
            if (Number(newvalue.toString()) > n) {
                evt.preventDefault();

            }
        }
    }

    allowOnlyDecimalNumbersToNthDigit(evt, value, n) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && ((charCode < 48 || charCode > 57) && charCode != 46)) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                var arrayOfSplit = value.toString().split(".");
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            } 
        }
        var current = String.fromCharCode(charCode);
        if (value) {
            var newvalue = value.toString().concat(current);
            if (newvalue.toString().length > n) {
                evt.preventDefault();

            }
        }

    }

    allowDecimalNumbers(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                var arrayOfSplit = value.toString().split(".");
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }

    }




    allowDecimalNumbersWithValueLessThanOrEqualInTextField(evt, value, current) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                var arrayOfSplit = value.toString().split(".");
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }
        var cursorPositionStart = evt.currentTarget.selectionStart;
        var cur = String.fromCharCode(charCode);
        var text = value.toString();
        var n = text.length;
        var newStr = "";
        if (cursorPositionStart == 0) {
            newStr = cur + text;
        } else if (cursorPositionStart == n) {
            newStr = text + cur;
        } else {

            newStr = text.slice(0, cursorPositionStart) + cur + text.slice(cursorPositionStart, n);
        }
        if (Number(newStr) > Number(current)) {
            evt.preventDefault();
        }
    }

    allowDecimalNumbersWithoutZeroInitially(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
            evt.preventDefault();
        }
        if (((value == "" || value == ".") || value == null) || (value && value.split(".")[0] == "")) {
            if (charCode == 48) {
                evt.preventDefault();
            }
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                var arrayOfSplit = value.toString().split(".");
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }
    }

    allowPositiveDecimalNumbers(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                var arrayOfSplit = value.toString().split(".");
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }

    }
    allowPositiveDecimalNumbersforFormatted(evt,value,n){
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            if ((evt.currentTarget.selectionStart-n) > value.toString().indexOf('.')) {
                var arrayOfSplit = value.toString().split(".");
                if (arrayOfSplit[1].toString().length > digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }
    }

    downloadUsingUrl(Value) {
        var link = document.createElement("a");
        //link.download = filename; then call this function wih the second parameter
        link.download = name;
        link.href = Value;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


    }

    percentageValidator(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var digitAfterDecimal = 0;

        if (charCode > 31 && ((charCode < 48 || charCode > 57) && charCode != 46)) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 2;
            var arrayOfSplit = value.toString().split(".");
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }
        /* Validation is applicable when we perform event on a particular location*/
        var cursorPositionStart = evt.currentTarget.selectionStart;
        var cur = String.fromCharCode(charCode);
        var text = value.toString();
        var n = text.length;
        var newStr = "";
        if (cursorPositionStart == 0) {
            newStr = cur + text;
        } else if (cursorPositionStart == n) {
            newStr = text + cur;
        } else {

            newStr = text.slice(0, cursorPositionStart) + cur + text.slice(cursorPositionStart, n);
        }
        if (Number(newStr) > 100) {
            evt.preventDefault();
        }
    }

    percentageValidatorFeeSchedule(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var digitAfterDecimal = 0;

        if (charCode > 31 && ((charCode < 48 || charCode > 57) && charCode != 46)) {
            evt.preventDefault();
        }
        if (value && value.toString() && value.toString().includes(".")) {
            if (charCode == 46) {
                evt.preventDefault();
            }
            var digitAfterDecimal = 4;
            var arrayOfSplit = value.toString().split(".");
            if (evt.currentTarget.selectionStart > value.toString().indexOf('.')) {
                if (arrayOfSplit[1].toString().length >= digitAfterDecimal) {
                    evt.preventDefault();
                }
            }
        }
        /* Validation is applicable when we perform event on a particular location*/
        var cursorPositionStart = evt.currentTarget.selectionStart;
        var cur = String.fromCharCode(charCode);
        var text = value.toString();
        var n = text.length;
        var newStr = "";
        if (cursorPositionStart == 0) {
            newStr = cur + text;
        } else if (cursorPositionStart == n) {
            newStr = text + cur;
        } else {

            newStr = text.slice(0, cursorPositionStart) + cur + text.slice(cursorPositionStart, n);
        }
        if (Number(newStr) > 100) {
            evt.preventDefault();
        }
    }






    allowRangeOnly(evt, value) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (!(charCode > 48 && charCode < 58)) {
            evt.preventDefault();
        }
        var current = String.fromCharCode(charCode);
        var newvalue = value.toString().concat(current);
        if (Number(newvalue) > 9) {
            evt.preventDefault();
        }
    }

    onPaste(event, currentValue, validationFunction) {
        //var errorServices = BaseComponent.getErrorPopUp();
        var Text = event.clipboardData.getData('text');
        if (isNaN(Text)) {
            event.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        } else {
            var newStr = "";
            if (currentValue) {
                var cursorPositionStart = event.currentTarget.selectionStart;
                var text = currentValue.toString();
                var n = text.length;

                if (cursorPositionStart == 0) {
                    newStr = Text + text;
                } else if (cursorPositionStart == n) {
                    newStr = text + Text;
                } else {
                    newStr = text.slice(0, cursorPositionStart) + Text + text.slice(cursorPositionStart, n);
                }

            } else {
                newStr = Text.toString();
            }
            switch (validationFunction) {
                case 'allowRangeOnly':
                    this.allowRangeOnly1(event, newStr);
                    break
                case 'allowPositiveDecimalNumbers':
                    this.allowPositiveDecimalNumbers1(event, newStr);
                    break

                case 'percentageValidator':
                    this.percentageValidator1(event, newStr)
                    break
                case 'allowOnlyNumbers':
                    this.allowOnlyNumbers1(event, newStr)
                    break
                case 'allowOnlyNumbersToNthDigit':
                    this.allowOnlyNumbersToNthDigit1(event, newStr, 3)
                    break
                case 'allowOnlyNumbersToNthDigit10':
                    this.allowOnlyNumbersToNthDigit1(event, newStr, 10)
                    break
                case 'allowOnlyNumbersToNthDigit6':
                    this.allowOnlyNumbersToNthDigit1(event, newStr, 6)
                    break
                case 'allowOnlyNumbersToNthValue':
                    this.allowOnlyNumbersToNthValue1(event, newStr)
                    break
            }

        }
    }



    allowOnlyNumbersToNthValue1(evt, newStr) {
        if (Number(newStr) > 125) {
            evt.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        } else {
            this.allowPositiveDecimalNumbers1(evt, newStr)
        }

    }

    allowOnlyNumbersToNthDigit1(evt, newStr, num) {
        if (newStr.length > num) {
            evt.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        } else {
            this.allowPositiveDecimalNumbers1(evt, newStr)
        }
    }


    allowOnlyNumbers1(evt, newStr) {
        if (newStr.includes('.')) {
            evt.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        }

    }



    percentageValidator1(evt, newStr) {
        if (Number(newStr) > 100) {
            evt.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        } else {
            this.allowPositiveDecimalNumbers1(evt, newStr);
        }
    }

    allowPositiveDecimalNumbers1(evt, newStr) {
        var temp = 0;
        for (let i = 0; i < newStr.length; i++) {
            if (newStr[i] == ".") {
                temp += 1;
            }
        }
        if (temp > 1) {
            evt.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        } else if (newStr.includes('.')) {
            if (newStr.indexOf('.') == (newStr.length - 3) || newStr.indexOf('.') == (newStr.length - 2)) {
                // nothing to do 
            }
            else {
                evt.preventDefault();
                var errorObj = new ErrorModel();
                errorObj['errorCode'] = 'ERR0107';
                errorObj['message'] = 'Invalid Input';
                errorObj['errorDescription'] = '';
                AuthMessageService.instance.showErrorPopup(errorObj);
            }
        }

    }



    allowRangeOnly1(evt, newStr) {
        if (Number(newStr) > 9) {
            evt.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            AuthMessageService.instance.showErrorPopup(errorObj);
        }
    }

    static exportToPdf(formHtml,name,formPrintCss) {
        var html = formHtml;
        setTimeout(function(){
              var popupWin = window.open('', '', 'height=400,width=800');;
              popupWin.document.open();
              popupWin.document.write('<html><title>'+name+'</title><style>'+formPrintCss+'</style></head><body >')
              popupWin.document.write(html);
              popupWin.document.write('</html>');
              popupWin.print();
              popupWin.document.close();
              popupWin.close();
        },500);
      }

}