import { Component, OnInit, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as $ from 'jquery';
import { ProductModel, AssetAllocationModel } from "../../../_models/catalog.model";
import { ErrorModel } from "../../../_models/response.model";
import { AuthMessageService } from "../../../_services/auth-message.service";
import { CatalogService } from "../../../_services/catalog.service";
import { Variables } from "../../../constants";


declare var _: any;
@Component({
  selector: "add-edit-product-popup",
  templateUrl: "./add-edit-product-popup.component.html",
  styleUrls: ["./add-edit-product-popup.component.less"]
})

export class AddEditProductPopup implements OnInit {
  @Input() compId: string;
  @Input() rowData;
  @Input() saveNewProductData;
  @Input() catalogDropdowns;

  productInfo = new ProductModel();
  entitledProductList = [];
  productListMembershipList = [];
  productClassDistributionList = [];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private authMessageService: AuthMessageService,
    private catalogService: CatalogService
  ) { }

  ngOnInit() {


    setTimeout(() => {
      this.getAllEntitledProductListData();
      if (this.rowData) {
        this.productInfo = this.rowData;
        this.productInfo.assetClassId = this.rowData.productAssetAllocations[0].assetClassId;
      }
  
      let assetClassDistribution = this.rowData.productAssetAllocations;
      if (this.rowData.productAssetAllocations && this.rowData.productAssetAllocations.length >1) {
        this.productInfo.assetClassId = 'BLENDED';
        this.onSelectingProductClass('Edit');
      }else{
        //this.productInfo.assetClassId=this.rowData.productClass;
        this.onSelectingProductClass('Edit');
      }
    }, 100);

   
  }

  getAllEntitledProductListData() {
    this.catalogService.getAllEntitledProductList().subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        this.entitledProductList = response['responseData'];
        this.entitledProductList = _.sortBy(this.entitledProductList, [function (o) { return o.name; }]);
        if (this.productInfo.productListsDataStoreId && this.productInfo.productListsDataStoreId.length > 0) {
          this.productInfo.productListsDataStoreId.forEach(id => {
            let productListIfExist = _.find(this.entitledProductList, function (item) {
              return item.dataStoreId == id;
            });
            if (productListIfExist) {
              this.productListMembershipList.push(productListIfExist);
            }
            _.remove(this.entitledProductList, function (n) {
              return n.dataStoreId == id;
            });
          });
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ngxSmartModalService.open(this.compId);
    });
  }

  closePopup() {
    this.ngxSmartModalService.getModal(this.compId).close();
    $("#" + this.compId).closest("add-edit-product-popup").remove();
  }

  // Select/Unselect product list
  selectProductList(item) {
    if (item.isSelected) {
      item.isSelected = false;
    } else {
      item.isSelected = true;
    }
  }

  // Set data in product list membership 
  setDataInProductListMembership() {
    this.entitledProductList.forEach(element => {
      if (element.isSelected) {
        element.isSelected = false;
        let ele = Object.assign({}, element);
        this.productListMembershipList.push(ele);
      }
    });
    this.productListMembershipList = _.sortBy(this.productListMembershipList, [function (o) { return o.name; }]);
    this.productListMembershipList.forEach(element => {
      _.remove(this.entitledProductList, function (n) {
        return n.dataStoreId == element.dataStoreId;
      });
    });
  }

  // Set data in entitled product list 
  setDataInEntitiledProductList() {
    this.productListMembershipList.forEach(element => {
      if (element.isSelected) {
        element.isSelected = false;
        let ele = Object.assign({}, element);
        this.entitledProductList.push(ele);
      }
    });
    this.entitledProductList = _.sortBy(this.entitledProductList, [function (o) { return o.name; }]);
    this.entitledProductList.forEach(element => {
      _.remove(this.productListMembershipList, function (n) {
        return n.dataStoreId == element.dataStoreId;
      });
    });
  }

  onSelectingProductClass(isFrom) {
    this.productClassDistributionList = [];
    if (this.productInfo.assetClassId == 'BLENDED') {
      this.catalogDropdowns.assetClassDataForDropdown.forEach(item => {
        if(item.objectId != 'BLENDED'){
          let obj = item;
          if (isFrom == 'Edit') {
            let assetClassObj = this.rowData.productAssetAllocations.find(ele => {
              return ele.assetClassId == item.objectId;
            });
            if (assetClassObj) {
              obj['percentageOwnership'] = assetClassObj.percentageOwnership;
            } else {
              obj['percentageOwnership'] = 0;
            }
          } else {
            obj['percentageOwnership'] = 0;
          }
          this.productClassDistributionList.push(obj);
        }
      });
    } else {
      let obj;
      let assetClassObj = this.catalogDropdowns.assetClassDataForDropdown.find(item => {
        return this.productInfo.assetClassId==item.objectId ;
      });
      if (assetClassObj) {
        let obj = assetClassObj;
        obj['percentageOwnership'] = 100;
        obj['isDisabled'] = true;
        this.productClassDistributionList.push(obj);
      }
    }

    console.log(this.productClassDistributionList);

  }

  // Save product data 
  saveProductData() {
    this.productInfo.productListsDataStoreId = [];
    this.productListMembershipList.forEach(element => {
      if (element.dataStoreId) {
        this.productInfo.productListsDataStoreId.push(element.dataStoreId);
      }
    });
    this.productInfo.productAssetAllocations = [];
    this.productClassDistributionList.forEach(element => {

      if (element.percentageOwnership > 0) {
        let obj = new AssetAllocationModel();
        obj.assetClassId = element.objectId;
        obj.percentageOwnership = element.percentageOwnership;
        this.productInfo.productAssetAllocations.push(obj);
      }
    });

    if (this.validateData(this.productInfo)) {
      this.saveNewProductData(this.productInfo, this);
    }
  }

  validateData(data) {
    if (!data.name) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter product name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.shortName) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter product short name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.productId) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter product id';
      errorObj['errorDescription'] = 'null';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.productType == 'null' ||data.productType==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please select product type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.status == 'null' || data.status==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please select status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.isActive == 'null' || data.isActive==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Is Product Active? Please set the status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.isPassive == 'null' || data.isPassive==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Is Product Passive? Please set the status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.isExternal == 'null' || data.isExternal==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Is Product External? Please set the status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.taxExempt == 'null' || data.taxExempt==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Is tax expempted? Please set the value';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.assetClassId == 'null' || data.assetClassId==null) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please select product class';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.productAssetAllocations.length == 0) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please set product class distribution data';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let countPercentage = 0;

    this.productClassDistributionList.forEach(item => {
      countPercentage += item.percentageOwnership;
    });
    if (countPercentage < 100) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please assign total 100% in product class distribution';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (countPercentage > 100) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Total product class distribution percentage cannot be more than 100';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }


    // if (data.productListsDataStoreId.length == 0) {
    //   var errorObj = new ErrorModel();
    //   errorObj['message'] = 'Please assign atleast 1 product list';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    return true;
  }

}
