import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Variables } from '../../../../constants';
import { DatePipe } from '@angular/common';
import { CommonFunctions } from '../../../../common';
import { EditIconRowTemplate } from '../../../../shared/custom-grid-row-templates/edit-icon';
import { GridComponent } from '../../../../shared/grid/grid.component';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { MaxCalculatorMainComponent } from '../../max-calculator-main/max-calculator-main.component';
import { AuthMessageService } from '../../../../_services/auth-message.service';
import { WorkflowBaseService } from '../../../../_services/workflow-services/workflow-base.service';
import { CashFlowObjectiveService } from '../../../../_services/workflow-services/cash-flow-objective.service';
import { CashFlowObjectiveModel, PrimaryInfoModel, CashInflowModel, CashOutflowModel, SimulationParameterModel, InvestmentManagementFeesModel } from '../../../../_models/cash-flow-objective.model';
import { ClientFeeStructureModel,CalInvestmentManagementFeesModel } from '../../../../_models/cal-workflow.models';
import * as _ from "lodash";
import * as $ from 'jquery';
import { YearPickerComponent, BsDatepickerConfig } from 'ngx-bootstrap';
import { element } from '@angular/core/src/render3/instructions';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-cal-fee-schedule',
  templateUrl: './cal-fee-schedule.component.html',
  styleUrls: ['./cal-fee-schedule.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class CalFeeScheduleComponent implements OnInit {

  divStatus: boolean = true;
  isxlsx: boolean = true;
  componentReference: any;
  deleteType: string = null;
  isEditTemplateNameMode:boolean = false;
  simulatorDivStatus: boolean;
  editedTemplateName:String = "";
  templateName : String;
  @ViewChildren('cashFlowGrid') gridComponentData: QueryList<GridComponent>;
  inflowGridComponentReference: any;
  outflowGridComponentReference: any;
  gridApiMangagement: any;
  feeTypeList:ClientFeeStructureModel[];
  taxabilityTypeList=[];
  commonFunction = new CommonFunctions();
  apiParams: {};
  isUserLevel:boolean = true;
  isIncludeFeeInAnalysis:boolean=false;
  isEffective:boolean =false;
  feeType:String;
  applyFeeType:string=null;
  taxStatus:String;
  taxabilityType;
  managementGridColumnDefs: any;
  isLastManagementRow = false;
  frameworkComponents: any;
  managementGridRowData: any;
  investmentManagementFeesMode: string = 'add';
  minimumManagementFees: number;
  dropdownsData: {};
  taxTypeFeeTypeMap:{};
  strategyObj: any = {};
  analyticsData;
  stepInfo = {
    'cashFlowObjectiveModel': new CashFlowObjectiveModel()
  };
  managementDivStatus: boolean;
  cashFlowObjectiveModel = new CashFlowObjectiveModel();
  cashInflowModel = new CashInflowModel();
  cashOutflowModel = new CashOutflowModel();
  simulationParameterModel = new SimulationParameterModel();
  investmentManagementFeesModel = new CalInvestmentManagementFeesModel();
  private dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  private showExpandMsg : boolean;
  isSaveEntityInformation: boolean;
  applyCurrentTier:boolean=false;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private workflowBaseService: WorkflowBaseService,
    private authMessageService: AuthMessageService,
    private datePipe: DatePipe,
    private cashFlowObjectiveService: CashFlowObjectiveService,
    private maxCalculatorMainComponent: MaxCalculatorMainComponent) {
    this.componentReference = { componentParent: this };
    this.frameworkComponents = {
      EditIconRowTemplate: EditIconRowTemplate
    };
  }

  ngAfterViewInit() {
    this.inflowGridComponentReference = this.gridComponentData.toArray().find(function (item) {
      return item.gridName == 'workflow-inflow-list';
    });
    this.outflowGridComponentReference = this.gridComponentData.toArray().find(function (item) {
      return item.gridName == 'workflow-outflow-list';
    });
    $(".ag-body .ag-body-viewport").css("overflow-x","scroll")
    $(".ag-pinned-left-cols-viewport").css("overflow-x","scroll")
    $(".ag-pinned-left-cols-viewport").css("overflow-y","hidden")
  }

  ngOnInit() {
    this.feeType = null;
    this.dpConfig.containerClass = "theme-blue";
    this.analyticsData = {
      parentComponent: "cashFlowObjectives"
    }
    //this.isSaveEntityInformation=false;
   
    //this.showExpandMsg = false;
    this.apiParams = {
      processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId
    };


    // let requestObj = {
    //   "processInstanceId": this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
    //   "taskDefinitionKey": "cashFlowObjectives"
    // };
    // this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
    //   if (response['responseType'] == Variables.statusSuccess) {
    //     if (response['responseData']) {
          
    //     }
    //   }
    // });
    let requestObj = {
      "portfolioId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID,
      "accId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.accountId,
      "processInstanceVO":{
        "processInstanceId" :this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
        "processDefinitionId":"PROPOSAL_SETUP"
      }
    };
    this.workflowBaseService.getProposalStepAllocationData(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        let grandTotal = 0, grandTotalPerc = 0;
        if(response['responseData']){ 
          console.log(response['responseData']);
          this.taxStatus = response['responseData']['taxStatus'];
          this.getFeeTemplatesForUser();
        }
      }
    });

    
    //this.getCashFlowSelectionEnum();


    this.managementGridColumnDefs =
      [
        {
          "headerName": "",
          "checkboxSelection": true,
          "headerCheckboxSelection": true,
          "width": 30,
          "minWidth": 30,
          "pinned": "left",
          "suppressResize": true,
          "lockPosition": true,
          "suppressSorting": true,
          "suppressMenu": true
        },
        {
          "headerName": "",
          "width": 30,
          "minWidth": 30,
          "maxWidth": 30,
          "pinned": "left",
          "suppressResize": true,
          "lockPosition": true,
          "suppressMenu": true,
          "suppressSorting": true,
          "cellRenderer": 'EditIconRowTemplate',
          "hide": false,
          "noHide": true
        },
        // {
        //   "headerName": "Schedule Id",
        //   "field": "scheduleId",
        //   "minWidth": 80,
        //   "backendFieldName": "scheduleId",
        //   "suppressSorting": true,
        //   "suppressMenu": true,
        //   "filter": "SelectFilterComponent",
        //   "hide": false
        // },
        {
          "headerName": "Begin Amount",
          "field": "beginAmountView",
          "minWidth": 70,
          "backendFieldName": "beginAmount",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "End Amount",
          "field": "endAmountView",
          "minWidth": 70,
          "backendFieldName": "endAmount",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "IM Fee (%)",
          "field": "imaFeePercentageView",
          "minWidth": 70,
          "backendFieldName": "imaFeePercentage",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Advisory Fee (%)",
          "field": "advisoryFeePercentageView",
          "minWidth": 70,
          "backendFieldName": "advisoryFeePercentage",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Account Fee (%)",
          "field": "accountFeeView",
          "minWidth": 70,
          "backendFieldName": "accountFee",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        }
      ]
      var ele = document.getElementsByClassName('height-fix')[0];
      if(ele) {
        ele.classList.remove("app-hierarchy-view");
      }
  }

  ngOnDestroy() {
    var ele = document.getElementsByClassName('height-fix')[0];
    if(ele) {
      ele.classList.add("app-hierarchy-view");
    }
  }


  saveMinimumFee() {
    if (!this.minimumManagementFees) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter minimum fee';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      // this.cashFlowObjectiveService.saveMinimumFee(this.minimumManagementFees, this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.taxabilityType).subscribe((response) => {
      //   if (response['responseType'] == Variables.statusSuccess) {
      //     if (response["responseData"]) {
      //       this.authMessageService.showSuccessPopup(response);

      //     }

      //   }
      // });
      let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
      if(!feeTypeModel.advisorFee.length){
        let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please create at least one fee tier';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
      }
    feeTypeModel.advisorFee[0].minimumFee =this.minimumManagementFees;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
    }

  }

  

  updateFeeType(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if(feeTypeModel){
      this.managementGridRowData = feeTypeModel["advisorFee"];
      this.managementGridRowData.forEach(element => {
        if(element.accountFee)
         element.accountFeeView = element.accountFee + "%";

        if(element.imaFeePercentage)
         element.imaFeePercentageView = element.imaFeePercentage + "%";

        if(element.advisoryFeePercentage)
         element.advisoryFeePercentageView = element.advisoryFeePercentage + "%";

        if(element.beginAmount || element.beginAmount == 0)
          element.beginAmountView = this.convertToCommaSeperatedSingleVal(element.beginAmount.toString()) ;

        if(element.endAmount || element.endAmount == 0)
          element.endAmountView = this.convertToCommaSeperatedSingleVal(element.endAmount.toString()) ;

      });
      if(feeTypeModel.advisorFee.length)
        this.minimumManagementFees = feeTypeModel.advisorFee[0].minimumFee;
      else
        this.minimumManagementFees = 0;
      this.isUserLevel = feeTypeModel.isUserLevel;
      this.isEffective = feeTypeModel.isEffective; 
      this.isIncludeFeeInAnalysis = feeTypeModel.includeFeeInAnalysis; 
    }
    
  }

  updateApplyFeeType(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.applyFeeType });
    if(feeTypeModel){
      this.managementGridRowData = feeTypeModel["advisorFee"];
      this.managementGridRowData.forEach(element => {
        if(element.accountFee)
         element.accountFeeView = element.accountFee + "%";

        if(element.imaFeePercentage)
         element.imaFeePercentageView = element.imaFeePercentage + "%";

        if(element.advisoryFeePercentage)
         element.advisoryFeePercentageView = element.advisoryFeePercentage + "%";

        if(element.beginAmount || element.beginAmount == 0)
          element.beginAmountView = this.convertToCommaSeperatedSingleVal(element.beginAmount.toString()) ;

        if(element.endAmount || element.endAmount == 0)
          element.endAmountView = this.convertToCommaSeperatedSingleVal(element.endAmount.toString()) ;

      });
      if(feeTypeModel.advisorFee.length)
        this.minimumManagementFees = feeTypeModel.advisorFee[0].minimumFee;
      else
        this.minimumManagementFees = 0;
      this.isUserLevel = feeTypeModel.isUserLevel;
      this.isEffective = feeTypeModel.isEffective; 
    }
    
  }


  convertToCommaSeperatedSingleVal(row) {
    let value = row
    var partBeforeDecimal = '', partAfterDecimal = '';
    var index = value.indexOf('.')
    if (value == '$' || value == "") {
      row = '$0';
    } else {
      if (index > -1) {
        partBeforeDecimal = value.substr(0, index);
        partAfterDecimal = value.substr(index, value.length - index);
      } else {
        partBeforeDecimal = value;
      }

      if (partBeforeDecimal) {
        partBeforeDecimal = partBeforeDecimal.replace(/,/g, "").replace('$', "");
        row = '$' + (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
      }
    }
    return row;
  }

  updateTaxType(){
    this.feeTypeList = this.taxTypeFeeTypeMap[this.taxabilityType];
        if(this.feeTypeList.length){
          this.feeType = this.feeTypeList[0].objectId;
          this.updateFeeType();
        }
  }

  editName(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if(feeTypeModel){
      this.editedTemplateName = feeTypeModel.name ;
      this.isEditTemplateNameMode = true;
    }
    
  }

  getFeeTemplatesForUser() { 
    this.workflowBaseService.getFeeTemplatesForUser().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.taxTypeFeeTypeMap = response['responseData'];
        this.taxabilityTypeList = Object.keys(this.taxTypeFeeTypeMap);
        if(this.taxStatus == "TAX_DEFERRED")
          this.taxabilityType = this.taxabilityTypeList[0];
        else{
          this.taxabilityType = this.taxabilityTypeList[1];
        }
        this.feeTypeList = this.taxTypeFeeTypeMap[this.taxabilityType];
        if(this.feeTypeList.length){
          if(!this.feeType)
            this.feeType = this.feeTypeList[0].objectId;
          if(this.feeTypeList[0].advisorFee.length)
            this.minimumManagementFees = this.feeTypeList[0].advisorFee[0].minimumFee;
          this.updateFeeType();
        }
          
      }
    });
  }

  saveNewTemplate(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.applyFeeType });
    //let duplicateName = _.find(this.feeTypeList , {'name' : this.templateName});
    let duplicateName = _.find(this.taxTypeFeeTypeMap[this.taxabilityTypeList[0]],(item)=>{
      return item.name.toLowerCase() == this.templateName.toLocaleLowerCase()
    });
    let duplicateName2 = _.find(this.taxTypeFeeTypeMap[this.taxabilityTypeList[1]] ,(item)=>{
      return item.name.toLowerCase()==this.templateName.toLocaleLowerCase();
    });
    if (!this.templateName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    if (!this.templateName.trim()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter a valid template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    // if (duplicateName) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Template with this name already exists';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   this.isEditTemplateNameMode = false;
    //   return false;
    // }
    if (duplicateName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with this name already exists in'+" "+duplicateName.taxablityType;
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    else if(duplicateName2)
    {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with this name already exists in'+" "+duplicateName2.taxablityType;
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;

    }
    if(!feeTypeModel)
      feeTypeModel =  {};
    feeTypeModel.objectId = null;
    feeTypeModel.taxablityType=this.taxabilityType
    feeTypeModel.name = this.templateName;
    feeTypeModel.includeFeeInAnalysis=this.isIncludeFeeInAnalysis;
    feeTypeModel.isUserLevel =this.isUserLevel;
    if(!this.applyFeeType){
      feeTypeModel.advisorFee = []; 
    }
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('templatePopModal').close();
        this.updateFeeType();
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveTemplateName(){

    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    let duplicateName = _.find(this.feeTypeList , {'name' : this.editedTemplateName});
    if (!this.editedTemplateName.trim()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter a valid template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    
    if (duplicateName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with this name already exists';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    feeTypeModel.name = this.editedTemplateName;
    feeTypeModel.isUserLevel =this.isUserLevel;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveUserLevel(){
    if(this.isUserLevel){
      let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
      feeTypeModel.isUserLevel =this.isUserLevel;
      this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
    }
  }

  saveIncludeFeeAnalysis(){
  
      let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
      feeTypeModel.includeFeeInAnalysis =this.isIncludeFeeInAnalysis;
      this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveIsEffective(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if(this.isEffective && !feeTypeModel["advisorFee"].length){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with no fee tiers cannnot be made default';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      setTimeout(() => {
        
      this.isEffective = false;
      }, 500);
      
    }else{
      feeTypeModel.isEffective =this.isEffective;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
    }
    
  }

  resetFeeTemplate(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    feeTypeModel.isEffective =this.isEffective;
    this.workflowBaseService.resetFeeTemplate(this.isUserLevel,this.feeType).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveFeeTemplate() {
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    let lastRow = feeTypeModel["advisorFee"][feeTypeModel["advisorFee"].length-1];
    if (!this.investmentManagementFeesModel.beginAmount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Begin Amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!feeTypeModel["advisorFee"].length && this.investmentManagementFeesModel.beginAmount != 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Begin amount of first tier should be zero';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (lastRow && this.investmentManagementFeesModel.beginAmount <= lastRow.beginAmount+1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Begin Amount should be greater than begin amount of last fee tier';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (!this.investmentManagementFeesModel.endAmount) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter End Amount';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (!this.investmentManagementFeesModel.imaFeePercentage && !this.investmentManagementFeesModel.advisoryFeePercentage && !this.investmentManagementFeesModel.accountFee) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter either IM Fee % or Advisory Fee %  or Account Fee %';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (!this.investmentManagementFeesModel.advisoryFeePercentage) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter Advisory Percentage';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if(feeTypeModel["advisorFee"].length)
      feeTypeModel["advisorFee"][feeTypeModel["advisorFee"].length-1].endAmount = this.investmentManagementFeesModel.beginAmount -1;
    feeTypeModel.isUserLevel =this.isUserLevel;
    feeTypeModel["advisorFee"].push(this.investmentManagementFeesModel);
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('addFee').close();
        this.getFeeTemplatesForUser();

      }
    });
  }

  editFeeTemplate() {
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if (!this.investmentManagementFeesModel.beginAmount && this.investmentManagementFeesModel.beginAmount != 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Begin Amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (!this.investmentManagementFeesModel.endAmount) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter End Amount';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (!this.investmentManagementFeesModel.imaFeePercentage && !this.investmentManagementFeesModel.advisoryFeePercentage && !this.investmentManagementFeesModel.accountFee) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter either IM Fee % or Advisory Fee %  or Account Fee %';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    feeTypeModel.isUserLevel =this.isUserLevel;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('addFee').close();
        this.isEditTemplateNameMode = false;
        this.getFeeTemplatesForUser();

      }
    });
  }

  getEntityInformation() {
    this.cashFlowObjectiveService.getEntityInformation(this.maxCalculatorMainComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.cashFlowObjectiveModel.primaryInfo = response['responseData'].primaryInfo;
        this.cashFlowObjectiveModel.secondaryInfo = response['responseData'].secondaryInfo;
        this.cashFlowObjectiveModel.planningTimeHorizon = response['responseData'].planningTimeHorizon;
      }
    });
  }

  

  

  convertToCommaSeperated(row,key){
    let value = row[key];
    var partBeforeDecimal = '',partAfterDecimal='';
    var index = value.indexOf('.')
    if(index>-1){
      partBeforeDecimal = value.substr(0,index);
      partAfterDecimal = value.substr(index,value.length-index);
    }else {
      partBeforeDecimal = value;
    }

    if(partBeforeDecimal){
      partBeforeDecimal = partBeforeDecimal.replace( /,/g, "" );
      row[key] = (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
    }
    if(row['isPercentBased']){
      row[key]=row[key]+"%";
    }

  }


  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {
    this.investmentManagementFeesModel = rowData;
    this.investmentManagementFeesMode = 'edit';
    this.ngxSmartModalService.open('addFee');
  }

  addAdvisorFee(){
    this.investmentManagementFeesModel = new CalInvestmentManagementFeesModel();
    this.investmentManagementFeesMode = 'add';
    this.ngxSmartModalService.open('addFee');
  }

 

 


  deleteOutflowAfterConfirmation() {
    var outflowAccountList = this.outflowGridComponentReference.getSelectedRows();
    let requestObj = []
    outflowAccountList.forEach(element => {
      requestObj.push(element.cashflowId)
    });
    this.cashFlowObjectiveService.deleteOutflow(requestObj, this.maxCalculatorMainComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.outflowGridComponentReference.getGridDataAfterUpdatingRecords();
        this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
      }
    });
  }

  deleteFeeSchedule(): void {
    var list = this.gridApiMangagement.getSelectedRows();
    if (list && list.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (list && list[0].scheduleId != this.managementGridRowData[this.managementGridRowData.length - 1].scheduleId) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Only last record can be deleted';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (list) {
      this.deleteType = "managementFee";
      this.ngxSmartModalService.open('deleteConfirmationModal');
    }
  }

  deleteFeeScheduleAfterConfirmation() {
    var managementFeeList = this.gridApiMangagement.getSelectedRows();
    this.cashFlowObjectiveService.deleteManagementFees(managementFeeList[0].scheduleId, this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,this.taxabilityType).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
        this.getFeeTemplatesForUser();
      }
    });
  }

  /*delete fee template*/
  deleteFeeTemplate(){
    if(!this.feeType || this.feeType == "null")
    {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select template';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;

    }
    else{
      this.ngxSmartModalService.open('deleteTemplateConfirmationModal');
      
    }
    

  } 

  deleteTemplateAfterConfirmation()
  {
        let feeTypeModel=_.find(this.feeTypeList,{'objectId':this.feeType})
        feeTypeModel.isUserLevel=this.isUserLevel; 
        this.workflowBaseService.deleteSelectedFeeTemplate(this.isUserLevel,this.feeType).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
            this.getFeeTemplatesForUser();
    
          }
        });
        
        if(feeTypeModel.name=='IRA' || feeTypeModel.name=='IMA')
        {
          this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
        }
        else{
          feeTypeModel.advisorFee = []; 
         this.updateFeeType();
        }
  }
  
  /*add template pop*/
  addTemplate(){
    this.templateName=null;
    this.applyFeeType=null;
    this.ngxSmartModalService.open('templatePopModal');
  }
  

  /* On grid ready */
  onGridReady(params) {
    this.gridApiMangagement = params.api;
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    },200);
  }

 

  close() {
    this.divStatus = true;
  }

  


  

  adjustHeightGridViewport(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let optionDiv = $('.optionDiv')[0].clientHeight;
    let importFormDiv = $('.importFormDiv')[0].clientHeight;
    let priSecInfoDiv = $('.priSecInfoDiv')[0].clientHeight;
    let gridHeader = $('.app-grid-heading')[0].clientHeight;
    let margin = 5+ extraMargin;
    var total = header+ pageheader+ margin+ stepHeader+ optionDiv+ importFormDiv+ 2*gridHeader+ priSecInfoDiv
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if(ele) {
      ele.setAttribute('style',"height: calc((100vh - "+total+"px)*0.5);")
    }
    var ele1 = document.getElementsByTagName('ag-grid-angular')[1];
    if(ele1) {
      ele1.setAttribute('style',"height: calc((100vh - "+total+"px)*0.5);")
    }
  }



}





