import { Component, OnInit, Input, Renderer2, ViewChild, ApplicationRef } from "@angular/core";

import { NgxSmartModalService } from "ngx-smart-modal";
import { Variables } from "src/app/constants";
import { EventEmitter } from 'events';
import { Router } from '@angular/router';
import { PresentationService } from "src/app/_services/presentation.service";
import { GridComponent } from '../../shared/grid/grid.component';
import { AuthMessageService } from "../../_services/auth-message.service";
import 'lodash';
import { LeftNavService } from "src/app/_services/left-nav.service";
import { FilterModel } from "../../_models/filter.model";

import { CustomCompileService } from "src/app/_services/custom-compile.service";
import { CommonFunctions } from 'src/app/common';
declare var _: any;
@Component({
  selector: "app-presentation",
  templateUrl: "./presentation.component.html",
  styleUrls: ["./presentation.component.less"]
})
export class PresentationComponent implements OnInit {

  userList = [];
  assignProposalTo = null;
  componentReference: {};
  selectedRows: any[];
  userId;
  colName;
  filterVO = new FilterModel();
  commonFunction = new CommonFunctions();
  @ViewChild(GridComponent) gridComponentData: GridComponent;
  apiParams: {};
  constructor(
    private compileService: CustomCompileService,
    private appRef: ApplicationRef,
    public ngxSmartModalService: NgxSmartModalService,
    public presentationService: PresentationService,
    private authMessageService: AuthMessageService,
    public renderer: Renderer2,
    private router: Router,
    private leftNavData: LeftNavService,


  ) {
    compileService.configure(this.appRef);
    this.componentReference = { componentParent: this };
  }
  mainContent: boolean = true;
  message: boolean = true;
  ngOnInit() {
    this.getUserListData();
    this.leftNavData.currentMessage.subscribe(message => {
      this.message = message;
      this.changeContainerSize();
    });
  }

  changeContainerSize() {
    if (this.message) {
      this.mainContent = true;
    } else {
      this.mainContent = false;
    }

  }

   /* Goto workflow */
   gotoWorkflow(node) {
     if(node.progressStatus=="Completed"){

     }else{
    if (node.processInstanceId) {
      localStorage.removeItem('workflowInfoData');
      let workflowInfoData = {
        processInstanceId: node.processInstanceId
      }

      if(node.proposalType == "MAX"){
        workflowInfoData["processDefinitionKey"] = "FINANCIAL_PLANNING_WORKFLOW";
        localStorage.setItem('workflowInfoData', JSON.stringify(workflowInfoData));
        this.router.navigate(['/proposalWorkflow']);
      }else{
        workflowInfoData["processDefinitionKey"] = "ALLOCATION_CALCULATOR";
        localStorage.setItem('workflowInfoData', JSON.stringify(workflowInfoData));
        this.router.navigate(['/maxCalculator']);
      }
    }
  }
}

  gridLinkClicked(){
    
  }

  getUserListData() {
    this.presentationService
      .getUserListData().subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.userList = response["responseData"];
          // response["responseData"].forEach(element => {
          //  this.userList.push(element.userName);
          // });
        }
      });
  }

  downloadRowData(rowData) {
    var link = rowData.reportURI;
    if (link == null) {
      let error = {
        errorCode: "ERR0107",
        message: "Please complete the workflow"
      };
      this.authMessageService.showErrorPopup(error);
    } else {

      let apiParams = {
        processInstanceId: rowData.processInstanceId
      }
      this.presentationService.downloadReportData(apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          let fileData = response['responseData'].fileData;
          let fileName = response['responseData'].fileName;
          if (fileData && fileName) {
            CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
          }
        }
      });
    }
  }

  previewRowData(rowData) {
    var link = rowData.reportURI;
    if (link == null) {
      let error = {
        errorCode: "ERR0107",
        message: "Please complete the workflow"
      };
      this.authMessageService.showErrorPopup(error);
    } else {

      let apiParams = {
        processInstanceId: rowData.processInstanceId
      }
      this.presentationService.downloadReportData(apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          let fileData = response['responseData'].fileData;
          let fileName = response['responseData'].fileName;
          if (fileData && fileName) {
            CommonFunctions.previewPdfUsingByteStream(fileData);
          }
        }
      });
    }
  }

  deletePresentation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length != 0) {
       var flagCheck = 1;
      // //var countWorkflow = 0
      // this.selectedRows.forEach(element => {
      //   if (element.progressStatus == "In Progress") {
      //     flagCheck = 0;
          //countWorkflow += 1;
       // }
      //});
      if (flagCheck) {
        this.ngxSmartModalService.open('deletePresentationModal');
      }//  else {
      //   let errorObj = {};
      //   errorObj['errorCode'] = 'ERR0107';
      //   //errorObj['message'] = String(countWorkflow)+ " Presentation(s) is/are In Progress ---can't be deleted" ;
      //   errorObj['message'] = " Presentation(s) is In Progress,can't be deleted" ;
      //   errorObj['errorDescription'] = '';
      //   this.authMessageService.showErrorPopup(errorObj);
      // }

    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }


  deletePresentationAfterConfirmation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    // this.filterVO = this.gridComponentData.filterVO;

    // this.filterVO.dataStoreIdList = [];
    var apiParams = []
    this.selectedRows.forEach(element => {
      var obj = { 'processInstanceId': '' }
      obj.processInstanceId = element.processInstanceId;
      obj['processDefinitionKey'] = element.processDefinitionKey;
      apiParams.push(obj);
    });

    this.presentationService.deletePresentationService(apiParams)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          response['message'] = "Presentation deleted successfully";
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deletePresentationModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }





  assignPresentation() {
    var selectedRows = this.gridComponentData.getSelectedRows();
    if(selectedRows.length == 0){
      let error = {
        errorCode: "ERR0107",
        message: "Please select presentation"
      };
      this.authMessageService.showErrorPopup(error);
    } else if (selectedRows.length > 1) {
      let error = {
        errorCode: "ERR0107",
        message: "Can't edit multiple relationship"
      };
      this.authMessageService.showErrorPopup(error);
    } else {
      this.presentationService
        .saveAssignTo(selectedRows[0]["dataStoreId"], this.assignProposalTo).subscribe(response => {
          if (response["responseType"] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.gridComponentData.getGridDataAfterUpdatingRecords();
          }
        });
    }

  }
}

