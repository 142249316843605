import { Component, OnInit, Renderer2, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { GridService } from "../../_services/grid.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { TransfereService } from "../../_services/transferService.service";
import { RelationshipsService } from "../../_services/relationships.service";
import { Variables } from "../../constants";
import {ResponseVO,ErrorModel} from '../../_models/response.model';
import { AuthMessageService } from "../../_services/auth-message.service";
import { BaseService } from "../../_services/base.service";
import { WorkflowBaseService } from "../../_services/workflow-services/workflow-base.service";
import { FilterModel } from "src/app/_models/filter.model";
import { SearchSort } from "src/app/_models/search-sort.model";
import { LeftNavService } from "src/app/_services/left-nav.service";
declare var _: any;

@Component({
  selector: "app-relationships",
  templateUrl: "./relationships.component.html",
  styleUrls: ["./relationships.component.less"]
})
export class RelationshipsComponent implements OnInit {
  cols: any[];
  rowData: any[];
  rightNavigationMenuList:any[];
  selectedNodes: any[];
  colName: string;
  userId;
  selectedPresentation : any=null;
  passedFrom: any[];
  tempData: Object[];
  innerChild: any;
  dataToDelete: [any];
  public filterVO = new FilterModel();
  loading: boolean;
  itemsPerPage = Variables.itemsPerPageArr;
  rowsPerPage: number;
  totalItems: number;
  selectedPresentaion :any=null;
  message:boolean = true;
  mainContent: boolean = true;

  constructor(
    private gridService: GridService,
    public  ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private workflowBaseService : WorkflowBaseService,
    private baseService : BaseService,
    private transferService: TransfereService,
    private relationshipsService: RelationshipsService,
    private authMessageService: AuthMessageService,
    public renderer: Renderer2,
    private zone: NgZone,
    private leftNavData: LeftNavService
  ) {}

  ngOnInit() {
    this.getRelationshipData();
    this.getConfigData();
    this.leftNavData.currentMessage.subscribe(message =>{
      this.message = message;
      this.changeContainerSize();
    });
    this.getRightNavigationData('ProposalType');
  }

  changeContainerSize(){
    if(this.message){
      this.mainContent = true;
    }else{
      this.mainContent = false;
    }

  }

  getRightNavigationData(requestData) {
    this.baseService.getRightNavigationData(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.rightNavigationMenuList = response['responseData'];
      }
    });
  }

  startNewProcess(item) {
    if(item!="null"){
      var relationshipNodes = [];
    relationshipNodes = _.filter(this.selectedNodes, {"parent": null});
    if(item == "ALLOCATION_CALCULATOR"){
      var requestObj = {
        "processDefinitionKey": item
      }
      this.workflowBaseService.startNewProcess(requestObj).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          localStorage.setItem('workflowInfoData', JSON.stringify(response['responseData']));
          this.router.navigate(['/maxCalculator']);
        }
      });
    }
    else if(relationshipNodes.length == 0){
      let error = {
        errorCode: "ERR0107",
        message:"Please select a relationship for creating presentation"
      };
      this.authMessageService.showErrorPopup(error);
    }else if(relationshipNodes.length > 1){
      let error = {
        errorCode: "ERR0107",
        message:"Can't  create presentation for multiple relationships"
      };
      this.authMessageService.showErrorPopup(error);
    }else if(relationshipNodes.length >= 0 && this.selectedNodes.length > 1){
      let error = {
        errorCode: "ERR0107",
        message:"Please select only relationship for creating presentation"
      };
      this.authMessageService.showErrorPopup(error);
    }else{
      var requestObj = {
        "processDefinitionKey": item
      }
      this.workflowBaseService.startNewProcess(requestObj).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          localStorage.setItem('workflowInfoDataRelationId',relationshipNodes[0]["data"]["id"] );
          localStorage.setItem('workflowInfoData', JSON.stringify(response['responseData']));
          this.router.navigate(['/proposalWorkflow']);
        }
      });
    }
    }
    
  }

  getConfigData() {
    this.cols = [
      {
        field: "name",
        header: "Name",
        width: "18.84%",
        name: "name",
        visibleName: "Name",
        sort: true,
        filter: false,
        filterType: "none",
        textAlign: "left",
        enableHiding: "true",
        showEllipsis: "true",
        showTooltip: "true",
        tooltipPlacement: "Type",
        backendFieldName: "name"
      },
      // {
      //   field: "shortName",
      //   header: "Short Name",
      //   width: "14.28%",
      //   name: "shortName",
      //   visibleName: "Short Name",
      //   sort: true,
      //   filter: false,
      //   filterType: "none",
      //   textAlign: "left",
      //   enableHiding: "true",
      //   showEllipsis: "true",
      //   showTooltip: "true",
      //   tooltipPlacement: "Type",
      //   backendFieldName: "shortName"
      // },
      // {
      //   field: "id",
      //   header: "Id",
      //   width: "14.28%",
      //   name: "Name",
      //   visibleName: "name",
      //   sort: true,
      //   filter: false,
      //   filterType: "none",
      //   textAlign: "left",
      //   enableHiding: "true",
      //   showEllipsis: "true",
      //   showTooltip: "true",
      //   tooltipPlacement: "Type",
      //   backendFieldName: "clientHierarchyId"
      // },
      {
        field: "type",
        header: "Type",
        width: "12%",
        name: "Name",
        visibleName: "name",
        sort: false,
        filter: false,
        filterType: "none",
        textAlign: "left",
        enableHiding: "true",
        showEllipsis: "true",
        showTooltip: "true",
        tooltipPlacement: "Type",
        backendFieldName: "objectType"
      },
      {
        field: "investableAssetsStr",
        header: "Investable Assets",
        width: "12%",
        name: "Name",
        visibleName: "name",
        sort: true,
        filter: false,
        filterType: "none",
        textAlign: "left",
        enableHiding: "true",
        showEllipsis: "true",
        showTooltip: "true",
        tooltipPlacement: "Type",
        backendFieldName: "investableAssetInBaseCurrency.decimalValue"
      },
      {
        field: "marketValueStr",
        header: "TMV",
        width: "12%",
        name: "Name",
        visibleName: "name",
        sort: true,
        filter: false,
        filterType: "none",
        textAlign: "left",
        enableHiding: "true",
        showEllipsis: "true",
        showTooltip: "true",
        tooltipPlacement: "Type",
        backendFieldName: "marketValueInSysCurrency.decimalValue"
      },
      {
        name: 'modifiedBy',
        visibleName: 'Updated By',
        sort: true,
        search: true,
        filterType: false,
        textAlign: 'left',
        width: "12%",
        enableHiding: true,
        showEllipsis: true,
        showTooltip: true,
        tooltipPlacement: 'Type',
        field: 'modifiedBy',
        header: 'Updated By',
        backendFieldName:'modifiedBy'
      },
      {
        name: 'modifiedDate',
        visibleName: 'Updated Date',
        sort: true,
        search: true,
        filterType: false,
        textAlign: 'left',
        width: "12%",
        enableHiding: true,
        showEllipsis: true,
        showTooltip: true,
        tooltipPlacement: 'Type',
        field: 'modifiedDate',
        header: 'Updated Date',
        backendFieldName:'modifiedDate'
      },
      {
        name: 'createdBy',
        visibleName: 'Created By',
        sort: true,
        search: true,
        filterType: false,
        textAlign: 'left',
        width: "12%",
        enableHiding: true,
        showEllipsis: true,
        showTooltip: true,
        tooltipPlacement: 'Type',
        field: 'createdBy',
        header: 'Created By',
        backendFieldName:'createdBy'
      },
      {
        name: 'createdDate',
        visibleName: 'Created Date',
        sort: true,
        search: true,
        filterType: false,
        textAlign: 'left',
        width: "12%",
        enableHiding: true,
        showEllipsis: true,
        showTooltip: true,
        tooltipPlacement: 'Type',
        field: 'createdDate',
        header: 'Created Date',
        backendFieldName:'createdDate'
      },
      // {
      //   field: "advisor",
      //   header: "Advisor",
      //   width: "14.28%",
      //   name: "Name",
      //   visibleName: "name",
      //   sort: true,
      //   filter: false,
      //   filterType: "none",
      //   textAlign: "left",
      //   enableHiding: "true",
      //   showEllipsis: "true",
      //   showTooltip: "true",
      //   tooltipPlacement: "Type",
      //   backendFieldName: "advisor"
      // },
      // {
      //   field: "decisionMaker",
      //   header: "Decision Maker",
      //   width: "14.28%",
      //   name: "Name",
      //   visibleName: "name",
      //   sort: true,
      //   filter: false,
      //   filterType: "none",
      //   textAlign: "left",
      //   enableHiding: "true",
      //   showEllipsis: "true",
      //   showTooltip: "true",
      //   tooltipPlacement: "Type",
      //   backendFieldName: "decisionMaker"
      // }
    ];

    this.setSearchSortView();
  }

  showFilter(filterName: string) {
    this.colName = filterName;
    this.ngxSmartModalService.getModal("filter").open();
  }

  checkFilter(value) {
    return value;
  }

  addRelation() {
    this.transferService.clearData();
    localStorage.setItem('dataItem', null);
    this.router.navigate(["/add-edit-relationship"]);
  }
  editRelation() {
    var relationshipNodes = [];
    relationshipNodes = _.filter(this.selectedNodes, {"parent": null});
    if(relationshipNodes.length == 0){
      let error = {
        errorCode: "ERR0107",
        message:"Please select relationship for editing"
      };
      this.authMessageService.showErrorPopup(error);
    }else if(relationshipNodes.length > 1){
      let error = {
        errorCode: "ERR0107",
        message:"Can't edit multiple relationship"
      };
      this.authMessageService.showErrorPopup(error);
    }else if(relationshipNodes.length >= 0 && this.selectedNodes.length > 1){
      let error = {
        errorCode: "ERR0107",
        message:"Please select only relationship for editing in the relationship hierarchy"
      };
      this.authMessageService.showErrorPopup(error);
    }
    else{
      this.transferService.clearData();
      this.transferService.setData(this.selectedNodes);
      this.router.navigate(["/add-edit-relationship"]);
    }

  }

  getRelationshipData() {
    this.relationshipsService
      .getRelationshipsData(this.filterVO)
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.prepareData(response["responseData"]["result"]);
          this.totalItems =
            response["responseData"]["paginationInfoVO"]["totalItems"];
          this.rowsPerPage =
            response["responseData"]["paginationInfoVO"]["itemsPerPage"];
          var wait = setInterval(()=>{
            if($('.app-pages-heading')[0].clientHeight){
              clearInterval(wait)
              this.relationshipTable(0);  
            }
          })
        }
      });
  }
  /* Adjust Grid according to viewport */ 
  relationshipTable(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let margin = 230+ extraMargin;
    var total = header+pageheader+margin;
    var ele = document.getElementsByClassName('ui-treetable-scrollable-body')[0];
    if(ele) {
      ele.setAttribute('style',"max-height : calc(100vh - "+total+"px);")
    }
  }

  onNodeSelect(event){
    this.selectedPresentation = null;
  }
  onNodeUnselect(event){
    this.selectedPresentation = null;
  }
  onNodeExpand(event){

  }

  prepareData(dataSet) {
    this.rowData = [];
    for (let entry of dataSet) {
      let singleData = {};
      singleData["data"] = entry;
      singleData["data"]["id"] = entry.clientId;
      singleData["data"]["type"] = entry.objectType;
      if(entry.children){
        singleData["children"] = [];
        entry.children.forEach(element => {
          var ele = {"data":element,"children":[]};
            ele["data"]["id"] = element.ownershipId
            ele["data"]["type"] = element.objectType;
          if(element.children){
            element.children.forEach(innerElement => {
              var innerEle = {"data":innerElement,"children":[]}
              innerEle["data"]["id"] = innerElement.accountId;
              innerEle["data"]["type"] = innerElement.objectType;
              ele["children"].push(innerEle);
            });
          }
          singleData["children"].push(ele);
        });
      }
      this.rowData.push(singleData);
    }
  }

  getLegalEntityListData(data) {
    data["children"] = [{data:''}];
    this.relationshipsService
      .getLegalEntityListData(data["data"]["dataStoreId"],data["data"].relationshipId)
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.setClientData(response["responseData"], data);
        }
      });
  }

  getAccountListData(data) {
    data["children"] = [];
    this.relationshipsService
      .getAccountListData(data["data"]["dataStoreId"])
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.setAccountData(response["responseData"], data);
        }
      });
  }

  setClientData(clientData, relationshipNode) {
    // relationshipNode.children =[];
    // for (let child of clientData) {
    //   relationshipNode.children.push({data:''});
    //  }
          var i = 0;
          for (let child of clientData) {
              relationshipNode.children[i]["data"] = child;
              relationshipNode.children[i]["data"]["id"] = child.ownershipId;
              relationshipNode.children[i]["children"] = [];
            // if(i==1){
            //   relationshipNode.children[i]["data"] = child;
            //   relationshipNode.children[i]["data"]["id"] = child.ownershipId;
            //   relationshipNode.children[i]["children"] = [{}];
            // } else{
            //   var singleChild = {};
            //   singleChild["data"] = child;
            //   singleChild["data"]["id"] = child.ownershipId;
            //   singleChild["children"] = [{}];
            //   relationshipNode.children.push(singleChild);
            // }
            this.loading = false;
            i++;
          }
  }

  setAccountData(accountData, clientNode) {
    clientNode.children = [];
    accountData.forEach(element => {
      clientNode.children.push({
        data: element
      });
    });
    this.loading = false;
  }

  sortColumn(event, colName) {
    var parentId = event.srcElement.parentElement.id;


    /* Resetting Dom Data as inital*/
    this.filterVO.searchParamsInfoVO.forEach(element => {
      if (
        <HTMLElement>(
          document.getElementById("relationshipGrid-" + element.fieldName)
        )
      ) {
        var selectedElementDOM = <HTMLCollection>(
          document.getElementById("relationshipGrid-" + element.fieldName)
            .children
        );
        this.renderer.removeClass(selectedElementDOM[0], "fa-sort-up");
        this.renderer.removeClass(selectedElementDOM[0], "fa-sort-down");
        this.renderer.addClass(selectedElementDOM[0], "fa-sort");
      }
    });
    var sortOnColumn = _.find(this.filterVO.searchParamsInfoVO, function(o) {
      return o.fieldName == colName;
    });
    var sortcolumnId = "relationshipGrid-" + colName;
    if (sortOnColumn.sortingOrder == "") {
      /* Resetting Model Data as inital*/
      this.filterVO.searchParamsInfoVO.forEach(element => {
        element.sortingOrder = "";
      });
      var selectedElementDOM = <HTMLCollection>(
        document.getElementById(sortcolumnId).children
      );
      this.renderer.addClass(selectedElementDOM[0], "fa-sort-up");
      sortOnColumn.sortingOrder = "ASC";
    } else if (sortOnColumn.sortingOrder == "ASC") {
      /* Resetting Model Data as inital*/
      this.filterVO.searchParamsInfoVO.forEach(element => {
        element.sortingOrder = "";
      });
      var selectedElementDOM = <HTMLCollection>(
        document.getElementById(sortcolumnId).children
      );
      this.renderer.addClass(selectedElementDOM[0], "fa-sort-down");
      sortOnColumn.sortingOrder = "DESC";
    } else if (sortOnColumn.sortingOrder == "DESC") {
      /* Resetting Model Data as inital*/
      this.filterVO.searchParamsInfoVO.forEach(element => {
        element.sortingOrder = "";
      });
      sortOnColumn.sortingOrder = "";
    }
    this.getRelationshipData();
  }

  paginate(event) {
    this.filterVO.paginationInfoVO.currentPage = event.page + 1;
    this.filterVO.paginationInfoVO.itemsPerPage = event.rows;

    this.getRelationshipData();
  }

  setSearchSortView() {
    for(let item of this.cols){
      let tempSearchSort = new SearchSort();
      if(item.sort || item.search){
        tempSearchSort.fieldName = item.backendFieldName;
        if(tempSearchSort.fieldName == "modifiedDate"){
          tempSearchSort.sortingOrder = 'DESC'
        }
        this.filterVO.searchParamsInfoVO.push(tempSearchSort);
      }
    }
    setTimeout(()=>{
      let modifiedDate = (<HTMLElement>document.getElementById('relationshipGrid-modifiedDate'));

      this.renderer.removeClass(modifiedDate.children[0], "fa-sort");
      this.renderer.addClass(modifiedDate.children[0], "fa-sort-down");
    },0)
  }


  deleteRelationshipDataConfirmation(){
    var isAccountOnBook = false;
    this.selectedNodes.forEach(element => {
      if(element.data.objectType == 'Account'){
        if(!isAccountOnBook)
          isAccountOnBook = element.data.isAccountOnBook;
        if(!isAccountOnBook && element.data.children){
          element.data.children.forEach(element2 => {
            if(element2.data.objectType == 'Account'){
              if(!isAccountOnBook)
              isAccountOnBook = element2.data.isAccountOnBook;
              if(!isAccountOnBook && element2.data.children){
                element2.data.children.forEach(element3 => {
                  if(element3.data.objectType == 'Account'){
                    if(!isAccountOnBook)
                    isAccountOnBook = element3.data.isAccountOnBook;
                  }
                });
              }
            }
          });
        }


      }
    });
    if(isAccountOnBook){
      var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Internal account cannot be deleted';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
    }else{
      if(this.selectedNodes && this.selectedNodes.length > 0){
        this.ngxSmartModalService.open('deleteConfirmationModal');
      }else{
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please select any record for deleting';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
      }
    }

  }

  deleteRecordsAfterConfirmation() {
    let relationshipListForDeleting = [];
    let clientListForDeleting = [];
    let accountListForDeleting = [];

    this.selectedNodes.forEach(element => {
      if(element.data.objectType == 'Relationship'){
        relationshipListForDeleting.push(element.data.dataStoreId);
      } else if(element.data.objectType == 'Owner'){
        clientListForDeleting.push(element.data.dataStoreId);
      } else if(element.data.objectType == 'Account'){
        accountListForDeleting.push(element.data.dataStoreId);
      }
    });

    if(accountListForDeleting && accountListForDeleting.length > 0){
      let tempFilterVO = {};
      Object.assign(tempFilterVO, this.filterVO);
      tempFilterVO['dataStoreIdList'] = accountListForDeleting;
      this.relationshipsService
      .deleteAccountData(tempFilterVO)
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          response['message']="Selected records deleted successfully"
          this.authMessageService.showSuccessPopup(response);
          this.getRelationshipData();
        }
      });
    }
    if(clientListForDeleting && clientListForDeleting.length > 0){
      let tempFilterVO = {};
      Object.assign(tempFilterVO, this.filterVO);
      tempFilterVO['dataStoreIdList'] = clientListForDeleting;
      this.relationshipsService
      .deleteClientData(tempFilterVO)
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          response['message']="Selected records deleted successfully"
          this.authMessageService.showSuccessPopup(response);
          this.getRelationshipData();
        }else{
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = response['messageList'];
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
        }
      });
    }
    if(relationshipListForDeleting && relationshipListForDeleting.length > 0){
      let tempFilterVO = {};
      Object.assign(tempFilterVO, this.filterVO);
      tempFilterVO['dataStoreIdList'] = relationshipListForDeleting;
      this.relationshipsService
      .deleteRelationshipData(tempFilterVO)
      .subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          response['message']="Selected records deleted successfully"
          this.authMessageService.showSuccessPopup(response);
          this.getRelationshipData();
        }
      });
    }

    this.selectedNodes = [];
    this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
  }





}
