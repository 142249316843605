import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { Variables } from '../../../../constants';

@Component({
  selector: 'product-holdings-tab',
  templateUrl: './product-holdings-tab.component.html',
  styleUrls: ['./product-holdings-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductHoldingsTabComponent implements OnInit {
  productId: any;
  productHoldingsData = {};
  
  productEquitySectorAllocationColumnDefs = [];
  productEquitySectorAllocationRowData = [];

  productTopHoldingsColumnDefs = [];
  productTopHoldingsRowData = [];
  gridApi: any;
  gridColumnApi: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private catalogProductInfoService: CatalogProductInfoService
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });

    this.productTopHoldingsColumnDefs = [
      { headerName: "Name", field: "name", width: 100 },
      { headerName: "Ticker", field: "ticker", width: 100 },
      { headerName: "Weight", field: "percentageWeight", width: 100 }
    ];

    this.productEquitySectorAllocationColumnDefs = [
      { headerName: "Sector", field: "sectorName", width: 100 },
      { headerName: "Product Weight", field: "productPercentWeightStr", width: 100 },
      { headerName: "Benchmark Weight", field: "benchmarkPercentWeightlStr", width: 100 },
      { headerName: "Variance", field: "varianceStr", width: 100 }
    ];

    this.getProductHoldingsData(this.productId);
  }

  getProductHoldingsData(productId) {
    this.catalogProductInfoService.getProductHoldingsInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productHoldingsData = response['responseData'];
          console.log(this.productHoldingsData);

          this.productEquitySectorAllocationRowData = this.productHoldingsData['productEquitySectorAllocationVO'];
          this.productTopHoldingsRowData = this.productHoldingsData['productTopHoldingsVO'];
        }
      });
  }


  /* On grid ready */
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

}
