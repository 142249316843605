import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class RelationshipOverviewService {

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };

    constructor(private http: HttpClient) { }

    saveBalanceSheetData(data) {
        return this.http.post(ApiUrls.saveBalanceSheetDataAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteBalanceSheets(filterInfoVO) {
        return this.http.post(ApiUrls.deleteBalanceSheetsAPI, filterInfoVO, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getInvestorInfo(requestData) {
        return this.http.get(ApiUrls.getInvestorInfoAPI + '?processInstanceId=' + requestData, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getExternalPartyData(requestData) {
        return this.http.get(ApiUrls.getExternalPartyInfoAPI+ '?processInstanceId=' + requestData, this.httpOptions)
            .pipe(
                response => response
            );
    }

    updateInvestorInfo(investorInfo) {
        return this.http.post(ApiUrls.updateInvestorInfoAPI, investorInfo, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getTaxInfoEnums() {
        return this.http.get(ApiUrls.getTaxInfoEnums, this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveTaxInfo(taxInfo) {
        return this.http.post(ApiUrls.saveTaxInfoAPI, taxInfo, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getTaxInfoData(apiParams) {
        return this.http.post(ApiUrls.getTaxInfoLisitngAPI + '?processInstanceId=' + apiParams.processInstanceId + '&taskDefinitionKey=' + apiParams.taskDefinitionKey + '&processDefinitionKey=' + apiParams.processDefinitionKey , this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteTaxInfo(filterInfoVO) {
        return this.http.post(ApiUrls.deleteTaxInfoAPI, filterInfoVO, this.httpOptions)
            .pipe(
                response => response
            );
    }
    sendFreeFormDetail(formBody) {
        return this.http.post(ApiUrls.saveFreeFormAPI,formBody,this.httpOptions)
            .pipe(
                response => response
            );
    }

    getFreeFormData(processInstanceId){
        return this.http.get(ApiUrls.getFreeFormAPI + '?processInstanceId=' + processInstanceId , this.httpOptions)
        .pipe(
            response => response
        );
    }

}
