import { Component } from '@angular/core';
import { GridFilterSortingService } from '../../../_services/grid-filter-sorting.service';

declare var _: any;
declare var $: any;
@Component({
  selector: 'app-like-filter',
  templateUrl: './like-filter.component.html',
  styleUrls: ['./like-filter.component.less']
})
export class LikeFilterComponent{

  params: any;
  private columnFilterData;

  constructor(
      private gridFilterSortingService: GridFilterSortingService
  ) { }

  agInit(params): void {
      this.params = params;
  }



  onApplyingFilter() {
      this.columnFilterData = this.params.column.colDef.searchSortData;
      this.gridFilterSortingService.setSortingData(this.columnFilterData);
  }

  onClearingFilter() {
      this.columnFilterData = this.params.column.colDef.searchSortData;
      this.columnFilterData.filterValue = "";
      this.columnFilterData.searchedValue = [];
      this.gridFilterSortingService.setSortingData(this.columnFilterData);
      //For removal of checkboxes selection on UI
      let listOfCheckboxes = document.getElementsByClassName(this.columnFilterData.fieldName + '_class');
      for (let key in listOfCheckboxes) {
          if (listOfCheckboxes.hasOwnProperty(key)) {
              listOfCheckboxes[key]['checked'] = false;
          }
      }


  }

  refresh(): boolean {
      return true;
  }

}
