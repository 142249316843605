import {Component} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'download-icon',
    template: `<div class="float-left w-100" >
                <input type="checkbox" *ngIf="params.node.data.isIncludedInFPW==true || params.node.data.isIncludedInFPW==false"   [(ngModel)]="params.node.data.isIncludedInFPW" (click)="gridButtonClicked(params.node.data.isIncludedInFPW)" />
            </div>`,
    styles   : []
})
export class ButtonTypeRowTemplate implements ICellRendererAngularComp {
    params:any;

    agInit(params:any):void {
        this.params = params;
    }

    gridButtonClicked(flag){
        this.params.context.componentParent.includePortfoliosInWorklow(!flag,this.params.node.data);
    }


    refresh(): boolean {
        return false;
    }
}