import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { AnalysisService } from '../../../../../_services/workflow-services/analysis.service';
import { Variables } from 'src/app/constants';

@Component({
  selector: 'app-goal-achievability',
  templateUrl: './goal-achievability.component.html',
  styleUrls: ['./goal-achievability.component.less']
})
export class GoalAchievabilityComponent implements OnInit {
  dropDownData: any;

  constructor(
    private analysisService: AnalysisService
  ) {
  }
  @Input() inputAnalysisData;
  @Input() inputRequestData;
  chartOption: EChartOption;
  goalAchievabiltyResponse: any = [];
  successMessage = '';
  labelsData: any=[];
  specificStrategy="";
  cashFlowList:any[];
  requestData:{};

  ngOnChanges() {
    this.goalAchievabiltyResponse = [];
    this.successMessage = '';
    this.labelsData = [];

    if (this.inputAnalysisData) {
      //console.log(this.inputAnalysisData);
      this.dropDownData=this.inputAnalysisData.strategyOptions;
      this.labelsData=this.inputAnalysisData['goalAchievabilityChartVO'].labels;
      this.goalAchievabiltyResponse = this.inputAnalysisData['goalAchievabilityChartVO'].chartData
      this.successMessage = this.inputAnalysisData['probOfSuccessMessage'];
      this.specificStrategy=this.inputAnalysisData.strategyOptions[0];
      this.cashFlowList=this.inputAnalysisData['cashFlowTimeHorizonList'];
    }
    this.requestData = this.inputRequestData;
    this.setLineAreaChartData();
    //console.log(this.successMessage);
  }


  getDataBasedOnAnalysisType() {
    if(this.requestData){
      this.requestData["specificStrategyId"] = this.specificStrategy["value"];
      this.analysisService.getanalysisinfo(this.requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            this.cashFlowList = response["responseData"].cashFlowTimeHorizonList;
        }
      });
    }
    
  }


  setLineAreaChartData() {
    var seriesDataList = [];
    var labels = null;

    for (let i = 0; i < this.goalAchievabiltyResponse.length; i++) {
      var seriesData = {};
      seriesData['name']=this.labelsData[i];
      seriesData['type'] = 'line';
      seriesData['areaStyle'] =  {normal: {areaStyle: {type: 'default'}}};
      seriesData['data'] = this.goalAchievabiltyResponse[i].endValues;
      seriesDataList.push(seriesData);
      if (this.goalAchievabiltyResponse.length == (i + 1)) {
        labels = this.goalAchievabiltyResponse[i].dateValues;
      }

    }
    //console.log(seriesDataList);
    console.log(this.labelsData);
   // console.log(labels);
    this.chartOption = {
      title: {
           text:this.successMessage,
           top:"bottom"
      },
      
      legend: {
        data: this.labelsData,
        orient:"vertical",
        right:true,
        top:-5
      },
      tooltip: {
        trigger: 'axis',
       
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels
        
      },
      yAxis: {
        type: 'value'
      },
      series: seriesDataList
    }
  }



  ngOnInit() {
  }

}
