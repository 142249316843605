import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../_models/user.model';
import { AuthenticationService } from '../../_services/authentication.service';
import { Variables } from '../../constants';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthMessageService } from '../../_services/auth-message.service';
import { SettingsService } from '../../_services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  user = new User();
  userId : string;
  authCodeAccepter:boolean;
  twoFactorCode : string;
  setShouldForcePasswordReset : boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private authMessageService : AuthMessageService,
    private settingsService: SettingsService
  ){ }

  ngOnInit() {
  }

  /* Authenticate user */
  authenticateUser(){
    let userModel =  Object.assign({}, this.user);
    if(this.authCodeAccepter)
    {
     if(!this.twoFactorCode)
     {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter MFA code';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
     }
    }
    if(this.user.isTwoFactorCode){
        userModel.password = this.twoFactorCode;
    }
    this.authenticationService.login(userModel).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
          if(response['responseData']['twoFactorEnabled'] == true){
            this.user.isTwoFactorCode = response['responseData']['twoFactorEnabled'];
            this.authCodeAccepter = true;
            this.setShouldForcePasswordReset = response['responseData']['setShouldForcePasswordReset'];

          } else{
            this.setShouldForcePasswordReset = response['responseData']['setShouldForcePasswordReset'];
            this.setInitialsAfterLogin(response);
          }
      }
    });
  }

  /* set intials after login */
  setInitialsAfterLogin(response){ //: Observable<any>
      localStorage.setItem('token', response.responseData.authToken);
      localStorage.setItem('userInfo.countryCode', response.responseData.countryCode);
      localStorage.setItem('userInfo.mobileNumber', response.responseData.mobileNumber);
      localStorage.setItem('userName', response.responseData.userName);
      localStorage.setItem('userCode', response.responseData.userCode);
      localStorage.setItem('role', response.responseData.entitlementData.role);
      localStorage.setItem('moduleList', JSON.stringify(response.responseData.entitlementData.modulesList));
      let state = response.responseData.entitlementData.state
      if(!this.setShouldForcePasswordReset){
          this.router.navigate([state]);
      } else{
        this.router.navigate(['/resetPassword']);
      }
  }


  /*Forgot Password Function*/
  submitForgotPassword(){
    this.settingsService.forgotPassword(this.userId).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.close('forgotPasswordModal');
      }
    });
  }



}
