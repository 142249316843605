import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../constants';
import { FilterModel } from '../_models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class RelationshipsService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };

  public filterVO = new FilterModel();

  constructor(private http: HttpClient) { }

  setRelationshipsData(body: any, toCreateOwnership) {
    return this.http.post(ApiUrls.setRelationshipsDataAPI + '?toCreateOwnership=' + toCreateOwnership, body, this.httpOptions)
      .pipe(
        response => response
      );
  }
  setClientData(body: any) {
    return this.http.post(ApiUrls.setClientDataAPI, body, this.httpOptions)
      .pipe(
        response => response
      );
  }
  setAccountData(body: any) {
    return this.http.post(ApiUrls.setAccountDataAPI, body, this.httpOptions)
      .pipe(
        response => response
      );
  }

  getRelationshipsData(value) {
    return this.http.post(ApiUrls.getRelationshpsDataAPI, value, this.httpOptions)
      .pipe(
        response => response
      );
  }

  getLegalEntityListData(data: any,relationshipId:string) {
    if(!data)
      data = '';
    return this.http.get(ApiUrls.getLegalEntityListDataAPI + "? =" + data +"&relationshipId="+relationshipId, this.httpOptions)
      .pipe(
        response => response
      );
  }
  getAccountListData(data: number) {

    return this.http.get(ApiUrls.getAccountListDataAPI + "?entityDataStoreId=" + data, this.httpOptions)
      .pipe(
        response => response
      );
  }

  deleteRelationshipData(value) {

    return this.http.post(ApiUrls.deleteRelationshipDataAPI, value, this.httpOptions)
      .pipe(
        response => response
      );
  }
  deleteClientData(value) {

    return this.http.post(ApiUrls.deleteClientDataAPI, value, this.httpOptions)
      .pipe(
        response => response
      );
  }
  deleteAccountData(value) {

    return this.http.post(ApiUrls.deleteAccountDataAPI, value, this.httpOptions)
      .pipe(
        response => response
      );
  }

}
