import { Component, OnInit ,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { TabsetComponent } from 'ngx-bootstrap';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';
import { MaxCalculatorMainComponent } from '../max-calculator-main/max-calculator-main.component';
import {CalAllocationComponent} from 'src/app/webPages/max-calculator/proposal-setup/cal-allocation/cal-allocation.component';
declare var _: any;
@Component({
  selector: 'app-proposal-setup',
  templateUrl: './proposal-setup.component.html',
  styleUrls: ['./proposal-setup.component.less']
})
export class ProposalSetupComponent implements OnInit {
  relationshipOverviewDropdowns: any;
  apiParams : any;
  
  constructor(
    private router: Router,
    private workflowBaseService : WorkflowBaseService,
    private authMessageService  : AuthMessageService,
    private maxCalculatorMainComponent : MaxCalculatorMainComponent,

  ) { }
  tabView = 'allocation';
  selectedTab: string;
  @ViewChild(CalAllocationComponent) calAllocationComponent: CalAllocationComponent;
  ngOnInit() {
    this.selectedTab = 'allocation';
  }

  changeTab(tab) {
    this.selectedTab = tab;
  }

  // onTabSelect(evt, eventFrom): void {
  //   let redirect = false;
  //   if(this.tabView == "allocation" && evt.id){
  //     if(!this.calAllocationComponent.primaryInfo.projectionPeriod){
  //       let errorObj = {};
  //       errorObj['errorCode'] = 'ERR0107';
  //       errorObj['message'] = 'Please enter Projection Period';
  //       errorObj['errorDescription'] = '';
  //       this.authMessageService.showErrorPopup(errorObj);
  //       redirect = true;
  //     }
  //     else  if(this.calAllocationComponent.primaryInfo.taxStatus == 'TAX_DEFERRED' && !this.calAllocationComponent.primaryInfo.dateOfBirth){
  //         let errorObj = {};
  //         errorObj['errorCode'] = 'ERR0107';
  //         errorObj['message'] = 'Date of Birth cannot be blank for Tax Deferred Account';
  //         errorObj['errorDescription'] = '';
  //         this.authMessageService.showErrorPopup(errorObj);
  //         redirect = true;
  //       }
  //     else if(!this.calAllocationComponent.totalCurrentAmount){
  //         let errorObj = {};
  //         errorObj['errorCode'] = 'ERR0107';
  //         errorObj['message'] = 'Please Enter current amount';
  //         errorObj['errorDescription'] = '';
  //         this.authMessageService.showErrorPopup(errorObj);
  //         redirect = true;
  //       }
  //     else if(!redirect){
  //       this.tabView = evt.id;
  //     }
  //     if(redirect){
  //         this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  //         this.router.navigate(['/maxCalculator/proposalSetup']));
  //     }
  //   }else if(evt.id){
  //     this.tabView = evt.id;
  //   }
    
   
    
  // }

  onTabSelect(id){
    let redirect = false;
    if(this.tabView == "allocation" && id ){
        if(this.calAllocationComponent.essestialDataLoaded){

          let unsavedAssetClassIds = [];
        this.calAllocationComponent.currentAllocationList.forEach((element,index)=>{
          if(!element.isHeader && !element.isSaved){
            unsavedAssetClassIds.push(element.objectId);
          }
          // if(element.isSelected)
          //   this.currentAllocationList.splice(index,1);
        })
        if(!this.calAllocationComponent.primaryInfo.projectionPeriod){
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please enter Projection Period';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          redirect = true;
        }
        else  if(this.calAllocationComponent.primaryInfo.taxStatus == 'TAX_DEFERRED' && !this.calAllocationComponent.primaryInfo.dateOfBirth){
            let errorObj = {};
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Date of Birth cannot be blank for Tax Deferred Account';
            errorObj['errorDescription'] = '';
            this.authMessageService.showErrorPopup(errorObj);
            redirect = true;
          }
          
        else if(!this.calAllocationComponent.totalCurrentAmount){
            let errorObj = {};
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Please Enter current amount';
            errorObj['errorDescription'] = '';
            this.authMessageService.showErrorPopup(errorObj);
            redirect = true;
          }
        else if(unsavedAssetClassIds.length){
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please save current allocation';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          redirect = true;
        }

        else if(!redirect){
          this.tabView = id;
        }
        }
      
    }else if(id){
      this.tabView = id;
    }
  }

}
