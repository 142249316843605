import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { MFAAuth } from '../../_models/mfa-auth.model';
import { AuthenticationService } from '../../_services/authentication.service';
import { SettingsService } from '../../_services/settings.service';
import { Variables } from '../../constants';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthMessageService } from '../../_services/auth-message.service';
import * as _ from "lodash";
import { LeftNavService } from 'src/app/_services/left-nav.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.less']
})
export class SettingComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private authMessageService : AuthMessageService,
    private leftNavData: LeftNavService
    ) { }

    oldPwd:string;
    newPwd:string;
    confirmPwd:string;
    checkedInput:boolean;
    OTPAccepter:boolean;
    sendBackOTPBtn:boolean;
    mfaAuth = new MFAAuth();
    imageURL:URL;
    isActivatedMFA : boolean;
    OTPActivate:boolean;
    OTPHit:boolean;
    message:boolean = true;
    mainContent: boolean = true;

  ngOnInit() {
    this.getActivatedMFADevices();
    this.mfaAuth.countryCode = localStorage.getItem('userInfo.countryCode');
    this.mfaAuth.mobileNumber = localStorage.getItem('userInfo.mobileNumber');
    if(this.mfaAuth.countryCode == "null")
    {
      this.mfaAuth.countryCode = '';
    }
    if(this.mfaAuth.mobileNumber == "null")
    this.mfaAuth.mobileNumber = '';

    this.leftNavData.currentMessage.subscribe(message =>{
      this.message = message;
      this.changeContainerSize();
    });

  }
  
  /* Get activated MFA devices */
  getActivatedMFADevices(){
    this.settingsService.getActivatedMFADevices().subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        if(response['responseData']){
          this.mfaAuth = response['responseData'][0];
          this.isActivatedMFA = true;
        } else{
          this.isActivatedMFA = false;
        }
      }
    });
  }

  /*Change Password Function*/
  submitChangePassword(){
    if(this.newPwd == this.confirmPwd){
      this.settingsService.changePassword(this.oldPwd, this.newPwd).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
      }
    });
    } else{
      let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Password do not match';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
    }

  }

  submitMFA(){
    if(this.checkedInput == false){
      this.mfaAuth.authType = Variables.googleAuth;
      this.settingsService.activateMFA(this.mfaAuth).subscribe((response) => {
        if(response['responseType'] == Variables.statusSuccess){
          //this.authMessageService.showSuccessPopup(response);
          this.imageURL = response['responseData'];
          this.ngxSmartModalService.getModal('googleAuthModal').open();
        }
      });
    } else{
      this.mfaAuth.authType = Variables.smsAuth;
      this.OTPHit = true;
      this.ngxSmartModalService.getModal('OTPModal').open();
    }
  }

  submitOTP(){

    /*validate country code*/
    if(!this.mfaAuth.countryCode)
   {
    let errorObj = {};
    errorObj['errorCode'] = 'ERR0107';
    errorObj['message'] = 'Please enter country code';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
    return false;
   }
   /*validate mobile number*/
   if(!this.mfaAuth.mobileNumber)
   {
    let errorObj = {};
    errorObj['errorCode'] = 'ERR0107';
    errorObj['message'] = 'Please enter phone number';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
    return false;
   }


    this.mfaAuth.authType = Variables.smsAuth;


    this.settingsService.activateMFA(this.mfaAuth).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
        this.OTPHit = false;
        this.OTPActivate = true;
        this.OTPAccepter = true;
      }
    });


  }

  submitGoogleAuth(){
    /*validate QR code*/
    if(!this.mfaAuth.authCode)
    {
      let errorObj = {};
    errorObj['errorCode'] = 'ERR0107';
    errorObj['message'] = 'Please enter QR code';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
    return false;

    }

    this.mfaAuth.authType = Variables.googleAuth;
      this.settingsService.enableMFA(this.mfaAuth).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
        this.getActivatedMFADevices();
        this.ngxSmartModalService.getModal('googleAuthModal').close();
      }
    });

  }

  submitOTPActivation(){
    this.mfaAuth.authType = Variables.smsAuth;
      this.settingsService.enableMFA(this.mfaAuth).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
        this.getActivatedMFADevices();
        this.ngxSmartModalService.getModal('OTPModal').close();
      }
    });

  }

  deactivateAuth(){
    this.settingsService.deactivateMFA().subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
        this.isActivatedMFA = false;
      }
    });

  }

  changeContainerSize(){
    if(this.message){
      this.mainContent = true;
    }else{
      this.mainContent = false;
    }
  }

}
