import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 

import { AuthenticationService } from '../../_services/authentication.service';
import { Variables } from '../../constants';
import { AuthMessageService } from '../../_services/auth-message.service';
import { SettingsService } from '../../_services/settings.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {

  constructor( 
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private authMessageService : AuthMessageService,) { }

    newPassword:string;
    confirmPassword:string;

  ngOnInit() {
  }

  loadLogout(){
    this.router.navigate(['/login']);
  }

  resetLater(){
    this.router.navigate(['/dashboard']);
  }

  submitResetPassword(){
    if(!this.newPassword ){
      alert('Please enter password');
      return false;
    }
    if(this.newPassword == this.confirmPassword){

      this.settingsService.resetPassword(this.newPassword).subscribe((response) => {
      if(response['responseType'] == Variables.statusSuccess){
        this.authMessageService.showSuccessPopup(response);
        this.router.navigate(['/dashboard']);
      }
    });
    }else{
      alert('Password do not match');
    }
    

  }

}
