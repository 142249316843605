import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ApiUrls } from '../constants';
import { MFAAuth } from '../_models/mfa-auth.model';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    
    public httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json',
            'subDomain'   : localStorage.getItem('subDomain'),
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };
    
    constructor(private http: HttpClient) {}

    forgotPassword(userId:string){
        let params = new HttpParams();
        params = params.append('userId', userId);
        return this.http.post(ApiUrls.forgotPasswordAPI + '?userId=' + userId , {} , this.httpOptions )
            .pipe(
                response => response
        );
    }

    resetPassword(newPassword:string){
        let params = new HttpParams();
        params = params.append('password',newPassword);
        return this.http.post(ApiUrls.resetPasswordAPI + '?password=' + newPassword , {} , this.httpOptions )
        .pipe(
            response => response
        );
    }

    changePassword(oldPassword:string, newPassword:string){
        let params = new HttpParams();
        params = params.append('password',newPassword);
        return this.http.post(ApiUrls.changePasswordAPI + '?oldPassword=' + oldPassword + '&newPassword=' + newPassword , {} , this.httpOptions )
        .pipe(
            response => response
        );
    }

    activateMFA(mfaAuth : MFAAuth){
        return this.http.post(ApiUrls.activateMfaAPI , mfaAuth , this.httpOptions )
        .pipe(
            response => response
        );
    }

    enableMFA(mfaAuth : MFAAuth){
        return this.http.post(ApiUrls.enableMfaAPI , mfaAuth , this.httpOptions )
        .pipe(
            response => response
        );
    }

    deactivateMFA(){
        return this.http.post(ApiUrls.deactivateMfaAPI , {} , this.httpOptions )
        .pipe(
            response => response
        );
    }

    getActivatedMFADevices(){
        return this.http.post(ApiUrls.getActivatedMFADevicesAPI , {} , this.httpOptions )
        .pipe(
            response => response
        );
    }


}