import { Component, OnInit, ElementRef, Renderer2, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
//import { $ } from 'protractor';
import * as $ from 'jquery';
import { SharedService } from '../../_services/shared.service';
import { LeftNavService } from 'src/app/_services/left-nav.service';
import {GridComponent}   from '../grid/grid.component'
import { BaseService } from '../../_services/base.service';
import { Variables } from '../../constants';
import { WorkflowBaseService } from '../../_services/workflow-services/workflow-base.service';
import { ProposalWorkflowComponent } from '../../../app/webPages/proposal-workflow/proposal-workflow.component';
declare var _: any;

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.less'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(-100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(-100%)', opacity: 0}))
        ])
      ]
    )
  ]
})
export class LeftNavigationComponent {
  moduleList = JSON.parse(localStorage.getItem('moduleList'));
  previousSelection = "";
  menuStatus:boolean;
  rightNav: boolean = true;
  rightNavigationMenuList = [];

  constructor(
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private sharedService:SharedService,
    private workflowBaseService: WorkflowBaseService,
    private baseService: BaseService,
    private gridComponent : GridComponent,
    private leftNavData: LeftNavService) {
      this.sharedService.activateRouteChange.subscribe((data) => {
      // this.menuStatus=true;
      if(data.indexOf("/proposalWorkflow/")==-1)
       this.selectCurrentSelection(data);
      });
     }

   ngAfterViewInit()
   {
   }

   ngOnInit() {
    this.leftNavData.currentMessage.subscribe(message => this.menuStatus = message);
    //this.getRightNavigationData('ProposalType');

    let workflowModule = this.moduleList.find(mod=>{
      return mod.moduleId == "workflow"
    })
    this.rightNavigationMenuList = workflowModule.childModules;
    _.remove(this.moduleList,mod=>{
      return mod.moduleId == "workflow"
    })
  }

menuTrigger()
{
  this.menuStatus =! this.menuStatus;
  this.leftNavData.changeMessage(this.menuStatus);
}


selectCurrentSelection(currentSelection:string)
{
let activeUrl = (currentSelection).replace('/','');
if(this.previousSelection !== "")
$("#"+this.previousSelection).css("background-color","");
$("#"+activeUrl).css("background-color","#5ab6e6");
 this.previousSelection=activeUrl;
}


  pageState(state){
    this.router.navigate(['/'+state]);
  }


  getRightNavigationData(requestData) {
    this.baseService.getRightNavigationData(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.rightNavigationMenuList = response['responseData'];
      }
    });
    console.log(this.rightNavigationMenuList);
    
  }

  startNewProcess(item) {
    var requestObj = {
      "processDefinitionKey": item.moduleId
    }
    this.workflowBaseService.startNewProcess(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        response['responseData']['processDefinitionKey']=item.moduleId;
        localStorage.setItem('workflowInfoData', JSON.stringify(response['responseData']));
        if(item.moduleId == "FINANCIAL_PLANNING_WORKFLOW"){
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/proposalWorkflow']));
        }else{
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/maxCalculator']));
        }
        
      }
    });
  }

  rightNavClick() {
    if (this.rightNav) {
      this.rightNav = false;
    } else {
      this.rightNav = true;
    }
  }

}
