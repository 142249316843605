import {Component} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'download-icon',
    template: '<div *ngIf="!completedWorkflow" class="float-left w-100" ><a  style="color : #007ad9" (click)="gridLinkClicked()">'+
                    '{{params.value}}'+
              '</a></div>'+'<div *ngIf="completedWorkflow" class="float-left w-100" >'+
              '{{params.value}}'+
        '</div>',
    styles   : []
})
export class LinkTypeRowTemplate implements ICellRendererAngularComp {
    params:any;
    hideDownLoadIcon : boolean = false;
    completedWorkflow: boolean =false;
    agInit(params:any):void {
        //this.params = params;
        this.params = params;
        if(this.params.node.data.gridName=="presentation" && this.params.node.data.progressStatus=="Completed"){
             this.completedWorkflow = true;
           
        }
    }

    gridLinkClicked(){
        //this.params.colDef['searchSortData'].searchedValue.push(this.params.node.data.name);
        this.params.context.componentParent.gridLinkClicked(this.params.node.data);
    }


    refresh(): boolean {
        return false;
    }
}