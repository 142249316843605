
import { Component, OnInit, ApplicationRef } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import { MaxCalculatorMainComponent } from '../../max-calculator-main/max-calculator-main.component';
import { RelationshipOverviewService } from '../../../../_services/workflow-services/relationship-overview.service';
import { TransfereService } from '../../../../_services/transferService.service';
import { AuthMessageService } from '../../../../_services/auth-message.service';
import { WorkflowBaseService } from '../../../../_services/workflow-services/workflow-base.service';
import { Variables } from '../../../../constants';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TaxInfoModel } from '../../../../_models/tax-info.model';
import { CommonFunctions } from '../../../../common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/models';
import { EditIconRowTemplate } from 'src/app/shared/custom-grid-row-templates/edit-icon';
import { CustomCompileService } from 'src/app/_services/custom-compile.service';
import * as _ from "lodash";

@Component({
  selector: 'app-cal-tax-info',
  templateUrl: './cal-tax-info.component.html',
  styleUrls: ['./cal-tax-info.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})

export class CalTaxInfoComponent implements OnInit {

  isView: boolean = true;
  apiParams: { processInstanceId: any; };
  taxInfoDropdowns: any;
  taxInfo = new TaxInfoModel();
  componentReference: {};
  federalCapitalGainTaxRateList: any;
  federalDividentTaxRateList: any;
  stateDividentTaxRateList: any;
  stateCapitalGainTaxRateList: any;
  isEditTaxView = false;
  federalTaxRate: any = null;
  federalCapitalGainTaxRate: any = null;
  federalDividentTaxRate: any = null;
  frameworkComponents: any;
  // selectedState: any = null;
  stateTaxRate: any = null;
  stateDividentTaxRate: any = null;
  stateCapitalGainTaxRate: any = null;
  taxInfoColumnDefs: any;
  gridApi: any;
  gridColumnApi: any;
  taxInfoRowData: any;
  selectedRows: any;
  filterVO: {};
  commonFunction = new CommonFunctions();
  datePickerConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  minMode: BsDatepickerViewMode = 'year';
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private maxCalculatorMainComponent: MaxCalculatorMainComponent,
    private transferService: TransfereService,
    private router: Router,
    public compileService: CustomCompileService,
    private datePipe: DatePipe,
    private workflowBaseService: WorkflowBaseService,
    private relationshipOverviewService: RelationshipOverviewService,
    private authMessageService: AuthMessageService,
    private appRef: ApplicationRef,

  ) {
    {
      compileService.configure(this.appRef);
      this.componentReference = { componentParent: this };
    };

    this.datePickerConfig = Object.assign({}, {
      containerClass: "theme-blue",
      showWeekNumbers: false,
      dateInputFormat: "YYYY",
      minMode: this.minMode

    });
    this.frameworkComponents = {
      EditIconRowTemplate: EditIconRowTemplate
    };


    this.filterVO = {};

  }


  ngOnInit() {
    setTimeout(() => {

      this.apiParams = {
        processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId
      };
      this.getTaxInfoEnums();




      this.taxInfoColumnDefs = [
        {
          "headerName": "",
          "checkboxSelection": true,
          "headerCheckboxSelection": true,
          "width": 40,
          "minWidth": 40,
          "pinned": "left",
          "suppressResize": true,
          "lockPosition": true,
          "suppressSorting": true,
          "suppressMenu": true
        },
        {
          "headerName": "",
          "width": 40,
          "minWidth": 40,
          "maxWidth": 40,
          "pinned": "left",
          "suppressResize": true,
          "lockPosition": true,
          "suppressMenu": true,
          "suppressSorting": true,
          "cellRenderer": "EditIconRowTemplate",
          "hide": false,
          "noHide": true
        },
        {
          "headerName": "Effective Start Year",
          "field": "effectiveStartYear",
          "minWidth": 175,
          "suppressMenu": true,
          "suppressSorting": false,
          "sort": "desc"
        },
        {
          "headerName": "Tax Type",
          "field": "taxTypeDisplay",
          "minWidth": 100,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "taxType",
        },
        // {
        //   "headerName": "Relationship Level",
        //   "field": "relationshipLevel",
        //   "minWidth": 100,
        //   "suppressMenu": true,
        //   "suppressSorting": false
        // },
        {
          "headerName": "Methodology",
          "field": "methodologyDisplay",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false
        },
        {
          "headerName": "Federal Tax Rate (%)",
          "field": "federalTaxRateStr",
          "minWidth": 165,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "federalTaxRate",
          
        },
        {
          "headerName": "State Tax Rate (%)",
          "field": "stateTaxRateStr",
          "minWidth": 165,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "stateTaxRate",
        },
        {
          "headerName": "Local Tax Rate (%)",
          "field": "localTaxRateStr",
          "minWidth": 165,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "localTaxRate"
        },
        {
          "headerName": "Effective Income Tax Rate (%)",
          "field": "effectiveIncomeTaxRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "effectiveIncomeTaxRate"
        },
        {
          "headerName": "Medicare Tax Rate (%)",
          "field": "medicareTaxRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "medicareTaxRate"
        },
        {
          "headerName": "Federal Capital Gain Tax Rate (%)",
          "field": "federalCapitalGainTaxRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "federalCapitalGainTaxRate"
        },
        {
          "headerName": "State Capital Gain Tax Rate (%)",
          "field": "stateCapitalGainTaxRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "stateCapitalGainTaxRate"
        },
        {
          "headerName": "Federal Dividend (%)",
          "field": "federalDividentTaxRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "federalDividentTaxRate"
        },
        
        {
          "headerName": "State Dividend (%)",
          "field": "stateDividentTaxRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "stateDividentTaxRate"
        },
        {
          "headerName": "Adjusted Gross Earned Income",
          "field": "adjustedGrossEarnedIncomeDecimalValueStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": true,
          "backendFieldName": "adjustedGrossEarnedIncomeDecimalValue"
        },
        {
          "headerName": "AGEI Growth",
          "field": "ageiGrowthRateStr",
          "minWidth": 200,
          "suppressMenu": true,
          "suppressSorting": false,
          "backendFieldName": "ageiGrowthRate"
        }
      ];
      $(".ag-body .ag-body-viewport").css("overflow-x", "scroll");
      $(".ag-pinned-left-cols-viewport").css("overflow-x", "scroll");
      $(".ag-pinned-left-cols-viewport").css("overflow-y", "hidden");
    },200);
    
    var ele = document.getElementsByClassName('height-fix')[0];
    if(ele) {
     
      ele.classList.remove("app-hierarchy-view");
    }
   
  }

  ngOnDestroy() {
    var ele = document.getElementsByClassName('height-fix')[0];
    if(ele) {
      ele.classList.add("app-hierarchy-view");
    }
  }
  editRowData(rowData) {
    this.editTaxInfo(rowData);
  }

  onSelectionChanged(params) {
    this.gridApi = params.api;
    var selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = selectedRows;
  }

  /* On grid ready */
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

 
  getTaxInfoData() {
    let apiParams = {
      processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      taskDefinitionKey: this.maxCalculatorMainComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey,
      processDefinitionKey: this.maxCalculatorMainComponent.workflowInfoData.processDefinitionKey

    }
    //let processInstanceId = this.maxCalculatorMainComponent.workflowInfoData.processInstanceId;
    this.relationshipOverviewService.getTaxInfoData(apiParams)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.taxInfoRowData = response['responseData'].result;
        }
      });
      var wait = setInterval(()=>{
        if($('.gridHeadingOptions')[0].clientHeight){
          clearInterval(wait)
          this.taxInfoGrid(0);  
        }
      });
  }

  // Adjust grid according to viewport
  taxInfoGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let tab = 20;
    let gridHeadingOptions = $('.gridHeadingOptions')[0].clientHeight;

    let margin = 130+ extraMargin;
    var total = header+ pageheader+ margin+ stepHeader+ tab+ gridHeadingOptions
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if(ele) {
      ele.setAttribute('style',"height: calc(100vh - "+total+"px);")
    }
  }

  deleteTaxInfo() {
    if (!this.selectedRows || this.selectedRows.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (this.selectedRows[0].effectiveStartYear == new Date().getFullYear()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Tax info of current year cannot be deleted';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (this.selectedRows && this.selectedRows.length != 0) {
      this.ngxSmartModalService.open('deleteTaxInfoModal');
    }
  }

  deleteTaxInfoAfterConfirmation() {
    // this.filterVO['dataStoreIdList'] = [];
    // this.selectedRows.forEach(element => {
    //   this.filterVO['dataStoreIdList'].push(element.dataStoreId);
    // });
    var requestData = {};
    requestData["processInstanceId"] = this.maxCalculatorMainComponent.workflowInfoData.processInstanceId;
    requestData["taskDefinitionKey"] = this.maxCalculatorMainComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey;
    requestData["processDefinitionKey"] = this.maxCalculatorMainComponent.workflowInfoData.processDefinitionKey;
    requestData["taxInfoIdList"] = [];
    this.selectedRows.forEach(element => {
      requestData['taxInfoIdList'].push(element.taxInfoId);
    });
    this.relationshipOverviewService.deleteTaxInfo(requestData)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteTaxInfoModal').close();
          this.selectedRows = [];
          this.getTaxInfoData();
        }
      });
  }



  addNewTaxInfo() {
    this.isView = false;
    this.isEditTaxView = false;
    this.taxInfo = new TaxInfoModel();
    this.taxInfo.relationshipLevel = true;
    this.federalTaxRate = null;
    // this.selectedState = null;
    this.stateTaxRate = null;
    this.federalCapitalGainTaxRate = null;
    this.federalDividentTaxRate = null;
    this.stateDividentTaxRate = null;
    this.stateCapitalGainTaxRate = null;
    if (this.taxInfoRowData && this.taxInfoRowData.length == 0)
      this.taxInfo.effectiveStartYear = new Date().getFullYear().toString();
  }

  editTaxInfo(row) {
    this.isView = false;
    this.isEditTaxView = true;
    this.taxInfo = Object.assign({}, row);// this is to make copy of row data so that it can not updated in actual row data before saving
    this.taxInfo.stateInfoCode =  _.find(this.taxInfoDropdowns.stateTaxRate, { stateInfoCode: this.taxInfo['stateInfoCode'] });
    if(this.taxInfo.stateTaxRate) { 
      this.stateTaxRate =  _.find(this.taxInfo.stateInfoCode['taxInfoVOList'], { percentageRate: this.taxInfo.stateTaxRate/100 });
      this.selectStateTaxRate(this.stateTaxRate);
    }
    else
      this.stateTaxRate = null;
    if(this.taxInfo.federalTaxRate) {
      this.federalTaxRate =  _.find(this.taxInfoDropdowns.federalTaxRate, { percentageRate: this.taxInfo.federalTaxRate/100 });
      this.selectFederalTaxRate(this.federalTaxRate,false);
    }
    else 
      this.federalTaxRate = null;
    if(this.taxInfo.federalDividentTaxRate || this.taxInfo.federalDividentTaxRate == 0)
      this.federalDividentTaxRate = this.taxInfo.federalDividentTaxRate/100;
    else  
      this.federalDividentTaxRate = null;
    if(this.taxInfo.federalCapitalGainTaxRate || this.taxInfo.federalCapitalGainTaxRate == 0)
      this.federalCapitalGainTaxRate = this.taxInfo.federalCapitalGainTaxRate/100;
    else
      this.federalCapitalGainTaxRate = null;
    if(this.taxInfo.stateCapitalGainTaxRate || this.taxInfo.stateCapitalGainTaxRate == 0) 
      this.stateCapitalGainTaxRate = this.taxInfo.stateCapitalGainTaxRate/100
    else
      this.stateCapitalGainTaxRate = null
    if(this.taxInfo.stateDividentTaxRate || this.taxInfo.stateDividentTaxRate == 0) 
      this.stateDividentTaxRate = this.taxInfo.stateDividentTaxRate/100;
    else
      this.stateDividentTaxRate = null;
  }

  getTaxInfoEnums() {
    this.relationshipOverviewService.getTaxInfoEnums()
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          console.log(response);
          this.taxInfoDropdowns = response['responseData'];
          this.taxInfoDropdowns["federalTaxRate"] = _.sortBy(this.taxInfoDropdowns["federalTaxRate"], 'percentageRate');
          this.taxInfoDropdowns["stateTaxRate"] = _.sortBy(this.taxInfoDropdowns["stateTaxRate"], 'stateInfoCode');
          this.taxInfoDropdowns["stateTaxRate"].forEach(element => {
            element.taxInfoVOList = _.sortBy(element.taxInfoVOList, 'percentageRate');
          });
          
        }

      this.getTaxInfoData();
      });
  }

  toggleView() {
    if (this.isView) {
      this.isView = false;
    } else {
      this.isView = true;
      this.selectedRows = []; //This is to empty selected Rows 
    }
  }

  toView() {
    this.isView = true;
  }

  saveTaxInfo() {
    if (_.isObject(this.taxInfo.effectiveStartYear)) {
      let tempDate = this.taxInfo.effectiveStartYear;
      this.taxInfo.effectiveStartYear = this.datePipe.transform(tempDate, 'yyyy');
    }

    if (this.validateData(this.taxInfo)) {
      var requestData = {};
      requestData["processInstanceId"] = this.maxCalculatorMainComponent.workflowInfoData.processInstanceId;
      requestData["taskDefinitionKey"] = this.maxCalculatorMainComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey;
      requestData["processDefinitionKey"] = this.maxCalculatorMainComponent.workflowInfoData.processDefinitionKey;
      if(this.taxInfo.stateInfoCode)
        this.taxInfo.stateInfoCode = this.taxInfo.stateInfoCode['stateInfoCode'];
      requestData["taxInfoVO"] = this.taxInfo;
      this.relationshipOverviewService.saveTaxInfo(requestData)
        .subscribe(response => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.toggleView();
            this.getTaxInfoData();
          }
        });
    }
  }

  validateData(data) {
    let errorFlag = false;
    if (!data.effectiveStartYear) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Start year cannot be blank';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.taxType == null || data.taxType == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Tax Type cannot be blank';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.methodology == null || data.methodology == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Methodology cannot be blank';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.taxType == 'TAXABLE') {
      if (data.methodology == 'STATE_TAX_ONLY') {
        if (CommonFunctions.isEmptyString(data.stateTaxRate) || data.stateTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.stateCapitalGainTaxRate) || data.stateCapitalGainTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State capital gain tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);

          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.stateDividentTaxRate || data.stateDividentTaxRate == "null")) {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State dividend tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
      }
      if (data.methodology == 'FEDERAL_TAX_ONLY') {
        if (CommonFunctions.isEmptyString(data.federalTaxRate) || data.federalTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.federalCapitalGainTaxRate) || data.federalCapitalGainTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal capital gain tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.federalDividentTaxRate) || data.federalDividentTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal dividend tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
      }
      if (data.methodology == 'FEDERAL_AND_STATE_TAX') {
        if (CommonFunctions.isEmptyString(data.federalTaxRate) || data.federalTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.federalCapitalGainTaxRate || data.federalCapitalGainTaxRate == "null")) {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal capital gain tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.federalDividentTaxRate) || data.federalDividentTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal dividend tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }

        if (CommonFunctions.isEmptyString(data.stateTaxRate) || data.stateTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.stateCapitalGainTaxRate) || data.stateCapitalGainTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State capital gain tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.stateDividentTaxRate) || data.stateDividentTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State dividend tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
      }
    }

    if (data.taxType == 'NON_TAXABLE') {
      if (data.methodology == 'FEDERAL_TAX_ONLY') {
        if (CommonFunctions.isEmptyString(data.stateTaxRate) || data.stateTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.stateCapitalGainTaxRate) || data.stateCapitalGainTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State capital gain tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.stateDividentTaxRate) || data.stateDividentTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'State dividend tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
      }
      if (data.methodology == 'STATE_TAX_ONLY') {
        if (CommonFunctions.isEmptyString(data.federalTaxRate) || data.federalTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.federalCapitalGainTaxRate) || data.federalCapitalGainTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal capital gain tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
        if (CommonFunctions.isEmptyString(data.federalDividentTaxRate) || data.federalDividentTaxRate == "null") {
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Federal dividend tax rate cannot be blank';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          errorFlag = true;
          return false;
        }
      }
    }
    if (errorFlag) {
      return false;
    }


    // if (!data.localTaxRate) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Local tax rate cannot be blank';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }

    return true;
  }

  saveCheckPopUp() {
    this.ngxSmartModalService.open("cancelConfirmation");
  }

  confirmedCheckPopup() {
   
    this.toggleView();
    //this.saveTaxInfo();
    this.ngxSmartModalService.close("cancelConfirmation");
    var wait = setInterval(()=>{
      if($('.gridHeadingOptions')[0].clientHeight){
        clearInterval(wait)
        this.taxInfoGrid(0);  
        $(".ag-body .ag-body-viewport").css("overflow-x", "scroll");
        $(".ag-pinned-left-cols-viewport").css("overflow-x", "scroll");
        $(".ag-pinned-left-cols-viewport").css("overflow-y", "hidden");
      }
    });
   
  }


  selectFederalTaxRate(federalTaxRate,shouldResetFedFields) {
    if(federalTaxRate !== "null") {
      this.taxInfo.federalTaxRate = federalTaxRate.percentageRate * 100;
      this.federalCapitalGainTaxRateList = federalTaxRate.capitalGainsTierVOList;
      this.federalDividentTaxRateList = federalTaxRate.dividendTierVOList;
      if(shouldResetFedFields){
        this.taxInfo.federalCapitalGainTaxRate = null;
        this.taxInfo.federalDividentTaxRate = null;
      }
    }
    else 
      this.taxInfo.federalTaxRate = null;
  }

  selectStateTaxRate(stateTaxRate) {
    if(stateTaxRate !== "null") {
      this.taxInfo.stateTaxRate = stateTaxRate.percentageRate * 100;
      this.stateCapitalGainTaxRateList = stateTaxRate.capitalGainsTierVOList;
      this.stateDividentTaxRateList = stateTaxRate.dividendTierVOList;
    }
    else 
      this.taxInfo.stateTaxRate = null;
  }

  selectFederalCapitalGainTaxRate(federalCapitalGainTaxRate) {
    if(federalCapitalGainTaxRate !== "null" )
      this.taxInfo.federalCapitalGainTaxRate = federalCapitalGainTaxRate * 100;
    else 
      this.taxInfo.federalCapitalGainTaxRate = null
  }

  selectStateCapitalGainTaxRate(stateCapitalGainTaxRate) {
    if(stateCapitalGainTaxRate !== "null")
      this.taxInfo.stateCapitalGainTaxRate = stateCapitalGainTaxRate * 100;
    else 
      this.taxInfo.stateCapitalGainTaxRate = null
  }

  selectFederalDividentTaxRate(federalDividentTaxRate) {
    if (federalDividentTaxRate !== "null")
      this.taxInfo.federalDividentTaxRate = federalDividentTaxRate * 100;
    else 
      this.taxInfo.federalDividentTaxRate = null;
  }

  selectStateDividentTaxRate(stateDividentTaxRate) {
    if(stateDividentTaxRate !== "null")
      this.taxInfo.stateDividentTaxRate = stateDividentTaxRate * 100;
    else
      this.taxInfo.stateDividentTaxRate = null;
  }

  selectMethodoly() {
    if(this.taxInfo && this.taxInfo.methodology && this.taxInfo.taxType) {
      if( (this.taxInfo.methodology == 'STATE_TAX_ONLY' && this.taxInfo.taxType == 'TAXABLE') || (this.taxInfo.methodology == 'FEDERAL_AND_STATE_TAX' && this.taxInfo.taxType == 'NON_TAXABLE')  || (this.taxInfo.methodology == 'FEDERAL_TAX_ONLY' && this.taxInfo.taxType == 'NON_TAXABLE')) {
        this.federalTaxRate = null;
        this.taxInfo.federalTaxRate = null;
        this.taxInfo.effectiveIncomeTaxRate = null;
        this.federalCapitalGainTaxRate = null;
        this.taxInfo.federalCapitalGainTaxRate = null;
        this.federalDividentTaxRate = null;
        this.taxInfo.federalDividentTaxRate = null;
        this.taxInfo.medicareTaxRate = null;
        this.taxInfo.adjustedGrossEarnedIncomeDecimalValue = null;
        this.taxInfo.ageiGrowthRate = null;
      }
      if( (this.taxInfo.methodology == 'STATE_TAX_ONLY' && this.taxInfo.taxType == 'NON_TAXABLE') || (this.taxInfo.methodology == 'FEDERAL_AND_STATE_TAX' && this.taxInfo.taxType == 'NON_TAXABLE') || (this.taxInfo.methodology == 'FEDERAL_TAX_ONLY' && this.taxInfo.taxType == 'TAXABLE') ) {
        this.taxInfo.stateInfoCode = null;
        this.stateTaxRate = null;
        this.taxInfo.stateTaxRate = null;
        this.stateCapitalGainTaxRate = null;
        this.taxInfo.stateCapitalGainTaxRate = null;
        this.stateDividentTaxRate = null;
        this.taxInfo.stateDividentTaxRate = null;
      }
      if(this.taxInfo.methodology == 'FEDERAL_AND_STATE_TAX' && this.taxInfo.taxType == 'TAXABLE') {
        this.taxInfo.effectiveIncomeTaxRate = null;
      }
    }
  }

}