import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/_services/base.service';
import { Variables } from '../../../constants';
import { CommonFunctions } from '../../../common';
import { AuthMessageService } from '../../../_services/auth-message.service';
import { WorkflowBaseService } from "../../../_services/workflow-services/workflow-base.service";
import { ProposalWorkflowComponent } from '../../proposal-workflow/proposal-workflow.component';
import { BaseComponent } from 'src/app/shared/base/base.component';
@Component({
  selector: 'app-create-presentation',
  templateUrl: './create-presentation.component.html',
  styleUrls: ['./create-presentation.component.less']
})
export class CreatePresentationComponent implements OnInit {

  email: string;
  state: string;
  isAttached: boolean = false;
  workflowInfoData: any;
  myInterval;
  constructor(
    private baseService: BaseService,
    private baseComponent: BaseComponent,
    private workflowBaseService: WorkflowBaseService,
    private authMessageService: AuthMessageService,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
  ) { }


  ngOnInit() {

    if (localStorage.getItem('workflowInfoData'))
      this.workflowInfoData = JSON.parse(localStorage.getItem('workflowInfoData'));
    this.getTaskDetails();


  }

  ngOnDestroy() {
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  }

  getTaskDetails() {
    let requestObj = {
      "processInstanceId": this.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "createPresentation",
      "hideLoader": true,
      "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    };
    this.myInterval = setInterval(() => {
      this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          if (response['responseData']) {
            this.state = response['responseData'].taskVariables.generatePresentation.reportStatus;
            this.email = response['responseData'].taskVariables.generatePresentation.email;

            if (this.state != 'INPROGRESS') {
              clearInterval(this.myInterval);

            } else {
              this.proposalWorkflowComponent.showPreviousButton = false;

            }
            if (this.state == 'SUCCESS') {
              this.proposalWorkflowComponent.showSubmitButton = true;
              this.proposalWorkflowComponent.showPreviousButton = true;
              

            } else {
              this.proposalWorkflowComponent.showSubmitButton = false;
            }

            if (this.state == 'FAILED') {
              var errorList = [];
              errorList = response['responseData'].taskVariables.generatePresentation.errorInFile;
              if (errorList.length > 0) {
                let errorObj = {};
                var errorMsg = [];
                errorObj['errorCode'] = 'ERR0107';
                errorList.forEach(element => {
                  var newStr = element + "\n";
                  errorMsg.push(newStr);
                });
                errorObj['message'] = errorMsg;
                errorObj['errorDescription'] = '';
                this.authMessageService.showErrorPopup(errorObj);
              }

            } 


          }
        }
      });
    }, 200)// Due to which multiple hit of api if It may cause any problem then u can change its interval time

  }

  generatePresentation() {
    // if(!this.email){
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter email';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    // }
    if (this.isAttached) {
      if (this.email && CommonFunctions.validateEmail(this.email)) {
        this.proposalWorkflowComponent.showPreviousButton = false;
        let requestData = {};
        requestData["email"] = this.email;
        requestData["isAttachment"] = this.isAttached;
        requestData['subDomain'] = this.baseComponent.subDomain;
        this.baseService.generatereport(this.workflowInfoData.processInstanceId, requestData).subscribe((response) => {
          this.authMessageService.showSuccessPopup(response);
          if (response['responseType'] == Variables.statusSuccess) {
            // this.authMessageService.showSuccessPopup(response);
            this.getTaskDetails();
          }
        });
      } else {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter email Address';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }
    }else{
      if(this.email){
        if(CommonFunctions.validateEmail(this.email)){
          this.proposalWorkflowComponent.showPreviousButton = false;
          let requestData = {};
          requestData["email"] = this.email;
          requestData["isAttachment"] = this.isAttached;
          requestData['subDomain'] = this.baseComponent.subDomain;
          this.baseService.generatereport(this.workflowInfoData.processInstanceId, requestData).subscribe((response) => {
            this.authMessageService.showSuccessPopup(response);
            if (response['responseType'] == Variables.statusSuccess) {
              // this.authMessageService.showSuccessPopup(response);
              this.getTaskDetails();
            }
          });
        }else{
          let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter email in proper format';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        }
      }else{
        this.proposalWorkflowComponent.showPreviousButton = false;
          let requestData = {};
          requestData["email"] = this.email;
          requestData["isAttachment"] = this.isAttached;
          requestData['subDomain'] = this.baseComponent.subDomain;
          this.baseService.generatereport(this.workflowInfoData.processInstanceId, requestData).subscribe((response) => {
            this.authMessageService.showSuccessPopup(response);
            if (response['responseType'] == Variables.statusSuccess) {
              // this.authMessageService.showSuccessPopup(response);
              this.getTaskDetails();
            }
          });
      }
    }
  //   if (this.email && !CommonFunctions.validateEmail(this.email)) {
  //     let errorObj = {};
  //     errorObj['errorCode'] = 'ERR0107';
  //     errorObj['message'] = 'Please enter email in proper format';
  //     errorObj['errorDescription'] = '';
  //     this.authMessageService.showErrorPopup(errorObj);
  //   }
  //   else {

  //   }

 }

  dowloadReport() {
    let apiParams = {
      processInstanceId: this.workflowInfoData.processInstanceId
    }
    this.workflowBaseService.dowloadReport(apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        
        let fileData = response['responseData'].fileData;
        let fileName = response['responseData'].fileName;
        if (fileData && fileName) {
          CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
        }
      }
    });
  }

  previewPresentation() {
    let apiParams = {
      processInstanceId: this.workflowInfoData.processInstanceId
    }
    this.workflowBaseService.dowloadReport(apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        let fileData = response['responseData'].fileData;
        let fileName = response['responseData'].fileName;
        if (fileData && fileName) {
          CommonFunctions.previewPdfUsingByteStream(fileData);
        }
      }
    });
  }


}
