export class ClientFeeStructureModel{
    name : String;
    objectId : String;
    advisorFee :any[];
    isUserLevel : boolean;

}

export class CalInvestmentManagementFeesModel{
    accountFee : number;
    beginAmount : number;
    endAmount   : number;
    imaFeePercentage : number;
    feeTemplateName : String;
    advisoryFeePercentage : number

}