import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgxSmartModalService } from "ngx-smart-modal";
import { ProposalWorkflowComponent } from '../proposal-workflow.component';
import { PortfolioModel } from '../../../_models/portfolio.model';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { PortfolioOverviewService } from '../../../_services/workflow-services/portfolio-overview-service';
import { Variables } from '../../../constants';
import { CommonFunctions } from '../../../common';
import { AuthMessageService } from '../../../_services/auth-message.service';
import { GridComponent } from '../../../shared/grid/grid.component';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from '../../../_services/base.service';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';
import { Account } from '../../../_models/relationship.model';
import { HoldingsDataModel, HoldingsModel } from '../../../_models/holdings.model';
import * as _ from "lodash";
import * as $ from 'jquery';
import { ErrorModel } from '../../../_models/response.model';
import { EChartOption } from 'echarts';
import { element } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-portfolio-overview',
  templateUrl: './portfolio-overview.component.html',
  styleUrls: ['./portfolio-overview.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class PortfolioOverviewComponent implements OnInit {
  frozenCols: any[];
  apiParams: {};
  isxlsx: boolean = true;
  rowData: any[];
  portfolioInfo = new PortfolioModel();
  accountInfo = new Account();
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  holdingsDataInfo = new HoldingsDataModel();
  accountForPortfolio: string = 'UnAl-Acc';
  commonFunction = new CommonFunctions();
  chartOption: EChartOption;
  @ViewChildren('portfolioOverViewGrid') gridComponentData: QueryList<GridComponent>;
  portfoilioGridComponentReference: any;
  accountGridComponentReference: any;
  dropdownsData: any;
  selectedAccountSubType: any;
  selectedRowsInPortfoliosList: any;
  selectedRowsInAccountsList: any;
  filterVO: any;
  unselectedHoldingList: any = [];
  ownersList: any;
  accountsListDataFromGrid: any;
  holdingColumnDef: { field: string; header: string; width: string; backendFieldName: string; }[];
  holdingColumnFrozenDefForAdd: { field: string; header: string; width: string; backendFieldName: string; }[];
  holdingColumnFrozenDefForEdit: { field: string; header: string; width: string; backendFieldName: string; }[];
  holdingColumnFrozenDef: { field: string; header: string; width: string; backendFieldName: string; }[];
  freezeWidth: string;
  holdingColumnFrozenForViewDef: { field: string; header: string; width: string; backendFieldName: string; }[];
  portfolioListFromGrid: any;
  isAllHoldingSelected: boolean;
  connectAccountList: {}[];
  totalUnallocatedPercentage: number;
  includeInWorkflow: boolean;
  emptyHoldingRows: any = [];

  public chartLabels: string[] = [];
  public chartData: number[] = [];
  public chartType: string = 'pie';
  // public chartOptions: any = {};
  holdingAction: any = '';
  showMarketValueInputBox: boolean = false;
  viewHoldingsColumnDef: { field: string; header: string; width: string; backendFieldName: string; }[];
  showOwnerDateOfBirth: boolean;
  obj: any;
  viewHoldingClosedStatus: boolean = true;
  obj1: any;
  // isResolvedOperationPerformed: boolean=false;


  ngAfterViewInit() {
    this.portfoilioGridComponentReference = this.gridComponentData.toArray().find(function (item) {
      return item.gridName == 'workflow-portfolio-list';
    });
    this.accountGridComponentReference = this.gridComponentData.toArray().find(function (item) {
      return item.gridName == 'workflow-portfolio-account-list';
    });


    let callCount = 1;
    let ifData = setInterval(() => {
      if (this.portfoilioGridComponentReference && this.portfoilioGridComponentReference.rowData) {
        let portfolioDataCopy = this.portfoilioGridComponentReference.rowData;
        if (portfolioDataCopy && portfolioDataCopy.length > 0) {
          for (let i = 0; i < portfolioDataCopy.length; i++) {
            this.portfolioListFromGrid.push(portfolioDataCopy[i]);
          }
        }
        this.setInvestableAssetsChartData();
        clearInterval(ifData);

      } else if (callCount > 10) {
        clearInterval(ifData);
      }
      callCount++;
    }, 500);

    $(".ag-body .ag-body-viewport").css("overflow-x", "scroll")
    $(".ag-pinned-left-cols-viewport").css("overflow-x", "scroll")
    $(".ag-pinned-left-cols-viewport").css("overflow-y", "hidden")
  }

  componentReference: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
    private datePipe: DatePipe,
    private router: Router,
    public portfolioOverviewService: PortfolioOverviewService,
    private authMessageService: AuthMessageService,
    private workflowBaseService: WorkflowBaseService) {
    this.portfolioListFromGrid = [];


    this.componentReference = { componentParent: this };
  }

  ngOnInit() {
    this.viewHoldingClosedStatus = true;
    this.getDropdownsData();
    // setTimeout(() => {

    // }, 2000);

    this.dpConfig.containerClass = "theme-blue";
    this.apiParams = {
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId
    };
    // for Add/ edit Holding Unfreez defination
    this.holdingColumnDef = [
      {
        field: "name",
        header: "Name",
        width: "140px",
        backendFieldName: "name"
      },
      {
        field: "symbol",
        header: "Symbol",
        width: "80px",
        backendFieldName: "symbol"
      },
      {
        field: "quantity",
        header: "QTY",
        width: "80px",
        backendFieldName: "quantity"
      },
      {
        field: "holdingResolvedName",
        header: "Resolved Name",
        width: "250px",
        backendFieldName: "holdingResolvedName"
      },
      {
        field: "asOfDate",
        header: "As of Date",
        width: "110px",
        backendFieldName: "asOfDate"
      },
      {
        field: "sector",
        header: "Sector",
        width: "140px",
        backendFieldName: "sector"
      },
      {
        field: "assetClassId",
        header: "Asset Class",
        width: "140px",
        backendFieldName: "assetClassId"
      },
      {
        field: "price",
        header: "Price",
        width: "90px",
        backendFieldName: "price.decimalValue"
      },
      {
        field: "lockedPercentage",
        header: "Locked (%)",
        width: "80px",
        backendFieldName: "lockedPercentage"
      },
      {
        field: "carveoutPercentage",
        header: "Carve Out (%)",
        width: "80px",
        backendFieldName: "carveoutPercentage"
      },
      {
        field: "totalMarketValueBase",
        header: "TMV Base",
        width: "120px",
        backendFieldName: "totalMarketValueBase.decimalValue"
      },
      {
        field: "totalMarketValueSystem",
        header: "TMV System",
        width: "120px",
        backendFieldName: "totalMarketValueSystem.decimalValue"
      },
      {
        field: "costBasis",
        header: "Cost Basis",
        width: "100px",
        backendFieldName: "costBasis.decimalValue"
      },
      {
        field: "acquisitionDateStr",
        header: "Acquisition Date",
        width: "100px",
        backendFieldName: "acquisitionDateStr"
      }
      // {
      //   field: "unrealizedLossStr",
      //   header: "Unrealized Gains",
      //   width: "100px",
      //   backendFieldName: "unrealizedLossStr"
      // }
    ];

    this.viewHoldingsColumnDef = [
      {
        field: "name",
        header: "Name",
        width: "140px",
        backendFieldName: "name"
      },
      {
        field: "symbol",
        header: "Symbol",
        width: "120px",
        backendFieldName: "symbol"
      },
      {
        field: "quantity",
        header: "QTY",
        width: "110px",
        backendFieldName: "quantity"
      },
      {
        field: "holdingResolvedName",
        header: "Resolved Name",
        width: "250px",
        backendFieldName: "holdingResolvedName"
      },
      {
        field: "asOfDate",
        header: "As of Date",
        width: "120px",
        backendFieldName: "asOfDate"
      },
      {
        field: "sectorName",
        header: "Sector",
        width: "200px",
        backendFieldName: "sector"
      },
      {
        field: "assetClassName",
        header: "Asset Class",
        width: "200px",
        backendFieldName: "assetClassId"
      },
      {
        field: "price",
        header: "Price",
        width: "120px",
        backendFieldName: "price.decimalValue"
      },
      {
        field: "lockedPercentage",
        header: "Locked (%)",
        width: "80px",
        backendFieldName: "lockedPercentage"
      },
      {
        field: "carveoutPercentage",
        header: "Carve Out (%)",
        width: "80px",
        backendFieldName: "carveoutPercentage"
      },
      {
        field: "totalMarketValueBase",
        header: "TMV Base",
        width: "120px",
        backendFieldName: "totalMarketValueBase.decimalValue"
      },
      {
        field: "totalMarketValueSystem",
        header: "TMV System",
        width: "120px",
        backendFieldName: "totalMarketValueSystem.decimalValue"
      },
      {
        field: "costBasis",
        header: "Cost Basis",
        width: "100px",
        backendFieldName: "costBasis.decimalValue"
      },
      {
        field: "unrealizedLossStr",
        header: "Unrealized Gains",
        width: "100px",
        backendFieldName: "unrealizedLossStr"
      },
      {
        field: "acquisitionDateStr",
        header: "Acquisition Date",
        width: "100px",
        backendFieldName: "acquisitionDateStr"
      }

    ];

    this.holdingColumnFrozenDefForAdd = [
      {
        field: "isSelected",
        header: "",
        width: "60px",
        backendFieldName: "isSelected"
      },
      {
        field: "removeRow",
        header: "",
        width: "50px",
        backendFieldName: "removeRow"
      }
    ];

    this.holdingColumnFrozenDefForEdit = [
      {
        field: "isSelected",
        header: "",
        width: "60px",
        backendFieldName: "isSelected"
      }
    ];

    this.holdingColumnFrozenForViewDef = [
      {
        field: "isSelected",
        header: "",
        width: "60px",
        backendFieldName: "isSelected"
      }
    ];

    var ele = document.getElementsByClassName('height-fix')[0];
    if (ele) {
      ele.classList.remove("app-hierarchy-view");
    }

  }

  convertToCommaSeperated(row, key) {
    let value = row[key].decimalValue;
    var partBeforeDecimal = '', partAfterDecimal = '';
    var index = value.indexOf('.')
    if (value == '$' || value == "") {
      row[key].decimalValue = '$0';
    } else {
      if (index > -1) {
        partBeforeDecimal = value.substr(0, index);
        partAfterDecimal = value.substr(index, value.length - index);
      } else {
        partBeforeDecimal = value;
      }

      if (partBeforeDecimal) {
        partBeforeDecimal = partBeforeDecimal.replace(/,/g, "").replace('$', "");
        row[key].decimalValue = '$' + (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
      }
    }

  }

  // convertToPercSeparated(row, key) {
  //   let value = row[key];
  //   //row[key] = value + "%";
  //   if(value=="%" || value==""){
  //     row[key]="0%";
  //   }else{
  //     if(row[key].includes('%')){
  //       row[key]=value;
  //     }else{
  //       row[key]=value+'%';
  //     }
  //   }
//}

  

  ngOnDestroy() {
    var ele = document.getElementsByClassName('height-fix')[0];
    if (ele) {
      ele.classList.add("app-hierarchy-view");
    }
  }

  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {
    if (rowData['objectType'] == 'Account') {
      this.editAccount(rowData);
    } else {
      this.editPortfolio(rowData);
    }
  }

  onSeletionChanged(param) {
    console.log(param);

  }

  editRelationship() {
    var dataObj = {};
    dataObj['data'] = {};
    let requestObj = {
      "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "relationshipSelection",
      "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinition
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData'].taskVariables) {
          dataObj['data']['relationshipId'] = response['responseData'].taskVariables.relationshipId;
          dataObj['isFrom'] = '/proposalWorkflow/relationshipOverview';
          var dataArray = [dataObj];
          var cache = [];
          var stringifiedData = JSON.stringify(dataArray, function (key, value) {
            if (typeof value === 'object' && value !== null) {
              if (cache.indexOf(value) !== -1) {
                // Duplicate reference found
                try {
                  // If this value does not reference a parent it can be deduped
                  return JSON.parse(JSON.stringify(value));
                } catch (error) {
                  // discard key if value cannot be deduped
                  return;
                }
              }
              // Store value in our collection
              cache.push(value);
            }
            return value;
          });
          cache = null; // Enable garbage collection

          localStorage.setItem('dataItem', stringifiedData);
          this.router.navigate(["/add-edit-relationship"]);
        }
      }
    });

  }

  addPortfolio() {
    this.portfolioInfo = new PortfolioModel();
    this.ngxSmartModalService.open('createPortfolio');

  }

  editPortfolio(rowData) {
    this.portfolioInfo = Object.assign({}, rowData);
    this.ngxSmartModalService.open('createPortfolio');
  }

  openDownloadConfirmation() {
    this.ngxSmartModalService.open('downloadConfirmationModal');
  }

  savePortfolio() {
    if (this.validatePortfolio(this.portfolioInfo)) {
      let tempDate = this.portfolioInfo.inceptionDate;
      this.portfolioInfo.inceptionDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
      this.portfolioInfo.processInstanceId = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      this.portfolioOverviewService.savePortfolioData(this.portfolioInfo).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('createPortfolio').close();
          this.refreshAllData();
        }
      });
    }

  }
  /* for updating value on another field due to operation perform on other field*/
  onBlur() {
    this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
      //if (typeof(element.price.decimalValue) == "string") {
      // element.price.decimalValue = element.price.decimalValue.replace(/,/g, "").replace('$', "");
      // }
      let decValue = 0;
      if (element.price.decimalValue.includes('$')) {
        decValue = element.price.decimalValue.replace(/,/g, "").replace('$', "");
      } else {
        decValue = element.price.decimalValue;
      }
      if (element.quantity != null && element.price.decimalValue != null) {
        element.totalMarketValueSystem.decimalValue = '$' + (Number(element.quantity) * Number(decValue)).toLocaleString();
      }
      //this.convertToCommaSeperated(rowData,key);

    });

  }

  deletePortfolio(): void {
    this.selectedRowsInPortfoliosList = this.portfoilioGridComponentReference.getSelectedRows();
    if (this.selectedRowsInPortfoliosList && this.selectedRowsInPortfoliosList.length != 0) {
      this.ngxSmartModalService.open('deletePortfolioModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deletePortfoliosAfterConfirmation() {
    this.selectedRowsInPortfoliosList = this.portfoilioGridComponentReference.getSelectedRows();
    this.filterVO = this.portfoilioGridComponentReference.filterVO;
    this.filterVO.dataStoreIdList = [];
    this.selectedRowsInPortfoliosList.forEach(element => {
      this.filterVO.dataStoreIdList.push(element.dataStoreId);
    });
    this.portfolioOverviewService.deletePortfolioData(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deletePortfolioModal').close();
          this.selectedRowsInAccountsList = [];
          this.refreshAllData();
        }
      });
  }

  addNewAccount() {
    // if (this.accountForPortfolio == 'UnAl-Acc') {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Select portfolio for adding account';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    // } else {
    //   this.accountInfo = new Account();
    //   this.ngxSmartModalService.open('account');
    // }
    this.selectedAccountSubType = [];
    this.accountInfo = new Account();
    if (this.ownersList.length == 0) {
      let errorObj = {};
      errorObj['message'] = 'Client doesnot exists for selected Relationship';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.accountInfo.ownershipDataStoreId = this.ownersList[0].dataStoreId;
      this.selectOwnerDateOfBirth('Form');
      this.ngxSmartModalService.open('account');
    }
  }
  editAccount(rowData) {
    this.accountInfo = Object.assign({}, rowData);
    this.selectAccountType('Controller');
    this.ngxSmartModalService.open('account');
  }

  deleteAccount(): void {
    this.selectedRowsInAccountsList = this.accountGridComponentReference.getSelectedRows();
    if (this.selectedRowsInAccountsList && this.selectedRowsInAccountsList.length != 0) {
      this.ngxSmartModalService.open('deleteAccountModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteAccountAfterConfirmation() {
    this.selectedRowsInAccountsList = this.accountGridComponentReference.getSelectedRows();
    this.filterVO = this.accountGridComponentReference.filterVO;
    this.filterVO.dataStoreIdList = [];
    this.selectedRowsInAccountsList.forEach(element => {
      this.filterVO.dataStoreIdList.push(element.dataStoreId);
    });
    this.portfolioOverviewService.deleteAccountData(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteAccountModal').close();
          this.selectedRowsInAccountsList = [];
          this.refreshAllData();
          //this.accountGridComponentReference.getGridDataAfterUpdatingRecords();
        }
      });
  }

  openAddHoldingPopup() {
    //obj=Object.assign({},this.accountGridComponentReference);
    if (!this.viewHoldingClosedStatus) {
      this.obj = this.obj1;
    } else {
      this.obj = this.accountGridComponentReference;
    }

    if (this.obj.selectedRows.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (this.obj.selectedRows.length > 1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select only one account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (this.obj.selectedRows[0].accountType != 'ASSET_ACCOUNT') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Holdings can be added to Asset Accounts only';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.holdingsDataInfo = new HoldingsDataModel();
      //this.accountsListDataFromGrid = this.accountGridComponentReference.rowData;
      this.holdingsDataInfo.accountId = this.obj.selectedRows[0].accountId;
      this.holdingsDataInfo.accountName = this.obj.selectedRows[0].name;
      this.holdingsDataInfo.carvedOutPercentage = this.obj.selectedRows[0].carvedOutPercentage;
      for (let i = 0; i < 5; i++) {
        let holdindData = new HoldingsModel();
        if (this.holdingsDataInfo.carvedOutPercentage && (this.holdingsDataInfo.carvedOutPercentage > 0)) {
          holdindData.carveoutPercentage = this.holdingsDataInfo.carvedOutPercentage;
        }
        holdindData['rowId'] = Math.floor((Math.random() * 10000000000) + 1);
        this.holdingsDataInfo.holdingsInfoVOs.push(holdindData);
      }
      this.holdingAction = 'addHolding';
      this.holdingColumnFrozenDef = this.holdingColumnFrozenDefForAdd;
      this.freezeWidth = '110px';
      this.isAllHoldingSelected = false;
      this.ngxSmartModalService.open('addHolding');
      
      
      setTimeout(() => {
        this.holdingsDataInfo.holdingsInfoVOs.forEach(item => {
          $("#addHolding-frozen-column-" + item.rowId).parent().parent().outerHeight(
            $("#addHolding-relsoveName-column-" + item.rowId).parent().parent().outerHeight()
          )
          $("#addHolding-relsoveName-column-" + item.rowId).parent().parent().outerHeight(
            $("#addHolding-relsoveName-column-" + item.rowId).parent().parent().outerHeight() + 1
          )
        })
        $("#add-holdings-header-isSelected").parent().parent().outerHeight(
          $("#add-holdings-header-Name").parent().parent().outerHeight()
        )
        $(".ui-table-scrollable-body").last().css({
          'overflow-x': 'auto',
          'overflow-y': 'overlay'
        });
      }, 50);

    }

  }

  openEditHoldingPopup() {
    if (this.accountGridComponentReference.selectedRows.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (this.accountGridComponentReference.selectedRows.length > 1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select only one account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.holdingAction = 'editHolding';
      this.holdingColumnFrozenDef = this.holdingColumnFrozenDefForEdit;
      this.freezeWidth = '60px';
      //this.accountsListDataFromGrid = this.accountGridComponentReference.rowData;
      this.holdingsDataInfo.accountId = this.accountGridComponentReference.selectedRows[0].accountId;
      this.holdingsDataInfo.accountName = this.accountGridComponentReference.selectedRows[0].name;
      this.holdingsDataInfo.carvedOutPercentage = this.accountGridComponentReference.selectedRows[0].carvedOutPercentage;
      this.isAllHoldingSelected = false;
      this.ngxSmartModalService.open('addHolding');
      this.portfolioOverviewService.getEditHoldingsList(this.holdingsDataInfo.accountId).subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.holdingsDataInfo.holdingsInfoVOs = response["responseData"];
          this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
            if (element.price && element.price.decimalValue)
              element.price.decimalValue = '$' + element.price.decimalValue.toLocaleString();

            if (element.costBasis && element.costBasis.decimalValue)

              element.costBasis.decimalValue = '$' + element.costBasis.decimalValue.toLocaleString();
            if (element.totalMarketValueBase && element.totalMarketValueBase.decimalValue)
              element.totalMarketValueBase.decimalValue = '$' + element.totalMarketValueBase.decimalValue.toLocaleString();
            if (element.totalMarketValueSystem && element.totalMarketValueSystem.decimalValue)
              element.totalMarketValueSystem.decimalValue = '$' + element.totalMarketValueSystem.decimalValue.toLocaleString();
            element['rowId'] = Math.floor((Math.random() * 10000000000) + 1);
            element['isSelected'] = false;
          });
          setTimeout(() => {
            this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
              $("#addHolding-frozen-column-" + element.rowId).parent().parent().outerHeight(
                $("#addHolding-relsoveName-column-" + element.rowId).parent().parent().outerHeight()
              )
              $("#addHolding-relsoveName-column-" + element.rowId).parent().parent().outerHeight(
                $("#addHolding-relsoveName-column-" + element.rowId).parent().parent().outerHeight() -2
              )
            });
            $("#add-holdings-header-isSelected").parent().parent().outerHeight(
              $("#add-holdings-header-Name").parent().parent().outerHeight()
            )
          }, 500)
        }
      });
    }
  }

  openViewHoldingPopup() {
    this.viewHoldingClosedStatus = false;
    this.obj1 = Object.assign({}, this.accountGridComponentReference);

    if (this.accountGridComponentReference.selectedRows.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (this.accountGridComponentReference.selectedRows.length > 1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select only one account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (this.accountGridComponentReference.selectedRows[0].accountType != 'ASSET_ACCOUNT') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Holdings can be of Asset Accounts only. So please select any assset account to view holdings.';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.holdingAction = 'viewHolding';
      //this.accountsListDataFromGrid = this.accountGridComponentReference.rowData;
      this.holdingsDataInfo.accountId = this.accountGridComponentReference.selectedRows[0].accountId;
      this.holdingsDataInfo.accountName = this.accountGridComponentReference.selectedRows[0].name;
      this.isAllHoldingSelected = false;
      this.ngxSmartModalService.open('viewHolding');
      let portfolioId = '';
      if (this.accountForPortfolio != 'UnAl-Acc') {
        portfolioId = this.accountForPortfolio;
      }
      this.portfolioOverviewService.getHoldingsList(this.holdingsDataInfo.accountId, portfolioId).subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.holdingsDataInfo.holdingsInfoVOs = response["responseData"];
          this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
            element['rowId'] = Math.floor((Math.random() * 10000000000) + 1);
            element['isSelected'] = false;
          });
          setTimeout(() => {
            this.holdingsDataInfo.holdingsInfoVOs.forEach(item => {
              $("#viewHolding-frozen-column-" + item.rowId).parent().parent().outerHeight(
                $("#viewHolding-relsoveName-column-" + item.rowId).parent().parent().outerHeight()
              )
              
            })
            $("#view-holdings-header-isSelected").parent().parent().outerHeight(
              $("#view-holdings-header-Name").parent().parent().outerHeight()
            )
          }, 75);
        }
      });
    }

  }

  deleteHoldings(): void {
    if (this.holdingsDataInfo.holdingsInfoVOs && this.holdingsDataInfo.holdingsInfoVOs.length != 0) {
      let voList = [];
      this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
        if (element['isSelected'] && (!element.lockedPercentage || element.lockedPercentage != 0)) {
          voList.push(element);
        }
      });
      if (voList.length != 0)
        this.ngxSmartModalService.open('deleteHoldingModal');
      else {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select any record for deleting';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }
    }
  }

  deleteHoldingAfterConfirmation() {
    this.filterVO = {};
    this.filterVO.dataStoreIdList = [];
    this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
      if (element['dataStoreId'] && element['isSelected'])
        this.filterVO.dataStoreIdList.push(element['dataStoreId']);
    });
    this.portfolioOverviewService.deleteHoldings(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteHoldingModal').close();
          // this.ngxSmartModalService.getModal('viewHolding').close();
          this.refreshAllData();
          let portfolioId = '';
          if (this.accountForPortfolio != 'UnAl-Acc') {
            portfolioId = this.accountForPortfolio;
          }
          this.portfolioOverviewService.getHoldingsList(this.holdingsDataInfo.accountId, portfolioId).subscribe(response => {
            if (response["responseType"] == Variables.statusSuccess) {
              this.holdingsDataInfo.holdingsInfoVOs = response["responseData"];
              this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
                element['rowId'] = Math.floor((Math.random() * 10000000000) + 1);
                element['isSelected'] = false;
              });
              setTimeout(() => {
                this.holdingsDataInfo.holdingsInfoVOs.forEach(item => {
                  $("#viewHolding-frozen-column-" + item.rowId).parent().parent().outerHeight(
                    $("#viewHolding-relsoveName-column-" + item.rowId).parent().parent().outerHeight()
                  )
                })
                $("#view-holdings-header-isSelected").parent().parent().outerHeight(
                  $("#view-holdings-header-Name").parent().parent().outerHeight()
                )
              }, 75);
              //this.ngxSmartModalService.getModal('viewHolding').open();
            }
          });
        }
      });
  }

  selectAllHoldings() {
    if (this.isAllHoldingSelected) {
      this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
        element['isSelected'] = true;
      });
    } else {
      this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
        element['isSelected'] = false;
      });
    }
  }

  changeSelectAllHoldings() {
    var toCheckMasterCount = 0;
    setTimeout(() => {
      this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
        if (element['isSelected'])
          toCheckMasterCount++
      });
      if (toCheckMasterCount == this.holdingsDataInfo.holdingsInfoVOs.length)
        this.isAllHoldingSelected = true;
      else
        this.isAllHoldingSelected = false
    }, 50)
  }

  addHoldingRow() {
    let holdingsVO = new HoldingsModel();
    if (this.holdingsDataInfo.carvedOutPercentage && (this.holdingsDataInfo.carvedOutPercentage > 0)) {
      holdingsVO.carveoutPercentage = this.holdingsDataInfo.carvedOutPercentage;
    }
    holdingsVO['rowId'] = Math.floor((Math.random() * 10000000000) + 1);
    this.holdingsDataInfo.holdingsInfoVOs.push(holdingsVO);
    setTimeout(() => {
      $("#addHolding-frozen-column-" + holdingsVO['rowId']).parent().parent().outerHeight(
        $("#addHolding-relsoveName-column-" + holdingsVO['rowId']).parent().parent().outerHeight()
      )
      $("#addHolding-relsoveName-column-" + holdingsVO['rowId']).parent().parent().outerHeight(
        $("#addHolding-relsoveName-column-" + holdingsVO['rowId']).parent().parent().outerHeight() + 1
      )
    }, 50)
  }

  removeHoldingRow(rowId) {
    _.remove(this.holdingsDataInfo.holdingsInfoVOs, function (n) {
      return n.rowId == rowId;
    });
  }

  saveHoldingsConfirmation() {
    var containsSelected = false;
    this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
      if (element['isSelected']) {
        containsSelected = true;
      }

    });
    if (containsSelected == false) {
      this.saveHoldings();
    } else {
      this.ngxSmartModalService.open('saveHoldingModal');
    }
  }

  saveHoldings() {
    let validationDataFlag = false;
    let validationDataFlag1 = false;
    let validationDataFlag2 = false;
    let acquisitionDatevalidation=false;
    let validationDataFlag3 = false;
    let validationDataFlagCent = false;
    let responseData = JSON.parse(JSON.stringify(this.holdingsDataInfo));
    let holdingsInfoVOs = [];
    this.emptyHoldingRows = [];
    //let noOfValidations = 0;
    responseData["holdingsInfoVOs"].forEach(element => {
      let noOfValidations = 0;
      let noOfValidations1 = 0;
      let noOfValidations3 = 0;
      if (element.isSelected && element.symbol && element.quantity && element.acquisitionDateStr) {
        if(element.acquisitionDateStr){
          let curDate=this.datePipe.transform(new Date(),'MM/dd/yyyy');
          element.acquisitionDateStr=this.datePipe.transform(element.acquisitionDateStr,'MM/dd/yyyy');
          if(curDate < element.acquisitionDateStr){
            acquisitionDatevalidation=true;
          }
        }
        holdingsInfoVOs.push(element);
      } else if (element.isSelected) {
        if (element.symbol) {
          noOfValidations3++;

        }
        if (element.quantity) {
          noOfValidations3++;
        }
        if(element.acquisitionDateStr){
          noOfValidations3++;
        }
        if (noOfValidations3 < 3) {
          validationDataFlag3 = true;
        }
      }
      else {
        if (element.name) {
          noOfValidations++;
        }
        // if(element.sectorId){
        //   noOfValidations++;
        // }
        if (element.asOfDate) {
          noOfValidations++;
        }
        if (element.assetClassId) {
          noOfValidations++;
        }
        if(element.acquisitionDateStr){
          noOfValidations++;
        }
        if (element.totalMarketValueSystem.decimalValue && ((element.quantity != '' || element.price.decimalValue != ''))) {

          noOfValidations++;
        }
        if (element.totalMarketValueSystem.decimalValue && ((element.quantity != null || element.price.decimalValue != null))) {

          noOfValidations++;
        }
        if (noOfValidations == 0) {
          if (element.sectorId) {
            noOfValidations1++;
          }
          if (element.lockedPercentage) {
            noOfValidations1++;
          }
          if (element.costBasis.decimalValue) {
            noOfValidations1++;
          }
          if (element.carveoutPercentage) {
            noOfValidations1++;
          }
          if (element.totalMarketValueBase.decimalValue) {
            noOfValidations1++;
          }
          if (element.symbol) {
            noOfValidations1++;
          }
          if (element.quantity) {
            noOfValidations1++;
          }
          if (noOfValidations1 > 0) {
            validationDataFlag2 = true;

          }
          // if(noOfValidations1==0){
          //   validationDataFlag3=true;
          // }
          this.emptyHoldingRows.push(element);
        } else {
          if (noOfValidations > 4) {
            holdingsInfoVOs.push(element);
          } else {
            validationDataFlag1 = true;
          }
        }
      }
      // if (!element.symbol) {
      //   noOfValidations++;
      // }
      // if (!element.asOfDate) {
      //   noOfValidations++;
      // }
      // if (!element.assetClassId) {
      //   noOfValidations++;
      // }
      // if (!element.totalMarketValueSystem.decimalValue && ((element.quantity == '' || element.price.decimalValue == ''))) {

      //   noOfValidations++;
      // }
      // if (!element.totalMarketValueSystem.decimalValue && ((element.quantity == null || element.price.decimalValue == null))) {

      //   noOfValidations++;
      // }
      // if (noOfValidations < 4)
      // holdingsInfoVOs.push(element);
    })
    if (validationDataFlag3) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Symbol , Quantity & Acquisition Date are mandatory for resolving';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.ngxSmartModalService.getModal('saveHoldingModal').close();
      return false;
    }

    if (validationDataFlag1 || validationDataFlag2) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Fill mandatory fields ' + ':' +
        'FOR RECOGNIZED HOLDINGS- Symbol , Quantity & Checkbox checked  ;' +
        'FOR UNRECOGNIZED HOLDINGS- Name, TMV or (Price & Quantity), As of date, Acquisition Date and Asset class.';;
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.ngxSmartModalService.getModal('saveHoldingModal').close();
      return false;

    }
    // if(validationDataFlag3){
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'You can not save blank rows.';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   this.ngxSmartModalService.getModal('saveHoldingModal').close();
    //   return false;
    // }

    responseData["holdingsInfoVOs"] = holdingsInfoVOs;
    responseData["holdingsInfoVOs"].forEach(element => {
      if (element.price && element.price.decimalValue)
        element.price.decimalValue = element.price.decimalValue.replace(/,/g, "").replace('$', "");
      if (element.costBasis && element.costBasis.decimalValue)
        element.costBasis.decimalValue = element.costBasis.decimalValue.replace(/,/g, "").replace('$', "");
      if (element.totalMarketValueBase && element.totalMarketValueBase.decimalValue)
        element.totalMarketValueBase.decimalValue = element.totalMarketValueBase.decimalValue.replace(/,/g, "").replace('$', "");
      if (element.totalMarketValueSystem && element.totalMarketValueSystem.decimalValue)
        element.totalMarketValueSystem.decimalValue = element.totalMarketValueSystem.decimalValue.replace(/,/g, "").replace('$', "");
      let tempDate = element.asOfDate;
      let curDate=this.datePipe.transform(new Date(),'MM/dd/yyyy');
      element.acquisitionDateStr=this.datePipe.transform(element.acquisitionDateStr,'MM/dd/yyyy');
      if(curDate < element.acquisitionDateStr){
        acquisitionDatevalidation=true;
      }
      element.asOfDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
      // if (element['isSelected']) {
      //   if (!element.symbol) {
      //     validationDataFlag = true;
      //     return false;
      //   }
      // } else {
      //   if (!element.symbol) {
      //     validationDataFlag = true;
      //     return false;
      //   }
      //   if (!element.asOfDate) {
      //     validationDataFlag = true;
      //     return false;
      //   }
      //   if (!element.assetClassId) {
      //     validationDataFlag = true;
      //     return false;
      //   }
      //   if (!element.totalMarketValueSystem.decimalValue && ((element.quantity == '' || element.price.decimalValue == ''))) {
      //     validationDataFlag = true;
      //     return false;
      //   }
      //   if (!element.totalMarketValueSystem.decimalValue && ((element.quantity == null || element.price.decimalValue == null))) {
      //     validationDataFlag = true;
      //     return false;
      //   }
      // }
      if ((parseFloat(element.lockedPercentage) + parseFloat(element.carveoutPercentage)) > 100) {
        validationDataFlagCent = true;
      }
      if (parseFloat(element.lockedPercentage) > 100 || parseFloat(element.carveoutPercentage) > 100) {
        validationDataFlagCent = true;
      }
    });
    // if (validationDataFlag) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please fill mandatory fields - Sector & TMV or (Price & Quantity) & Asset class & As of date';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (validationDataFlagCent) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Sum of locked and carve out percentage cannot be greater than 100';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if(acquisitionDatevalidation){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Acquisition Date should be less than Current Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    this.portfolioOverviewService.saveHoldings(responseData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['messageList']) {
          this.ngxSmartModalService.getModal('saveHoldingModal').close();
          this.prepareDataForHoldingWithoutResolve(response, this.emptyHoldingRows);
          let errorObj = {};
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = response['messageList'];
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          return false;

        } else {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('addHolding').close();
          this.holdingsDataInfo = new HoldingsDataModel();
          this.ngxSmartModalService.getModal('saveHoldingModal').close();
          this.refreshAllData();
        }
      }

    });
  }

  downloadTemplate() {
    this.portfolioOverviewService
      .downloadTemplateWorkflow(this.isxlsx)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          var fileData = response['responseData'].fileData;
          var fileName = response['responseData'].fileName;
          if (fileData && fileName) {
            CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
          }
        }
        this.isxlsx = true;
      });
  }

  uploadHoldingFile(fileDataObj) {
    var file = $('#upload-file').val();
    var fileName = null;
    var fileExtension = '';
    if (fileDataObj.target[0].files[0]) {
      fileName = fileDataObj.target[0].files[0].name;
      fileExtension = fileName.split(".").pop(-1);
      fileExtension = fileExtension.toLowerCase();
    }
    if (!fileName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select a file to upload';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } if (fileExtension != 'xlsx' && fileExtension != 'xls') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Only excel file can be used for import';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else {
      let formData = new FormData();
      // Add your values in here
      var fileData = fileDataObj.target[0].files[0];
      var accountId = fileDataObj.target[1].value;
      if (fileData) {
        formData.append('configFile', fileData, fileData.name);
        formData.append('accountId', accountId);

        this.portfolioOverviewService.uploadData(formData)
          .subscribe((response) => {
            if (response['responseType'] == Variables.statusSuccess) {
              this.authMessageService.showSuccessPopup(response);
              this.ngxSmartModalService.getModal('addHolding').close();
              this.refreshAllData();
            }
          });
      }
    }
  }

  resolveHoldings() {
    //this.isResolvedOperationPerformed=true;
    let selectedHoldingsList = [];
    this.unselectedHoldingList = [];
    this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
      if (element['isSelected']) {
        selectedHoldingsList.push(element);
      } else {
        this.unselectedHoldingList.push(element)
      }
    });
    if (selectedHoldingsList.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select atleast 1 holding';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let mandatoryFieldsMissingFlag = false;
    let acquisitionDatevalidation=false;
    selectedHoldingsList.forEach(element => {
      if (element.price && element.price.decimalValue)
        element.price.decimalValue = element.price.decimalValue.replace(/,/g, "").replace('$', "");
      // if (element.carvedOutPercentage)
      //   element.carvedOutPercentage = element.carvedOutPercentage.replace('%', "");
      // if (element.lockedPercentage)
      //   element.lockedPercentage = element.lockedPercentage.replace('%', "");
      if(element.acquisitionDateStr){
        let curDate=this.datePipe.transform(new Date(),'MM/dd/yyyy');
        element.acquisitionDateStr=this.datePipe.transform(element.acquisitionDateStr,'MM/dd/yyyy');
        if(curDate < element.acquisitionDateStr){
          acquisitionDatevalidation=true;
        }
      }

      if (element.costBasis && element.costBasis.decimalValue)
        element.costBasis.decimalValue = element.costBasis.decimalValue.replace(/,/g, "").replace('$', "");
      if (element.totalMarketValueBase && element.totalMarketValueBase.decimalValue)
        element.totalMarketValueBase.decimalValue = element.totalMarketValueBase.decimalValue.replace(/,/g, "").replace('$', "");
      if (element.totalMarketValueSystem && element.totalMarketValueSystem.decimalValue)
        element.totalMarketValueSystem.decimalValue = element.totalMarketValueSystem.decimalValue.replace(/,/g, "").replace('$', "");
      if (!element['symbol'] || !element['quantity'] || !element['acquisitionDateStr']) {
        mandatoryFieldsMissingFlag = true;
        return false;
      }
    });
    if (mandatoryFieldsMissingFlag) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Symbol , Quantity & Acquisition Date are mandatory for resolving';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else if(acquisitionDatevalidation){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Acquisition Date should be less than Current Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }else {
      let sysCurrency = this.holdingsDataInfo.sysCurrency;
      let baseCurrency = this.holdingsDataInfo.currency;
      this.portfolioOverviewService.resolveHoldings(selectedHoldingsList, sysCurrency, baseCurrency)
        .subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            let resolvedHoldingList = response['responseData'];
            resolvedHoldingList.forEach(element => {
              if (element.price && element.price.decimalValue)
                element.price.decimalValue = '$' + element.price.decimalValue.toLocaleString();
              // if (element.carvedOutPercentage)
              //   element.carvedOutPercentage = element.carvedOutPercentage.toLocaleString()+'%';
              // if (element.lockedPercentage)
              //   element.lockedPercentage = element.lockedPercentage.toLocaleString()+'%';

              if (element.costBasis && element.costBasis.decimalValue)
                element.costBasis.decimalValue = '$' + element.costBasis.decimalValue.toLocaleString();
              if (element.totalMarketValueBase && element.totalMarketValueBase.decimalValue)
                element.totalMarketValueBase.decimalValue = '$' + element.totalMarketValueBase.decimalValue.toLocaleString();
              if (element.totalMarketValueSystem && element.totalMarketValueSystem.decimalValue)
                element.totalMarketValueSystem.decimalValue = '$' + element.totalMarketValueSystem.decimalValue.toLocaleString();
              var rowData = selectedHoldingsList.find(item => {
                return (item.symbol == element.symbol && item.rowId == element.rowId);
              });
              if (rowData) {
                element.rowId = rowData.rowId;
              }
              var holdingInfoIndexInExistingList = this.holdingsDataInfo.holdingsInfoVOs.findIndex(item => {
                return (item.symbol == element.symbol && item.rowId == element.rowId);
              });
              if (holdingInfoIndexInExistingList >= 0) {
                this.holdingsDataInfo.holdingsInfoVOs[holdingInfoIndexInExistingList] = {};
                element.asOfDate = new Date(element.asOfDate);
                this.holdingsDataInfo.holdingsInfoVOs[holdingInfoIndexInExistingList] = element;
              }
              if (element.holdingResolvedName == "<b><FONT COLOR='#FF0000'>Unable to Resolve</FONT></b>") {
                element.holdingResolvedName = 'Unable to resolve';
                element.holdingResolvedNameErrorFlag = true;
              }

            });
            // this.unselectedHoldingList.forEach(element=>{
            //   resolvedHoldingList.push(element);
            // });
            setTimeout(() => {
              resolvedHoldingList.forEach(element => {
                let sectorInputId = element.rowId + '_sector_id';
                let sectorIdDom = document.getElementById(sectorInputId);
                let assetClassInputId = element.rowId + '_asset-class_id';
                let assetClassIdDom = document.getElementById(assetClassInputId);
                if (element.isNotResolved) {
                  assetClassIdDom['disabled'] = false;
                  sectorIdDom['disabled'] = false;
                } else {
                  assetClassIdDom['disabled'] = true;
                  sectorIdDom['disabled'] = true;
                }

              })
              this.unselectedHoldingList.forEach(element => {
                resolvedHoldingList.push(element);
              });
              this.holdingsDataInfo.holdingsInfoVOs = resolvedHoldingList;
              this.holdingsDataInfo.holdingsInfoVOs.forEach(ele => {
                $("#addHolding-frozen-column-" + ele['rowId']).parent().parent().outerHeight(
                  $("#addHolding-relsoveName-column-" + ele['rowId']).parent().parent().outerHeight()
                )
                $("#addHolding-relsoveName-column-" + ele['rowId']).parent().parent().outerHeight(
                  $("#addHolding-relsoveName-column-" + ele['rowId']).parent().parent().outerHeight() +1
                )
              })
              if (response['messageList'].length != 0) {
                var errorList = [];
                errorList = response['messageList'];
                if (errorList.length > 0) {
                  let errorObj = {};
                  var errorMsg = [];
                  errorObj['errorCode'] = 'ERR0107';
                  errorList.forEach(element => {
                    var newStr = element + "\n";
                    errorMsg.push(newStr);
                    // errorObj['message'] = element;
                  });
                  errorObj['message'] = errorMsg;
                  errorObj['errorDescription'] = '';
                  this.authMessageService.showErrorPopup(errorObj);
                }
                // let errorObj = {};
                // errorObj['errorCode'] = 'ERR0107';
                // errorObj['message'] = response['messageList'];
                // errorObj['errorDescription'] = '';
                // this.authMessageService.showErrorPopup(errorObj);
              } else {
                this.authMessageService.showSuccessPopup(response);

              }
            }, 200);


            // this.authMessageService.showSuccessPopup(response);
            // this.ngxSmartModalService.getModal('addHoldingModal').close();
            // this.refreshAllData();
          }
        });


    }


  }

  prepareDataForHoldingWithoutResolve(response, list_1) {
    let selectedHoldingsList = [];
    this.unselectedHoldingList = [];
    this.holdingsDataInfo.holdingsInfoVOs.forEach(element => {
      if (element['isSelected']) {
        selectedHoldingsList.push(element);
      }
      // } else {
      //   this.unselectedHoldingList.push(element)
      // }
    });
    let resolvedHoldingList = response['responseData'];
    resolvedHoldingList.forEach(element => {
      if (element.costBasis && element.costBasis.decimalValue)
        element.costBasis.decimalValue = '$' + element.costBasis.decimalValue.toLocaleString();
      if (element.totalMarketValueBase && element.totalMarketValueBase.decimalValue)
        element.totalMarketValueBase.decimalValue = '$' + element.totalMarketValueBase.decimalValue.toLocaleString();
      if (element.totalMarketValueSystem && element.totalMarketValueSystem.decimalValue)
        element.totalMarketValueSystem.decimalValue = '$' + element.totalMarketValueSystem.decimalValue.toLocaleString();
      var rowData = selectedHoldingsList.find(item => {
        return (item.symbol == element.symbol && item.rowId == element.rowId);
      });
      if (rowData) {
        element.rowId = rowData.rowId;
      }
      var holdingInfoIndexInExistingList = this.holdingsDataInfo.holdingsInfoVOs.findIndex(item => {
        return (item.symbol == element.symbol && item.rowId == element.rowId);
      });
      if (holdingInfoIndexInExistingList >= 0) {
        this.holdingsDataInfo.holdingsInfoVOs[holdingInfoIndexInExistingList] = {};
        element.asOfDate = new Date(element.asOfDate);
        this.holdingsDataInfo.holdingsInfoVOs[holdingInfoIndexInExistingList] = element;
      }
      if (element.holdingResolvedName == "<b><FONT COLOR='#FF0000'>Unable to Resolve</FONT></b>") {
        element.holdingResolvedName = 'Unable to resolve';
        element.holdingResolvedNameErrorFlag = true;
      }

    });
    setTimeout(() => {
      resolvedHoldingList.forEach(element => {
        let sectorInputId = element.rowId + '_sector_id';
        let sectorIdDom = document.getElementById(sectorInputId);
        let assetClassInputId = element.rowId + '_asset-class_id';
        let assetClassIdDom = document.getElementById(assetClassInputId);
        if (element.isNotResolved) {
          assetClassIdDom['disabled'] = false;
          sectorIdDom['disabled'] = false;
        } else {
          assetClassIdDom['disabled'] = true;
          sectorIdDom['disabled'] = true;
        }

      })
      list_1.forEach(element => {
        resolvedHoldingList.push(element);
      });
      this.holdingsDataInfo.holdingsInfoVOs = resolvedHoldingList;
      this.holdingsDataInfo.holdingsInfoVOs.forEach(ele => {
        $("#addHolding-frozen-column-" + ele['rowId']).parent().parent().outerHeight(
          $("#addHolding-relsoveName-column-" + ele['rowId']).parent().parent().outerHeight()
        )
        $("#addHolding-relsoveName-column-" + ele['rowId']).parent().parent().outerHeight(
          $("#addHolding-relsoveName-column-" + ele['rowId']).parent().parent().outerHeight() + 1
        )
      })
    }, 200)
  }

  setInvestableAssetsChartData() {
    // this.chartOptions = {
    //   'backgroundColor': [],
    //   legend: {
    //     display: true,
    //     position: 'bottom'
    //   },
    //   maintainAspectRatio: false
    // }
    this.chartLabels = [];
    this.chartData = [];
    if (this.portfoilioGridComponentReference.rowData.length > 0) {
      var obj;
      this.portfoilioGridComponentReference.rowData.forEach(element => {
        obj = {};
        this.chartLabels.push(element.name);
        if (element.investableAssetsDecimalValue) {
          obj = {
            name: element.name,
            value: element.investableAssetsDecimalValue,
            itemStyle: {
              color: element.color
            }
          }
        }
        else if (element.name == "UnAllocated Assets") {
          obj = {
            name: element.name,
            value: 0,
            itemStyle: {
              color: element.color
            }
          }
        }
        this.chartData.push(obj);
      });
      this.chartOption = {
        tooltip: {
          trigger: 'item',
          formatter: "{b} : {c}<br> {d}%",
          confine: true
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          bottom: 150,
          height: 80,
          left: 0,
          data: this.chartLabels,
          formatter: function (name) {
            if (name.length > 25)
              return name.substring(0, 20) + '...'
            else
              return name;
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['52%', '28%'],
            data: this.chartData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 2,
                borderColor: "#fff"
              },
            },
            label: {
              show: false
            }
          }
        ]
      };
    }
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // events on slice click
  // public chartClicked(e: any): void {
  //   console.log(e);
  // }

  // event on pie chart slice hover
  // public chartHovered(e: any): void {
  //   console.log(e);
  // }
  selectOwnerDateOfBirth(isFrom) {
    let selectedOwner = this.accountInfo.ownershipDataStoreId;
    let dob = this.ownersList.find(function (item) {
      return item.dataStoreId == selectedOwner;
    });
    if (dob) {
      this.accountInfo.dateOfBirth = dob.dateOfBirth;
    } else {
      this.accountInfo.dateOfBirth = null;
    }
  }

  selectAccountType(isFrom) {
    let selectedAccountType = this.accountInfo.accountType;
    if (selectedAccountType == "null") {
      this.selectedAccountSubType = [];
    }
    if (isFrom == "Form") {
      this.accountInfo.accountSubType = null;
    }
    let accountData = this.dropdownsData.accountType.find(function (item) {
      return item.code == selectedAccountType;
    });
    if (accountData) {
      this.selectedAccountSubType = accountData.accountSubTypeVO;
    }
    setTimeout(() => {
      if (selectedAccountType == 'LIABILITY' || selectedAccountType == 'OPERATIONAL_CASH') {
        this.showMarketValueInputBox = true;
        this.accountInfo.carvedOutPercentage = 100;
        this.accountInfo.totalMarketValueInMoney.currency = 'USD';
        document.getElementById('account-carve-out-percentage-id')['disabled'] = true;
      } else {
        this.showMarketValueInputBox = false;
        if (isFrom == 'Form') {
          this.accountInfo.carvedOutPercentage = 0;
          this.accountInfo.totalMarketValueInMoney.decimalValue = 0;
          this.accountInfo.totalMarketValueInMoney.currency = '';
          document.getElementById('account-carve-out-percentage-id')['disabled'] = false;
        }
      }
    }, 200);
  }

  saveAccount() {
    if (!this.accountInfo.shortName)
      this.accountInfo.shortName = this.accountInfo.name;
    let currentDate = this.accountInfo.inceptionDate;
    let dobDay = this.accountInfo.dateOfBirth;
    var compareDate = this.getCompareDate(currentDate, dobDay);
    if (this.validateAccountData(this.accountInfo, compareDate)) {
      this.selectedRowsInPortfoliosList = this.portfoilioGridComponentReference.getSelectedRows();
      this.accountInfo.portfolioId = this.accountForPortfolio;
      let tempDate = this.accountInfo.inceptionDate;
      this.accountInfo.inceptionDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
      if (this.accountInfo.portfolioId == 'UnAl-Acc') {
        this.accountInfo.portfolioId = '';
      }
      this.portfolioOverviewService.saveAccountData(this.accountInfo).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('account').close();
          this.refreshAllData();
          //this.accountGridComponentReference.getGridDataAfterUpdatingRecords();
        }
      });
    }
  }
  getCompareDate(currentDate, currentDate1) {
    var currentDay = this.datePipe.transform(currentDate, 'dd');
    var currentMonth = this.datePipe.transform(currentDate, 'MM');
    var currentYear = this.datePipe.transform(currentDate, 'yyyy');
    var dobDay = this.datePipe.transform(currentDate1, 'dd');
    var dobMonth = this.datePipe.transform(currentDate1, 'MM');
    var dobYear = this.datePipe.transform(currentDate1, 'yyyy');
    if (dobYear < currentYear) {
      return true;
    } else if (dobYear == currentYear) {
      if (dobMonth < currentMonth) {
        return true;
      } else if (dobMonth == currentMonth) {
        if (dobDay < currentDay) {
          return true;
        } else {
          return false;
        }

      } else if (dobMonth > currentMonth) {
        return false
      }

    } else if (dobYear > currentYear) {
      return false
    }


  }

  validateAccountData(data, compareDate) {

    if (!data.name) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }

    // if (!data.shortName) {
    //   var errorObj = new ErrorModel();
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter short name';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }


    if (data.ownershipDataStoreId == null || data.ownershipDataStoreId == "") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter client name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.accountType || data.accountType == "null") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter account type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.accountSubType == null || !data.accountSubType || data.accountSubType == "null") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter account sub type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.accountType && data.accountType != "ASSET_ACCOUNT") {
      if (!data.totalMarketValueInMoney.decimalValue) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter market value';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
    }
    if (data.taxStatus == null || !data.taxStatus || data.taxStatus == "null") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter tax status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (data.currency == null || data.currency == "") {
    //   var errorObj = new ErrorModel();
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter currency';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (!data.inceptionDate) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter inception date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (this.showOwnerDateOfBirth) {
      if (!data.dateOfBirth) {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please enter owner date of birth';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }

    }
    if (!compareDate) {

      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Date of Birth before inception date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.isQualified && data.isQualified != false || data.isQualified == "null") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter account qualified status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.taxStatus == "TAXABLE" && data.isQualified == 'true') {

      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Taxable Account cannot be qualified';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.carvedOutPercentage > 100) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Carve out percentage cannot be greater than 100';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }


    // if (data.taxStatus != "TAXABLE" && data.isQualified == 'false') {

    //   var errorObj = new ErrorModel();
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Non Taxable or tax exempt Account should be qualified';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }

    return true;
  }

  validatePortfolio(data) {
    if (!data.name) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.purposeCode == null || data.purposeCode == "" || data.purposeCode == "null") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter portfolio purpose';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.inceptionDate) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter inception date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (data.baseCurrency == null || data.baseCurrency == "") {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter base currency';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }
  onPortfolioSelectionForAccountList() {
    if (this.accountForPortfolio == 'UnAl-Acc') {
      this.accountGridComponentReference.apiParams = {
        processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId
      };
      this.accountGridComponentReference.getGridDataAfterUpdatingRecords();
    } else {
      this.accountGridComponentReference.apiParams = {
        portfolioId: this.accountForPortfolio
      };
      this.accountGridComponentReference.getGridDataAfterUpdatingRecords();
    }
  }

  connectAccount() {
    this.selectedRowsInAccountsList = this.accountGridComponentReference.getSelectedRows();
    if (this.selectedRowsInAccountsList && this.selectedRowsInAccountsList.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select at least one account for connecting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else if (this.selectedRowsInAccountsList && this.selectedRowsInAccountsList.length > 1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'You can connect only one account at a time';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else {
      this.portfolioOverviewService.getConnectedPortfolioListData(this.selectedRowsInAccountsList[0].accountId).subscribe(response => {
        if (response["responseType"] == Variables.statusSuccess) {
          this.connectAccountList = [];
          this.connectAccountList = response["responseData"];
          if (this.connectAccountList.length > 0) {
            let totalUnallocatedPercentage = 100;
            this.connectAccountList.forEach(function (item) {
              if (item['carvedInPercentage']) {
                totalUnallocatedPercentage -= item['carvedInPercentage'];
              }
              // else if(item['carvedInPercentage'] == 0){
              // item['carvedInPercentage'] = null;
              // }
            });

            this.totalUnallocatedPercentage = totalUnallocatedPercentage;
            this.ngxSmartModalService.open('connectAccountModal');
          } else {
            let errorObj = {};
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'No portfolios found';
            errorObj['errorDescription'] = '';
            this.authMessageService.showErrorPopup(errorObj);
            return false;
          }
        }
      });
    }
  }

  includePortfoliosInWorklow(flag, row) {
    this.includeInWorkflow = flag;
    //this.selectedRowsInPortfoliosList = this.portfoilioGridComponentReference.getSelectedRows();
    this.selectedRowsInPortfoliosList = [row];
    if (this.selectedRowsInPortfoliosList && this.selectedRowsInPortfoliosList.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      if (this.includeInWorkflow) {
        errorObj['message'] = 'Please select any portfolio for including in workflow';
      } else {
        errorObj['message'] = 'Please select any portfolio for excluding in workflow';
      }
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      let portfolioAlreadyIncluded = false;
      let portfolioAlreadyExcluded = false;
      let portfolioUnAllocatedInclude = false;
      let ifAnyPortfolioWithNoInvestableAssets = false;
      this.selectedRowsInPortfoliosList.forEach(element => {
        if (element.portfolioId == "UnAl-Acc") {
          portfolioUnAllocatedInclude = true;
        }
        if (this.includeInWorkflow && element.isIncludedInFPW) {
          portfolioAlreadyIncluded = true;
        }
        if (!this.includeInWorkflow && !element.isIncludedInFPW) {
          portfolioAlreadyExcluded = true;
        }
        if (!element.investableAssetsDecimalValue || element.investableAssetsDecimalValue == 0) {
          ifAnyPortfolioWithNoInvestableAssets = true;
        }
      });

      if (portfolioAlreadyIncluded) {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select only those portfolios which are not included in workflow';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      } else if (portfolioAlreadyExcluded) {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select only those portfolios which are not excluded';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      } else if (ifAnyPortfolioWithNoInvestableAssets) {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select only those portfolios which have investable assets';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      } else if (portfolioUnAllocatedInclude) {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select a Portfolio';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      } else {
        this.includeExcludeAfterConfirmation(row);
        //this.ngxSmartModalService.open('includeExcludeConfirmationModal');
      }
    }

  }

  includeExcludeAfterConfirmation(row) {
    let selectedPortfoliosId = [];
    this.selectedRowsInPortfoliosList = [row];
    this.selectedRowsInPortfoliosList.forEach(element => {
      selectedPortfoliosId.push(element.portfolioId);
    });
    let dataObj = {
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      taskDefinitionKey: this.proposalWorkflowComponent['selectedStep']['definitionKey'],
      includeInFPW: this.includeInWorkflow,
      portfolioIds: selectedPortfoliosId

    };
    this.portfolioOverviewService.includePortfoliosInWorkflow(dataObj).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('includeExcludeConfirmationModal').close();
        this.refreshAllData();
      }
    });
  }

  onSetCarveInPercentage(value, index, isFrom) {
    if (isFrom == 'onkeyup') {
      let total = 100;
      this.connectAccountList.forEach(element => {
        if (parseFloat(element['carvedInPercentage']))
          total -= parseFloat(element['carvedInPercentage']);
      });
      this.totalUnallocatedPercentage = total;
      if (this.totalUnallocatedPercentage < 0) {
        this.connectAccountList[index]['carvedInPercentage'] = 0;
        total = 100;
        this.connectAccountList.forEach(element => {
          if (parseFloat(element['carvedInPercentage']))
            total -= parseFloat(element['carvedInPercentage']);
        });
        this.totalUnallocatedPercentage = total;
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'You cannot give carve in % more than unallocated %';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }
    }
  }

  saveConnectAccountData() {
    this.selectedRowsInAccountsList = this.accountGridComponentReference.getSelectedRows();
    this.portfolioOverviewService.saveConnectedPortfolioListData(this.selectedRowsInAccountsList[0].accountId, this.connectAccountList).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('connectAccountModal').close();
        this.refreshAllData();
      }
    });
  }

  refreshAllData() {
    this.accountGridComponentReference.getGridDataAfterUpdatingRecords();
    this.portfoilioGridComponentReference.getGridDataAfterUpdatingRecords();
    setTimeout(() => {
      this.portfolioListFromGrid = [];
      let portfolioDataCopy = this.portfoilioGridComponentReference.rowData;
      if (portfolioDataCopy && portfolioDataCopy.length > 0) {
        for (let i = 0; i < portfolioDataCopy.length; i++) {
          this.portfolioListFromGrid.push(portfolioDataCopy[i]);
        }
      }
      this.setInvestableAssetsChartData();
    }, 5000);
  }

  /* Get dropdowns data */
  getDropdownsData() {
    this.apiParams = {
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      stepName: "portfolioOverview"
    };

    this.workflowBaseService.getEnumDataBasedOnStep(this.apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {

        this.dropdownsData = response['responseData'];
        this.dropdownsData['assetClassDataForDropdown'] = [];
        if (this.dropdownsData.assetClass) {
          this.dropdownsData.assetClass.forEach(element => {
            element.isHeader = true;
            element.displayName = element.name;
            this.dropdownsData['assetClassDataForDropdown'].push(element);
            if (element.assetSubClassVO) {
              element.assetSubClassVO.forEach(item => {
                item.isHeader = false;
                item.displayName = '- - ' + item.name;
                this.dropdownsData['assetClassDataForDropdown'].push(item);
              });
            }
          });
        }
      }
    });
    this.portfolioOverviewService.getOwnerListData(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        this.ownersList = response['responseData'];

      }
    });
  }
  onTaxStatusTypeBlur(event) {
    let selectedTaxStatusType = event.target.value;
    if (selectedTaxStatusType == "TAX_DEFERRED") {
      this.showOwnerDateOfBirth = true;
    } else {
      this.showOwnerDateOfBirth = false;
    }
  }
  toToggelVar() {
    this.viewHoldingClosedStatus = true;
  }

}