import {Component} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'edit-icon',
    template: '<div class="float-left w-100" *ngIf="!hideIcon" [ngClass]="{\'download-printHide\':disableIcon}">'+
                    '<fa name="pencil" class="cursor" (click)="editNewProductList()"></fa>'+
              '</div>',
    styles   : []
})
export class EditIconRowTemplate implements ICellRendererAngularComp {
    params:any;
    hideIcon : boolean = false;
    disableIcon : boolean = false;

    agInit(params:any):void {
        this.params = params;
        if(this.params.node.data.gridName=="workflow-portfolio-list" && this.params.node.data.name=="UnAllocated Assets"){
            this.hideIcon = true;
        }
        // if(this.params.node.data.gridName=="user-listing" && this.params.node.data.isTerminated){
        //     this.disableIcon = true;
        // }
    }

    public editNewProductList() {
        this.params.context.componentParent.editRowData(this.params.node.data);
    }

    refresh(): boolean {
        return false;
    }
}