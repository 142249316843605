import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import 'jquery';
import 'lodash';
//import 'bootstrap';
import 'less';

//Bootstrap
import { TabsModule } from 'ngx-bootstrap';

//Angular Material
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  NativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';



import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppComponent } from './app.component';
import { BaseComponent } from './shared/base/base.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent }   from './webPages/login/login.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderInterceptorService } from './_helpers/loader-interceptor.service';
import { DashboardComponent } from './webPages/dashboard/dashboard.component';
import { HeaderComponent } from './shared/header/header.component';
import { AuthMessageComponent } from './shared/auth-message/auth-message.component';
import { LeftNavigationComponent } from './shared/left-navigation/left-navigation.component';
import { RightNavigationComponent } from './shared/right-navigation/right-navigation.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SettingComponent } from './webPages/setting/setting.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ResetPasswordComponent } from './webPages/reset-password/reset-password.component';
import { PresentationComponent } from './webPages/presentation/presentation.component';
import { RelationshipsComponent } from './webPages/relationships/relationships.component';
import { CatalogComponent } from './webPages/catalog/catalog.component';
import { DataLoadConfigurationComponent } from './webPages/data-load-configuration/data-load-configuration.component';
import { DocumentCenterComponent } from './webPages/document-center/document-center.component';
import { TableModule } from 'primeng/table';
import {TreeTableModule} from 'primeng/treetable';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';
import { BsDatepickerModule } from 'ngx-bootstrap';
import {PaginatorModule} from 'primeng/paginator';
import { SelectFilterComponent } from './shared/custom-filters/select-filter/select-filter.component';
import { BooleanFilterComponent } from './shared/custom-filters/boolean-filter/boolean-filter.component';
import { AddEditRelationshipComponent } from './webPages/add-edit-relationship/add-edit-relationship.component';
import { ProposalWorkflowComponent } from './webPages/proposal-workflow/proposal-workflow.component';
import { RelationshipSelectionComponent } from './webPages/proposal-workflow/relationship-selection/relationship-selection.component';
import { RelationshipOverviewComponent } from './webPages/proposal-workflow/relationship-overview/relationship-overview.component';
import { CdkTableModule } from '@angular/cdk/table';
import { AddEditDocumentPopup } from './shared/popups/add-edit-document-popup/add-edit-document-popup.component';
import { SharedService } from './_services/shared.service';
import { InvestorInfoComponent } from './webPages/proposal-workflow/relationship-overview/investor-info/investor-info.component';
import { FilterPipe} from './_pipe/filter.pipe';
import { CustomGridHeaderComponent } from './shared/custom-grid-header/custom-grid-header.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DateFilterComponent } from './shared/custom-filters/date-filter/date-filter.component';
import { AddEditProductListPopup } from './shared/popups/add-edit-product-list-popup/add-edit-product-list-popup.component';
import { ProgressBarRowTemplate } from './shared/custom-grid-row-templates/progress-bar';
import { LinkTypeRowTemplate } from './shared/custom-grid-row-templates/linkType-row';
import { ProductFilterComponent } from './shared/product-filter/product-filter.component';
import { ProductComparePopup } from './shared/popups/product-compare-popup/product-compare-popup.component';
import { AddEditProductPopup } from './shared/popups/add-edit-product-popup/add-edit-product-popup.component';
import { EditIconRowTemplate } from './shared/custom-grid-row-templates/edit-icon';
import { HierarchyRowTemplate } from './shared/custom-grid-row-templates/hierarchy-row';
import { DownloadIconRowTemplate } from './shared/custom-grid-row-templates/download-icon';
import { ButtonTypeRowTemplate } from './shared/custom-grid-row-templates/buttonType-row';
import { ProductInfoComponent } from './webPages/catalog/product-info/product-info.component';
import { ProductOverviewTabComponent } from './webPages/catalog/product-info-tabs/product-overview-tab/product-overview-tab.component';
import { ProductDetailsTabComponent } from './webPages/catalog/product-info-tabs/product-details-tab/product-details-tab.component';
import { ProductCharactersticsTabComponent } from './webPages/catalog/product-info-tabs/product-characterstics-tab/product-characterstics-tab.component';
import { ProductManagementTabComponent } from './webPages/catalog/product-info-tabs/product-management-tab/product-management-tab.component';
import { ProductPerformanceTabComponent } from './webPages/catalog/product-info-tabs/product-performance-tab/product-performance-tab.component';
import { ProductFeesTabComponent } from './webPages/catalog/product-info-tabs/product-fees-tab/product-fees-tab.component';
import { ProductHoldingsTabComponent } from './webPages/catalog/product-info-tabs/product-holdings-tab/product-holdings-tab.component';
import { BalanceSheetComponent } from './webPages/proposal-workflow/relationship-overview/balance-sheet/balance-sheet.component';
import { TaxInfoComponent } from './webPages/proposal-workflow/relationship-overview/tax-info/tax-info.component';
import { AddEditWorkflowBalanceSheetComponent } from './shared/popups/add-edit-workflow-balance-sheet/add-edit-workflow-balance-sheet.component';
import { SelectAllHeaderComponent } from './shared/custom-grid-header/select-all-header/select-all-header.component';
import { PortfolioOverviewComponent } from './webPages/proposal-workflow/portfolio-overview/portfolio-overview.component';
import { CashFlowObjectiveComponent } from './webPages/proposal-workflow/cash-flow-objective/cash-flow-objective.component';
import { Chart } from 'chart.js';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { SortablejsModule } from 'angular-sortablejs'; 
import { StrategySelectionComponent } from './webPages/proposal-workflow/strategy-selection/strategy-selection.component';
import { PortfolioConstructionComponent } from './webPages/proposal-workflow/portfolio-construction/portfolio-construction.component';
import { InvestmentsComponent } from './webPages/proposal-workflow/portfolio-construction/investments/investments.component';
import { AccountsComponent } from './webPages/proposal-workflow/portfolio-construction/accounts/accounts.component';
import { SlideSelectionComponent } from './webPages/proposal-workflow/slide-selection/slide-selection.component';
import { LeftNavService } from './_services/left-nav.service';
import { LikeFilterComponent } from './shared/custom-filters/like-filter/like-filter.component';
import { CreatePresentationComponent } from './webPages/proposal-workflow/create-presentation/create-presentation.component';
import { PdfChartComponent } from './webPages/pdf-chart/pdf-chart.component';
import { TruncatePipe } from './_pipe/trimoff.pipe.';
import { MaxCalculatorModule } from './webPages/max-calculator/max-calculator.module';
import { SharedModule } from './shared/shared.module';
import { UserManagementComponent } from './webPages/user-management/user-management.component';
import { WelcomeComponent } from './webPages/welcome/welcome.component';
import { BuySellSummaryComponent } from './webPages/proposal-workflow/portfolio-construction/buy-sell/buy-sell-summary/buy-sell-summary.component';


@NgModule({
  exports: [
    CdkTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    NativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgbModule
  ],
  imports: [MaxCalculatorModule],
  declarations: [] 
})
export class FolioMaxMaterialModule {}




@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    LoginComponent,
    LoaderComponent,
    DashboardComponent,
    HeaderComponent,
    AuthMessageComponent,
    LeftNavigationComponent,
    RightNavigationComponent,
    FooterComponent,
    SettingComponent,
    ResetPasswordComponent,
    PresentationComponent,
    RelationshipsComponent,
    CatalogComponent,
    DataLoadConfigurationComponent,
    DocumentCenterComponent,
    AddEditRelationshipComponent,
    ProposalWorkflowComponent,
    RelationshipSelectionComponent,
    RelationshipOverviewComponent,
    AddEditDocumentPopup,
    InvestorInfoComponent,
    AddEditProductListPopup,
    ProductFilterComponent,
    ProductComparePopup,
    AddEditProductPopup,
    ProductInfoComponent,
    ProductOverviewTabComponent,
    ProductDetailsTabComponent,
    ProductCharactersticsTabComponent,
    ProductManagementTabComponent,
    ProductPerformanceTabComponent,
    ProductFeesTabComponent,
    ProductHoldingsTabComponent,
    BalanceSheetComponent,
    TaxInfoComponent,
    AddEditWorkflowBalanceSheetComponent,
    PortfolioOverviewComponent,
    CashFlowObjectiveComponent,
    StrategySelectionComponent,
    PortfolioConstructionComponent,
    InvestmentsComponent,
    BuySellSummaryComponent,
    AccountsComponent,
    SlideSelectionComponent,
    CreatePresentationComponent,
    PdfChartComponent,
    UserManagementComponent,
    WelcomeComponent
  ],
  entryComponents: [AddEditDocumentPopup,AddEditProductListPopup,ProductComparePopup,AddEditProductPopup,AddEditWorkflowBalanceSheetComponent],
  imports: [
    FolioMaxMaterialModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    BrowserAnimationsModule,
    TableModule,
    TreeTableModule,
    MultiSelectModule,
    TreeModule,
    BsDatepickerModule.forRoot(),
    PaginatorModule,
    AgGridModule.withComponents([SelectFilterComponent,BooleanFilterComponent,LikeFilterComponent,DateFilterComponent,CustomGridHeaderComponent,ProgressBarRowTemplate,EditIconRowTemplate,HierarchyRowTemplate,SelectAllHeaderComponent,DownloadIconRowTemplate,LinkTypeRowTemplate,ButtonTypeRowTemplate]),
    TabsModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 }),
    SortablejsModule,
    SharedModule,
    ChartsModule,
    NgxEchartsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    LeftNavService,
    ProposalWorkflowComponent,
    CreatePresentationComponent
  ],
  bootstrap: [AppComponent,BaseComponent]
})
export class AppModule { }
