import { Component, OnInit,Input } from '@angular/core';
import { Variables } from '../../../../../constants';
import { AnalysisService } from '../../../../../_services/workflow-services/analysis.service';
import { async } from 'q';

@Component({
  selector: 'app-cash-spending',
  templateUrl: './cash-spending.component.html',
  styleUrls: ['./cash-spending.component.less']
})
export class CashSpendingComponent implements OnInit {
  dropDownData: any;
 
  constructor(
    private analysisService : AnalysisService
  ) { }
  @Input() inputAnalysisData;
  @Input() inputRequestData;
  specificStrategy={};
  cashFlowList:any[];
  requestData:{};
  ngOnChanges() {
    this.f1(this.inputAnalysisData);
    

   // f1();

    
  }
   returnInputWithoutNull(data) { 
    return new Promise(resolve => {
      //setTimeout(() => {
        if(data!=null){
          resolve(data);
        } 
      //}, 500);
    });
  }
  
  async f1(data) {
    var x=await this.returnInputWithoutNull(data);
    if(x){
      console.log(x);
      
      this.cashFlowList = x["spendingRateTimeHorizonList"];
      this.specificStrategy=x['strategyOptions'][0];
      this.dropDownData=x['strategyOptions'];
    }
    this.requestData = this.inputRequestData;
  }

  ngOnInit(){

  }

  getDataBasedOnAnalysisType() {
    if(this.requestData){
      this.requestData["specificStrategyId"] = this.specificStrategy["value"];
      this.analysisService.getanalysisinfo(this.requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            this.cashFlowList = response["responseData"].spendingRateTimeHorizonList;
        }
      });
    }
    
  }

}
