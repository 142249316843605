import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import {PaginatorModule} from 'primeng/paginator';
import { DateFilterComponent } from './../shared/custom-filters/date-filter/date-filter.component';
import { SelectFilterComponent } from './../shared/custom-filters/select-filter/select-filter.component';
import { LikeFilterComponent } from './../shared/custom-filters/like-filter/like-filter.component';
import { CustomGridHeaderComponent } from './../shared/custom-grid-header/custom-grid-header.component';
import { EditIconRowTemplate } from './../shared/custom-grid-row-templates/edit-icon';
import {HierarchyRowTemplate } from'./../shared/custom-grid-row-templates/hierarchy-row';
import { SelectAllHeaderComponent } from './../shared/custom-grid-header/select-all-header/select-all-header.component';
import {ProgressBarRowTemplate} from './../shared/custom-grid-row-templates/progress-bar';
import {LinkTypeRowTemplate} from './../shared/custom-grid-row-templates/linkType-row';
import { GridComponent } from './../shared/grid/grid.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AnalysisStrategyComponent } from './../webPages/proposal-workflow/analysis-strategy/analysis-strategy.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FilterPipe} from './../_pipe/filter.pipe';
import { ButtonTypeRowTemplate } from './../shared/custom-grid-row-templates/buttonType-row';
import { DownloadIconRowTemplate } from './../shared/custom-grid-row-templates/download-icon';
import { PrintIconRowTemplate } from './../shared/custom-grid-row-templates/printType-row';
import { AllocationChartsComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/allocation-charts/allocation-charts.component';
import { CashFlowComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/cash-flow/cash-flow.component';
import { CashSpendingComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/cash-spending/cash-spending.component';
import { EffeicientFrontierComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/effeicient-frontier/effeicient-frontier.component';
import { GoalAchievabilityComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/goal-achievability/goal-achievability.component';
import { HistoricalStatisticesComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/historical-statistices/historical-statistices.component';
import { LevelAssetsComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/level-assets/level-assets.component';
import { MarketDownturnComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/market-downturn/market-downturn.component';
import { RangeOfAssetsComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/range-of-assets/range-of-assets.component';
import { RangeOfReturnComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/range-of-return/range-of-return.component';
import { TaxCalcInflationComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/tax-calc-inflation/tax-calc-inflation.component';
import { TaxCalcLinearComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/tax-calc-linear/tax-calc-linear.component';
import { TaxCalcNonQualifiedComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/tax-calc-non-qualified/tax-calc-non-qualified.component';
import { TaxCalcQualifiedComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/tax-calc-qualified/tax-calc-qualified.component';
import { HypotheticalGrowthComponent } from './../webPages/proposal-workflow/analysis-strategy/reports/hypothetical-growth/hypothetical-growth.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import {FeeSummaryPopupComponent}  from 'src/app/webPages/max-calculator/cal-portfolio-construction/fee-summary-popup/fee-summary-popup.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { TabsModule } from 'ngx-bootstrap';
import { TruncatePipe } from './../_pipe/trimoff.pipe.';
import { BooleanFilterComponent } from './custom-filters/boolean-filter/boolean-filter.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    AngularFontAwesomeModule,
    PaginatorModule,
    NgbModule,
    TabsModule,
    AgGridModule.withComponents([SelectFilterComponent,LikeFilterComponent,DateFilterComponent,CustomGridHeaderComponent,ProgressBarRowTemplate,EditIconRowTemplate,HierarchyRowTemplate,SelectAllHeaderComponent,DownloadIconRowTemplate,PrintIconRowTemplate,LinkTypeRowTemplate,ButtonTypeRowTemplate,BooleanFilterComponent]),
    ChartsModule,
    NgxEchartsModule
    
  ],
  declarations: [
    GridComponent,
    FilterPipe,
    DateFilterComponent,
    SelectFilterComponent,
    AnalysisStrategyComponent,
    AllocationChartsComponent,
    CashFlowComponent,
    CashSpendingComponent,
    EffeicientFrontierComponent,
    GoalAchievabilityComponent,
    HistoricalStatisticesComponent,
    LevelAssetsComponent,
    MarketDownturnComponent,
    RangeOfAssetsComponent,
    RangeOfReturnComponent,
    TaxCalcInflationComponent,
    TaxCalcLinearComponent,
    TaxCalcNonQualifiedComponent,
    TaxCalcQualifiedComponent,
    HypotheticalGrowthComponent,
    CustomGridHeaderComponent,
    EditIconRowTemplate,
    ProgressBarRowTemplate,
    LikeFilterComponent,
    SelectAllHeaderComponent,
    LinkTypeRowTemplate,
    ButtonTypeRowTemplate,
    HierarchyRowTemplate,
    FeeSummaryPopupComponent,
    DownloadIconRowTemplate,
    PrintIconRowTemplate,
    TruncatePipe,
    BooleanFilterComponent

  ],
  providers: [GridComponent],
  exports:[GridComponent,FilterPipe,AnalysisStrategyComponent,TruncatePipe,FeeSummaryPopupComponent],
})
export class SharedModule { }
