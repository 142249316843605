import { Injectable,Output,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ApiUrls } from '../../constants';
import { BehaviorSubject } from 'rxjs';
import { timeout } from 'q';


@Injectable({
  providedIn: 'root'
})
export class WorkflowBaseService{
  

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };
  workflowStepsData: any[];
  @Output() callWorflowChildFunction = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient
  ) {
    this.workflowStepsData = [];
  }

  // private messageSource = new BehaviorSubject(this.workflowStepData);
  // currentMessage = this.messageSource.asObservable();

  startNewProcess(requestData){
    return this.http.post(ApiUrls.startNewProcessAPI, requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  saveProcessName(requestData){
    return this.http.post(ApiUrls.saveProcessNameAPI, requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  executeFunctionOnNextORPrevoius(isNext){
    setTimeout(() => {
      this.callWorflowChildFunction.emit(isNext);
      
    }, 1000);
  }

  getProcessInfo(requestData){
    return this.http.post(ApiUrls.getProcessInfoDataAPI, requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  getEnumDataBasedOnStep(apiParams){
    this.httpOptions['params'] = apiParams;
    return this.http.get(ApiUrls.getEnumDataBasedOnWorkflowStepAPI,this.httpOptions)
    .pipe(
        response => response
    );
  }

  getFeeDetails(requestObj){
    return this.http.post(ApiUrls.getFeeDetailsAPI + '?portfolioId=' + requestObj.portfolioId + '&isPopUpView=' + requestObj.isPopUpView, "" , this.httpOptions)
    .pipe(
        response => response
    );
  }

  updateFeeIncluded(portfolioId,requestObj){
    return this.http.post(ApiUrls.updateFeeIncludedAPI + '?portfolioId=' + portfolioId, requestObj, this.httpOptions)
    .pipe(
        response => response
    );
  }

  gotoNextStep(requestObj){
    return this.http.post(ApiUrls.gotoNextStepInWorkflowAPI , requestObj , this.httpOptions)
    .pipe(
        response => response
    );
  }

  gotoPrevStep(requestObj){
    return this.http.post(ApiUrls.gotoPrevStepInWorkflowAPI , requestObj , this.httpOptions)
    .pipe(
        response => response
    );
  }

  getTaskDetails(requestObj,setAsCurrentStep){
    return this.http.post(ApiUrls.getTasksDetailsAPI + '?setAsCurrentStep=' + setAsCurrentStep, requestObj , this.httpOptions)
    .pipe(
        response => response
    );
  }

  saveGeneratePresentationDetails(processInstanceId,emailId){
    return this.http.post(ApiUrls.saveGeneratePresentationDetailsAPI + '?processInstanceId=' + processInstanceId + '&emailId=' + emailId, "" , this.httpOptions)
    .pipe(
        response => response
    );
  }

  updateProjectionPeriod(processInstanceId,projectionPeriod){
    return this.http.post(ApiUrls.updateProjectionPeriodAPI + '?projectionPeriod=' + projectionPeriod, "" , this.httpOptions)
    .pipe(
        response => response
    );
  }

  updateAccountTaxStatus(processInstanceId,taxStatus,dateOfBirth){
    return this.http.post(ApiUrls.updateAccountTaxStatusAPI + '?taxStatus=' + taxStatus + '&dateOfBirth=' + dateOfBirth, "" , this.httpOptions)
    .pipe(
        response => response
    );
  }

  deleteCurrentStrategies(requestData){
    return this.http.post(ApiUrls.deleteCurrentStrategiesAPI , requestData, this.httpOptions)
    .pipe(
        response => response
    );
  }

  getCurrentStrategiesAAC(requestData){
    return this.http.post(ApiUrls.getCurrentStrategiesAACAPI , requestData, this.httpOptions)
    .pipe(
        response => response
    );
  }

  getProposalStepAllocationData(requestData){
    return this.http.post(ApiUrls.getProposalStepAllocationDataAPI , requestData, this.httpOptions)
    .pipe(
        response => response
    );
  }

  addCurrentStrategies(requestData){
    return this.http.post(ApiUrls.addCurrentStrategiesAPI , requestData, this.httpOptions)
    .pipe(
        response => response
    );
  }

  getInvestmentTemplate(userId){
    return this.http.post(ApiUrls.getInvestmentTemplateAPI+"?userId="+userId+ "", this.httpOptions)
    .pipe(
        response => response
    );
  }

  saveInvestmentTemplate(investmentTemplateVO){
    return this.http.post(ApiUrls.getFeeTemplatesForUserAPI , investmentTemplateVO, this.httpOptions)
    .pipe(
        response => response
    );
  }

  getFeeTemplatesForUser(){
    return this.http.post(ApiUrls.getFeeTemplatesForUserAPI , "", this.httpOptions)
    .pipe(
        response => response
    );
  }

  saveFeeTemplate(requestData){
    return this.http.post(ApiUrls.saveFeeTemplateAPI , requestData, this.httpOptions)
    .pipe(
        response => response
    );
  }

  deleteSelectedFeeTemplate(isUserLevel,objectId){
    return this.http.post(ApiUrls.deleteFeeTemplateAPI +"?isUserLevel="+isUserLevel+"&objectId="+objectId, "", this.httpOptions)
    .pipe(
        response => response
    );
  }

  resetFeeTemplate(isUserLevel,objectId){
    return this.http.post(ApiUrls.resetFeeTemplateAPI +"?isUserLevel="+isUserLevel+"&objectId="+objectId, "", this.httpOptions)
    .pipe(
        response => response
    );
  }

  getGeneratePresentationDetails(processInstanceId){
    return this.http.get(ApiUrls.getGeneratePresentationDetailsAPI + '?processInstanceId=' + processInstanceId  , this.httpOptions)
    .pipe(
        response => response
    );
  }
  

  dowloadReport(apiParams){
    this.httpOptions['params'] = apiParams;
    return this.http.get(ApiUrls.dowloadReportAPI , this.httpOptions)
    .pipe(
        response => response
    );
  }

  getAssetClassExclusionInfo(requestObj){
    return this.http.post(ApiUrls.getAssetClassExclusionInfoAPI , requestObj , this.httpOptions)
    .pipe(
        response => response
    );
  }

  saveAssetClassExclusionInfo(requestObj){
    return this.http.post(ApiUrls.saveAssetClassExclusionInfoAPI , requestObj , this.httpOptions)
    .pipe(
        response => response
    );
  }

  setStepInfoVsStepName(stepName,stepInfo){
    this.workflowStepsData[stepName] = stepInfo;
  }

  getStepInfoVsStepName() {
    return this.workflowStepsData;
  }
  

}
