import { Injectable } from '@angular/core';
//import { Injectable,Output,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


import { ApiUrls } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };
  private messageSource = new BehaviorSubject({});
  currentMessage = this.messageSource.asObservable();
 // @Output() sendEventToCheck = new EventEmitter<boolean>();
  constructor(private http: HttpClient) { }

  changeMessage(message: object) {
    this.messageSource.next(message);
  }
  getGridConfig(gridName) {
    let apiParams = {
      'gridName': gridName
    };
    this.httpOptions['params'] = apiParams;
    //return this.http.get(ApiUrls.getGridConfigDataAPI + gridName + '.json' , this.httpOptions )
    return this.http.get(ApiUrls.getGridConfigDataAPI, this.httpOptions)
      .pipe(
        response => response
      );
  }

  // sendDataToEnableCheck(data){
  //   this.sendEventToCheck.emit(data);
  // }

  getGridSearchSortData(gridName, apiParams) {
    if (apiParams) {
      apiParams['gridName'] = gridName;
    } else {
      apiParams = {
        'gridName': gridName
      };
    }
    this.httpOptions['params'] = apiParams;
    //return this.http.get(ApiUrls.getGridSearchSortDataAPI  , this.httpOptions )
    return this.http.get(ApiUrls.getGridSearchSortDataAPI, this.httpOptions)
      .pipe(
        response => response
      );
  }

  getGridData(filterVO, gridName, apiParams) {
    this.httpOptions['params'] = apiParams;
    return this.http.post(ApiUrls.getGridDataAPI[gridName], filterVO, this.httpOptions)
      .pipe(
        response => response
      );
  }

}
