import { Component, OnInit } from '@angular/core';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { ActivatedRoute } from '@angular/router';
import { Variables } from '../../../../constants';

@Component({
  selector: 'product-management-tab',
  templateUrl: './product-management-tab.component.html',
  styleUrls: ['./product-management-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductManagementTabComponent implements OnInit {
  productId: string;
  productManagementData:any= {};
  productManagementAdvisoryBoardVO= [];
  productManagementFirmInformationVO:any= {};
  productManagementManagersVO= [];
  productManagementMarketingContactsVO= [];

  constructor(
    private activeRoute: ActivatedRoute,
    private catalogProductInfoService: CatalogProductInfoService
    ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });
    this.getProductManagementData(this.productId);
  }

  getProductManagementData(productId) {
    this.catalogProductInfoService.getProductManagementInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productManagementData = response['responseData'];
          this.productManagementAdvisoryBoardVO = this.productManagementData['productManagementAdvisoryBoardVO'];
          this.productManagementFirmInformationVO = this.productManagementData['productManagementFirmInformationVO'];
          this.productManagementManagersVO = this.productManagementData['productManagementManagersVO'];
          this.productManagementMarketingContactsVO = this.productManagementData['productManagementMarketingContactsVO'];
        }
      });
  }

}
