import { Component, OnInit, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as $ from 'jquery';
import { BalanceSheetModel } from "../../../_models/balance-sheet.model";
import { ErrorModel } from "../../../_models/response.model";
import { AuthMessageService } from "../../../_services/auth-message.service";
import { CommonFunctions } from "src/app/common";

declare var _: any;

@Component({
  selector: 'app-add-edit-workflow-balance-sheet',
  templateUrl: './add-edit-workflow-balance-sheet.component.html',
  styleUrls: ['./add-edit-workflow-balance-sheet.component.less']
})

export class AddEditWorkflowBalanceSheetComponent implements OnInit {

  @Input() compId: string;
  @Input() rowData;
  @Input() saveBalanceSheetData;
  @Input() dropdownsData;

  balanceSheetInfo :any= new BalanceSheetModel();
  commonFunction = new CommonFunctions();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private authMessageService: AuthMessageService
  ) {}

  ngOnInit() {
    if (this.rowData) {
      this.balanceSheetInfo = this.rowData;
    }

    
  }

  ngAfterViewInit()
  {
    setTimeout(() => {
    this.ngxSmartModalService.open(this.compId);
    });
  //   $(document).ready(function () {
  //     $('.myTextBox').bind('copy paste cut', function (e) {
  //        e.preventDefault();
  //         alert('cut,copy & paste options are disabled !!');
  //     });
  // });
  }

  closePopup()
  {
    this.ngxSmartModalService.getModal(this.compId).close();
    $("#"+this.compId).closest("app-add-edit-workflow-balance-sheet").remove();
  }

  submitBalanceSheet(){
    if(this.validateData(this.balanceSheetInfo)){
      this.saveBalanceSheetData(this.balanceSheetInfo, this);
    }
  }


  validateData(data) {
    if (!data.name) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter balance sheet name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.marketValue ) {
      if(!data.price || !data.quantity){
        var errorObj = new ErrorModel();
        errorObj['message'] = 'Please enter market value or (price & quantity)';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
    }
    if (!data.category) {
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please select category';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }

  calculateMktVal() {
    if(this.balanceSheetInfo.price && this.balanceSheetInfo.quantity)
      this.balanceSheetInfo.marketValue = Number(this.balanceSheetInfo.price) * parseInt(this.balanceSheetInfo.quantity)
    else
      this.balanceSheetInfo.marketValue = null;
  }

}
