import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";
import { BaseService } from "../../../_services/base.service";
import { LeftNavService } from 'src/app/_services/left-nav.service';
import { Variables } from "../../../constants";
import { WorkflowBaseService } from "../../../_services/workflow-services/workflow-base.service";
import { ErrorModel } from "../../../_models/response.model";
import { AuthMessageService } from "../../../_services/auth-message.service";

@Component({
  selector: 'app-max-calculator-main',
  templateUrl: './max-calculator-main.component.html',
  styleUrls: ['./max-calculator-main.component.less']
})
export class MaxCalculatorMainComponent implements OnInit {

  code: any;
  workflowInfoData: any;
  currentProcessRelationshipId : string;
  showSubmitButton:boolean=false;
  showPreviousButton:boolean=true;
  hideNextButton:boolean=false;
  isEditProcessName:boolean=false;

  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private leftNavData: LeftNavService,
    private baseService: BaseService,
    private workflowBaseService: WorkflowBaseService,
    private authMessageService: AuthMessageService
  ) { }

  stepList: any[];
  selectedStep:any= {};
  message:boolean = true;
  isLeftPanelOpen: boolean = true; 

  ngOnInit() {
    this.stepList = [];
    this.workflowInfoData = {};
    this.leftNavData.currentMessage.subscribe(message =>{
      this.message = message;
    });
    this.route.params.subscribe(params => { this.code = params.code });
    if (localStorage.getItem('workflowInfoData')) {
      this.workflowInfoData = JSON.parse(localStorage.getItem('workflowInfoData'));
      this.getProcessInfoData(this.workflowInfoData.processInstanceId);
      this.getTaskDetails('proposalSetup');
    }
    
    
    
  }

 

  getProcessInfoData(processInstanceId) {
    var requestObj = {
      "processInstanceId": processInstanceId,
      "processDefinitionKey" : this.workflowInfoData.processDefinitionKey

    }
    this.workflowBaseService.getProcessInfo(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.workflowInfoData = response['responseData'];
        console.log(this.workflowInfoData);

        if(!this.workflowInfoData.processVariables.processName)
         this.workflowInfoData.processVariables.processName = this.workflowInfoData.processInstanceName;

        this.stepList = [];
        let stepData = response['responseData'].processVariables.processStateInfo.stepsPriorityInfo;
        let stepDataKeys = Object.keys(stepData);
        stepDataKeys.forEach(element => {
          this.stepList.push(stepData[element]);
        });
        let currentStepDefinationKey = response['responseData'].processVariables.processStateInfo.currentTaskDefinitionKey;
        let stepExist = _.find(this.stepList, function (e) {
          return e.definitionKey == currentStepDefinationKey;
        });
        if (stepExist) {
          this.selectedStep = stepExist;
          this.router.navigate([stepExist.templateReference], { relativeTo: this.route });
        }
      }
    });
  }

  editProcessName(){
    this.isEditProcessName = true;
  }

  saveProcessName(){
    this.workflowInfoData.taskDefinitionKey = this.workflowInfoData.currentTaskDetails.taskDefinitionKey;
    this.workflowBaseService.saveProcessName(this.workflowInfoData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditProcessName = false;
        
      }
     
    });
  }

  nextStep(event) {
    var currentStepDefinitionKey = this.selectedStep["definitionKey"];
    var curretRouteIndex = this.selectedStep["seqNumber"] - 1;
    if (curretRouteIndex <= this.stepList.length - 1) {
      let taskDefinationKey = this.stepList[curretRouteIndex].definitionKey;
      let requestObj = {
        "processInstanceId": this.workflowInfoData.processInstanceId,
        "taskDefinitionKey": taskDefinationKey,
        "processDefinitionKey" : this.workflowInfoData.processDefinitionKey
      };
      let requestObjData =true;
      if(event)
        requestObjData = this.setStepRequestData(requestObj, this.stepList[curretRouteIndex].templateReference);
      if (requestObjData) {
        this.workflowBaseService.gotoNextStep(requestObj).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            if(this.showSubmitButton){
              this.router.navigate(["/presentation"]);
            }else{
              var nextRoute = response['responseData'].taskDefinitionKey;
              this.workflowInfoData.currentTaskDetails = response['responseData'];
              this.router.navigate([nextRoute], { relativeTo: this.route });
              this.selectedStep = _.find(this.stepList, function (item) {
                return nextRoute == item.definitionKey;
              });
            }
          
            
          }
         
        });

      }



    }
  }

  setStepRequestData(requestObj, step) {
    let workflowStepInfo = this.workflowBaseService.getStepInfoVsStepName();
    let stepInfo = workflowStepInfo[step];
    
    /* for Relationship selection step */
    if (step == 'relationshipSelection') {
      if (stepInfo && stepInfo.selectedRelationshipData && stepInfo.selectedRelationshipData.dataStoreId) {
        if (stepInfo.customProcessName != null) {
          if (stepInfo.customProcessName == '') {
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Please enter custom name for process, as you selected for custom name  Or Change to default name.';
            errorObj['errorDescription'] = '';
            this.authMessageService.showErrorPopup(errorObj);
            return false;
          } else {
            requestObj['taskVariables'] = {
              "processName": stepInfo.customProcessName,
              "relationshipId": stepInfo.selectedRelationshipData.clientId
            };
            this.workflowInfoData.processVariables.processName = stepInfo.customProcessName;
            return requestObj;
          }
        } else {
          requestObj['taskVariables'] = {
            "relationshipId": stepInfo.selectedRelationshipData.clientId
          }
          return requestObj;
        }
      } else {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select one relationship';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
    }
     /* for Cash Flow Objective step */
     if (step == 'cashFlowObjectives') {
      if (stepInfo && stepInfo.cashFlowObjectiveModel && stepInfo.cashFlowObjectiveModel.primaryInfo) {
        if(!stepInfo.cashFlowObjectiveModel.primaryInfo.lifeExpectancy){
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please save life expectancy';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          return false;
        }
        if(!stepInfo.cashFlowObjectiveModel.planningTimeHorizon){
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'Please save planning time horizon';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
          return false;
        }
      }
    }
    if(step == 'portfolioConstruction'){
      if(stepInfo && stepInfo.isChangeAfterSave){
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please save all allocations before moving to next step';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
    }

    if(step == 'slideSelection'){
      this.workflowBaseService.executeFunctionOnNextORPrevoius(true);
      return false;
    }
    return requestObj;

  }

  previousStep(event) {
    var currentStepDefinitionKey = this.selectedStep["definitionKey"];
    var previousRouteIndex = this.selectedStep["seqNumber"] - 1;
    if (previousRouteIndex > -1) {
      let taskDefinationKey = this.stepList[previousRouteIndex].definitionKey;
      // if(currentStepDefinitionKey == "slideSelection"){
      //   this.workflowBaseService.executeFunctionOnNextORPrevoius(true);
      // }
      let requestObj = {
        "processInstanceId": this.workflowInfoData.processInstanceId,
        "taskDefinitionKey": taskDefinationKey,
        "processDefinitionKey" : this.workflowInfoData.processDefinitionKey
      };
      this.workflowBaseService.gotoPrevStep(requestObj).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          var prevRoute = response['responseData'].taskDefinitionKey;
          this.workflowInfoData.currentTaskDetails = response['responseData'];
          this.router.navigate([prevRoute], { relativeTo: this.route });
          this.selectedStep = _.find(this.stepList, function (item) {
            return prevRoute == item.definitionKey;
          });
        }
        this.showSubmitButton = false;
        this.showPreviousButton=true;
        this.hideNextButton=false;
      });
    }
  }

  getTaskDetails(previousRouteIndex) {
    let requestObj = {
      "processInstanceId": this.workflowInfoData.processInstanceId,
      "taskDefinitionKey": previousRouteIndex,
      "processDefinitionKey" : this.workflowInfoData.processDefinitionKey
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData'].taskVariables) {
          this.currentProcessRelationshipId = response['responseData'].taskVariables.relationshipId;
        }
      }
    });
  }

  navigateToStepFromPreviousCompletedSteps(previousRouteIndex){
  if(this.showPreviousButton){
    if (previousRouteIndex > -1 && previousRouteIndex < this.selectedStep["seqNumber"]) {
      let taskDefinationKey = this.stepList[previousRouteIndex].definitionKey;
      let requestObj = {
        "processInstanceId": this.workflowInfoData.processInstanceId,
        "taskDefinitionKey": taskDefinationKey,
        "setAsCurrentStep" : true,
        "processDefinitionKey" : this.workflowInfoData.processDefinitionKey
    
      };
      this.workflowBaseService.getTaskDetails(requestObj,true).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          var prevRoute = response['responseData'].taskDefinitionKey;
          this.workflowInfoData.currentTaskDetails = response['responseData'];
          this.router.navigate([prevRoute], { relativeTo: this.route });
          this.selectedStep = _.find(this.stepList, function (item) {
            return prevRoute == item.definitionKey;
          });
        }
        this.showSubmitButton = false;
        this.showPreviousButton=true;
        this.hideNextButton=false;

      });
    }
  }
  }

  cancelStep() {
    this.router.navigate(["/dashboard"]);
    //this.showPreviousButton=true;
  }

  onClose() {
    this.isLeftPanelOpen = false;
  }
  onOpen() {
    this.isLeftPanelOpen = true;
  }


}
