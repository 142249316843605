export class StrategyCharacteristicModel{
    expectedReturn:string='0.00%';
    afterTaxExpectedReturn:string='0.00%';
    standardDeviation:string='0.00%';
    afterTaxYield:string='0.00%';
    afterTaxStandardDeviation:string='0.00%';
    sharpRatio:string='0.00%';
    afterTaxSharpRatio:string='0.00%';
    yield:string='0.00%'
    currentexpectedReturn:string='0.00%';
    currentafterTaxExpectedReturn:string='0.00%';
    currentstandardDeviation:string='0.00%';
    currentafterTaxYield:string='0.00%';
    currentafterTaxStandardDeviation:string='0.00%';
    currentsharpRatio:string='0.00%';
    currentafterTaxSharpRatio:string='0.00%';
    currentyield:string='0.00%'
}