import { Component, Input, OnInit, AfterViewInit, Output } from '@angular/core';

import { GridFilterSortingService } from '../../../_services/grid-filter-sorting.service';
import { GridService } from '../../../_services/grid.service';
declare var _: any;
declare var $: any;

@Component({
    selector: 'select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.less']
})
export class SelectFilterComponent implements AfterViewInit {
    params: any;
    private columnFilterData;
    //subscription;

    // @Input() message;
    checkObj: any = {};
    isApplyFilter = false;
    subscription: any;
    constructor(
        private gridFilterSortingService: GridFilterSortingService,
        private gridService: GridService
    ) { }

    agInit(params): void {
        this.params = params;

    }
    // ngOnInit(){
    //     this.subscription = this.gridFilterSortingService.sendEventToCheck.subscribe(data => {
    //         this.enableCheckForAlreadyAppliedFilter(data);
    //       });
    // }

    // enableCheckForAlreadyAppliedFilter(data){

    // }
    // ngOnInit(){


    // }
    ngAfterViewInit() {
        //if(!this.isApplyFilter){
        this.subscription = this.gridService.currentMessage.subscribe(message => {
            this.checkObj = message;

            //if(!this.isApplyFilter){
            var previousFind = this.checkObj.searchedValue[0];
            if (this.params.context.componentParent.apiParams && this.params.context.componentParent.apiParams.gridName == "catalog-product") {
                this.setCheckBoxForAlreadyApplyFilter(this.checkObj);
            }
        });

        //     }
    }


    setCheckBoxForAlreadyApplyFilter(obj) {
        let key1;
        let listOfCheckboxes = document.getElementsByClassName(this.checkObj.fieldName + '_class');
        for (let j in this.checkObj.paramListData) {
            if (this.checkObj.paramListData[j] == this.checkObj.searchedValue[0]) {
                key1 = j;
            }
        }
        for (let key in listOfCheckboxes) {
            if (listOfCheckboxes.hasOwnProperty(key) && key == key1) {
                listOfCheckboxes[key]['checked'] = true;
            }
        }
        //obj.searchedValue=[];
        // for(let key in listOfCheckboxes){

        // }
    }
    changeSelection(item) {
        var ifItemExist = _.find(this.params.colDef['searchSortData'].searchedValue, function (ele) {
            return ele == item;
        });
        if (!ifItemExist) {
            this.params.colDef['searchSortData'].searchedValue.push(item);
            if (this.params.colDef['searchSortData'].searchedValue.length > 0 && (this.params.colDef['searchSortData'].searchedValue.length == this.params.colDef['searchSortData'].paramListData.length)) {
                document.getElementById(this.params.colDef['searchSortData'].fieldName + '_select-all')['checked'] = true;
            }
        } else {
            document.getElementById(this.params.colDef['searchSortData'].fieldName + '_select-all')['checked'] = false;
            _.remove(this.params.colDef['searchSortData'].searchedValue, function (ele) {
                return ele == item;
            });
        }
    }

    onSelectAll(event) {
        if (event.target.checked) {
            this.params.colDef['searchSortData'].searchedValue = [];
            for (let item of this.params.colDef['searchSortData'].paramListData) {
                if (document.getElementById(this.params.colDef['searchSortData'].fieldName + '_' + item)) {
                    document.getElementById(this.params.colDef['searchSortData'].fieldName + '_' + item)['checked'] = true;
                }
            }
            let paramDataCopy = Object.assign([], this.params.colDef['searchSortData'].paramListData);
            this.params.colDef['searchSortData'].searchedValue = paramDataCopy;
        } else {
            this.params.colDef['searchSortData'].searchedValue = [];
            for (let item of this.params.colDef['searchSortData'].paramListData) {
                if (document.getElementById(this.params.colDef['searchSortData'].fieldName + '_' + item)) {
                    document.getElementById(this.params.colDef['searchSortData'].fieldName + '_' + item)['checked'] = false;
                }
            }
            this.params.colDef['searchSortData'].searchedValue = [];

        }

    }

    onApplyingFilter() {
        //this.isApplyFilter=true;
        this.columnFilterData = this.params.column.colDef.searchSortData;
        this.gridFilterSortingService.setSortingData(this.columnFilterData);
    }

    onClearingFilter() {
        this.columnFilterData = this.params.column.colDef.searchSortData;
        this.columnFilterData.filterValue = "";
        this.columnFilterData.searchedValue = [];
        this.gridFilterSortingService.setSortingData(this.columnFilterData);
        //For removal of checkboxes selection on UI
        let listOfCheckboxes = document.getElementsByClassName(this.columnFilterData.fieldName + '_class');
        for (let key in listOfCheckboxes) {
            if (listOfCheckboxes.hasOwnProperty(key)) {
                listOfCheckboxes[key]['checked'] = false;
            }
        }


    }

    refresh(): boolean {
        return true;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }


    // ngOnDestroy() {
    //     this.subscription.unsubscribe();
    //   }


}
