export class PrimaryInfoModel{
    entiyId: string;
    entityName: string;
    retirementAge: number;
    lifeExpectancy:number;
    dateOfBirth:string;
}

export class CashFlowObjectiveModel{
    relationshipId: string;
    planningTimeHorizon: number;
    primaryInfo:any=new PrimaryInfoModel();
    secondaryInfo:any=new PrimaryInfoModel();
}

export class CashInflowModel{
    cashflowId: string;
    type: string = null;
    subType: string = null;
    inflationRate:number;
    inflationRateStr:string;
    amount: any;
    amountStr: string;
    frequency: string = 'ONE_TIME';
    startDate:string;
    endDate:string;
    clientHierarchyId:string = null;
    isPercentExposed:boolean;  
}

export class CashOutflowModel{
    cashflowId: string;
    isPercentBased:boolean;
    type: string = null;
    subType: string = null;
    inflationRate:number;
    inflationRateStr:string;
    amount: any;
    amountStr: string;
    frequency: string = 'ONE_TIME';
    startDate:string;
    endDate:string;
    portfolioId:string = null;
    accountVOList:any = [];  
    accountIds:string[] = [];
    
}

export class SimulationParameterModel{
    numberOfTrials: number;
    probabilitySuccessForTarget: number;
    lifeExpectancy: number;
    inflationRate:number;
    inflationRateStr:string;
    removeOutliners :boolean;
    calculateTaxes: boolean;
}

export class InvestmentManagementFeesModel{
    dataStoreId: number;
    beginAmount: number;
    endAmount: number;
    scheduleId:string;
    feePercentage: number;
    minimumFee: number;
    maximumFee:number;
    accountFee : number;
    imaFeePercentage : number;
    feeTemplateName : String;
    advisoryFeePercentage : number
}