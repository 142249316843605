export class User{
    userCode: string;
    password: string;
    isTwoFactorCode: boolean;
}

export class UserInfoModel{
    firstName: string;
    middleName: string;
    lastName: string;
    userName: string;
    userId: string;
    email: string;
    companyName:string;
    password: string;
    trialPeriodEndDate : any;
    trialDaysRemaining : number;
    dob : string;
    gender:string =null;
    isAutoGenerated:boolean =false;
    role=null;
    isDataLoadConfigEditable:boolean =false;
    isSlideSelectionEditable:boolean = false;
    modulesWithPermissionList:any[];
    contactInfoVO = {
        phoneNumberVOList : [{
            type : "Home",
            isPrimary : true,
            value : null,
            countryCode : null
        }],
        emailVOList : [{
            type : "Home",
            isPrimary : true,
            value : null
        }],
        addressVOList : [{
            type : "Home",
            addressLine1 : "",
            addressLine2 : "",
            city : "",
            state : "",
            country : "",
            zipCode : "",
            isPrimary : true
        }]
    }
}