import { Component, OnInit } from '@angular/core';
import { EChartOption } from 'echarts';
import { PdfChartService } from '../../_services/pdf-chart.service';
import * as echarts from 'echarts';
import { Variables } from '../../constants';
import { ActivatedRoute } from "@angular/router";
import { from } from 'rxjs';
//import { resolve } from 'path';

@Component({
  selector: 'app-pdf-chart',
  templateUrl: './pdf-chart.component.html',
  styleUrls: ['./pdf-chart.component.less']
})
export class PdfChartComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private pdfChartService: PdfChartService) { }
  chartOption: EChartOption;
  isChartFinished;
  processInstaId = '';
  token = '';
  slideId = '';
  strSlide = '';
  firstLoop;
  secondLoop;
  dataList=[];
  showForPie=false;
  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.processInstaId = queryParams.get("processInstanceId");
      this.token = queryParams.get("token");
      this.strSlide = queryParams.get("slideName");
    })
    this.slideId = this.strSlide.split('_').join(" ");
    //  var params = {};
    // var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/, function (m, key, value) {
    //     params[key] = value;
    //     });
    //     console.log(params);
    //import { tokenKey } from "@angular/core/src/view";

    //import { reject } from "q";

    /* To get a parameter from url which is coming through backend*/
    // var params = {};
    // var parts = null;
    // parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    //   params[key] = value;
    // });
    //   function getUrlVars() {
    //     var vars = {};
    //     var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, void function(m,key,value) {
    //         vars[key] = value;
    //     });
    //     return vars;
    // }

    // var processInstaId=params['processInstanceId'];
    // var token=params['token'];
    // var strSlide=params['slideName'];

    // var processInstaId = '1512501';
    // var token = '1512501aWOPIrMYQrCZjmy';
    // var str = "Current_Holding-Consolidated"
    // var slideId = str.split("_").join(' ');
    //console.log(params);
    /* to close the current Open tab in any browser*/


    /*Hit api again to bring chart data */




  }

  responseDataOfUrl = null;// variable to get response

  ngAfterViewInit() {
    let compRef = this;
    console.log(compRef.token);
    let token = this.token;
    let processInstaId = this.processInstaId;
    let slideId = this.slideId;

    console.log(compRef.token);
    // this.pdfChartService.getSlideChartData(this.token,this.processInstaId,this.slideId).subscribe((response) => {
    //   if (response['responseType'] == Variables.statusSuccess) {
    //     this.responseDataOfUrl = response['responseData'];
    //     //console.log(data);

    //     this.firstLoop = this.responseDataOfUrl.responseData.slideVsChartDataVO;

    //     for (let i = 0; i < this.firstLoop.length; i++) {
    //    this.secondLoop = this.firstLoop[i].slideCommonChartTypeDataVOList;

    //       for (let j = 0; j < this.secondLoop.length; j++) {
    //          //var status='';
    //         // var chart = null;
    //         var chartData= this.setOptionValueForDifferentChart(i, j);
    //         this.chartOption=chartData;

    //       }
    //     }
    //   }
    // });
    $.ajax({

      url: "http://hexaview.com:8085/foliomax/rest/reporting/getSlideChartData?processInstanceId=" + compRef.processInstaId + "&slideId=" + compRef.slideId,
      dataType: 'json',

      type: 'GET',
      async: false,
      beforeSend: function (xhr) {

        xhr.setRequestHeader("Content-Type", 'application/json');
        xhr.setRequestHeader("Accept", 'application/json');
        xhr.setRequestHeader("X-Auth-Token", compRef.token);
      },
      processData: false,
      success: function (data) {
        compRef.responseDataOfUrl = data;
        console.log(data);

        compRef.firstLoop = compRef.responseDataOfUrl.responseData.slideVsChartDataVO;
        //var dataList=[];
        for (var k=0; k < compRef.firstLoop.length; k++){
          compRef.firstLoop[k].slideCommonChartTypeDataVOList.forEach(element => {
            element['slideName']=compRef.firstLoop[k].slideName;
            compRef.dataList.push(element);
          });
        }
        console.log(compRef.dataList);
        let myVar = setInterval(function () {
          if ($('#chartDivId').is(':visible')) {
            clearInterval(myVar);
                  for(let j=0,p1 = Promise.resolve();j<compRef.dataList.length;j++){
                     p1=p1.then(_=>
                      new Promise<any>(resolve=>{
                     var chartData = compRef.setOptionValueForDifferentChart( j);
                     compRef.chartOption = chartData;
                     compRef.checkIfChartPrepared(j).then(function () {
                     
                       resolve();
                       if(j==(compRef.dataList.length-1)){
                         window.close();
                       }
                     })
                    }
                  ));
                  }
          }
        }, 100)

      },
      error: function (jqXhr, textStatus, errorThrown) {
        console.log(errorThrown);
      }

    });
  }

checkIfChartPrepared(x) {
    return new Promise((resolve, reject) => {

      // myChart.on('finished', function () {
      //   let cPromise = convertChartIntoImage(x, y);
      //   cPromise.then(function () {
      //     resolve("Success");
      //   });
      //   myChart.off('finished');
      //   myChart.dispose();
      // });
      // let myVar = setInterval(()=>{
      //   if(this.isChartFinished){
      //     let cPromise = convertChartIntoImage(x, y);
      //       cPromise.then(function () {
      //       resolve("Success");
      //   });
      //   clearInterval(myVar);
      //   }
      // })
      let myVar = setInterval(() => {
        if (this.isChartFinished) {
          let cPromise = this.convertChartIntoImage(x);
          cPromise.then(function () {
            resolve("Success");
          });
        }

        clearInterval(myVar);
      }, 1000)

    });
  }

  onChartFinished() {
    this.isChartFinished = true;
  }




  /* function to prepare chart data*/

  setOptionValueForDifferentChart( _j) {

    var option = null;
    option = {
      color: ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
      title: {
        text: '',
        left: ''
      },
      tooltip: {
        trigger: '',
        axisPointer: {
          type: ''
        }
      },
      legend: {
        data: [],
        icon: '',
        itemHeight: 14,
        itemWidth: 25,
        orient: 'horizontal',
        right: 'auto',
        top: 'auto'

      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: 60,
        containLabel: false
      },
      xAxis: {
        type: '',
        splitLine: {
          show: true
        },
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        },
        data: [],
        axisLabel: {
          formatter: null

        },
        splitArea: {
          show: true,
          areaStyle: {
            show: false,
            color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
          }
        }
      },
      yAxis: {
        type: '',
        name: '',
        nameTextStyle: {
          padding: [0, 0, 0, 0]
        },
        axisTick: {
          show: true
        },
        splitLine: {
          show: true
        },
        axisLine: {
          show: true
        },
        data: [],
        axisLabel: {
          formatter: null

        },
        splitArea: {
          show: true,
          areaStyle: {
            show: false,
            color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
          }
        }
      },
      series: []
    };
   // var chartType = this.secondLoop[_j].chartType;
    var chartType = this.dataList[_j].chartType;

    if (chartType == "BarGraph") {
      for (let k = 0; k < this.dataList[_j].slideCommonChartData.length; k++) {
        var seriesObject = {};
        if (this.dataList[_j].slideCommonChartData[k].isX_Category) {
          option.color = this.dataList[_j].slideCommonChartData[k].color;
          option.grid.left = '5%';
          option.grid.containLabel = true;
          option.xAxis.type = 'category';
          option.legend.icon = 'rect';
          option.legend.itemHeight = 6;
          option.legend.itemWidth = 6;
          option.legend.data = this.dataList[_j].slideCommonChartData[k].legends;
          option.xAxis.splitArea.show = false;
          option.yAxis.splitArea.show = false;
          option.xAxis.axisTick.show = false;
          option.yAxis.axisTick.show = false;
          option.xAxis.axisLine.show = false;
          option.yAxis.axisLine.show = false;
          option.yAxis.axisLabel.formatter = '{value}%';
          option.xAxis.data = this.dataList[_j].slideCommonChartData[k].xData;
          option.yAxis.splitLine.show = false;
          option.yAxis.type = 'value';
          option.tooltip.trigger = 'axis';
          seriesObject['type'] = 'bar';
          seriesObject['data'] = this.dataList[_j].slideCommonChartData[k].yData;
          option.series.push(seriesObject);

        } else {
          option.color = this.dataList[_j].slideCommonChartData[k].color;
          option.grid.containLabel = true;
          option.grid.left = '5%';
          option.yAxis.type = 'category';
          option.xAxis.type = 'value';
          option.legend.icon = 'rect';
          option.legend.itemHeight = 6;
          option.legend.itemWidth = 6;
          option.xAxis.axisTick.show = false;
          option.yAxis.axisTick.show = false;
          option.xAxis.splitArea.show = false;
          option.yAxis.splitArea.show = false;
          option.xAxis.axisLine.show = false;
          option.yAxis.axisLine.show = false;
          option.xAxis.splitLine.show = false;
          option.xAxis.axisLabel.formatter = '{value}%';
          option.yAxis.data = this.dataList[_j].slideCommonChartData[k].yData;
          option.tooltip.trigger = 'axis';
          seriesObject['type'] = 'bar';
          seriesObject['data'] = this.dataList[_j].slideCommonChartData[k].xData;
          option.series.push(seriesObject);
        }

      }
      return option;
    } else if (chartType == "LineGraph") {
      for (let k = 0; k < this.dataList[_j].slideCommonChartData.length; k++) {
        var seriesObject = {};
        if (this.dataList[_j].slideCommonChartData[k].isX_Category) {
          option.legend.icon = 'rect';
          option.legend.itemHeight = 4;
          option.legend.itemWidth = 45;
          option.legend.orient = 'vertical';
          option.legend.right = 'left';
          option.legend.top = 'center';
          option.grid.right = '25%';
          option.grid.containLabel = true;
          option.color = this.dataList[_j].slideCommonChartData[k].color;
          option.xAxis.type = 'category';
          option.xAxis.data = this.dataList[_j].slideCommonChartData[k].xData;
          option.xAxis.splitLine.show = false;
          option.xAxis.splitArea.show = false;
          option.xAxis.splitArea.areaStyle.show = false;
          option.yAxis.splitArea.show = false;
          option.yAxis.axisLabel.formatter = '${value}';
          option.yAxis.splitArea.areaStyle.show = false;
          option.yAxis.type = 'value';
          option.tooltip.trigger = 'axis';
          seriesObject['type'] = 'line';
          seriesObject['name'] = this.dataList[_j].slideCommonChartData[k].legends[0];
          option.legend.data.push(this.dataList[_j].slideCommonChartData[k].legends[0]);
          seriesObject['showSymbol'] = false;
          seriesObject['data'] = this.dataList[_j].slideCommonChartData[k].yData;
          seriesObject['color'] = this.dataList[_j].slideCommonChartData[k].color[0];
          option.series.push(seriesObject);

        } else {
          option.color = this.dataList[_j].slideCommonChartData[k].color;
          option.legend.icon = 'rect';
          option.legend.itemHeight = 4;
          option.legend.itemWidth = 45;
          option.legend.orient = 'vertical';
          option.legend.right = 'left';
          option.legend.top = 'center';
          option.grid.right = '25%';
          option.grid.containLabel = true;
          option.yAxis.type = 'category';
          option.xAxis.type = 'value';
          option.yAxis.splitLine.show = false;
          option.yAxis.splitArea.show = false;
          option.yAxis.splitArea.areaStyle.show = false;
          option.xAxis.splitArea.show = false;
          option.xAxis.splitArea.areaStyle.show = false;
          option.xAxis.axisLabel.formatter = '${value}';
          option.yAxis.data = this.dataList[_j].slideCommonChartData[k].yData;
          option.tooltip.trigger = 'axis';
          seriesObject['type'] = 'line';
          seriesObject['name'] = this.dataList[_j].slideCommonChartData[k].legends[0];
          option.legend.data.push(this.dataList[_j].slideCommonChartData[k].legends[0]);
          seriesObject['showSymbol'] = false;
          seriesObject['data'] = this.dataList[_j].slideCommonChartData[k].xData;
          seriesObject['color'] = this.dataList[_j].slideCommonChartData[k].color[0];
          option.series.push(seriesObject);
        }

      }
      //console.log(option);
      return option;
    } else if (chartType == "FieldGraph") {
     var  option2 = {

        xAxis: {
            type: 'value',
            splitLine:{
                show:false
            },
            axisTick:{
                show:false
            },
            axisLine:{
                show:false
            },
            axisLabel:{
                formatter:"{value}%"
            },
            splitNumber:3,
            name:"standard deviation (risk)",
            nameLocation:'center',
            nameGap:35,
            nameTextStyle:{
                color:'#00bfff',
                fontSize:20
            },
            splitArea:{
                show:true,
                areaStyle:{
                    color:['#ADFF2F','#006400','#228B22']
                }
            }
        },
        yAxis: {
            type: 'value',
            splitLine:{
                show:false
            },
             axisTick:{
                show:false
            },
             axisLabel:{
                formatter:"{value}%"
            },
            axisLine:{
                show:false
            },
            name:"return",
            nameLocation:'center',
            nameGap:45,
            nameTextStyle:{
                color:'#00bfff',
                fontSize:20
            }
           
        },
        series: [{
            symbol:'diamond',
            symbolSize:10,
            data: [],
            type: 'line',
            color:'#000080',
        
        }]
    };
      for (let k = 0; k < this.dataList[_j].slideCommonChartData.length; k++) {
          var list_1=[];
          for (let m=0;m<this.dataList[_j].slideCommonChartData[k].xData.length;m++){
              var list_2=[];
              list_2.push(this.dataList[_j].slideCommonChartData[k].xData[m],this.dataList[_j].slideCommonChartData[k].yData[m])
              list_1.push(list_2);
          }
        option2.series[0].data=list_1;
        

      }
      return option2;
    } else if (chartType == "CandleGraph") {
      var listOfModelValues = [];
      var listOfPeriodDisplay = [];
      var candleResponse = this.dataList[_j].slideCandleStickChartData;
      listOfModelValues = candleResponse[0].modelValues;
      listOfPeriodDisplay = candleResponse[0].xValues;
      var k = 0;
      var listOfSeriesData = [];

      while (k < candleResponse[0].labels.length) {
        var listOFLabelsWiseData = [];
        for (let i = 0; i < listOfPeriodDisplay.length; i++) {
          var listOfStrategyData = [];
          listOfStrategyData.push(listOfModelValues[k].averageReturnList[i]);
          listOfStrategyData.push(listOfModelValues[k].bestReturnList[i]);
          listOfStrategyData.push(listOfModelValues[k].secondBestReturnList[i]);
          listOfStrategyData.push(listOfModelValues[k].secondWorstReturnList[i]);
          listOfStrategyData.push(listOfModelValues[k].worstReturnList[i]);
          listOFLabelsWiseData.push(listOfStrategyData);
        }
        listOfSeriesData.push(listOFLabelsWiseData);
        k = k + 1;
      }
      // var legendsForPie = {
      //   y: '',
      //   data: '',
      //   left: ''////see it later

      // };

      var seriesData = [];
      var default_color = ['#FFC0CB', "#96cb7f", "#7cfc00"];//only for testing purpose
      for (let i = 0; i < listOfSeriesData.length; i++) {
        var seriesObject = {};
        var itemStyle = {};
        seriesObject['name'] = candleResponse[0].labels[i];
        seriesObject['type'] = 'boxplot';
        seriesObject['data'] = listOfSeriesData[i];
        itemStyle['color'] = default_color[i];// only for testing purpose
        seriesObject['itemStyle'] = itemStyle;
        seriesData.push(seriesObject);
      }
      //option.color=candleResponse[0].color;

      option.title.text = 'Range Of Returns ( % )';
      option.title.left = 'center';
      // option.legendsForPie.y = '90%';
      // option.legendsForPie.data = candleResponse[0].labels;
      // option.legendsForPie.left = true;
      // option['legend'] = legendsForPie;
      option.tooltip.trigger = 'item';
      option.tooltip.axisPointer.type = 'shadow';
      option.xAxis.type = 'category';
      option.xAxis.data = candleResponse[0].xValues;
      option.xAxis.splitArea.show = false;
      option.yAxis.type = 'value';
      option.yAxis.name = '( Based on After Tax Returns )';
      option.yAxis.nameTextStyle.padding = [0, 0, 0, 60];
      option.yAxis.splitLine.show = false;
      option.series = seriesData;
      return option;
    } else if (chartType == "PieGraph") {
      //this.showForPie=true;
      var radius='100%';
      var option1={
        title:{
             text:'',
             left:'',
             textStyle:{
              fontSize:40 
             },
        },
        tooltip:{

        },

        series: []
      };
      var resultElement = this.dataList[_j].slideCommonChartData[0];
      // option.xAxis.axisLine.show = false;
      // option.yAxis.axisLine.show = false;
      if(resultElement.title!=null){
        option1.title.text=resultElement.title;
        option1.title.left='center';
        radius='75%';
      }
      this.showForPie=true;
      var labelLine = {
        show: false
      }
      var seriesObjectForPie = {};
      var data = [];
      seriesObjectForPie['type'] = 'pie';
      seriesObjectForPie['radius'] = radius;
      seriesObjectForPie['center'] = ['50%', '50%']
      seriesObjectForPie['labelLine'] = labelLine;

      for (let k = 0; k < resultElement.color.length; k++) {
        var multiDataObject = {
          value: '',
          itemStyle: {
            // color: {
            //   type: 'radial',
            //   x: 0.5,
            //   y: 0.5,
            //   r: 0.5,
            //   colorStops: [{
            //     offset: 0, color: '#ffffff'
            //   }, {
            //     offset: 1, color: ''
            //   }],
            //   global: false

            // }
            color:''
          }
        };
        multiDataObject.value = resultElement.pieGraphData[k];
        multiDataObject.itemStyle.color = resultElement.color[k];
        data.push(multiDataObject);
      }
      seriesObjectForPie['data'] = data;
      option1.series.push(seriesObjectForPie);
      return option1;
    } else {
      return option;
    }
  }

  /*provide a time period to prepare a chart after that creating an image */
  convertChartIntoImage( _j) {
    return new Promise((resolve, reject) => {
      var chartImageName = this.dataList[_j].chartImgName;

      //var chartImageName = this.secondLoop[_j].chartImgName;
      var canvas = document.getElementsByTagName('canvas')[0];
      var ctx = canvas.getContext('2d');
      var img = new Image();
      img.src = canvas.toDataURL("image/png");
      var block = img.src.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      console.log(contentType);
      console.log(realData);
      var blob = this.b64toBlob(realData, contentType);
      //document.body.appendChild(im/g);
      // var el=document.getElementById('chartDivId');
      // el.remove();
      var fd = new FormData();
      fd.append('slideName', this.dataList[_j].slideName);
      fd.append('processInstanceId', this.processInstaId);
      fd.append('extension', "png");
      fd.append('chartList', blob, chartImageName);

      var promise = this.uploadImageOnUrl(fd);
      promise.then(function () {
        resolve("Success");
      })



    })



  }



  uploadImageOnUrl(formData) {

    //let formData = new FormData(document.getElementById("myAwesomeForm"));
    let compRef = this;
    return new Promise((resolve, reject) => {

      this.pdfChartService.uploadChartData(this.token, this.processInstaId, formData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          resolve('success');
          console.log('success');
        }

      });

      // $.ajax({

      //   url:"http://hexaview.com:8085/foliomax/rest/reporting/uploadChartImg?processInstaceId=" + compRef.processInstaId,
      //   type: 'POST',
      //   async: false,
      //   data: formData,
      //   contentType: 'multipart/form-data',
      //   cache: false,
      //   processData: false,
      //   // beforeSend: function (xhr) {
      //   // 	xhr.setRequestHeader("X-Auth-Token", '1397501DFfjAmDpruqghYb');
      //   // },
      //   headers: {
      //     'X-Auth-Token': compRef.token
      //   },

      //   success: function (data) {
      //     console.log("Successfully  Uploaded");
      //     //resolve("Successs");
      //   },
      //   error: function (jqXhr, textStatus, errorThrown) {
      //     console.log(errorThrown);
      //     resolve("Failure");
      //   }

      // });
    })


  }


  /* Convert  Binary file encoded using base64 to Binary large Object(blob) which is useful to send large chunk of data*/

  b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


}


