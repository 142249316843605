export class BalanceSheetModel {
    dataStoreId: number;
    name: string;
    marketValue: number;
    price: string='';
    category: string='';
    quantity: string='';
    description: string;
    relationshipLevel:boolean=false;
}

export class BalanceSheetFreeFormModel {
    annualIncomeNeeds:string;
    estatePlan:string;
    giftingPlans:string;
    restriction:string;
    timeHorizon:string;
}