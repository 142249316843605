import { MoneyModel } from './../_models/money.model'

export class HoldingsModel {
    name: string;
    symbol: string;
    quantity: string=null;
    asOfDate: string;
    price = new MoneyModel();
    lockedPercentage: string;
    carveoutPercentage: number;
    isCarvedOut: boolean;
    totalMarketValueBase = new MoneyModel();
    totalMarketValueSystem = new MoneyModel();
    description: string;
    holdingResolvedSymbol: string;
    holdingResolvedName: string;
    assetClassId: string = null;
    sectorId: string = null;
    isRecognized: string;
    notes: string;
    costBasis = new MoneyModel();

}

export class HoldingsDataModel {
    accountId: string = null;
    accountName: string = null;
    carvedOutPercentage: number = 0;
    currency: string = 'USD';
    sysCurrency: string = 'USD';
    holdingsInfoVOs = [];
}


