import { Component, OnInit,Input } from '@angular/core';
import { Variables } from '../../../../../constants';
import { AnalysisService } from '../../../../../_services/workflow-services/analysis.service';

import * as $ from 'jquery';
@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.less']
})
export class CashFlowComponent implements OnInit {
  dropDownData: any;

  constructor(

    private analysisService :AnalysisService
  ) { }
  cashFlowList:any[];
  specificStrategy='';
  includeFeeColumn:Boolean;
  requestData :{};
  @Input() inputAnalysisData;
  @Input() inputRequestData;
  ngOnChanges(){
    
      if(this.inputAnalysisData){
        this.dropDownData=this.inputAnalysisData.strategyOptions;
        this.cashFlowList = this.inputAnalysisData.cashFlowList;
        this.specificStrategy=this.inputAnalysisData.strategyOptions[0];
        this.includeFeeColumn = this.inputAnalysisData.includeFeeInAnalysis;
        
      }
      
    
    this.requestData = this.inputRequestData;
  
  }

  ngOnInit() {
    var tableHeight=$('.cash-flow-table').height();
    var tableHeaderHeight=$('.table-header').height();
    $('.scrollable-div-table').height(tableHeight-tableHeaderHeight-10);
   // console.log("height of temp"+tableHeight);
   // console.log("height of temp2:"+tableHeaderHeight);
  }

  


  getDataBasedOnAnalysisType() {
    if(this.requestData){
      this.requestData["specificStrategyId"] = this.specificStrategy["value"];
      this.analysisService.getanalysisinfo(this.requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.includeFeeColumn = response["responseData"].includeFeeInAnalysis;
          this.cashFlowList = response["responseData"].cashFlowList;
        }
      });
    }
    
  }


}
