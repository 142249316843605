import { Component, OnInit, Input } from '@angular/core';
import { WorkflowBaseService } from "../../../../_services/workflow-services/workflow-base.service";
import { Variables } from '../../../../constants';
import { LoaderService } from 'src/app/_services/loader.service';
import { EChartOption } from 'echarts';
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';
@Component({
  selector: 'app-fee-summary-popup',
  templateUrl: './fee-summary-popup.component.html',
  styleUrls: ['./fee-summary-popup.component.less']
})
export class FeeSummaryPopupComponent implements OnInit {

  @Input() public portfolioId;
  @Input() public processInstanceId;
  feeSummaryVO;
  feeIncludedVO;
  feeSummaryAssetList;
  investmentFeeDetailsVO;
  chartOption: EChartOption;
  chartLabels;
  chartData;
  totalInvestmentFeeDetailsVO;
  


  constructor(
    private workflowBaseService : WorkflowBaseService,
    private loaderService : LoaderService
  ) { }

  ngOnInit() {
    this.feeSummaryVO = {};
    this.feeIncludedVO = {};
    this.totalInvestmentFeeDetailsVO = {};
    this.getFeeDetails();
    this.feeSummaryAssetList = [];
    this.investmentFeeDetailsVO = {};
  }

  printPopup(id)
  {

    var node = document.getElementById(id);
    var tableDiv = (<HTMLElement>document.querySelector('#'+id+' .fee-summary-second-list'));
    var icon = document.getElementById(id+"-icon");
    var img;
    var filename;
    var newImage;
    let componentRef = this;
    componentRef.loaderService.show();
    tableDiv.style.height = "inherit";
    icon.style.display = "none";
    domtoimage.toPng(node, { bgcolor: '#fff' })

      .then(function(dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = function(){

        var pdfWidth = img.width;
        var pdfHeight = img.height;

          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if(pdfWidth > pdfHeight)
          {
            doc = new jsPDF('l', 'px', [pdfWidth+20 , pdfHeight*2]);
          }
          else
          {
            doc = new jsPDF('p', 'px', [pdfWidth+20 , pdfHeight*2]);
          }


          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          tableDiv.style.height = "48vh";
          icon.style.display = "block";
          doc.addImage(newImage, 'PNG',  10, 10, width-20, height-400);
          filename = 'mypdf_' + '.pdf';
          componentRef.loaderService.hide();
          //doc.save(filename);
          let blob = doc.output("blob");
          var url = window.URL.createObjectURL(blob);
              window.open(url,'knskf');
                setTimeout(function(){
                    window.URL.revokeObjectURL(url);
              }, 100);

        };


      })
      .catch(function(error) {

       // Error Handling
       componentRef.loaderService.hide();

      });



  }

  getFeeDetails(){
    var apiParams = {
      processInstanceId : this.processInstanceId,
      portfolioId : this.portfolioId,
      isPopUpView : true  
    };

    this.workflowBaseService.getFeeDetails(apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        console.log(data);
        this.feeIncludedVO = data.feeIncludedVO
        this.feeSummaryVO = data.feeSummaryVO;
        this.investmentFeeDetailsVO = data.investmentFeeDetailsVO;
        if(data.totalInvestmentFeeDetailsVO)
         this.totalInvestmentFeeDetailsVO = data.totalInvestmentFeeDetailsVO[0];
        this.createFeeDetailAssetList();
      }
    });
  }

  createFeeDetailAssetList(){
    this.feeSummaryAssetList = [];
    if(this.investmentFeeDetailsVO.length){
      this.investmentFeeDetailsVO.forEach(element => {
        element.level = 1; 
        this.feeSummaryAssetList.push(element);
        element.investmentFeeDetailsVOList.forEach(element2 => {
          element2.level = 2; 
          this.feeSummaryAssetList.push(element2)
          element2.investmentFeeDetailsVOList.forEach(element3 => {
            element3.level = 3; 
            this.feeSummaryAssetList.push(element3)
        
          });
        });
      });
    }
    console.log(this.feeSummaryAssetList);
    this.prepareChartData();
    
  }

  prepareChartData() {
    this.chartLabels = [];
    this.chartData = [];
    if (this.feeSummaryAssetList.length) {
      var obj;
      this.feeSummaryAssetList.forEach(element=>{
        if(element.level == 1 && element.proposedAlloAmt){
            this.chartLabels.push(element.name);
            this.chartData.push({
            name : element.name,
            value : element.proposedAlloAmt,
            // itemStyle: {
            //   color: element.color
            // }
          })
        }
        
      })
      this.chartOption = {
        tooltip: {
          trigger: 'item',
          formatter: "{b} : {c}<br> {d}%",
          confine: true
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          bottom: 150,
          height: 80,
          left: 0,
          data: this.chartLabels,
          formatter: function (name) {
            if (name.length > 25)
              return name.substring(0, 20) + '...'
            else
              return name;
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['52%', '28%'],
            data: this.chartData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 2,
                borderColor: "#fff"
              },
            },
            label: {
              show: false
            }
          }
        ]
      };
    }
  }

}
