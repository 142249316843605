import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
};

  constructor(private http: HttpClient) { }

  getanalysismetadata(data){
    return this.http.post(ApiUrls.getanalysismetadataAPI,data,this.httpOptions)
    .pipe(
         response =>response
    );
  }

  getanalysisinfo(data){
    return this.http.post(ApiUrls.getanalysisinfoAPI,data,this.httpOptions)
    .pipe(
         response =>response
    );
  }
}
