import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-calc-qualified',
  templateUrl: './tax-calc-qualified.component.html',
  styleUrls: ['./tax-calc-qualified.component.less']
})
export class TaxCalcQualifiedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
