import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
//import { element } from '@angular/core/src/render3/instructions';
@Component({
  selector: 'app-effeicient-frontier',
  templateUrl: './effeicient-frontier.component.html',
  styleUrls: ['./effeicient-frontier.component.less']
})
export class EffeicientFrontierComponent implements OnInit {
  dropDownData: any;

  constructor() { }

  chartOption: any;
  @Input() public inputAnalysisData;

  ngOnChanges() {
    var seriesList=[];
    
    if (this.inputAnalysisData) {
      this.dropDownData=this.inputAnalysisData.expectedRiskReturnVO;
      this.chartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          top:20,
          data: []
        },
        xAxis: [
          {
            type: 'value',
            name: "EXPECTED VOLATILITY (%)",
            splitLine: {
              show: false
            },

            nameLocation: 'center',
            nameGap: 35,
            // nameTextStyle: {
            //   color: '#00bfff',
            //   fontSize: 20
            // }


          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'EXPECTED RETURN (%)',

            nameLocation: 'center',
            nameGap: 35,
            // nameTextStyle: {
            //   color: '#00bfff',
            //   fontSize: 20
            // },
            splitLine: {
              show: false
            },
            axisLabel: {
            }
          }/*we have below attribute to merge line with curve chart*/
          // },
          // {
          //     type: 'value',
          //     name: 'Expected Return (%)',
          //     splitLine: {
          //       show: false
          //     },
          //     axisLabel: {
          //     }
          // }
        ],
        series: seriesList
        // [
        
          // },
          // {
          //     name:'',
          //     type:'scatter',
          //     yAxisIndex: 1,
          //     data:[]
          // }
        // ]
      };
      this.inputAnalysisData.curveChart.forEach(element => {
        this.chartOption.xAxis[0].data.push(element.xValue);
        this.chartOption.series[1].data.push(element.yValue);
      })
      // this.inputAnalysisData.scatteredChart.forEach(element => {
      //   this.chartOption.series[0].data.push([element.xValue,element.yValue]);
      //   // this.chartOption.xAxis[0].data.push(element.label);
      // })
      var tempDataObj2Data =[];
      for(let i=0;i<this.inputAnalysisData.scatteredChart.length;i++){
        let tempDataObj={
      
          name: '',
          type: 'scatter',
         // stack:'pankaj',
          smooth: true,
          // lineStyle:{
          //   type:'dot'
          // },
          data: []
        
      }


      
        let element1=this.inputAnalysisData.scatteredChart[i];
        this.chartOption.legend.data.push(element1.label);
        tempDataObj.name=element1.label;
        tempDataObj.data.push([element1.xValue,element1.yValue]);
        tempDataObj2Data.push([element1.xValue,element1.yValue]);
        seriesList.push(tempDataObj);
        
      }
      let tempDataObj2={
      
        name: '',
        type: 'line',
        lineStyle:'dashed',
       // stack:'pankaj',
        smooth: true,
        // lineStyle:{
        //   type:'dot'
        // },
        data: tempDataObj2Data
      
    }
    seriesList.push(tempDataObj2);
    
      
    }

  }

  ngOnInit() {
  }
}
