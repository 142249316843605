
import { Component, OnInit,ViewChild,QueryList,ViewChildren } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Variables } from '../../../constants';
import { CommonFunctions } from '../../../common';
import { ErrorModel } from '../../../_models/response.model';
import { AuthMessageService } from '../../../_services/auth-message.service';
import { GridComponent } from '../../../shared/grid/grid.component';
import { WorkflowBaseService } from "../../../_services/workflow-services/workflow-base.service";
import { PortfolioConstructionService } from '../../../_services/workflow-services/portfolio-construction.service'
import { MaxCalculatorMainComponent } from '../max-calculator-main/max-calculator-main.component';

import { timeout } from 'q';
import * as _ from "lodash";
import * as $ from 'jquery';
//import { request } from 'http';

@Component({
  selector: 'app-cal-portfolio-construction',
  templateUrl: './cal-portfolio-construction.component.html',
  styleUrls: ['./cal-portfolio-construction.component.less']
})
export class CalPortfolioConstructionComponent implements OnInit {
  componentReference:any;
  selectedPortfolio:string = null;
  decimalPoints:number = 2;
  analyticsData;
  feeSummaryVO;
  feeIncludedVO;
  dropdownsData: any;
  blendedList:any = [];
  templatePopupMode = "add";
  commonFunction=new CommonFunctions();
  assetClassHeirarchy:any = [];
  originalAssetClassHeirarchy:any = [];
  assetClassHeirarchyTotals:any = {};

  confirmationType;
  isChangeAfterSave:boolean = false;
  stepData = {
    isChangeAfterSave : false
  }
  selectedTemplate;
  templateName;
  investmentTemplateList = [];
  productComponentReference;
  investmentTemplateVO={};
  securityComponentReference
  @ViewChildren('investmentGrid') popUpGridComponentData: QueryList<GridComponent>;
  @ViewChild(GridComponent) gridComponentData: GridComponent;
  constructor(
    public  ngxSmartModalService: NgxSmartModalService,
    private portfolioConstructionService: PortfolioConstructionService,
    private authMessageService : AuthMessageService,
    private workflowBaseService : WorkflowBaseService,
    private proposalWorkflowComponent: MaxCalculatorMainComponent
  ) { }
   
  ngAfterViewInit(){
    

  }
  ngOnInit() {  
    this.feeSummaryVO = {};
    this.feeIncludedVO = {};
    this.analyticsData= {
      parentComponent : "portfolioConstruction",
      selectedStrategy : '',
      processDefinitionKey : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    }
    this.componentReference = { componentParent: this };
    let callCount = 1;
    var apiParams = {
      processInstanceId : this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      stepName : 'portfolioConstruction'  
    };

    this.workflowBaseService.getEnumDataBasedOnStep(apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.dropdownsData = data;
        if(!this.selectedPortfolio && this.dropdownsData.portfolioList.length)
          this.selectedPortfolio = this.dropdownsData.portfolioList[0].portfolioId;
          this.getFeeDetails();
          this.getInvestmentTemplate(null);
          this.onChangePortfolio();
      }
    });
   
    // var wait = setInterval(()=>{
    //   if($('.investments-total')[0].clientHeight){
    //     clearInterval(wait)
    //     this.adjustHeightViewport(0);  
    //   }
    // })
  
  }
  onChangeValuePosition(){
  
  }

  

  getFeeDetails(){
    var apiParams = {
      processInstanceId : this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      portfolioId : this.selectedPortfolio,
      isPopUpView : false 
    };

    this.workflowBaseService.getFeeDetails(apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.feeSummaryVO = data.feeSummaryVO;
        this.feeIncludedVO = data.feeIncludedVO;
      }
    });
  }

  updateFeeIncluded(){ 

    this.workflowBaseService.updateFeeIncluded(this.selectedPortfolio,this.feeIncludedVO).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.ngxSmartModalService.getModal('feeInclude').close();
        this.getFeeDetails();
      }
    });
  }

  deleteTemplateAfterConfirmation(){
    this.portfolioConstructionService.deleteInvestmentTemplate(this.selectedTemplate).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
          this.getInvestmentTemplate(this.selectedTemplate);
      }
    });
  }

  openDeleteTemplateConfirmation(){
    if(!this.selectedTemplate){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select template';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }else{
      this.ngxSmartModalService.open("deleteTemplateConfirmationModal");
    }
  }

  saveAssetHierarchy(){
   if(parseFloat(this.assetClassHeirarchyTotals['proposedAmountCent']) > 100){
    var errorObj = new ErrorModel();
    errorObj['errorCode'] = 'ERR0107';
    errorObj['message'] = 'Total Proposed percentage should not be greater than 100%';
    errorObj['errorDescription'] = '';
    this.authMessageService.showErrorPopup(errorObj);
   }else{
        let assetClassHeirarchyCopy = JSON.parse(JSON.stringify(this.assetClassHeirarchy));
        assetClassHeirarchyCopy.forEach(element => {
          element.proposedAmountValue = parseFloat(element.proposedAmountValue).toFixed(this.decimalPoints);
          element.offTargetAmount = (element.proposedAmountValue - element.targetAmountValue).toFixed(this.decimalPoints);
        if(element.proposedAmountPercentage)
          element.proposedAmountPercentage = parseFloat(element.proposedAmountPercentage).toFixed(this.decimalPoints);
        if(element.currentAmountValue)
          element.currentAmountValue = parseFloat(element.currentAmountValue).toFixed(this.decimalPoints);
        if(element.currentAmountPercentage)
          element.currentAmountPercentage = parseFloat(element.currentAmountPercentage).toFixed(this.decimalPoints);
      
       
     
        });
        let refinedData =   this.convertToOriginalVo(assetClassHeirarchyCopy);
        let requestData = {};
        requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
        requestData["portfolioId"]=this.selectedPortfolio;
        requestData["strategyTacticalInfoVOList"] = refinedData;
        requestData["blendedInvestors"] = this.blendedList;
        this.portfolioConstructionService.saveUpdatedTacticalStrategies(requestData).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
              console.log(response);
              this.authMessageService.showSuccessPopup(response);
              this.isChangeAfterSave = false;
              this.stepData.isChangeAfterSave = false;
              this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
              this.getFeeDetails();
              
          }
        });
   }
  }

  convertToCommaSeperated(row) {
    let value = row
    var partBeforeDecimal = '', partAfterDecimal = '';
    var index = value.indexOf('.')
    if (value == '$' || value == "") {
      row = '$0';
    } else {
      if (index > -1) {
        partBeforeDecimal = value.substr(0, index);
        partAfterDecimal = value.substr(index, value.length - index);
      } else {
        partBeforeDecimal = value;
      }

      if (partBeforeDecimal) {
        partBeforeDecimal = partBeforeDecimal.replace(/,/g, "").replace('$', "");
        row = '$' + (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
      }
    }
    return row;
  }

  valueBlurHandler(){
    this.assetClassHeirarchy.forEach(element => {
      if(element.hierarchyId == 3){
        element.proposedAmountValue = element.proposedAmountValueStr.replace(/,/g, "").replace('$', "");
      }
    });
    this.calculateProposedPercentages();
    //this.saveAssetHierarchy()
  }

  percentBlurHandler(asset){
    this.calculateProposedValue(asset);
    //this.saveAssetHierarchy()
  }

  blendedValueBlurHandler(asset){  
    this.calculateProposedPercentageBlended(asset);
    //this.saveAssetHierarchy()
  }
  
  blendedPercentBlurHandler(asset){  
    this.calculateProposedValueBlended(asset);
    //this.saveAssetHierarchy()
  }

  onChangePortfolio(){
    //this.portfolioConstructionComponent.selectedPortfolio = this.selectedPortfolio;
    if(this.selectedPortfolio && this.selectedPortfolio != "null"){
      this.portfolioConstructionService.getTacticalStrategies(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio,this.selectedTemplate).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            if(response['responseData'] ){
              this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO,true);
              this.blendedList = response['responseData'].blendedInvestors;
              this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
  
            }
            
        }
      });
    }
    
  }

  removeInvestment(){
    let investmentIds = [];
    this.assetClassHeirarchy.forEach(element => {
      if(element.isSelected)
        investmentIds.push(element.investmentId);
    });
    this.blendedList.forEach(element => {
      if(element.isSelected)
        investmentIds.push(element.investmentId);
    });
    if(!investmentIds.length){
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select product/strategy';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else{
      this.portfolioConstructionService.removeInvestment(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio,investmentIds).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          //this.selectedTemplate = null;
          if(response['responseData'] ){
            this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO,true);
            this.blendedList = response['responseData'].blendedInvestors;
            this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
            this.isChangeAfterSave = false;
            this.stepData.isChangeAfterSave = false;
            this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
          }
        }
      });
    }
    
  }

  totalCalculation(data){
    this.assetClassHeirarchyTotals["currentAmount"] = 0;
    this.assetClassHeirarchyTotals["currentAmountCent"] = 0;
    this.assetClassHeirarchyTotals["proposedAmount"] = 0;
    this.assetClassHeirarchyTotals["proposedAmountCent"] = 0;
    this.assetClassHeirarchyTotals["targetAmount"] = 0;
    this.assetClassHeirarchyTotals["targetAmountCent"] = 0;
    this.assetClassHeirarchyTotals["offTarget"] = 0;
    this.assetClassHeirarchyTotals["offTargetCent"] = 0;
    data.forEach(element => {
      this.assetClassHeirarchyTotals["currentAmount"] = ( parseFloat(this.assetClassHeirarchyTotals["currentAmount"]) +         parseFloat(element.currentAmountValue)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["currentAmountCent"] =(parseFloat(this.assetClassHeirarchyTotals["currentAmountCent"]) + parseFloat(element.currentAmountPercentage)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["proposedAmount"] =(parseFloat(this.assetClassHeirarchyTotals["proposedAmount"]) +       parseFloat (element.proposedAmountValue)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["proposedAmountCent"] =(parseFloat(this.assetClassHeirarchyTotals["proposedAmountCent"]) + parseFloat(element.proposedAmountPercentage)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["targetAmount"] =(parseFloat(this.assetClassHeirarchyTotals["targetAmount"]) +       parseFloat (element.targetAmountValue));
      this.assetClassHeirarchyTotals["targetAmountCent"] =(parseFloat(this.assetClassHeirarchyTotals["targetAmountCent"]) + parseFloat(element.targetAmountPercentage)).toFixed(this.decimalPoints);
      element.offTargetAmount=parseFloat(element.proposedAmountValue) - parseFloat(element.targetAmountValue);
      if(element.proposedAmountValue && parseFloat(element.proposedAmountValue)!=0)
        element.offTargetPercentage=(element.offTargetAmount/element.proposedAmountValue)*100;
      this.assetClassHeirarchyTotals["offTarget"] = (parseFloat(this.assetClassHeirarchyTotals["offTarget"])+parseFloat(element.offTargetAmount));
      
    });
    if(this.assetClassHeirarchyTotals["targetAmount"] && this.assetClassHeirarchyTotals["targetAmount"] !=0)
      this.assetClassHeirarchyTotals["offTargetCent"] = ((parseFloat(this.assetClassHeirarchyTotals["offTarget"])/parseFloat(this.assetClassHeirarchyTotals["targetAmount"]))*100).toFixed(this.decimalPoints);

      if(this.assetClassHeirarchyTotals["targetAmountCent"] == "100.00"){
        this.assetClassHeirarchyTotals["targetAmount"] = this.assetClassHeirarchyTotals["currentAmount"];
      }
      if(this.assetClassHeirarchyTotals["offTargetCent"] == "-100.00"){
        this.assetClassHeirarchyTotals["offTarget"] =  -this.assetClassHeirarchyTotals["currentAmount"];
      }

    }

  refineVo(data,isFirstTime){
    let refinedData = [];
    data.forEach(element => {
      element.hierarchyId = 1;
      element.offTargetAmount = element.proposedAmountValue;
      element.offTargetPercent = element.proposedAmountPercentage;

      element.proposedAmountValue = parseFloat(element.proposedAmountValue).toFixed(this.decimalPoints)
      element.offTargetAmount = (element.proposedAmountValue - element.targetAmountValue).toFixed(this.decimalPoints);
      // if(element.targetAmountValue && element.targetAmountValue!=0)
      // element.offTargetPercent = ((element.proposedAmountValue - element.targetAmountValue)/element.targetAmountValue).toFixed(this.decimalPoints);
      element.offTargetPercent = (parseFloat(element.proposedAmountPercentage) - parseFloat(element.targetAmountPercentage)).toFixed(this.decimalPoints);
       
      if(element.proposedAmountPercentage)
        element.proposedAmountPercentage = parseFloat(element.proposedAmountPercentage).toFixed(this.decimalPoints);
      if(element.currentAmountValue)
        element.currentAmountValue = parseFloat(element.currentAmountValue).toFixed(this.decimalPoints);
      if(element.currentAmountPercentage)
        element.currentAmountPercentage = parseFloat(element.currentAmountPercentage).toFixed(this.decimalPoints);
      if(isFirstTime)
       element.originalProposedAmountValue = element.proposedAmountValue;
      refinedData.push(element);
      element["strategyTacticalInfoVOChild"].forEach(element2 => {
        element2.hierarchyId = 2;
        element2.offTargetAmount = element2.proposedAmountValue;
        element2.offTargetPercent = element2.proposedAmountPercentage;

        element2.proposedAmountValue = parseFloat(element2.proposedAmountValue).toFixed(this.decimalPoints);
        element2.offTargetAmount = (element2.proposedAmountValue - element2.targetAmountValue).toFixed(this.decimalPoints);
        // if(element2.targetAmountValue && element2.targetAmountValue!=0)
        // element2.offTargetPercent = ((element2.proposedAmountValue - element2.targetAmountValue)/element2.targetAmountValue).toFixed(this.decimalPoints);
        
        element2.offTargetPercent = (parseFloat(element2.proposedAmountPercentage) - parseFloat(element2.targetAmountPercentage)).toFixed(this.decimalPoints);

        if(element2.proposedAmountPercentage)
         element2.proposedAmountPercentage = parseFloat(element2.proposedAmountPercentage).toFixed(this.decimalPoints);
        if(element2.currentAmountValue)
         element2.currentAmountValue = parseFloat(element2.currentAmountValue).toFixed(this.decimalPoints);
        if(element2.currentAmountPercentage)
         element2.currentAmountPercentage = parseFloat(element2.currentAmountPercentage).toFixed(this.decimalPoints);
        if(isFirstTime)
          element2.originalProposedAmountValue = element2.proposedAmountValue;
        refinedData.push(element2);
        if(!element2["strategyTacticalInfoVOChild"])
         element2["strategyTacticalInfoVOChild"] = [];
        element2["strategyTacticalInfoVOChild"].forEach(element3 => {
          if(element3.currentAmountValue )
           element3.currentAmountValue = element3.currentAmountValue.toFixed(this.decimalPoints);
          if(element3.currentAmountPercentage )
           element3.currentAmountPercentage = element3.currentAmountPercentage.toFixed(this.decimalPoints);
           if(element3.proposedAmountValue ){
            element3.proposedAmountValue = element3.proposedAmountValue.toFixed(this.decimalPoints);
            element3.proposedAmountValueStr = this.convertToCommaSeperated(element3.proposedAmountValue);
           }else{
            element3.proposedAmountValueStr = "$0.00"
           }
           
          if(element3.proposedAmountPercentage)
           element3.proposedAmountPercentage = parseFloat(element3.proposedAmountPercentage).toFixed(this.decimalPoints);
          if(isFirstTime)
          element3.originalProposedAmountValue = element3.proposedAmountValue;
          element3.hierarchyId = 3;
          element3.parentClassId = element2.assetClassId;
          refinedData.push(element3);
        });
      });
    });
    return refinedData;
  }

  convertToOriginalVo(data){
    let refinedData = [];
    data.forEach(element => {
      if(element.hierarchyId == 1){
        refinedData.push(element);
        element.strategyTacticalInfoVOChild = [];
      }
       
      else if(element.hierarchyId == 2){
        element.strategyTacticalInfoVOChild = [];
        refinedData[refinedData.length -1].strategyTacticalInfoVOChild.push(element);
      }
      else if(element.hierarchyId == 3){
        element.strategyTacticalInfoVOChild = [];
        if(element.proposedAmountValue)
          element.proposedAmountValue = parseFloat(element.proposedAmountValue);
        if(element.proposedAmountPercentage)
           element.proposedAmountPercentage = parseFloat(element.proposedAmountPercentage);
        refinedData[refinedData.length -1].strategyTacticalInfoVOChild[refinedData[refinedData.length -1].strategyTacticalInfoVOChild.length-1].strategyTacticalInfoVOChild.push(element);
      }
    });

    return refinedData;
  }

  calculateProposedPercentageBlended(product){
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let investableValue = portfolioObj["investableAssetsDecimalValue"];
      if(product.proposedAmountValue == ""){
        product.proposedAmountValue=0
        product.proposedAmountPercentage=0.00;
      }else{
      product.proposedAmountPercentage = ((product.proposedAmountValue/investableValue)*100 ).toFixed(this.decimalPoints);
      
      product.blendedInvestors.forEach(element => {
          let assetClass = _.find(this.assetClassHeirarchy, { assetClassId: element.assetClassId });
          if(assetClass){
            assetClass.proposedAmountValue = assetClass.originalProposedAmountValue + product.proposedAmountValue * element.percentageOwnership;
            let blendedProduct = _.find(this.assetClassHeirarchy, { investmentId: product.investmentId, parentClassId : element.assetClassId });
             if(blendedProduct){
               blendedProduct.proposedAmountValue =  (product.proposedAmountValue * element.percentageOwnership)/100;
            }
          }
      });
    }
      this.calculateProposedPercentages();
    },100);
  }

  calculateProposedValueBlended(product){
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let investableValue = portfolioObj["investableAssetsDecimalValue"];
      if(product.proposedAmountPercentage == ""){
        product.proposedAmountPercentage=0.00;
        product.proposedAmountValue=0;
      }else{
      product.proposedAmountValue = ((product.proposedAmountPercentage * investableValue)/100).toFixed(2);
     
      product.blendedInvestors.forEach(element => {
          let assetClass = _.find(this.assetClassHeirarchy, { assetClassId: element.assetClassId });
          if(assetClass){
            assetClass.proposedAmountValue = assetClass.originalProposedAmountValue + product.proposedAmountValue * element.percentageOwnership
            let blendedProduct = _.find(this.assetClassHeirarchy, { investmentId: product.investmentId, parentClassId : element.assetClassId });
             if(blendedProduct){
               blendedProduct.proposedAmountValue = (product.proposedAmountValue * element.percentageOwnership)/100;
            }
          }
      });
    }
      this.calculateProposedPercentages();
    },100);
  }

  calculateProposedValue(product){
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let investableValue = portfolioObj["investableAssetsDecimalValue"];
      if(product.proposedAmountPercentage == ""){
        product.proposedAmountValue=0;
        product.proposedAmountPercentage=0.00;
      }else{
      product.proposedAmountValue = (product.proposedAmountPercentage * investableValue)/100;
      }
      this.calculateProposedPercentages();
    },100);
  }

  calculateProposedPercentages(){
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let refinedData =   this.convertToOriginalVo(this.assetClassHeirarchy);
      var investableValue = portfolioObj["investableAssetsDecimalValue"];
      if(investableValue){
        refinedData.forEach(element => {
          let sumProposedValue2 = 0;
          element["strategyTacticalInfoVOChild"].forEach(element2 => {
            if(!element2["strategyTacticalInfoVOChild"])
             element2["strategyTacticalInfoVOChild"] = [];
            let sumProposedValue = 0;
            element2["strategyTacticalInfoVOChild"].forEach(element3 => {
              if(element3.proposedAmountValue==""){
                element3.proposedAmountValue =0;
              }
              element3.proposedAmountPercentage = ((element3.proposedAmountValue/investableValue)*100 ).toFixed(this.decimalPoints);
              sumProposedValue=sumProposedValue + parseFloat(element3.proposedAmountValue) - parseFloat(element3.originalProposedAmountValue);
            });
            element2.proposedAmountValue = parseFloat(element2.originalProposedAmountValue) + sumProposedValue;
            element2.proposedAmountPercentage = ((element2.proposedAmountValue/investableValue)*100 ).toFixed(this.decimalPoints);
            sumProposedValue2 = sumProposedValue2 + parseFloat(element2.proposedAmountValue);
          });
          element.proposedAmountValue =  sumProposedValue2;
          element.proposedAmountPercentage = ((element.proposedAmountValue/investableValue)*100 ).toFixed(this.decimalPoints);
        });

      }
      this.totalCalculation(refinedData);
      this.assetClassHeirarchy = this.refineVo(refinedData,false);

    }
      , 100);  
  }


  saveProduct(){
    let selectedRows  = this.gridComponentData.getSelectedRows();
    if(selectedRows && selectedRows.length){
      let requestData = {};
      requestData["portfolioId"] = this.selectedPortfolio;
      requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      requestData["productIds"]=[];
      selectedRows.forEach(element => {
        requestData["productIds"].push(element.productId);
      });
      if(this.selectedPortfolio){
        this.portfolioConstructionService.addProductInAsset(requestData).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
              this.authMessageService.showSuccessPopup(response);
              this.ngxSmartModalService.getModal('addProducts').close();
              //this.selectedTemplate = null;
              // this.onChangePortfolio();
              // this.getInvestmentTemplate(null);
              if(response['responseData'] ){
                this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO,true);
                this.blendedList = response['responseData'].blendedInvestors;
                this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
                this.isChangeAfterSave = false;
                this.stepData.isChangeAfterSave = false;
                this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
              }
          }
        });
      }else{
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select portfolio';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      }
      
     
    }else{
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select product';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
   
  }

  saveSecurities(){
    let selectedRows  = this.gridComponentData.getSelectedRows();
    if(selectedRows && selectedRows.length){
      let requestData = {};
      requestData["portfolioId"] = this.selectedPortfolio;
      requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      requestData["securityIds"]=[];
      selectedRows.forEach(element => {
        requestData["securityIds"].push(element.securityId);
      });
      if(this.selectedPortfolio){
        this.portfolioConstructionService.addProductInAsset(requestData).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
              this.authMessageService.showSuccessPopup(response);
              this.ngxSmartModalService.getModal('addSecurities').close();
              //this.selectedTemplate = null;
              if(response['responseData'] ){
                this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO,true);
                this.blendedList = response['responseData'].blendedInvestors;
                this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
                this.isChangeAfterSave = false;
                this.stepData.isChangeAfterSave = false;
                this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
              }
              
          }
        });
      }else{
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select portfolio';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      }
      
     
    }else{
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select security';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
   
  }

  analysis(){
    this.analyticsData.selectedStrategy = ''
    this.ngxSmartModalService.open("portfolioAnalytics");
  }

  openFeeSummaryPopup(){
    this.ngxSmartModalService.open("feeSummary");
  }

  openFeeIncludePopup(){
    this.ngxSmartModalService.open("feeInclude");
  }

  addProductsFn(){
    this.ngxSmartModalService.open("addProducts");
    setTimeout(()=>{
      $(".ag-body .ag-body-viewport").css("overflow-x","scroll")
      $(" .ag-pinned-left-cols-viewport").css("overflow-x","scroll")
      $(" .ag-pinned-left-cols-viewport").css("overflow-y","hidden")
    },100);
  }
  addSecuritiesFn(){
    this.ngxSmartModalService.open("addSecurities");
    setTimeout(()=>{
      $(".ag-body .ag-body-viewport").css("overflow-x","hidden")
      $(" .ag-pinned-left-cols-viewport").css("overflow-x","hidden")
      $(" .ag-pinned-left-cols-viewport").css("overflow-y","hidden")
    },100);
  }

  adjustHeightViewport(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let tab = $('.relationship-overview-tabset')[0].firstElementChild.clientHeight;
    let options = $('.investment-tab-button')[0].clientHeight;
    let totalRow = $('.investments-total')[0].clientHeight;
    let margin = 155+ extraMargin;
    var total = header+ pageheader+ margin+ stepHeader+ tab+ options+ totalRow + 120;
    var ele = document.getElementsByTagName('tbody')[0];
    if(ele) {
      ele.setAttribute('style',"height: calc(100vh - "+total+"px);")
    }
  }

  getInvestmentTemplate(template){
    this.selectedTemplate = template;

    this.portfolioConstructionService.getInvestmentTemplate().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          this.investmentTemplateList = response["responseData"];
          if(this.selectedTemplate) 
            this.onChangePortfolio();
           
      }
    });
  }

  onChangeTemplate(){
    this.onChangePortfolio();
  }

  openTemplateModal(mode){
    if(mode == 'edit'){
      if(!this.selectedTemplate){
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select template';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      this.investmentTemplateVO = _.find(this.investmentTemplateList, { 'objectId': this.selectedTemplate });
      //this.templateName = this.investmentTemplateVO["name"];
    }else{
      this.investmentTemplateVO = {};
    }
    this.templatePopupMode = mode;

    this.ngxSmartModalService.open("investmentTemplatePopup");

    

    setTimeout(() => {
      this.productComponentReference = this.popUpGridComponentData.toArray().find(function (item) {
        return item.gridName == 'catalog-product-portfolio-construction';
      });
      this.securityComponentReference = this.popUpGridComponentData.toArray().find(function (item) {
        return item.gridName == 'workflow-security-grid';
      });
    },);
  }

  removeInvestmentPopUp(index){
    this.investmentTemplateVO['investments'].splice(index,1)
  }

  saveInvestmentTemplate(){
    let duplicateInvestment = false;
    if(!this.investmentTemplateVO["name"]){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let selectedRows  = this.productComponentReference.getSelectedRows();
    let selectedRows2  = this.securityComponentReference.getSelectedRows();
    let investmentList = [];
    if(this.templatePopupMode == "edit"){
      investmentList = this.investmentTemplateVO["investments"];
    }
     
     selectedRows.forEach(element => {
      let temp = _.find(investmentList, { 'investmentId': element.productId,'investmentType' : "product" });
      if(!temp){
        investmentList.push(
          {
            investmentType : "product",
            investmentId   : element.productId,
            proposedAmountPercentage : 0.0,
            name          : element.name
  
          }
        ) 
      }else{
        duplicateInvestment = true;
        
      }
      
     });
     selectedRows2.forEach(element => {
      let temp = _.find(investmentList, { 'investmentId': element.productId,'investmentType' : "security" });
      if(!temp){
        investmentList.push(
          {
            investmentType : "security",
            investmentId   : element.securityId,
            proposedAmountPercentage : 0.0,
            name          : element.name
  
          }
        ) 
      }else{
        duplicateInvestment = true;
      }
      
     });

     if(duplicateInvestment){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Investment(s) already added';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
     }

     let requestData ={
      name : this.investmentTemplateVO["name"],
      objectId : null,
      investments : investmentList

     }
     if(this.templatePopupMode == 'edit')
      requestData.objectId = this.investmentTemplateVO["objectId"]
    
    this.portfolioConstructionService.saveInvestmentTemplate(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('investmentTemplatePopup').close();
          this.getInvestmentTemplate(this.selectedTemplate);
      }
    });
  }

  updateChangeFlag(){
    this.isChangeAfterSave = true;
    this.stepData.isChangeAfterSave = true;
    this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
  }

  openConfirmationModal(param){
    this.confirmationType = param
    if(this.isChangeAfterSave){
      this.ngxSmartModalService.open("deleteConfirmationModal");
    }else if(param == "Product"){
      this.addProductsFn();

    }else if(param == "Security"){
      this.addSecuritiesFn();

    }else if(param == "remove"){
      this.removeInvestment();

    }
  }

  deleteAfterConfirmation(){
    this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
    if(this.confirmationType == "Product"){
      this.addProductsFn();

    }else if(this.confirmationType == "Security"){
      this.addSecuritiesFn();

    }else if(this.confirmationType == "remove"){
      this.removeInvestment();

    }
  }

  ngOnDestroy() {
    this.stepData.isChangeAfterSave = false;
    this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
  }





}
