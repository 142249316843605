import { Component, OnInit } from '@angular/core';
import { GridFilterSortingService } from '../../../_services/grid-filter-sorting.service';

@Component({
  selector: 'app-select-all-header',
  templateUrl: './select-all-header.component.html',
  styleUrls: ['./select-all-header.component.less']
})
export class SelectAllHeaderComponent implements OnInit {
  params: any;

  selectAllCheckbox: boolean;
  selectAllOrTotal: string;

  constructor(
    private gridFilterSortingService: GridFilterSortingService
  ) { }

  ngOnInit() {
    this.selectAllCheckbox = false;
    this.selectAllOrTotal = null;
  }

  agInit(params): void {
    this.params = params;
  }

  changeSelection(flag) {
    this.selectAllCheckbox = true;
    this.setSelectAllOrTotalRows(flag);
  }

  selectUnselectHeaderOption() {
    if (!this.selectAllCheckbox) {
      this.selectAllOrTotal = null;
      this.setSelectAllOrTotalRows(null);
    } else {
      this.selectAllOrTotal = 'ALL';
      this.setSelectAllOrTotalRows('ALL');
    }
  }

  setSelectAllOrTotalRows(data){
    let prepData={
      type : data,
      grid : this.params["gridName"]
    }
    this.gridFilterSortingService.setSelectAllInfo(prepData);
  }

}
