

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Variables } from '../../../constants';
import { DatePipe } from '@angular/common';
import { ErrorModel } from "../../../_models/response.model";
import { CommonFunctions } from '../../../common';
import { DocumentCenterService } from "src/app/_services/document-center.service";
import { DocumentCenterModel } from "src/app/_models/document-center.model";
import { WorkflowBaseService } from "../../../_services/workflow-services/workflow-base.service";
import { SlideSelectionService } from 'src/app/_services/workflow-services/slide-selection.service';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SlideSelectionModel, PresentationDetailModel, TemplateModel, SectionModel, SlideModel } from 'src/app/_models/slide-selection.model';
import { MaxCalculatorMainComponent } from '../max-calculator-main/max-calculator-main.component';
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { GridComponent } from '../../../shared/grid/grid.component';
import * as _ from "lodash";
import * as $ from 'jquery';
import { BsDatepickerConfig } from 'ngx-bootstrap';

@Component({
  selector: 'app-cal-slide-selection',
  templateUrl: './cal-slide-selection.component.html',
  styleUrls: ['./cal-slide-selection.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class CalSlideSelectionComponent implements OnInit {

  componentReference: {};
  templateId: string;
  stepInfo = {};
  slideOutputOptions;
  listOfTemplates = [];
  originalSectionVO;
  document = new DocumentCenterModel();
  defaultSearchingData: any = null;
  currentSlide = new SlideModel();
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  slideSelectionModel = new SlideSelectionModel();
  subscription;
  portfolioId:string;
  strategiesOneTwoVO :any={};
  selectedStrategyOfPortfolio=[];

  @ViewChild(GridComponent) gridComponentData: GridComponent;
  currentDate: Date;
  selectedFile: File;
  typeOfFile: string;
  isSlideSelectionEditable:boolean;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private datePipe: DatePipe,
    private documentCenterService: DocumentCenterService,
    private slideSelectionService: SlideSelectionService,
    private authMessageService: AuthMessageService,
    private workflowBaseService: WorkflowBaseService,
    private proposalWorkflowComponent: MaxCalculatorMainComponent
  ) {
    this.componentReference = { componentParent: this };
  }

  ngOnInit() {
    this.slideOutputOptions = {
      consolidated: false,
      portfolio: false,
      taxable: false,
      retirement: false
    };
    // let requestObj = {
    //   "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
    //   "taskDefinitionKey": "slideSelection"
    // };
    // this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
    //   if (response['responseType'] == Variables.statusSuccess) {
    //     if (response['responseData'].taskVariables && response['responseData'].taskVariables.slideOutputOptions) {
    //       this.slideOutputOptions = response['responseData'].taskVariables.slideOutputOptions;
    //     }else{
    //       this.stepInfo["slideOutputOptions"] = this.slideOutputOptions;
    //       this.workflowBaseService.setStepInfoVsStepName('slideSelection', this.stepInfo);
    //     }
    //   }
    // });
    this.dpConfig.containerClass = "theme-blue";
    this.subscription = this.workflowBaseService.callWorflowChildFunction.subscribe(data => {
      this.saveSlideSelection(data);
    });
    this.getPresentationSlide();
    this.getExistingTemplates();
    this.getSlideSelectionEnum();
    this.defaultSearchingData = {};
    this.defaultSearchingData["colId"] = "extension";
    this.defaultSearchingData["colValue"] = 'pdf';
    this.dpConfig.containerClass = "theme-blue";
    this.currentDate = new Date();
    this.slideSelectionModel.presentationDetailsVO.datePrepared = (this.currentDate.getMonth() + 1) + '/' + this.currentDate.getDate() + '/' + this.currentDate.getFullYear();
  }

  getSlideSelectionEnum() {
    let apiParams={
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      stepName: "slideSelection"
    }
   this.workflowBaseService.getEnumDataBasedOnStep(apiParams).subscribe((response) => {
     if (response['responseType'] == Variables.statusSuccess) {
        this.portfolioId =Object.keys(response['responseData'].portfolioIdVsStrategies)[0];
        this.selectedStrategyOfPortfolio = response['responseData']['portfolioIdVsStrategies'][this.portfolioId].passedStrategies;
        this.getStrategiesOneTwo();

     }
   });
 }

 getStrategiesOneTwo() {
   this.slideSelectionService.getStrategiesOneTwo(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.portfolioId).subscribe((response) => {
     if (response['responseType'] == Variables.statusSuccess) {
        this.strategiesOneTwoVO = response['responseData'].portfolioStrategiesOneTwoVOList[0];
     }
   });
 }

 setStrategiesOneTwo() {
  let request = {};
  request['processInstanceId'] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
  request["portfolioStrategiesOneTwoVOList"] = [this.strategiesOneTwoVO];
 this.slideSelectionService.setStrategiesOneTwo(request).subscribe((response) => {
   if (response['responseType'] == Variables.statusSuccess) {
     this.authMessageService.showSuccessPopup(response)
   }
 });
}

  getSlideSelectionDetails() {
    this.slideSelectionService.getSlideSelectionDetails(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData']) {
          if (response['responseData'].presentationDetailsVO) {
            this.slideSelectionModel.presentationDetailsVO = response['responseData'].presentationDetailsVO;
          } else {
            //this.slideSelectionModel.presentationDetailsVO.presentationTitle = this.proposalWorkflowComponent.workflowInfoData.processInstanceName;
            if (this.proposalWorkflowComponent.workflowInfoData.processVariables && this.proposalWorkflowComponent.workflowInfoData.processVariables.processName)
              this.slideSelectionModel.presentationDetailsVO.presentationTitle = this.proposalWorkflowComponent.workflowInfoData.processVariables.processName;
            else
              this.slideSelectionModel.presentationDetailsVO.presentationTitle = this.proposalWorkflowComponent.workflowInfoData.processInstanceName;
          }
          if (response['responseData'].templateVO) {
            this.setselectedTemplates(response['responseData'].templateVO);
          }
          if (response['responseData'].slideOutputOptions) {
            this.slideOutputOptions = response['responseData'].slideOutputOptions;
          }



          console.log(this.slideSelectionModel);
        }
        // if (this.proposalWorkflowComponent.workflowInfoData.processVariables && this.proposalWorkflowComponent.workflowInfoData.processVariables.processName)
        //   this.slideSelectionModel.presentationDetailsVO.presentationTitle = this.proposalWorkflowComponent.workflowInfoData.processVariables.processName;
        // else
        //   this.slideSelectionModel.presentationDetailsVO.presentationTitle = this.proposalWorkflowComponent.workflowInfoData.processInstanceName;

      }
    });
  }

  setselectedTemplates(data) {
    this.slideSelectionModel.templateVO.sectionVOList = JSON.parse(JSON.stringify(this.originalSectionVO));
    this.slideSelectionModel.templateVO.isCoverPage = data.isCoverPage;
    this.slideSelectionModel.templateVO.isDefault = data.isDefault;
    this.slideSelectionModel.templateVO.templateId = data.templateId;
    this.slideSelectionModel.templateVO.templateName = data.templateName;
    this.slideSelectionModel.templateVO.isCurrentSelected = data.isCurrentSelected;
    this.slideSelectionModel.templateVO.isProposedSelected = data.isProposedSelected;
    this.slideSelectionModel.templateVO.isStrategyOneSelected = data.isStrategyOneSelected;
    this.slideSelectionModel.templateVO.isStrategyTwoSelected = data.isStrategyTwoSelected;
    if (data.sectionVOList) {
      data.sectionVOList.forEach(element => {
        let section = _.find(this.slideSelectionModel.templateVO.sectionVOList, { sectionId: element.sectionId });
        if (section) {
          element.slideVOList.forEach(element2 => {
            let slide = _.find(section.slideVOList, { id: element2.id });
            if (slide)
              slide.isSelected = true;
          });
        }
        this.selectUnselectSlide(section);
      });
    }

  }

  changeInSlideOptions(type, value) {
    this.slideSelectionModel.templateVO.sectionVOList.forEach(section => {
      section.slideVOList.forEach(element => {
        if (element.reportType == type)
          element.isSelected = value;
      });
    })
    // this.stepInfo["slideOutputOptions"] = this.slideOutputOptions;
    // this.workflowBaseService.setStepInfoVsStepName('slideSelection', this.stepInfo);
  }

  getExistingTemplates() {
    this.slideSelectionService.getExistingTemplates(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        if (response['responseData']) {
          this.listOfTemplates = [];
          for (var key in response['responseData']) {
            this.listOfTemplates.push(response['responseData'][key]);
          }
        }


      }
    });
  }

  saveTemplate() {
    // if(!this.slideSelectionModel.templateVO.templateId || this.slideSelectionModel.templateVO.templateId == "null"){
    //   var errorObj = new ErrorModel();
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please select Template';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (!this.slideSelectionModel.templateVO.templateName) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!this.slideSelectionModel.templateVO.templateId || this.slideSelectionModel.templateVO.templateId == "null") {
      let temp = _.find(this.listOfTemplates,{templateName : this.slideSelectionModel.templateVO.templateName});
      if(temp){
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Template with this name already exists';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      
    }
    let requestObj = JSON.parse(JSON.stringify(this.slideSelectionModel.templateVO));
    let tempSectionList = [];
    requestObj.sectionVOList.forEach((element, index) => {
      let tempSlideList = [];
      element.slideVOList.forEach((element2, index2) => {
        if (element2.isSelected)
          tempSlideList.push(element2);
      });
      element.slideVOList = tempSlideList;
      if (element.slideVOList.length)
        tempSectionList.push(element)
    });
    requestObj.sectionVOList = tempSectionList;

    this.slideSelectionService.saveTemplate(this.proposalWorkflowComponent.workflowInfoData.processInstanceId, requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.authMessageService.showSuccessPopup(response);
        this.getExistingTemplates();

      }
    });
  }

  saveSlideSelection(isNext) {
    if (!this.slideSelectionModel.presentationDetailsVO.preparedFor) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter detail of prepared for';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else if (!this.slideSelectionModel.presentationDetailsVO.datePrepared) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter detail of prepared date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else if (!this.slideSelectionModel.presentationDetailsVO.preparedBy) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter detail of prepared By';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    else if (!this.slideSelectionModel.presentationDetailsVO.presentationTitle) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter detail of presentation Title';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    else if (this.slideSelectionModel.templateVO.isStrategyOneSelected && 
      (!this.strategiesOneTwoVO.strategiesOneId || this.strategiesOneTwoVO.strategiesOneId == "null") ) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please Select Strategy 1';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    else if (this.slideSelectionModel.templateVO.isStrategyTwoSelected &&
       (!this.strategiesOneTwoVO.strategiesTwoId || this.strategiesOneTwoVO.strategiesTwoId == "null") ) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please Select Strategy 2';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let requestObj = JSON.parse(JSON.stringify(this.slideSelectionModel));
    requestObj["slideOutputOptions"] = this.slideOutputOptions;
    let tempSectionList = [];
    let tempDate = requestObj.presentationDetailsVO.datePrepared;
    requestObj.presentationDetailsVO.datePrepared = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
    requestObj.templateVO.sectionVOList.forEach((element, index) => {
      let tempSlideList = [];
      element.slideVOList.forEach((element2, index2) => {
        if (element2.isSelected)
          tempSlideList.push(element2);
      });
      element.slideVOList = tempSlideList;
      if (element.slideVOList.length)
        tempSectionList.push(element)
    });
    requestObj.templateVO.sectionVOList = tempSectionList;
    this.slideSelectionService.saveSlideSelection(this.proposalWorkflowComponent.workflowInfoData.processInstanceId, requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        //this.authMessageService.showSuccessPopup(response);
        this.subscription.unsubscribe();
        if (isNext)
          this.proposalWorkflowComponent.nextStep(null);


      }
    });
  }

  getTemplateDetails() {
    if (this.slideSelectionModel.templateVO.templateId && this.slideSelectionModel.templateVO.templateId != "null") {
      this.slideSelectionService.getTemplateDetails(this.slideSelectionModel.templateVO.templateId).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          if (response['responseData'])
            this.setselectedTemplates(response['responseData']);

        }
      });
    }

  }

  getPresentationSlide() {
    this.slideSelectionService.getPresentationSlide(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isSlideSelectionEditable = response['responseData']['isSlideSelectionEditable'];
        this.proposalWorkflowComponent.hideNextButton = !this.isSlideSelectionEditable;
        this.slideSelectionModel.templateVO.sectionVOList = response['responseData']['sectionVOList'];
        this.slideSelectionModel.templateVO.sectionVOList.forEach(section => {
          section.isDisabled = true;
          section.slideVOList.forEach(element => {
            if (element.selectionType == "MANDATROY")
              element.isSelected = true;
            else
              section.isDisabled = false;
          });
        });
        this.originalSectionVO = JSON.parse(JSON.stringify(response['responseData']['sectionVOList']));
        this.getSlideSelectionDetails();
      }
    });
  }

  selectUnselectSlide(section) {
    let allSelected = true;
    section.slideVOList.forEach(element => {
      if (!element.isSelected)
        allSelected = false;
    });
    if (allSelected)
      section.isSelected = true;
    else
      section.isSelected = false;
  }

  selectUnselectSection(section) {
    if (section.isSelected) {
      section.slideVOList.forEach(element => {
        if (element.selectionType != "MANDATROY")
          element.isSelected = true;
      });
    } else {
      section.slideVOList.forEach(element => {
        if (element.selectionType != "MANDATROY")
          element.isSelected = false;
      });
    }
  }

  addDocumentFn(slide) {
    this.currentSlide = slide;
    this.ngxSmartModalService.open('addDocument');
  }

  removeDoc(slide, index) {
    slide.documentInfoList.splice(index, 1);
  }

  addDocuments() {
    if (!this.currentSlide.documentInfoList)
      this.currentSlide.documentInfoList = [];
    let selectedRows = this.gridComponentData.getSelectedRows();
    selectedRows.forEach(element => {
      var obj = {
        name: '',
        dataStoreId: '',
        isBefore: false
      }
      if (this.currentSlide.documentInfoList.filter(e => e.dataStoreId === element.dataStoreId).length == 0) {
        obj.name = element['documentName'];
        obj.dataStoreId = element['dataStoreId'];
        this.currentSlide.documentInfoList.push(obj);
      }
    })

    // this.currentSlide.documentInfoList = selectedRows;
    this.ngxSmartModalService.close('addDocument');
  }

  openUploadSection() {
    this.ngxSmartModalService.open("uploadDocument")
  }

  fileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    this.typeOfFile = this.selectedFile.type;
  }


  addDocument123(documentData) {
    if (this.validateData(this.document)) {
      let formData = new FormData();
      // Add your values in here
      var documentName = documentData.target[1].value;
      var documentDescription = documentData.target[2].value;
      var documentFile = documentData.target[3].files[0];
      var dataStoreId = this.document.dataStoreId || '';
      formData.append('documentName', documentName);
      formData.append('description', documentDescription);
      if (documentFile) {
        formData.append('document', documentFile, documentFile.name);
      }
      formData.append('dataStoreId', dataStoreId);
      this.documentCenterService.saveDocumentsData(formData)
        .subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.ngxSmartModalService.getModal('uploadDocument').close();
            this.gridComponentData.getGridDataAfterUpdatingRecords();
          }
        });
    }
  }

  validateData(data) {
    if (this.typeOfFile != 'application/pdf') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'You can Upload only pdf file';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (CommonFunctions.isEmptyString(data.documentName)) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter document name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (!data.document == 'Edit Document') {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please select document';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    return true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}

