import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { ApiUrls } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ProductFilterService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };


constructor(private http: HttpClient) { }


// getAccountListData(data: number){

//   return this.http.get(ApiUrls.getAccountListDataAPI + "?entityDataStoreId=" + data, this.httpOptions )
//   .pipe(
//       response => response
//   );
// }


// deleteClientData(value){

//   return this.http.post(ApiUrls.deleteClientDataAPI, value, this.httpOptions )
//   .pipe(
//       response => response
//   );
// }


}
