import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class TransfereService {

  constructor( ) { }

  private data;

  setData(data){
    this.data = data;

    // Note: cache should not be re-used by repeated calls to JSON.stringify.
    var cache = [];
    var stringifiedData = JSON.stringify(this.data, function(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Duplicate reference found
                try {
                    // If this value does not reference a parent it can be deduped
                    return JSON.parse(JSON.stringify(value));
                } catch (error) {
                    // discard key if value cannot be deduped
                    return;
                }
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });
    cache = null; // Enable garbage collection

    localStorage.setItem('dataItem', stringifiedData);
  }

  getData(){
    let temp = this.data;
    //this.clearData();
    return temp;
  }

  clearData(){
    this.data = undefined;
  }

}