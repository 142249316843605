import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthMessageService } from '../../_services/auth-message.service';

import { ResponseVO } from '../../_models/response.model';

@Component({
  selector: 'app-auth-message',
  templateUrl: './auth-message.component.html',
  styleUrls: ['./auth-message.component.less']
})
export class AuthMessageComponent implements OnInit {

    responseDataObj = new ResponseVO();
    showErrorDescription = false;
    showErrorPopup :boolean;
    showSuccessPopup : boolean;

    private subscription: Subscription;

    constructor(private authMessageService: AuthMessageService) { }

    ngOnInit() {
        this.subscription = this.authMessageService.authMessageState
        .subscribe((stateObj) => {
            this.showErrorPopup = stateObj.showErrorPopup;
            this.showSuccessPopup = stateObj.showSuccessPopup;
            this.responseDataObj = stateObj['responseDataObj'] || {};
            this.showErrorDescription = false;
            if(this.showSuccessPopup){
                // setTimeout(()=>{
                //     this.hideSuccessMessagePopup();
                // },3000); 
                var that = this;
                        $(document).bind('click', function(e) {                            
                            var $clicked = $(e.target);
                            //if (! $clicked.parents().hasClass("app-auth-msg-popup-container"))
                            if(that.showSuccessPopup)
                            that.hideSuccessMessagePopup();
                });
            }
        });    
    }
 
    /* Show More Details in Error Message Popup */
    showMoreOrLessErrorDetails(){
        if(this.showErrorDescription == true){
            this.showErrorDescription = false;
        } else if(this.showErrorDescription == false){
            this.showErrorDescription = true;
        }
    }

    // errorMessagePopup(messageObj){
    //     this.showErrorDescription = false; /*Declared in Common JS*/
    //     this.showErrorPopup = true;
    //     this.errorData = messageObj;
    //     // if(errorData.errorCode == errorCodesVsName.tokenExpiryCode){
    //     //     $localStorage.isLogout = true;
    //     //     $(".main-body").css({"display":"none",'background':'transparent'});
    //     //     $state.go('login');
    //     // }
    // }// errorMessagePopup(messageObj){
    //     this.showErrorDescription = false; /*Declared in Common JS*/
    //     this.showErrorPopup = true;
    //     this.errorData = messageObj;
    //     // if(errorData.errorCode == errorCodesVsName.tokenExpiryCode){
    //     //     $localStorage.isLogout = true;
    //     //     $(".main-body").css({"display":"none",'background':'transparent'});
    //     //     $state.go('login');
    //     // }
    // }

    /* Hide Errror Message Popup */
    hideErrorMessagePopup(){
        this.authMessageService.hideErrorPopup();
    }

    /* Hide Success Message Popup */
    hideSuccessMessagePopup(){
        this.authMessageService.hideSuccessPopup();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }


}
