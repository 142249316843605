import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Router } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { Variables, ApiUrls } from '../constants';

import { AuthMessageService } from '../_services/auth-message.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authMessageService   : AuthMessageService,
        private authenticationService: AuthenticationService,
        private router               :Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: { 
                'subDomain'   : localStorage.getItem('subDomain'),
                'X-Auth-Token': localStorage.getItem('token') || ''
            }
        });
        return next.handle(request).pipe(
            tap((response) => {
                if(response['body'] && response['body']['responseType'] && response['body']['responseType'] == Variables.statusError){
                  this.authMessageService.showErrorPopup(response['body']);
                }
            }),
            catchError(err => {
            if(err.error && err.error.responseType == Variables.statusError){
                this.showErrorMessage(err.error);
            }

            if (err.error && (err.error.errorCode === "ERR0028" || err.error.errorCode === "ERR0027") ) {
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                // location.reload(true);
                this.router.navigate(['/login']);
            }
            
            let error = err.error && (err.error.message || err.statusText);
            if(!error){
                error = 'Some Error Occured';
            }
            return throwError(error);
        }))
    }

    private showErrorMessage(errorObj) {
        this.authMessageService.showErrorPopup(errorObj);
    }
      
      
}
