import { Component, OnInit } from '@angular/core';
import { Variables } from '../../../constants';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';
import { ProposalWorkflowComponent } from '../proposal-workflow.component';
import { PortfolioConstructionService } from '../../../_services/workflow-services/portfolio-construction.service';
import { ErrorModel } from '../../../_models/response.model';
import { AuthMessageService } from '../../../_services/auth-message.service';

@Component({
  selector: 'app-portfolio-construction',
  templateUrl: './portfolio-construction.component.html',
  styleUrls: ['./portfolio-construction.component.less']
})
export class PortfolioConstructionComponent implements OnInit {

  constructor(
    private authMessageService : AuthMessageService,
    private PortfolioConstructionService:PortfolioConstructionService,
    private workflowBaseService : WorkflowBaseService,
    private proposalWorkflowComponent : ProposalWorkflowComponent,
  ) { 
    
  }
  tabView = 'investments';
  selectedTab: string;
  selectedPortfolio:string = null;
  portfolioContructionDropdown:any;
 
  ngOnInit() {
    this.getDropdownsData();
  }
  changeTab(tab) {
    this.selectedTab = tab;
  }

  /* need to change it because its get impacted when we are selecting any thing*/
  onTabSelect(evt): void {
    if(evt.id && evt.id !== 'investments'){
      this.PortfolioConstructionService.validatePortfolioAllocation(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response)=>{
        if (response["responseType"] == Variables.statusSuccess) {
          this.tabView = evt.id;
        }else{
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = response['message'];
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);
        }
      });
    } else{
      if(evt.id==undefined){
        //this check is to handle slected content fire Select event
      }else{
        this.tabView = evt.id;
      }
    }
  }


   /* Get dropdowns data */
   getDropdownsData() {
    var apiParams = {
      processInstanceId : this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      stepName : 'portfolioConstruction'  
    };

    this.workflowBaseService.getEnumDataBasedOnStep(apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.portfolioContructionDropdown = data;
      }
    });
  }

}
