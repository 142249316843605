import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from '../../_services/base.service';
import { Variables } from '../../constants';
import { WorkflowBaseService } from '../../_services/workflow-services/workflow-base.service';
import { ProposalWorkflowComponent } from '../../../app/webPages/proposal-workflow/proposal-workflow.component';

@Component({
  selector: 'app-right-navigation',
  templateUrl: './right-navigation.component.html',
  styleUrls: ['./right-navigation.component.less']
})
export class RightNavigationComponent implements OnInit {

  rightNav: boolean = true;
  rightNavigationMenuList = [];

  constructor(
    private router: Router,
    private workflowBaseService: WorkflowBaseService,
    private baseService: BaseService,
    private proposalWorkflowComponent : ProposalWorkflowComponent
  ) { }

  ngOnInit() {
    this.getRightNavigationData('ProposalType');
  }

  getRightNavigationData(requestData) {
    this.baseService.getRightNavigationData(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.rightNavigationMenuList = response['responseData'];
      }
    });
  }

  startNewProcess(item) {
    var requestObj = {
      "processDefinitionKey": item.code
    }
    this.workflowBaseService.startNewProcess(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        localStorage.setItem('workflowInfoData', JSON.stringify(response['responseData']));
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['/proposalWorkflow']));
      }
    });
  }

  rightNavClick() {
    if (this.rightNav) {
      this.rightNav = false;
    } else {
      this.rightNav = true;
    }
  }
}
