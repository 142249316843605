import { Component, OnInit, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import {GridFilterSortingService} from '../../../_services/grid-filter-sorting.service';

import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DateFilterComponent{
  params: any;
  startDate;
  endDate;
  private columnFilterData;

  constructor(
    private gridFilterSortingService: GridFilterSortingService,
    private datePipe : DatePipe
  ) { }

  ngOnInit() {
  }

  agInit(params): void {
    this.params = params;
  }

  onDateChange(date: NgbDate,type){ 
    if(date.day){
      var day =  date.day  < 10 ? "0" + date.day : date.day ;
      var month =  date.month  < 10 ? "0" + date.month : date.month;
      var year = date.year  < 10 ? "0" + date.year : date.year;
      if(type == 'startDate'){
        this.params.colDef.searchSortData.searchedValue[0] = month + '/' + day + '/' + year;
      }else if(type == 'endDate'){
        this.params.colDef.searchSortData.searchedValue[1] = month + '/' + day + '/' + year;
      }
    }else{
      let formattedDate  = this.datePipe.transform(date, 'MM/dd/yyyy');
      if(type == 'startDate'){
        this.params.colDef.searchSortData.searchedValue[0] = formattedDate;
      }else if(type == 'endDate'){
        this.params.colDef.searchSortData.searchedValue[1] = formattedDate;
      }
    }
    
  }

  addDatepickerToAgGridContext(e){
    if(document.getElementsByTagName("ngb-datepicker")[0]){
      this.params.api.gridCore.popupService.activePopupElements.push(document.getElementsByTagName("ngb-datepicker")[0]);
     
    }
   
    
     //$('ngb-datepicker').attr("style", "padding :0px;top:235px;left:990px;");
}

  onApplyingFilter(){
    this.columnFilterData = this.params.column.colDef.searchSortData;
    this.gridFilterSortingService.setSortingData(this.columnFilterData);    
  }

  onClearingFilter(){
    this.columnFilterData = this.params.column.colDef.searchSortData;
    this.columnFilterData.filterValue = "";
    this.columnFilterData.searchedValue = [];
    this.startDate = '';
    this.endDate = '';
    this.gridFilterSortingService.setSortingData(this.columnFilterData);    
  }

}
