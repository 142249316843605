export class SlideSelectionModel{
    presentationDetailsVO = new PresentationDetailModel();
    templateVO = new TemplateModel();
}

export class PresentationDetailModel{
    presentationTitle : string=null;
    preparedFor : string=null;
    preparedBy : string=null;
    datePrepared : string=null;
}

export class TemplateModel{
    templateId =null;
    templateName : string=null;
    isCoverPage : boolean=true;
    isDefault : boolean=false;
    sectionVOList = [] ;
    isCurrentSelected : string=null;
    isProposedSelected : string=null;
    isStrategyOneSelected : string=null;
    isStrategyTwoSelected : string=null;
}

export class SectionModel{
    sectionName : string=null;
    sectionId : string=null;
    slideVOList = [];
}

export class SlideModel{
    name : string=null;
    id : string=null;
    selectionType : string=null;
    documentInfoList = [];
}