import { Component, OnInit } from '@angular/core';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { Variables } from '../../../../constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'product-overview-tab',
  templateUrl: './product-overview-tab.component.html',
  styleUrls: ['./product-overview-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductOverviewTabComponent implements OnInit {

  productId: string;
  apiParams = {
    productDetailsDataStoreId: ''
  };

  productOverviewData = {};
  productDetails:any = {};

  performanceColumnDefs = [];
  performanceRowData = [];

  offeringsColumnDefs = [];
  offeringsRowData = [];

  productListMembershipColumnDefs = [];
  productListMembershipRowData = [];

  classificationColumnDefs = [];
  classificationRowData = [];
  gridApi: any;
  gridColumnApi: any;

  constructor(
    private catalogProductInfoService: CatalogProductInfoService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });

    this.apiParams = {
      productDetailsDataStoreId: this.productId
    };

    this.performanceColumnDefs = [
      {
        headerName: "Name",
        field: "productName",
        width: 100
      },
      {
        headerName: "MTD",
        field: "monthToDate",
        width: 100
      },
      {
        headerName: "QTD",
        field: "quarterToDate",
        width: 100
      },
      {
        headerName: "YTD",
        field: "yearToDate",
        width: 100
      },
      {
        headerName: "1 Yr.",
        field: "oneYearReturn",
        width: 100
      },
      {
        headerName: "2 Yr.",
        field: "twoYearReturn",
        width: 100
      },
      {
        headerName: "3 Yr.",
        field: "threeYearReturn",
        width: 100
      },
      {
        headerName: "5 Yr.",
        field: "fiveYearReturn",
        width: 100
      },
      {
        headerName: "7 Yr.",
        field: "sevenYearReturn",
        width: 100
      },
      {
        headerName: "10 Yr.",
        field: "tenYearReturn",
        width: 100
      },
      {
        headerName: "Inception Date",
        field: "inceptionToDateReturn",
        width: 100
      }];

    this.offeringsColumnDefs = [
      {
        headerName: "Name",
        field: "offeringsName",
        width: 120
      },
      {
        headerName: "Min. Invt.",
        field: "minimumInvestment",
        width: 100
      },
      {
        headerName: "Max. Invt.",
        field: "maximumInvestment",
        width: 100
      },
      {
        headerName: "Add. Invt.",
        field: "additionalInvestment",
        width: 100
      },
      {
        headerName: "Tradeable",
        field: "isTradeable",
        width: 100
      },
      {
        headerName: "Default Offering",
        field: "isDefaultOffering",
        width: 120
      }];

    this.productListMembershipColumnDefs = [
      {
        headerName: "Product List Name",
        field: "name",
        width: 200
      }];

    this.classificationColumnDefs = [
      {
        headerName: "Schema",
        field: "schemaId",
        width: 150
      },
      {
        headerName: "Product SuperClass",
        field: "productSuperClass",
        width: 200
      },
      {
        headerName: "Product Class",
        field: "productClass",
        width: 150
      },
      {
        headerName: "Percentage",
        field: "percentage",
        width: 150
      }];

    this.getProductOverviewData(this.productId);
  }

  getProductOverviewData(productId) {
    this.getProductOverviewInfo(productId);
  }

  getProductOverviewInfo(productId) {
    this.catalogProductInfoService.getProductOverviewInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productOverviewData = response['responseData'];
          this.prepareData(this.productOverviewData);
        }
      });
  }

  prepareData(data) {
    this.productDetails = data['productDetailsVO'];
    this.productListMembershipRowData = data['productList'];
    this.offeringsRowData = data['productOfferingsVO'];
    this.performanceRowData = data['productPerformanceVOList'];
    this.classificationRowData = data['productAssetClassVO'];
  }


  /* On grid ready */
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }


}
