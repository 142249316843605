import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class CatalogService {

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };

    constructor(private http: HttpClient) { }

    saveNewProductListData(data) {
        return this.http.post(ApiUrls.saveProductListDataAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteProductLists(filterInfoVO) {
        return this.http.post(ApiUrls.deleteProductListDataAPI, filterInfoVO, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getAllEntitledProductList() {
        return this.http.get(ApiUrls.getAllEntitledProductListAPI,this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveNewProductData(data) {
        return this.http.post(ApiUrls.saveProductDataAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteProducts(filterInfoVO) {
        return this.http.post(ApiUrls.deleteProductDataAPI, filterInfoVO, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getComparisonInfoData(requestData,comparisoncriterion) {
        return this.http.post(ApiUrls.getProductComparisonInfoDataAPI + '?productParam=' + comparisoncriterion , requestData, this.httpOptions)
            .pipe(
                response => response
            );
    }

}
