

export class StrategySelectionModel{
  portfolio : string=null;
  proposed : {}=null;
  assetClass : string=null;
  productId : string=null;
  assetClassId : string=null;
  currentAmountValue : number=0;
  currentAmountPercentage : number=0;
  proposedAmountValue : number=0;
  proposedAmountPercentage : number=0;
  differenceAmountValue : number=0;
  differenceAmountPercentage : number=0;
}


export class totalStrategySelectionModel{
  totalCurrentAmount : string;
  totalCurrentPerc : number=0;
  totalProposedAmount : number=0;
  totalProposedPerc : number=0;
  totalDifferenceAmount : number=0;
  totalDifferencePerc : number=0;

}