import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { LeftNavService } from 'src/app/_services/left-nav.service';
import { BaseService } from 'src/app/_services/base.service';
import awaitSelector, { watchAwaitSelector } from 'await-selector';
import { Router } from '@angular/router';
import { Variables } from '../../constants';
import { element } from '@angular/core/src/render3/instructions';
declare var _: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  dashboardSummary :any= {};
  accountGridColumnDefs = {};
  userId;
  componentReference;
  colName;
  relationshipGridColumnDefs = {};
  gridApiAccount: any;
  loadDashboard: boolean = false;
  gridApiRelationship: any;
  constructor(
    private leftNavData: LeftNavService,
    private baseService: BaseService,
    private router: Router
  ) { }
  message: boolean = true;
  mainContent: boolean = true;
  ngOnInit() {
    this.leftNavData.currentMessage.subscribe(message => {
      this.message = message;
      this.changeContainerSize();
    });




    this.accountGridColumnDefs =
      [
        {
          "headerName": "Name",
          "field": "name",
          "minWidth": 80,
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "headerTooltip": "Name",
          "hide": false
        },
        {
          "headerName": "Account #",
          "field": "accountNumber",
          "minWidth": 80,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Account Number",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Relationship",
          "field": "relationship",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Relationship",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Market Value",
          "field": "marketValue",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Market Value",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Unrealized Gain",
          "field": "unrealizedGain",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Unrealized Gain",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Cost Basis",
          "field": "costBasis",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Cost Basis",
          "filter": "SelectFilterComponent",
          "hide": false
        }
      ]
    this.relationshipGridColumnDefs =
      [
        {
          "headerName": "Name",
          "field": "name",
          "minWidth": 80,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Name",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "No. of Accounts",
          "field": "numberOfAccounts",
          "minWidth": 80,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "No. of Accounts",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Market Value",
          "field": "marketValue",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Market Value",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Unrealized Gain",
          "field": "unrealizedGain",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Unrealized Gain",
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Cost Basis",
          "field": "costBasis",
          "minWidth": 70,
          "suppressSorting": true,
          "suppressMenu": true,
          "headerTooltip": "Cost Basis",
          "filter": "SelectFilterComponent",
          "hide": false
        }
      ]
    this.getDashBoardSummary();
  }

  changeContainerSize() {
    if (this.message) {
      this.mainContent = true;
    } else {
      this.mainContent = false;
    }

  }
  getDashBoardSummary() {
    this.baseService.getDashBoardSummary().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.dashboardSummary = response['responseData'];
        setTimeout(() => {
          this.gridApiRelationship.sizeColumnsToFit();
          this.gridApiAccount.sizeColumnsToFit();
        }, 50);

        // // Workaround for state reload
        let myVar = setInterval(() => {
          if ($('.ag-body-container').width() > 0) {

            let contWidth = $('.ag-body-viewport').width();
            let viewWidth = $('.ag-full-width-viewport-wrapper').width();
            let contHeight = $('.ag-body-viewport').height();
            let viewHeight = $('.ag-body-container').height();
            if (contWidth == viewWidth && viewHeight > contHeight+1) {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(["/dashboard"]));
            }
            if (viewHeight > 1)
              clearInterval(myVar);
          }
        }, 700)
      //   setTimeout(()=>{
      //     if ($('.ag-body-container').width() > 0) {

      //           let contWidth = $('.ag-body-viewport').width();
      //           let viewWidth = $('.ag-full-width-viewport-wrapper').width();
      //           let contHeight = $('.ag-body-viewport').height();
      //           let viewHeight = $('.ag-body-container').height();
      //           if (contWidth == viewWidth && viewHeight > contHeight+1) {
      //             this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      //               this.router.navigate(["/dashboard"]));
      //           }
      //   }
      // },900);

        /*this is to handle the appearance of selectoe on dom*/

        // watchAwaitSelector(element => {
        //   if (element[0].clientWidth>0 ) {
        //     let contWidth = $('.ag-body-viewport').width();
        //     let viewWidth = $('.ag-full-width-viewport-wrapper').width();
        //     let contHeight = $('.ag-body-viewport').height();
        //     let viewHeight = $('.ag-body-container').height();
        //     if (contWidth == viewWidth && viewHeight > contHeight + 1) {
        //       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //         this.router.navigate(["/dashboard"]));
        //     }
        //   }
        // }, '.ag-body-container')


        // this.message1();
        // let myVar = $('.ag-body-container').width() > 0 ? $('.ag-body-container') : null;
        // if (!myVar) {
        //   myVar.promise().done(function () {
        //     // if ($('.ag-body-container').width() > 0) {
        //     let contWidth = $('.ag-body-viewport').width();
        //     let viewWidth = $('.ag-full-width-viewport-wrapper').width();
        //     let contHeight = $('.ag-body-viewport').height();
        //     let viewHeight = $('.ag-body-container').height();
        //     if (contWidth == viewWidth && viewHeight > contHeight) {
        //       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //         this.router.navigate(["/dashboard"]));
        //     }
        //     // }
        //   });
        // }
        // await()
      }
    });


  }
  // scaryClown() {
  //   return new Promise(resolve => {

  //     let myVar = setInterval(() => {
  //       if ($('.ag-body-container').width() > 0) {
  //       } else {
  //         resolve('success');
  //         clearInterval(myVar);
  //       }

  //     }, 500);

  //   });
  // }

  // async message1() {
  //   let msg = await this.scaryClown();
  //   if (msg == "success") {
  //     let contWidth = $('.ag-body-viewport').width();
  //     let viewWidth = $('.ag-full-width-viewport-wrapper').width();
  //     let contHeight = $('.ag-body-viewport').height();
  //     let viewHeight = $('.ag-body-container').height();
  //     if (contWidth == viewWidth && viewHeight > contHeight+1) {
  //       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
  //         this.router.navigate(["/dashboard"]));
  //     }
  //   }
  //   console.log('Message:', msg);
  // }


  /* On grid ready */
  onGridReadyRelationship(params) {
    this.gridApiRelationship = params.api;
    params.api.sizeColumnsToFit();
  }
  onGridReadyAccount(params) {
    this.gridApiAccount = params.api;
    params.api.sizeColumnsToFit();
  }


  /* Goto workflow */
  gotoWorkflow(node) {
    if (node.processInstanceId) {
      localStorage.removeItem('workflowInfoData');
      let workflowInfoData = {
        processInstanceId: node.processInstanceId
      }
      if(node.proposalType == "MAX"){
        workflowInfoData["processDefinitionKey"] = "FINANCIAL_PLANNING_WORKFLOW";
        localStorage.setItem('workflowInfoData', JSON.stringify(workflowInfoData));
        this.router.navigate(['/proposalWorkflow']);
      }else{
        workflowInfoData["processDefinitionKey"] = "ALLOCATION_CALCULATOR";
        localStorage.setItem('workflowInfoData', JSON.stringify(workflowInfoData));
        this.router.navigate(['/maxCalculator']);
      }
    }
  }

}
