import { Component, OnInit } from '@angular/core';
import { Variables } from '../../../constants';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';
import { ProposalWorkflowComponent } from '../proposal-workflow.component';

@Component({
  selector: 'app-relationship-overview',
  templateUrl: './relationship-overview.component.html',
  styleUrls: ['./relationship-overview.component.less']
})
export class RelationshipOverviewComponent implements OnInit {
  relationshipOverviewDropdowns: any;
  apiParams : any;

  constructor(
    private workflowBaseService : WorkflowBaseService,
    private proposalWorkflowComponent : ProposalWorkflowComponent,

  ) { }
  tabView = 'investorInfo';
  selectedTab: string;
  ngOnInit() {
    this.selectedTab = 'investorInfo';
    this.getDropdownsData();
  }

  changeTab(tab) {
    this.selectedTab = tab;
  }

  onTabSelect(evt): void {
    if(evt.id)
      this.tabView = evt.id;
  }

  /* Get dropdowns data */
  getDropdownsData() {
    this.apiParams = {
      processInstanceId : this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      stepName : "relationshipOverview"
    };

    this.workflowBaseService.getEnumDataBasedOnStep(this.apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.relationshipOverviewDropdowns = data;
      }
    });
  }

}

