import { Injectable,Output,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ApiUrls } from '../../app/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfChartService {

  constructor(
    private http: HttpClient
  ) { }

  getSlideChartData(token,processInstaId,slideId){
    let httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Auth-Token': token
      })
    };
    return this.http.get(ApiUrls.getSlideChartDataAPI+"?processInstanceId=" + processInstaId + "&slideId=" + slideId,httpOptions)
    .pipe(
        response => response
    );
  }



  uploadChartData(token,processInstaId,formData){
    let httpOptions = {
      headers: new HttpHeaders({
          //'Content-Type': 'application/json'
          // 'X-Auth-Token': token
      })
    };

  const options = {} as any; // Set any options you like
    return this.http.post(ApiUrls.uploadChartImgAPI+"?X-Auth-Token=" + token,formData,options)
    .pipe(
        response => response
    );
  }

 
}
