import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { AnalysisStrategyComponent } from 'src/app/webPages/proposal-workflow/analysis-strategy/analysis-strategy.component';
import * as _ from "lodash";
@Component({
  selector: 'app-range-of-assets',
  templateUrl: './range-of-assets.component.html',
  styleUrls: ['./range-of-assets.component.less']
})
export class RangeOfAssetsComponent implements OnInit {

  listOfChartData: any;
  listOfModelValues: any;
  periodDisplay: any;
  listOfSeriesData: any = [];
  removeProposedGraph: boolean;
  rangesOfPeriod:any;
  changeInListData: any[];
  strategy1:any;
  strategy2:any;
  valueTruncate:any;
  timePeriod1: any;
  timePeriod2: any;
  timePeriod3: any;
  timePeriod4: any;

  constructor(
    private analysisStrategyComponent:AnalysisStrategyComponent
  ) { }
  listOfData = [];
  chartOption: EChartOption;

  @Input() public inputAnalysisData;

  ngOnChanges() {
    this.listOfChartData = [];
    this.listOfData = [];
    this.changeInListData=[];
    this.listOfModelValues = [];
    this.periodDisplay = [];
    this.strategy1=this.analysisStrategyComponent.selectedStrategy1['key'];
    this.strategy2=this.analysisStrategyComponent.selectedStrategy2['key'];
    if (this.inputAnalysisData) {
      // console.log(this.inputAnalysisData);
      // this.strategy1=this.analysisStrategyComponent.selectedStrategy1['key'];
      // this.strategy2=this.analysisStrategyComponent.selectedStrategy2['key'];
      this.removeProposedGraph=this.analysisStrategyComponent.flagForRemovingProposedColumn;
      if(!this.removeProposedGraph){
        this.valueTruncate=[4,'...'];
      }else{
        this.valueTruncate=[8,'...'];
      }
      this.listOfData = this.inputAnalysisData.rangesDataRowList;
      //this.changeInListData=Object.assign({},this.listOfData);
      this.changeInListData=_.cloneDeep(this.listOfData);
      this.rangesOfPeriod=this.inputAnalysisData.rangesHeader;
      this.timePeriod1=this.rangesOfPeriod.period1;
      this.timePeriod2=this.rangesOfPeriod.period2;
      this.timePeriod3=this.rangesOfPeriod.period3;
      this.timePeriod4=this.rangesOfPeriod.period4;
      this.listOfChartData = this.inputAnalysisData.chartData;
      this.listOfModelValues = this.listOfChartData.modelValues;
      this.periodDisplay = this.listOfChartData.xValues;
    
    this.listOfSeriesData = [];
    var k = 0;
    while (k < this.listOfChartData.labels.length) {
      var listOFLabelsWiseData = [];
      for (let i = 0; i < this.listOfChartData.xValues.length; i++) {
        var listOfStrategyData = [];
        if(this.listOfModelValues[k].averageReturnList){
          listOfStrategyData.push(this.listOfModelValues[k].averageReturnList[i]);
          listOfStrategyData.push(this.listOfModelValues[k].bestReturnList[i]);
          listOfStrategyData.push(this.listOfModelValues[k].secondBestReturnList[i]);
          listOfStrategyData.push(this.listOfModelValues[k].secondWorstReturnList[i]);
          listOfStrategyData.push(this.listOfModelValues[k].worstReturnList[i]);
          listOFLabelsWiseData.push(listOfStrategyData);
        }
        
      }
      this.listOfSeriesData.push(listOFLabelsWiseData);
      k = k + 1;
    }
    console.log(this.listOfSeriesData);
    this.setDataToCandelChart();
    }
  
  }
  setDataToCandelChart() {
    
    var seriesData = [];
    var default_color_light=['#cf4440','#3a607a','#76b9c2','#fa8b64'];
    var default_color=['#c23531','#2f4554','#61a0a8', '#d48265'];
    for (let i = 0; i < this.listOfSeriesData.length; i++) {
      var seriesObject = {};
      var itemStyle={};
      seriesObject['name'] = this.listOfChartData.labels[i];
      seriesObject['type'] = 'boxplot';
      seriesObject['data'] = this.listOfSeriesData[i];
      itemStyle['color']=default_color_light[i];
      itemStyle['borderColor']=default_color[i];
      seriesObject['itemStyle']=itemStyle;
      seriesData.push(seriesObject);

    }
  
    // console.log(seriesData);
    this.chartOption = {
      title: {
        text: 'Range Of Assets ( $ )',
        left: 'center',
      },
      toolbox: {
        show: true

      },
      legend: {
        y: "95%",
        data: this.listOfChartData.labels,
        left: true
      },
      tooltip: {
        trigger: 'item',
        // formatter: function (params) {
        //   var res = params[0].name;
        //   res += '<br/> opening :' + params[0].value[0] + ' highest :' + params[0].value[3];
        //   res += '<br/>  close :' + params[0].value[1] + 'lowest : ' + params[0].value[2];
        //   return res;
        // }
        axisPointer:{
          type:"shadow"
        }
      },
      xAxis: {
        type: 'category',
        data: this.listOfChartData.xValues,
        splitArea:{
          show:false
        }
      },
      yAxis: {
        type: 'value',
        name: '( Based on After Tax Returns )',
        splitLine: {
          show: false
        },
        axisLabel:{
          formatter:"${value}"
      }
        //boundaryGap: [0.01, 0.01]
      },
      series: seriesData
    }
  }
   
  /*changes as per the event on selection over the legend*/

  onChartEvent(event: any) {
    // this.strategy1=this.analysisStrategyComponent.selectedStrategy1['key'];
    // this.strategy2=this.analysisStrategyComponent.selectedStrategy2['key'];
    // console.log(this.listOfChartData.labels);
    // console.log(event.selected);
    // console.log(this.listOfData);
    // console.log(this.changeInListData);
    // if(!event.selected['Current']){
    //   this.changeInListData.forEach(element => {
    //     element.period1.current='';
    //     element.period2.current='';
    //     element.period3.current='';
    //     element.period4.current='';
    //     });
      
    // }else{
    //   for(let i=0;i<this.changeInListData.length;i++){
    //     this.changeInListData[i].period1.current=this.listOfData[i].period1.current;
    //     this.changeInListData[i].period2.current=this.listOfData[i].period2.current;
    //     this.changeInListData[i].period3.current=this.listOfData[i].period3.current;
    //     this.changeInListData[i].period4.current=this.listOfData[i].period4.current;
    //   }
    // }
    // if (!this.removeProposedGraph) {
    //   if (!event.selected['Proposed']) {
    //     this.changeInListData.forEach(element => {
    //       element.period1.proposed = '';
    //       element.period2.proposed = '';
    //       element.period3.proposed = '';
    //       element.period4.proposed = '';
    //     });

    //   } else {
    //     for (let i = 0; i < this.changeInListData.length; i++) {
    //       this.changeInListData[i].period1.proposed = this.listOfData[i].period1.proposed;
    //       this.changeInListData[i].period2.proposed = this.listOfData[i].period2.proposed;
    //       this.changeInListData[i].period3.proposed = this.listOfData[i].period3.proposed;
    //       this.changeInListData[i].period4.proposed = this.listOfData[i].period4.proposed;
    //     }
    //   }
    // }
    // if(!event.selected['Strategy 1']){
    //   this.changeInListData.forEach(element => {
    //     element.period1.strategy1='';
    //     element.period2.strategy1='';
    //     element.period3.strategy1='';
    //     element.period4.strategy1='';
    //     });
      
    // }else{
    //   for(let i=0;i<this.changeInListData.length;i++){
    //     this.changeInListData[i].period1.strategy1=this.listOfData[i].period1.strategy1;
    //     this.changeInListData[i].period2.strategy1=this.listOfData[i].period2.strategy1;
    //     this.changeInListData[i].period3.strategy1=this.listOfData[i].period3.strategy1;
    //     this.changeInListData[i].period4.strategy1=this.listOfData[i].period4.strategy1;
    //   }
    // }
    // if(!event.selected['Strategy 2']){
    //   this.changeInListData.forEach(element => {
    //     element.period1.strategy2='';
    //     element.period2.strategy2='';
    //     element.period3.strategy2='';
    //     element.period4.strategy2='';
    //     });
      
    // }else{
    //   for(let i=0;i<this.changeInListData.length;i++){
    //     this.changeInListData[i].period1.strategy2=this.listOfData[i].period1.strategy2;
    //     this.changeInListData[i].period2.strategy2=this.listOfData[i].period2.strategy2;
    //     this.changeInListData[i].period3.strategy2=this.listOfData[i].period3.strategy2;
    //     this.changeInListData[i].period4.strategy2=this.listOfData[i].period4.strategy2;
    //   }
    // }
    for(let key in event.selected){
      var value=event.selected[key];
      key=key.toLowerCase();
      if(!value){
        this.setValueForChartReflection(key);
      }else{
        this.revertSetValueForChartReflection(key);
      }
    }
  }
  
  setValueForChartReflection(key){
    if(key!=this.strategy1.toLowerCase() && key!=this.strategy2.toLowerCase()){
      this.changeInListData.forEach(element => {
            element.period1[key]='';
            element.period2[key]='';
            element.period3[key]='';
            element.period4[key]='';
            });
    }else if(key==this.strategy1.toLowerCase()){
      this.changeInListData.forEach(element => {
            element.period1.strategy1='';
            element.period2.strategy1='';
            element.period3.strategy1='';
            element.period4.strategy1='';
            });
    }else if(key== this.strategy2.toLowerCase()){
      this.changeInListData.forEach(element => {
            element.period1.strategy2='';
            element.period2.strategy2='';
            element.period3.strategy2='';
            element.period4.strategy2='';
            });
    }

  }

  revertSetValueForChartReflection(key){
    if(key!=this.strategy1.toLowerCase() && key!=this.strategy2.toLowerCase()){
      for(let i=0;i<this.changeInListData.length;i++){
            this.changeInListData[i].period1[key]=this.listOfData[i].period1[key];
            this.changeInListData[i].period2[key]=this.listOfData[i].period2[key];
            this.changeInListData[i].period3[key]=this.listOfData[i].period3[key];
            this.changeInListData[i].period4[key]=this.listOfData[i].period4[key];
          }
    }else if(key==this.strategy1.toLowerCase()){
      for(let i=0;i<this.changeInListData.length;i++){
            this.changeInListData[i].period1.strategy1=this.listOfData[i].period1.strategy1;
            this.changeInListData[i].period2.strategy1=this.listOfData[i].period2.strategy1;
            this.changeInListData[i].period3.strategy1=this.listOfData[i].period3.strategy1;
            this.changeInListData[i].period4.strategy1=this.listOfData[i].period4.strategy1;
          }
    }else if(key== this.strategy2.toLowerCase()){
      for(let i=0;i<this.changeInListData.length;i++){
            this.changeInListData[i].period1.strategy2=this.listOfData[i].period1.strategy2;
            this.changeInListData[i].period2.strategy2=this.listOfData[i].period2.strategy2;
            this.changeInListData[i].period3.strategy2=this.listOfData[i].period3.strategy2;
            this.changeInListData[i].period4.strategy2=this.listOfData[i].period4.strategy2;
          }
    }

  }
  
  ngOnInit() {
    
  }


}
