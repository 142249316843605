import { Component, OnInit } from '@angular/core';
import { WorkflowBaseService } from "../../../../_services/workflow-services/workflow-base.service";
import { MaxCalculatorMainComponent } from '../../max-calculator-main/max-calculator-main.component';
import { Variables } from '../../../../constants';
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { CommonFunctions } from '../../../../common';
import { DatePipe } from '@angular/common';
import { LeftNavService } from 'src/app/_services/left-nav.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { StrategySelectionModel } from 'src/app/_models/strategy-selection.model';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { EChartOption } from 'echarts';
import { element } from '@angular/core/src/render3/instructions';

declare var _: any;
@Component({
  selector: 'app-cal-allocation',
  templateUrl: './cal-allocation.component.html',
  styleUrls: ['./cal-allocation.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class CalAllocationComponent implements OnInit {

  constructor(
    private workflowBaseService: WorkflowBaseService,
    private leftNavData: LeftNavService,
    private maxCalculatorMainComponent : MaxCalculatorMainComponent,
    private datePipe: DatePipe,
    private authMessageService: AuthMessageService,
  ) { 
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 2);
  }
  maxDate;
  strategyInfo = new StrategySelectionModel();
  primaryInfo;
  currentAllocationList = [];
  allocationDropdowns;
  totalCurrentAmount=0;
  totalCurrentPerc='0';
  assetClassExclusionObject:any={};
  chartOption: EChartOption;
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  commonFunction = new CommonFunctions();
  chartLabels;
  apiParams;
  essestialDataLoaded=false;
  chartData;

  ngOnInit() {
    this.dpConfig.containerClass = "theme-blue";
    this.primaryInfo={};
    this.primaryInfo.taxStatus = "null";
    this.allocationDropdowns={};
    this.assetClassExclusionObject={};
    
    var wait = setInterval(()=>{
      if(this.maxCalculatorMainComponent.workflowInfoData && this.maxCalculatorMainComponent.workflowInfoData.processInstanceId){
        clearInterval(wait)
        this.getAssetClassExclusionInfo();
        this.getDropdownData();
        this.leftNavData.currentMessage.subscribe(message =>{
          if(this.maxCalculatorMainComponent.workflowInfoData.processVariables)
          setTimeout(() => {
            this.getCurrentStrategiesAAC()
          }, 1500);
            
        }); 
      }
    },500);
    
  }

  ngAfterViewInit(){

  }

  getDropdownData() {
    this.apiParams = {
      processInstanceId: this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      stepName: "proposalSetup"
    };
    this.workflowBaseService.getEnumDataBasedOnStep(this.apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.allocationDropdowns = response['responseData'];
        this.allocationDropdowns['assetClassDataForDropdown'] = [];
        console.log(this.allocationDropdowns);
        if (this.allocationDropdowns.assetClass) {
          this.allocationDropdowns.assetClass.forEach(element => {
            element.isHeader = true;
            element.displayName = element.name;
            this.allocationDropdowns['assetClassDataForDropdown'].push(element);
            if (element.assetSubClassVO) {
              element.assetSubClassVO.forEach(item => {
                item.isHeader = false;
                item.isParentId = element.objectId;
                item.displayName = '- - ' + item.name;
                this.allocationDropdowns['assetClassDataForDropdown'].push(item);
              });
            }
          });
        }
       // this.getCurrentStrategiesAAC();
        this.getProposalStepAllocationData();
      }

    });
  }

  createCurrentAllocationList(){

    
    
    if (this.allocationDropdowns.assetClass) {
      this.allocationDropdowns.assetClass.forEach(element => {
        let asset = _.find(this.currentAllocationList, function (item) {
          return item.objectId == element.objectId;
        });
        if(!asset){
          let assetObj = element;
        assetObj["isHeader"] = true;
        assetObj["amount"]   = 0;
        assetObj["percentage"]   = 0; 
        this.currentAllocationList.push(assetObj);
        }
        else{
          asset.color = element.color;
        }
        

        
      });
    }

    this.prepareChartData();
    
  }

  

  currentAllocationListCalculator(){
    let lastHeaderIndex = 0,sumTillNow=0,grandTotal = 0,grandTotalPercentage=0;
    for(let i=0;i<this.currentAllocationList.length; i++ ){
      if(this.currentAllocationList[i].isHeader){
        this.currentAllocationList[lastHeaderIndex].amount = sumTillNow;
        grandTotal = grandTotal + sumTillNow;

        sumTillNow = 0;
        lastHeaderIndex = i;
      }
        
      else{
        if(this.currentAllocationList[i].amount){
          this.currentAllocationList[i].amount = this.currentAllocationList[i].amount.replace( /,/g, "" )
          sumTillNow  = sumTillNow + parseFloat(this.currentAllocationList[i].amount);
        }
          
      }
    }
    this.currentAllocationList[lastHeaderIndex].amount = sumTillNow;
    grandTotal = grandTotal + sumTillNow;
    this.totalCurrentAmount = grandTotal;

    for(let i=0;i<this.currentAllocationList.length; i++ ){
      if(this.currentAllocationList[i].isHeader){
        if(grandTotal){
          grandTotalPercentage = grandTotalPercentage + (this.currentAllocationList[i].amount/grandTotal)*100;
          this.currentAllocationList[i].percentage = ((this.currentAllocationList[i].amount/grandTotal)*100).toFixed(2);
        }else{
          this.currentAllocationList[i].percentage = 0;
        }
        
        lastHeaderIndex = i;
      }
      else{
        if(grandTotal)
         this.currentAllocationList[i].percentage = ((this.currentAllocationList[i].amount/grandTotal)*100).toFixed(2);
        else{
          this.currentAllocationList[i].percentage = 0;
        }
        }
    }
    this.totalCurrentPerc = grandTotalPercentage.toFixed(2);

    this.currentAllocationList.forEach(item=>{
      if(!item.isHeader){
        if(item.amount)
         item.amount = parseFloat(item.amount).toLocaleString();
        
      }
    })
    //this.addCurrentStrategies();
  }

  addAssetClass(){
    let assetClassToAdd = this.strategyInfo.assetClass;


    if(!assetClassToAdd){

      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please Select Asset class';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;

    }

    let assetExist = _.find(this.currentAllocationList, function (item) {
      return item.objectId == assetClassToAdd["objectId"];
    });

    

    if(assetExist){

      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Asset class already added';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;

    }

   

    if(assetClassToAdd){
      let assetParent = _.find(this.currentAllocationList, function (item) {
        return item.objectId == assetClassToAdd["isParentId"];
      });
      if(assetParent){
        let index = this.currentAllocationList.indexOf(assetParent);
        assetClassToAdd["amount"]   = 0;
        assetClassToAdd["percentage"]   = 0; 
        this.currentAllocationList.splice(index+1,0,assetClassToAdd);
      }
    }
  }

  getAssetClassExclusionInfo() {
    let requestObj = {
      "processInstanceId": this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "proposalSetup"
    };
    this.workflowBaseService.getAssetClassExclusionInfo(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if(response['responseData'][this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID]){
          this.assetClassExclusionObject = response['responseData'][this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID];
        }else if(response['responseData'].portfolioId){
          this.assetClassExclusionObject = response['responseData'].portfolioId;
        }
      }
      this.adjustHeightViewport(50)
    });
  }

  adjustHeightViewport(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let tab = $('.proposal-setup-tabs')[0].clientHeight;
    let footer = $('.footer')[0].clientHeight;
    //let margin = 155+ extraMargin;
    var total = header+ pageheader + stepHeader+ tab + footer;
    let ele = document.getElementsByClassName('proposal-setup-tab-main')[0];
    if(ele) {
      ele.setAttribute('style',"height: calc(88vh - "+total+"px);")
    }

    let mainDivHeight = $('.proposal-setup-tab-main')[0].clientHeight;
    let rightHeader = $('.allocation-current-heading')[0].clientHeight;
    let rightFooter = $('.allocation-current-total')[0].clientHeight;
    let rightButtons = $('.unassigned-positions-button')[0].clientHeight;
    let rightTableHeight = mainDivHeight - (rightHeader+rightFooter+rightButtons);
    let ele2 = document.getElementsByClassName('set-current-allocation')[0];
    if(ele2) {
      ele2.setAttribute('style',"height: calc("+rightTableHeight+"px - 5vh);");
    }

    setTimeout(() => {
        let leftFirstDiv = $('.first-left-div')[0].clientHeight;
        let leftSecondDiv = $('.second-left-row')[0].clientHeight;
        let chartHeading = $('.current-chart-heading')[0].clientHeight;
        let leftTableHeight = mainDivHeight - (leftFirstDiv+leftSecondDiv+chartHeading);
        let ele3 = document.getElementsByClassName('current-allocation-summary')[0];
        if(ele3) {
          ele3.setAttribute('style',"height: calc("+leftTableHeight+"px - 7vh);");
        }
      },1000);

    
  }

  convertToCommaSeperated(row,key){
    let value = row[key];
    var partBeforeDecimal = '',partAfterDecimal='';
    var index = value.indexOf('.')
    if(index>-1){
      partBeforeDecimal = value.substr(0,index);
      partAfterDecimal = value.substr(index,value.length-index);
    }else {
      partBeforeDecimal = value;
    }

    if(partBeforeDecimal){
      partBeforeDecimal = partBeforeDecimal.replace( /,/g, "" );
      row[key] = (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
    }

  }

  saveAssetClassExclusionInfo() {
    let portfolio = this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID 
    let requestObj = {
      "processInstanceId": this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "proposalSetup",
      "portfolioIdVsAssetClassExclusionMap" : {}
    }

    requestObj.portfolioIdVsAssetClassExclusionMap[portfolio] = {
      "portfolioObjectId" : this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID,
      "assetClassExclusionVOs" : this.assetClassExclusionObject["assetClassExclusionVOs"],
      "cashAllocation"   :  this.assetClassExclusionObject["cashAllocation"]
    }
    this.workflowBaseService.saveAssetClassExclusionInfo(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if(response['responseData'].portfolioId){
          this.assetClassExclusionObject = response['responseData'].portfolioId;
        }
        this.authMessageService.showSuccessPopup(response);
      }
    });
  }
  

  getCurrentStrategiesAAC() { 
    let requestObj = {
      "portfolioId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID,
      "processInstanceVO":{
        "processInstanceId" :this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
        "processDefinitionId":"PROPOSAL_SETUP"
      }
    };
    this.workflowBaseService.getCurrentStrategiesAAC(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        let grandTotal = 0,grandTotalPerc=0;
        if(response['responseData']){ 
          
          response['responseData'].forEach(element => {
            let assetObj = element;
            assetObj["isHeader"] = true;
            assetObj["name"] = element.assetClassName;
            assetObj["objectId"] = element.assetClassId;
            assetObj["amount"]   = element.currentAmountValue;
            grandTotal = grandTotal + element.currentAmountValue;
            grandTotalPerc = grandTotalPerc + element.currentAmountPercentage;
            assetObj["percentage"]   = element.currentAmountPercentage.toFixed(2); 
            this.currentAllocationList.push(assetObj);
              element.strategySelectionInfoVOChild.forEach(element2 => {
                let assetObj = element2;
                assetObj["isHeader"] = false;
                assetObj["name"] = element2.assetClassName;
                assetObj["objectId"] = element2.assetClassId;
                if(element2.currentAmountValue)
                  assetObj["amount"]   = element2.currentAmountValue.toLocaleString();
                assetObj["percentage"]   = element2.currentAmountPercentage.toFixed(2); 
                this.currentAllocationList.push(assetObj);
              });
          });
          
        }
        this.totalCurrentAmount = grandTotal;
        this.totalCurrentPerc = grandTotalPerc.toFixed(2);
        this.createCurrentAllocationList();
      }
    });
  }

  calGetProposalStepAllocationData() { 
    let requestObj = {
      "portfolioId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID,
      "accId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.accountId,
      "processInstanceVO":{
        "processInstanceId" :this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
        "processDefinitionId":"PROPOSAL_SETUP"
      }
    };
    this.workflowBaseService.getProposalStepAllocationData(requestObj).subscribe((response) => {
     // this.prepareChartData();
    })
  }

  getProposalStepAllocationData() { 
    let requestObj = {
      "portfolioId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.portfolioID,
      "accId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.accountId,
      "processInstanceVO":{
        "processInstanceId" :this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
        "processDefinitionId":"PROPOSAL_SETUP"
      }
    };
    this.workflowBaseService.getProposalStepAllocationData(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        let grandTotal = 0, grandTotalPerc = 0;
        if(response['responseData']){ 
          console.log(response['responseData']);
          this.currentAllocationList = [];
          this.primaryInfo.taxStatus = response['responseData']['taxStatus'];
          this.primaryInfo.projectionPeriod = response['responseData']['projectionPeriod'];
          this.primaryInfo.dateOfBirth = response['responseData']['dateOfBirth'];

          response['responseData']['strategyProposedAllocationVO'].forEach(element => {
            let assetObj = element;
            assetObj["isHeader"] = true;
            assetObj["name"] = element.assetClassName;
            assetObj["objectId"] = element.assetClassId;
            assetObj["amount"]   = element.currentAmountValue;
            grandTotal = grandTotal + element.currentAmountValue;
            grandTotalPerc = grandTotalPerc + element.currentAmountPercentage;
            assetObj["percentage"]   = element.currentAmountPercentage.toFixed(2); 
            this.currentAllocationList.push(assetObj);
              element.strategySelectionInfoVOChild.forEach(element2 => {
                let assetObj = element2;
                assetObj["isHeader"] = false;
                assetObj["name"] = element2.assetClassName;
                assetObj["objectId"] = element2.assetClassId;
                assetObj["isSaved"]   = true;
                if(element2.currentAmountValue)
                  assetObj["amount"]   = element2.currentAmountValue.toLocaleString();
                assetObj["percentage"]   = element2.currentAmountPercentage.toFixed(2); 
                this.currentAllocationList.push(assetObj);
              });

          });

          
        }
        this.totalCurrentAmount = grandTotal;
        this.totalCurrentPerc  = grandTotalPerc.toFixed(2);
        this.createCurrentAllocationList();
        this.essestialDataLoaded = true;
      }
    });
  }

  addCurrentStrategies() {
    let strategyCurrentAllocationVOList = [];
    this.currentAllocationList.forEach(item=>{
      if(!item.isHeader){
        item.assetId = item.objectId;
        if(item.amount)
          item.amount = item.amount.replace( /,/g, "" );
        item.currentAmountValue = parseFloat(item.amount);
        strategyCurrentAllocationVOList.push(item);
      }
    })

    let requestObj = {
      "accId": this.maxCalculatorMainComponent.workflowInfoData.processVariables.accountId,
      "processInstanceVO":{
        "processInstanceId" :this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
        "processDefinitionId":"PROPOSAL_SETUP"
      },
      "strategyCurrentAllocationVO" : strategyCurrentAllocationVOList
    };
    this.workflowBaseService.addCurrentStrategies(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
          this.getProposalStepAllocationData();
      }
    });
  }

  updateProjectionPeriod() {
    if(!this.primaryInfo.projectionPeriod){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter projection period';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    this.workflowBaseService.updateProjectionPeriod(this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,this.primaryInfo.projectionPeriod).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
      }
    });
  }

  deleteCurrentStrategies(isReset) {
    let requestObj = {};
    if(!isReset){
      let assetClassIdList = [],unsavedAssetClassIds=[];
      this.currentAllocationList.forEach((element,index)=>{
        if(element.isSelected && element.isSaved)
          assetClassIdList.push(element.assetClassId);
        else if(element.isSelected){
          unsavedAssetClassIds.push(element.objectId);
        }
        // if(element.isSelected)
        //   this.currentAllocationList.splice(index,1);
      })
      assetClassIdList.forEach(item=>{
        _.remove(this.currentAllocationList, function (n) {
          return n.assetClassId == item;
        });
      })
      unsavedAssetClassIds.forEach(item=>{
        _.remove(this.currentAllocationList, function (n) {
          return n.objectId == item;
        });
      })
      


      if(!assetClassIdList.length && !unsavedAssetClassIds.length){
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select asset class for removing';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }

      
    requestObj['assetClassId'] = assetClassIdList,
    requestObj["processInstanceId"]= this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
    requestObj["acId"]= this.maxCalculatorMainComponent.workflowInfoData.processVariables.accountId
     
    }else{
      requestObj['processInstanceId'] = [],
      requestObj["processInstanceId"] =  this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,
      requestObj["acId"] = this.maxCalculatorMainComponent.workflowInfoData.processVariables.accountId,
      requestObj["deleteAll"] = true
    }
    
    this.currentAllocationListCalculator();
    this.workflowBaseService.deleteCurrentStrategies(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        if(isReset)
          this.getProposalStepAllocationData();
        else
          this.calGetProposalStepAllocationData();
      }
    });
  }

  updateAccountTaxStatus() {
    if(!this.primaryInfo.taxStatus || this.primaryInfo.taxStatus == 'null'){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please Select tax status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if(this.primaryInfo.taxStatus == 'TAX_DEFERRED' && !this.primaryInfo.dateOfBirth){
      let errorObj = {};
      // errorObj['errorCode'] = 'ERR0107';
      // errorObj['message'] = 'Date of Birth cannot be blank for Tax Deferred Account';
      // errorObj['errorDescription'] = '';
      // this.authMessageService.showErrorPopup(errorObj);
      return false;
    }

    
       let tempDate = this.datePipe.transform(this.primaryInfo.dateOfBirth, 'MM/dd/yyyy');
      this.workflowBaseService.updateAccountTaxStatus(this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,this.primaryInfo.taxStatus,tempDate).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
      }
    });
  }

  updateDateOfBirth() {
    if(this.primaryInfo.taxStatus == 'TAX_DEFERRED' && !this.primaryInfo.dateOfBirth){
      let errorObj = {};
      // errorObj['errorCode'] = 'ERR0107';
      // errorObj['message'] = 'Date of Birth cannot be blank for Tax Deferred Account';
      // errorObj['errorDescription'] = '';
      // this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    
       let tempDate = this.datePipe.transform(this.primaryInfo.dateOfBirth, 'MM/dd/yyyy');
      this.workflowBaseService.updateAccountTaxStatus(this.maxCalculatorMainComponent.workflowInfoData.processInstanceId,this.primaryInfo.taxStatus,tempDate).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        let successObj = {};
        successObj['message'] = "Date of birth updated successfully";
        this.authMessageService.showSuccessPopup(successObj);
      }
    });
  }

  prepareChartData() {
    this.chartLabels = [];
    this.chartData = [];
    if (true) {
      var obj;
      this.currentAllocationList.forEach(element=>{
        if(element.isHeader && element.amount){
            this.chartLabels.push(element.name);
            this.chartData.push({
            name : element.name,
            value : element.amount.toFixed(2),
            itemStyle: {
              color: element.color
            }
          })
        }
        
      })
      this.chartOption = {
        tooltip: {
          trigger: 'item',
          formatter: "{b} : {c}<br> {d}%",
          confine: true
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          // right: 10,
          // top: 10,
          // bottom: 20,
          // height: 80,
          bottom: 5,
          left: 'center',
          data: this.chartLabels,
          formatter: function (name) {
            if (name.length > 25)
              return name.substring(0, 20) + '...'
            else
              return name;
          },
        },
        series: [
          {
            type: 'pie',
            radius: '62%',
            center: ['35%', '43%'],
            data: this.chartData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 2,
                borderColor: "#fff"
              },
            },
            label: {
              show: false
            }
          }
        ]
      };
    }
  }

}
