import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiUrls } from '../constants';
import { FilterModel } from '../_models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class DataloadConfigurationService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };

  public filterVO = new FilterModel();

constructor(private http: HttpClient) { }



getAllDataloadConfig(){

  return this.http.get(ApiUrls.getAllDataloadConfigAPI , this.httpOptions )
  .pipe(
      response => response
  );
}
uploadData(formData){
  const options = {} as any; // Set any options you like
  return this.http.post(ApiUrls.uploadDataAPI, formData , options )
  .pipe(
      response => response
  );
}

downloadData(docId,isxlsx){

  return this.http.get(ApiUrls.downloadDataAPI + '?dataStoreId=' + docId + '&isXLS=' + isxlsx, this.httpOptions)
  .pipe(
      response => response
  );
}

downloadTemplate(docId,isxlsx){

  return this.http.get(ApiUrls.downloadTemplateAPI + '?dataStoreId=' + docId  + '&isXLS=' + isxlsx, this.httpOptions)
  .pipe(
      response => response
  );
}
}
