import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Variables } from '../../constants';
import { AuthenticationService } from '../../_services/authentication.service';
import * as $ from 'jquery';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  loggedInUser = localStorage.getItem('userName');

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
  }

  /* Loading Settings Page */
  loadSetting() {
    this.router.navigate(['/setting']);
  }

  ngAfterViewInit() {
    let count = 0;
    let checkRightNavElement = setInterval(() => {
      if ($("#rightNavElement") && $("#header")) {
        setTimeout(()=>{
          $("#rightNavElement").css("top", $("#header")[0].clientHeight);
        },500);
        clearInterval(checkRightNavElement);
      } else if (count == 5) {
        clearInterval(checkRightNavElement);
      }
      count++;
    }, 500);

  }


  /* Logout */
  logout() {
    this.authenticationService.logout().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        //localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

}
