export class PortfolioModel {
    dataStoreId: number;
    processInstanceId: string;
    name: string;
    purposeCode: string= null;
    note:string;
    baseCurrency:string='USD';
    description:string;
    inceptionDate:string;
    profilioId:string;
}
