import { Component, OnInit, ViewChild } from '@angular/core';
import { Variables } from '../../constants';
import { BaseService } from '../../_services/base.service';
import { RoutingStateService } from '../../_services/routing-state.service';
import { SharedService } from '../../_services/shared.service';
import { LeftNavigationComponent } from '../left-navigation/left-navigation.component';
import * as less from 'less'; 

@Component({
  selector: 'app-root',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.less']
})
export class BaseComponent implements OnInit {
  currentActivePageUrl: string;
  constructor(
    private baseService: BaseService,
    private routingStateService: RoutingStateService,
    private sharedService: SharedService
  ) {
    routingStateService.loadRouting();
    this.sharedService.routeChange.subscribe((data) => {
      this.currentActivePageUrl = data.split('?')[0];
    });
  }
  subDomain:any;

  ngOnInit() {
    /* Set subdomain for theme info */
    var currentURL = window.location.href;
    var refinedURL = currentURL.split("//")[1];
    this.subDomain = refinedURL.split(".")[0];
    if (this.subDomain == 'www') {
      this.subDomain = refinedURL.split(".")[1];
    }
    localStorage.setItem('subDomain', this.subDomain);

    /* Get theme data */
    this.loadAppTheme();

  }

  /* For loading theme data */
  loadAppTheme() {
    less.modifyVars({ 'tenant': this.subDomain });
    // less.refreshStyles();
    // less.refresh(true);

    // this.baseService.getThemeData().subscribe((response) => {

    //   if(response['responseType'] == Variables.statusSuccess){
    //     if(this.initializeThemeData(response) == true){

    //       //TODO: load less file Once the data is loaded from backend
    //     }
    //   }
    // });
  }

  /* Fo Initializing theme */
  initializeThemeData(themeData) {
    for (let resource of themeData.responseData) {
      window[resource.code] = resource.codeValue;
      // if(resource.code == '$es_logo') {
      // 	baseService.setImageVariables(resource); //Set Image Variables
      // }
      // if(resource.code == '$es_appBaseFontSize') {
      // 	resource.codeValue = setFontSize();
      // 	setTimeout(function () {
      // 		es_breadCrumbsHeight = parseInt(resource.codeValue) + 15 + 5; //15 is padding(5+5 in px) + margin(5px) in Bread Crumbs div
      // 		basicContentHeight = windowHeight - es_headerHeight - es_contentPanelTopPadding - es_contentPanelBottomPadding - es_breadCrumbsHeight - ($('.footer-main-container').height());
      // 	}, 500);
      // }
      // if(resource.code == '$es_faviconIcon') {
      // 	var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      // 	link.type = 'image/x-icon';
      // 	link.rel = 'shortcut icon';
      // 	link.href = resource.codeValue;
      // 	document.getElementsByTagName('head')[0].appendChild(link);
      // }
    };
    return true;
  }
}
