import { Component, OnInit, ApplicationRef } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthMessageService } from '../../../../../_services/auth-message.service';
import { PortfolioConstructionService } from '../../../../../_services/workflow-services/portfolio-construction.service';
import { WorkflowBaseService } from '../../../../../_services/workflow-services/workflow-base.service';
import { PortfolioConstructionComponent } from '../../portfolio-construction.component';
import { ProposalWorkflowComponent } from '../../../proposal-workflow.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Variables } from '../../../../../constants';
import { CustomCompileService } from 'src/app/_services/custom-compile.service';
import { HierarchyRowTemplate } from 'src/app/shared/custom-grid-row-templates/hierarchy-row';



@Component({
  selector: 'app-buy-sell-summary',
  templateUrl: './buy-sell-summary.component.html',
  styleUrls: ['./buy-sell-summary.component.less']
})
export class BuySellSummaryComponent implements OnInit {
  selectedViewType: String = null;
  viewSelectedItems = [];
  portfolioSelectedItems = [];
  accountSelectedItems = [];
  enablePortfolio: Boolean = false;
  enableAccount: Boolean = false;
  portfolioDropdownSettings: IDropdownSettings;
  accountDropdownSettings: IDropdownSettings;
  viewDropdownSettings: IDropdownSettings;
  viewTypeData = [];
  buySummaryColumnDefs: any;
  sellSummaryColumnDefs: any;
  taxSummaryColumnDefs: any;
  portfolioData = [];
  accountData = [];
  componentReference = {};
  frameworkComponents: any;
  gridApi: any;
  gridColumnApi: any;
  buySellSectionData = [];


  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private portfolioConstructionService: PortfolioConstructionService,
    private authMessageService: AuthMessageService,
    private workflowBaseService: WorkflowBaseService,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
    private portfolioConstructionComponent: PortfolioConstructionComponent,
    public compileService: CustomCompileService,
    private appRef: ApplicationRef
  ) {
    {
      compileService.configure(this.appRef);
      this.componentReference = { componentParent: this };
    };
    this.frameworkComponents = {
      HierarchyRowTemplate: HierarchyRowTemplate
    };

  }

  ngOnInit() {
    this.getBuySellSummaryData('Consolidate', []);
    this.viewTypeData = [{ item_id: 'Consolidate', item_text: 'Consolidated' },
    { item_id: 'Portfolio', item_text: 'Portfolio' },
    { item_id: 'Account', item_text: 'Account' }
    ];
    this.viewSelectedItems = [{ item_id: 'Consolidate', item_text: 'Consolidated' }];
    this.portfolioDropdownSettings = {
      singleSelection: false,
      idField: 'item_id_portfolio',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      maxHeight: 150
    };
    this.accountDropdownSettings = {
      singleSelection: false,
      idField: 'item_id_account',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      maxHeight: 150
    };
    this.viewDropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      maxHeight: 150,
      closeDropDownOnSelection: true
    };
    this.buySummaryColumnDefs = [
      {
        "headerName": "Investment name",
        "field": "name",
        "minWidth": 175,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "name",
        "cellRenderer": "HierarchyRowTemplate"
      },
      {
        "headerName": "Amount",
        "field": "investmentAmountStr",
        "minWidth": 165,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "investmentAmount",
      },
      {
        "headerName": "Ticker",
        "field": "ticker",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "ticker"
      },
      {
        "headerName": "Cusip",
        "field": "cusip",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "cusip"
      },
      {
        "headerName": "Unit/Share",
        "field": "units",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "units"
      }
    ];

    this.taxSummaryColumnDefs = [
      {
        "headerName": "Name",
        "field": "name",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "name"
      },
      {
        "headerName": "Value",
        "field": "valueStr",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "value"
      }
    ];
    this.sellSummaryColumnDefs = [
      {
        "headerName": "Investment name",
        "field": "name",
        "minWidth": 175,
        "suppressMenu": true,
        "suppressSorting": false,
        "cellRenderer": "HierarchyRowTemplate",
        "backendFieldName": "name"
      },
      {
        "headerName": "Amount",
        "field": "investmentAmountStr",
        "minWidth": 165,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "investmentAmount",
      },
      {
        "headerName": "Ticker",
        "field": "ticker",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "ticker"
      },
      {
        "headerName": "Cusip",
        "field": "cusip",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "cusip"
      },
      {
        "headerName": "Unit/Share",
        "field": "units",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "units"
      },
      {
        "headerName": "Realized Gain/Loss",
        "field": "realizedGainStr",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "realizedGain"
      },
      {
        "headerName": "Unrealized Gain/Loss",
        "field": "unRealizedGainStr",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "unRealizedGain"
      },
      {
        "headerName": "Long/Short",
        "field": "investmentType",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "investmentType"
      },
      {
        "headerName": "Effective Income Tax Amount",
        "field": "effectiveIncomeTaxAmtStr",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "effectiveIncomeTaxAmt"
      },
      {
        "headerName": "Capital Gains Tax Amount",
        "field": "capitalGainTaxAmtStr",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "capitalGainTaxAmt"
      },
      {
        "headerName": "Locked",
        "field": "isLocked",
        "minWidth": 200,
        "suppressMenu": true,
        "suppressSorting": false,
        "backendFieldName": "isLocked"
      }
    ];
  }

  setPortfolioData() {
    if (this.portfolioConstructionComponent && this.portfolioConstructionComponent.portfolioContructionDropdown) {
      this.portfolioConstructionComponent.portfolioContructionDropdown.portfolioAccountEnumList.forEach(element => {
        let obj = { item_id_portfolio: '', item_text: '' };
        obj.item_id_portfolio = element.portfolioId;
        obj.item_text = element.name;
        this.portfolioData.push(obj);
      });
    }
  }

  onViewItemSelect(item: any) {
    if (item) {
      this.portfolioData = [];
      this.portfolioSelectedItems = [];
      this.accountSelectedItems = [];
      this.accountData = [];
      if (item.item_id === 'Consolidate') {
        this.enablePortfolio = false;
        this.enableAccount = false;
        this.getBuySellSummaryData('Consolidate', []);
      } else if (item.item_id === 'Portfolio') {
        this.enablePortfolio = true;
        this.enableAccount = false;
        this.setPortfolioData();
      } else if (item.item_id === 'Account') {
        this.enablePortfolio = true;
        this.enableAccount = true;
        this.setPortfolioData();
      }
    }
  }

  onPortfolioItemDeSelect(evt) {
    //this.portfolioSelectedItems=evt;
    this.onPortfolioItemSelect();
  }

  onPortfolioSelectAll(evt) {
    this.portfolioSelectedItems = evt;
    this.onPortfolioItemSelect();
  }
  onPortfolioItemSelect() {
    if (this.portfolioSelectedItems) {
      let idPortfolioCollection = [];
      let listDatatoSend = [];
      let accountDataObj = [];
      this.portfolioSelectedItems.forEach(element => {
        let objToSend = {
          portfolioId: "",
          accIdVsAccName: {},
          accIds: []
        };
        objToSend.portfolioId = element.item_id_portfolio;
        listDatatoSend.push(objToSend);
        idPortfolioCollection.push(element.item_id_portfolio);
      });
      if (this.viewSelectedItems[0].item_id == "Account") {
        this.portfolioConstructionComponent.portfolioContructionDropdown.portfolioAccountEnumList.forEach(element => {
          if (idPortfolioCollection.includes(element.portfolioId)) {
            Object.keys(element.accIdVsAccName).map(function (key, value) {
              let obj = { item_id_account: '', item_text: '' };
              obj.item_id_account = key;
              obj.item_text = element.accIdVsAccName[key];
              accountDataObj.push(obj);
            });
          }
        });

        if (accountDataObj.length == 0) {
          this.accountSelectedItems = [];
        }
        this.accountData = accountDataObj;
        return;
      }
      this.getBuySellSummaryData("Portfolio", listDatatoSend);//call api to set Portfolio Section Data
    }
  }
  onAccountItemDeSelect(evt) {
    //this.accountSelectedItems=evt;
    this.onAccountItemSelect();
  }

  onAccountSelectAll(evt) {
    this.accountSelectedItems = evt;
    this.onAccountItemSelect();
  }

  onAccountItemSelect() {
    if (this.accountSelectedItems && this.viewSelectedItems[0].item_id == "Account") {
      let idAccountCollection = [];
      let listDatatoSend = [];
      let idPortfolioCollection = [];

      this.accountSelectedItems.forEach(element => {
        idAccountCollection.push(element.item_id_account);
      });
      if (this.portfolioSelectedItems) {
        this.portfolioSelectedItems.forEach(element => {
          //objToSend.portfolioId=element.item_id_portfolio;
          //listDatatoSend.push(objToSend);
          idPortfolioCollection.push(element.item_id_portfolio);
        });
      }
      this.portfolioConstructionComponent.portfolioContructionDropdown.portfolioAccountEnumList.forEach(element => {
        if (idPortfolioCollection.includes(element.portfolioId)) {
          let objToSend = {
            portfolioId: "",
            accIdVsAccName: {},
            accIds: []
          };
          objToSend.portfolioId = element.portfolioId;
          Object.keys(element.accIdVsAccName).map(function (key, value) {
            if (idAccountCollection.includes(key)) {
              objToSend.accIds.push(key)
            }
          });
          listDatatoSend.push(objToSend);
        }
      });
      this.getBuySellSummaryData("Account", listDatatoSend);//call api to set Portfolio-Account Section Data
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    this.gridApi = params.api;
    //var selectedRows = this.gridApi.getSelectedRows();
    //this.selectedRows = selectedRows;
  }

  collapsebleIconHandler(id) {
    var e = document.getElementById(id);
    var e1 = document.getElementById('icon'+id);
    if (e.style.display == 'block') {
      e.style.display = 'none';
      e1.classList.remove('fa-rotate-180');
    }
    else {
      e.style.display = 'block';
      e1.classList.add('fa-rotate-180');
    }
  }

  getBuySellSummaryData(viewType, portfolioAccountData) {
    this.buySellSectionData = [];
    let requestData = {
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      portfolioAccountList: [],
      buyAndSellDetailsVO: [],
      viewType: "",
    }
    if (viewType === "Consolidate") {
      requestData.viewType = "Consolidate";
    } else if (viewType === "Portfolio") {
      requestData.viewType = "Portfolio";
      requestData.portfolioAccountList = portfolioAccountData;
    } else {
      requestData.viewType = "Account";
      requestData.portfolioAccountList = portfolioAccountData;
    }
    this.portfolioConstructionService.getBuySellSummaryData(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.toGetBuySellSectionData(response['responseData']['buyAndSellDetailsVO']);
      }

    });
  }

  toGetBuySellSectionData(data) {
    if (data) {
      var level=0;
      data.forEach(element => {
        var obj = {
          title: '',
          taxSummaryData: [],
          buySummaryData: [],
          sellSummaryData: []
        }
        obj.title = element.portfolioName;
        obj.taxSummaryData = element.buyAndSellSummaryVO;
        this.getHierarchyData(element.buyDataVO, "childDataVO", obj.buySummaryData, level);
        this.getHierarchyData(element.sellDataVO, "childDataVO", obj.sellSummaryData, level);
        if (obj.buySummaryData && obj.sellSummaryData) {
          this.buySellSectionData.push(obj);
        }
      });
      console.log(this.buySellSectionData);
    }
  }

  getHierarchyData(buySellData, childNode, node, hierarchyLevel) {
    buySellData.forEach(element => {
      element['level'] = hierarchyLevel;
      node.push(element);
      if (element[childNode]) {
        this.getHierarchyData(element[childNode], childNode, node, hierarchyLevel + 1);
      }
    });

  }

  onExportSummary() {
    var taxSummaryColumn = this.taxSummaryColumnDefs.map(ele => ele.headerName) || [];
    var sellSummaryColumn = this.sellSummaryColumnDefs.map(ele => ele.headerName) || [];
    var buySummaryColumn = this.buySummaryColumnDefs.map(ele => ele.headerName) || [];
    var result = [];
    for (let k = 0; k < this.buySellSectionData.length; k++) {
      result.push(this.buySellSectionData[k]['title']);
      let tempData = this.convertDataIntoRow(this.buySellSectionData[k]['taxSummaryData'], this.taxSummaryColumnDefs, taxSummaryColumn) || [];
      let tempData1 = this.convertDataIntoRow(this.buySellSectionData[k]['sellSummaryData'], this.sellSummaryColumnDefs, sellSummaryColumn) || [];
      let tempData2 = this.convertDataIntoRow(this.buySellSectionData[k]['buySummaryData'], this.buySummaryColumnDefs, buySummaryColumn) || [];
      result.push(tempData);
      result.push(tempData1);
      result.push(tempData2);
    }
    var CsvString = "";
    result.forEach(function (RowItem, RowIndex) {
      if(RowIndex%4!==0){//to access String for every repetative section
        CsvString += "\r\n";
        if(RowIndex==1){
          CsvString+="Tax Summary";
        }else if(RowIndex==2){
          CsvString+="Sell Summary";
        }else if(RowIndex==3){
          CsvString+="Buy Summary";
        }
        CsvString += "\r\n";
        RowItem.forEach(function (ColItem, ColIndex) {
          ColItem.forEach(function(col,index){
            col.forEach(element => {
              if(String(element).includes(',')){
                let ele=String(element).replace(/,/g,'');
                CsvString += ele + ',';
              }else{
                CsvString += element + ',';
              }
            });
           CsvString += "\r\n";
          });
        });
      } else {
        CsvString += RowItem + ',';
        CsvString += "\r\n";
      }
    });
    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    var x = document.createElement("A");
    x.setAttribute("href", CsvString);
    x.setAttribute("download", 'Export_Summary' + "_Exported.csv");
    document.body.appendChild(x);
    x.click();


  }

  convertDataIntoRow(data, column, topRow) {
    var returnList = [];
    returnList.push([topRow]);//Header for Each Grid
    for (let i = 0; i < data.length; i++) {
      var row = [];
      var columnData = [];
      for (let j = 0; j < column.length; j++) {
        let tempData = column[j]['field'];
        columnData.push(data[i][tempData]);
      }
      row.push(columnData);
      returnList.push(row);
    }
    return returnList;
  }
}
