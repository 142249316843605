import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/_services/base.service';
import { AuthMessageService } from "src/app/_services/auth-message.service";
import { Variables } from '../../constants';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.less']
})
export class WelcomeComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private baseService: BaseService,
    private router: Router,
    private authMessageService:AuthMessageService) {
    
  }
  paramData;
  isUserVerified:boolean = false;
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramData = params;
    });
    setTimeout(() => {
     this.confirmRegistration();
    }, 500);
  }
  confirmRegistration(){
    this.baseService.confirmRegistration(this.paramData).subscribe((response) => {
      console.log(response);
      
      if (response['responseType'] == Variables.statusSuccess) {
        this.isUserVerified = true;
        this.authMessageService.showSuccessPopup(response);
      }
    });
  }

  goToLogin(){
    this.router.navigate(["/login"]);
  }

}
