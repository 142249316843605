import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class CashFlowObjectiveService {

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };

    constructor(private http: HttpClient) { }

    saveEntityInformation(data,apiParams) {
        this.httpOptions['params'] = apiParams;
        return this.http.post(ApiUrls.saveEntityInformationAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getCashFlowSelectionEnum(processInstanceId) {
        return this.http.get(ApiUrls.getCashFlowSelectionEnumAPI+"?processInstanceId="+processInstanceId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getSimulationParameter(apiParams) {
        this.httpOptions['params'] = apiParams;
        return this.http.get(ApiUrls.getSimulationParameterAPI,this.httpOptions)
            .pipe(
                response => response
            );
    }

    analyseWorkflow(apiParams) {
        this.httpOptions['params'] = apiParams;
        return this.http.get(ApiUrls.analyseWorkflowAPI, this.httpOptions)
            .pipe(
                response => response
            );
    }

    getManagementFees(processInstanceId) {
        //this.httpOptions['params']=apiParams;
        return this.http.post(ApiUrls.getManagementFeesAPI+"?processInstanceId="+processInstanceId,this.httpOptions)
            .pipe(
                response => response
            );
    }

    getEntityInformation(processInstanceId) {
        return this.http.post(ApiUrls.getEntityInformationAPI+"?processInstanceId="+processInstanceId, "",this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveDefaultManagementFees(processInstanceId) {
        return this.http.post(ApiUrls.saveDefaultManagementFeesAPI+"?processInstanceId="+processInstanceId,this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveMinimumFee(minimumFee,processInstanceId,taxabilityType) {
        return this.http.post(ApiUrls.saveMinimumFeeAPI+"?processInstanceId="+processInstanceId+"&minimumFee="+minimumFee +"&taxabilityType="+taxabilityType,this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveManagementFees(data,processInstanceId) {
        //this.httpOptions['params'] = apiParams;
        return this.http.post(ApiUrls.saveManagementFeesAPI,data,this.httpOptions)
            .pipe(
                response => response
            );
    }

    editManagementFees(data,processInstanceId) {
        return this.http.post(ApiUrls.editManagementFeesAPI,data,this.httpOptions)
            .pipe(
                response => response
            );
    }

    downloadCashflowTemplate(processInstanceId,isxlsx) {
        return this.http.get(ApiUrls.downloadCashflowTemplateAPI+"?processInstanceId="+processInstanceId +"&isxlsx="+isxlsx)
            .pipe(
                response => response
            );
    }

    saveInflow(data,apiParams) {
        this.httpOptions['params'] = apiParams;
        return this.http.post(ApiUrls.saveInflowAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveSimulationParameter(data,apiParams) {
        this.httpOptions['params'] = apiParams;
        return this.http.post(ApiUrls.saveSimulationParameterAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    saveOutflow(data,apiParams) {
        this.httpOptions['params'] = apiParams;
        return this.http.post(ApiUrls.saveOutflowAPI, data, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteInflow(cashflowId,processInstanceId) {
        return this.http.post(ApiUrls.deleteInflowAPI+"?processInstanceId="+processInstanceId + "&cashflowId="+cashflowId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteOutflow(cashflowId,processInstanceId) {
        return this.http.post(ApiUrls.deleteOutflowAPI+"?processInstanceId="+processInstanceId + "&cashflowId="+cashflowId, this.httpOptions)
            .pipe(
                response => response
            );
    }

    deleteManagementFees(scheduleId,processInstanceId,taxabilityType) {
        //this.httpOptions['params'] = apiParams;
        return this.http.post(ApiUrls.deleteManagementFeesAPI+"?processInstanceId="+processInstanceId+"&scheduleId="+scheduleId+"&taxabilityType="+taxabilityType,this.httpOptions)
            .pipe(
                response => response
            );
    }

    uploadData(processInstanceId,formData) {
        const options = {} as any; // Set any options you like
        return this.http.post(ApiUrls.importCashflowDataAPI+"?processInstanceId="+processInstanceId,formData,options)
            .pipe(
                response => response
            );
    }

    

    


}
