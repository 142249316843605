import { Component, OnInit, Input } from '@angular/core';
import { AnalysisStrategyComponent } from 'src/app/webPages/proposal-workflow/analysis-strategy/analysis-strategy.component';
@Component({
  selector: 'app-level-assets',
  templateUrl: './level-assets.component.html',
  styleUrls: ['./level-assets.component.less']
})
export class LevelAssetsComponent implements OnInit {
  typesOfCharts: any;
  showAnalysisTypeChartData: any[];
  removeProposedGraph: boolean;
  @Input() public inputAnalysisData;
  strategy1: any;
  strategy2: any;

  constructor(
    private analysisStrategyComponent:AnalysisStrategyComponent
  ) { }
  ngOnChanges(){
    this.strategy1=this.analysisStrategyComponent.selectedStrategy1['key'];
    this.strategy2=this.analysisStrategyComponent.selectedStrategy2['key'];
    console.log(this.inputAnalysisData);
    this.removeProposedGraph=this.analysisStrategyComponent.flagForRemovingProposedColumn;
    if(this.inputAnalysisData){
      this.typesOfCharts = this.inputAnalysisData;
      this.convertIntoList(this.typesOfCharts);

    }
  }
  ngOnInit() {


  }


  convertIntoList(Object) {
    this.showAnalysisTypeChartData = [];
    if (Object) {
      Object.forEach(element => {
        element.isHeader = true;
        this.showAnalysisTypeChartData.push(element);
        if (element.children) {
          element.children.forEach(item => {
            item.isHeader = false;
            item.parentId = element.id;
            item.assetClassName="- -"+item.assetClassName;
            this.showAnalysisTypeChartData.push(item);


          });
        }

      });
    }
  }


}
