import { Component, OnInit, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as $ from 'jquery';
import { ProductListModel } from "src/app/_models/catalog.model";
import { ErrorModel } from "../../../_models/response.model";
import { AuthMessageService } from "../../../_services/auth-message.service";
import { CommonFunctions } from "src/app/common";


declare var _: any;
@Component({
  selector: "add-edit-product-list-popup",
  templateUrl: "./add-edit-product-list-popup.component.html",
  styleUrls: ["./add-edit-product-list-popup.component.less"]
})

export class AddEditProductListPopup implements OnInit {
  @Input() compId: string;
  @Input() saveNewProductListData;
  @Input() rowData;
  @Input() catalogDropdowns;
  commonFunction=new CommonFunctions();

  productListInfo = new ProductListModel();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private authMessageService : AuthMessageService
  ) { }

  ngOnInit() {
    if(this.rowData){
      this.productListInfo = this.rowData;
    }
    else
      this.productListInfo.type = null;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ngxSmartModalService.open(this.compId);
    });
  }

  closePopup() {
    this.ngxSmartModalService.getModal(this.compId).close();
    $("#" + this.compId).closest("add-edit-product-list-popup").remove();
  }

  saveProductList() {
    if(this.validateData(this.productListInfo)){
      this.saveNewProductListData(this.productListInfo,this);
    }
  }
  
  /* Validate data */
  validateData(data){
    if(!data.name){
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please enter product list name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } 
    // if(!data.sortOrder){
    //   var errorObj = new ErrorModel();
    //   errorObj['message'] = 'Please enter sort order';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // } 
    if(data.type=='null' || !data.type){
      var errorObj = new ErrorModel();
      errorObj['message'] = 'Please select type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } 
    // if(!data.description){
    //   var errorObj = new ErrorModel();
    //   errorObj['message'] = 'Please enter description';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // } 
    return true;
  }


}
