import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient) { }



  // file from event.target.files[0]
  uploadFile(url: string, documentData): Observable<HttpEvent<any>> {


    let input = new FormData();
    // Add your values in here
    input.append('document', documentData.document);
    input.append('documentName', documentData.documentName);
    input.append('description', documentData.description);



    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })

    };

    //let data = {"description" : documentdesc , "document" : formData, "name": documentname}

    const req = new HttpRequest('POST', url, input, options);
    return this.http.request(req);
  }

}
