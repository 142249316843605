import {Component} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'printType-icon',
    template: `<div class="float-left w-100" [ngClass]="{'download-printHide':hidePrintIcon}">
                    <fa name="print" class="cursor" (click)="downloadRowReport()"></fa>
              </div>`,
    styles   : []
})
export class PrintIconRowTemplate implements ICellRendererAngularComp {
    params:any;
    hidePrintIcon : boolean = false;

    agInit(params:any):void {
        this.params = params;
        if(this.params.node.data.gridName=="presentation" && this.params.node.data.progressStatus=="In Progress"){
            this.hidePrintIcon = true;
        }
    }

    public downloadRowReport() {
        this.params.context.componentParent.previewRowData(this.params.node.data);
    }

    refresh(): boolean {
        return false;
    }
}