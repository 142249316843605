export class TaxInfoModel {
    taxInfoId: string;
    processInstanceId: string;
    effectiveStartYear: string;
    taxType: string = null;
    methodology: string = null;
    federalTaxRate: number=null;
    stateTaxRate: number=null;
    effectiveIncomeTaxRate: number=null;
    effectiveCapitalGainsTaxRate: number=null;
    federalCapitalGainTaxRate: number=null;
    stateCapitalGainTaxRate: number=null;
    federalDividentTaxRate: number = null;
    stateDividentTaxRate: number=null;
    localTaxRate: number;
    medicareTaxRate: number;
    adjustedGrossEarnedIncomeDecimalValue: number;
    adjustedGrossEarnedIncomeCurrency: string;
    ageiGrowthRate: number;
    relationshipLevel:boolean=false;
    stateInfoCode : string = null
}