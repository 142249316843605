import { Component, OnInit, Input } from '@angular/core';
import { ProposalWorkflowComponent } from '../proposal-workflow.component';
import { Variables } from '../../../constants';
import { LoaderService } from 'src/app/_services/loader.service';
import { CommonFunctions } from '../../../common';
import { AuthMessageService } from '../../../_services/auth-message.service';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';
import { AnalysisService } from '../../../_services/workflow-services/analysis.service';
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';
import {MaxCalculatorMainComponent} from 'src/app/webPages/max-calculator/max-calculator-main/max-calculator-main.component'
import * as $ from 'jquery';
@Component({
  selector: 'app-analysis-strategy',
  templateUrl: './analysis-strategy.component.html',
  styleUrls: ['./analysis-strategy.component.less']
})
export class AnalysisStrategyComponent implements OnInit {
  flagForRemovingProposedColumn: boolean=false;
  constructor(
    private workflowBaseService: WorkflowBaseService,
    private loaderService : LoaderService,
    private authMessageService : AuthMessageService,
    private analysisService :AnalysisService,
    private proposalWorkflowComponent: ProposalWorkflowComponent
  ) { }
  @Input() public parentData;
  @Input() public workflow;
  @Input() public processInstanceId;
  report:any = {};
  listOfChildComps:any[];
  taskDefinitionKey = "strategySelection";
  listOfPortfolios:any[];
  listOfStrategies:any[];
  gridColumnDefs:any[];
  gridRowData:any[];
  analysisRequestData:any;
  gridApi;
  gridColumnApi;
  analysisData:any;
  isPrintMode:boolean = false;
  
  selectedPortfolio:{}="";
  selectedStrategy1:{}="";
  selectedStrategy2:{}="";
  labelToDisplay = ''
  private portfoioVsProposedPortfolioInfo = null;
  ngOnInit() {
  //  this.listOfChildComps = ["allocationCharts","levelAssets","efficientFrontier","rangeOfReturn","rangeOfAssets","hypotheticalGrowth",
  //                          "historicalStatistices","goalAchievability","cashFlow","marketDownturn","cashSpending","taxCalcLinear","taxCalcInflation",
  //                          "taxCalcQualified","taxCalcNonQualified"
  //                                ];
  if(this.workflow != "cal-workflow"){
    this.processInstanceId = this.proposalWorkflowComponent.workflowInfoData.processInstanceId ;
  }else{
    this.taskDefinitionKey = "proposalSetup";
  }
  if (this.parentData.parentComponent == "strategySelection")  
    this.labelToDisplay = 'Selected Strategy'
  else if(this.parentData.parentComponent == "portfolioConstruction")
    this.labelToDisplay = 'Proposed Strategy'
  if(this.parentData.parentComponent=='cashFlowObjectives'){
    this.flagForRemovingProposedColumn=true;
  
  }
  this.setGridHeader();
  this.getanalysismetadata();
  }

  ngOnChanges(): void {
  }
  setGridHeader(){
    setTimeout(()=>{
      this.gridColumnDefs = [  
      {
        "headerName": "Asset Class",
        "field": "assetClassName",
        "minWidth": 120,
        "suppressMenu": true,
        "suppressSorting" : true
      },
      {
        "headerName": "Current",
        "field": "currentAllocation",
        "minWidth": 80,
        "suppressMenu": true,
        "suppressSorting" : true
      },
      {
        "headerName": "Proposed",
        "field": "proposedAllocation",
        "minWidth": 80,
        "suppressMenu": true,
        "suppressSorting" : true,
        "hide": this.flagForRemovingProposedColumn
      },
      {
        "headerName":this.selectedStrategy1['key'],
        "field": "firstModelAllocation",
        "minWidth": 80,
        "suppressMenu": true,
        "suppressSorting" : true
      },
      {
        "headerName": this.selectedStrategy2['key'],
        "field": "secondModelAllocation",
        "minWidth": 80,
        "suppressMenu": true,
        "suppressSorting" : true
      }
  
    ]
  },200);
  }

   /* On grid ready */
   onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  getanalysismetadata() {
    let requestData ={
      processInstanceId : this.processInstanceId,
      taskDefinitionKey : this.parentData.parentComponent
    }
    this.analysisService.getanalysismetadata(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
       this.listOfChildComps = response["responseData"].analysisTypes;
       this.listOfPortfolios = response["responseData"].portfolioInfo;
       if(this.listOfPortfolios && this.listOfPortfolios.length)
        this.selectedPortfolio = this.listOfPortfolios[0];
        this.onChangeSelectedPortfolio();
        this.getGridData();
        this.getSelectedStrategy();
      }
    });
  }

  getGridData() {
    let requestData ={
      processInstanceId : this.processInstanceId,
      taskDefinitionKey : this.parentData.parentComponent,
      portfolioId       : this.selectedPortfolio["value"],
      analysisType      : "analysisSummary",
      firstModelId      : this.selectedStrategy1["value"],
      secondModelId     : this.selectedStrategy2["value"],
      section           : this.parentData.parentComponent
    }
    this.setGridHeader();
    this.gridRowData = [] ;
    this.analysisService.getanalysisinfo(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
       this.gridRowData = response["responseData"];
       setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
       }, 100);
      }
    });
  }

  getDataBasedOnAnalysisType() {
    if(this.report){
      let requestData ={
        processInstanceId : this.processInstanceId,
        taskDefinitionKey : this.parentData.parentComponent,
        portfolioId       : this.selectedPortfolio["value"],
        analysisType      : this.report["value"],
        firstModelId      : this.selectedStrategy1["value"],
        secondModelId     : this.selectedStrategy2["value"],
        section           : this.parentData.parentComponent
      }
      this.analysisData=null;
      this.analysisService.getanalysisinfo(requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            this.analysisData = response["responseData"];
            this.analysisRequestData = requestData;
            if(this.report["value"] == "allocationCharts"){
              $('.analysis-main-div').animate({
                scrollTop : 300
              },1000)
            }
           
        }
      });
    }
    
  }

  onChangeSelectedPortfolio(){
    this.listOfStrategies = this.selectedPortfolio["children"];
    console.log(this.selectedPortfolio);
    
    if(this.listOfStrategies && this.listOfStrategies.length){
      this.selectedStrategy1 = this.listOfStrategies[0];
      this.selectedStrategy2 = this.listOfStrategies[1];
      this.report=this.listOfChildComps[0];
    }
    if(this.selectedPortfolio){
      this.getGridData();
      this.getDataBasedOnAnalysisType();
    }
    
  }

  printIconId;

  printPopup(){
    // var formContainer = $(document.getElementsByClassName('row analysis-main-div'));
    // var html = formContainer.html();
    // CommonFunctions.exportToPdf(html,"Analysis Popup","" )


    this.isPrintMode = true;

    setTimeout(() => {
      var upperSectionNode = document.getElementById("analysis-upper-div-id");
    var node1 = document.getElementById("agGrid");
    this.printIconId = document.getElementById("print-icon-id");
    if(this.report["value"] == "allocationCharts")
     node1.style.height = "62vh";

   this.printIconId.style.display = "none";
    var img;
    var filename;
    var newImage;
    let componentRef = this;
    componentRef.loaderService.show();

    domtoimage.toPng(upperSectionNode, { bgcolor: '#fff' })

      .then(function(dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        
        img.onload = function(){

        var pdfWidth = 1020;
        var pdfHeight = img.height;
        //node1.style.display = "none";
          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if(pdfWidth > pdfHeight)
          {
            doc = new jsPDF('l', 'px', [pdfWidth , pdfHeight*2]);
          }
          else
          {
            doc = new jsPDF('p', 'px', [pdfWidth , pdfHeight]);
          }


          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();


          doc.addImage(newImage, 'PNG',  10, 10, width-20, height-300);// image for main grid added (this is common for all popups)

         
          componentRef.isPrintMode = false;

          switch (componentRef.report["value"]) {

            case "allocationCharts": 
                  componentRef.createSecondPage(doc,node1,"app-allocation-charts");
                  break;

            case "secondLevelAssets": 
                componentRef.createSecondPage(doc,node1,"app-level-assets");
                break;
                
            case "rangeOfReturns": 
                componentRef.createSecondPage(doc,node1,"app-range-of-return");
                break;
            
            case "efficientFrontier": 
            componentRef.createSecondPage(doc,node1,"app-effeicient-frontier");
            break;

            case "rangeOfReturnsReal": 
                componentRef.createSecondPage(doc,node1,"app-range-of-return");
                break;  

            case "rangeOfAssets": 
                  componentRef.createSecondPage(doc,node1,"app-range-of-assets");
                  break;

            case "rangeOfAssetsReal": 
                componentRef.createSecondPage(doc,node1,"app-range-of-assets");
                break;

            case "hypotheticalGrowth": 
            componentRef.createSecondPage(doc,node1,"app-hypothetical-growth");
            break;

            case "historicalStatistices": 
                componentRef.createSecondPage(doc,node1,"app-historical-statistices");
                break;
            
                case "goalAchievability": 
            componentRef.createSecondPage(doc,node1,"app-goal-achievability");
            break;

            case "cashFlow": 
                componentRef.createSecondPage(doc,node1,"app-cash-flow");
                break;
            
                case "cashFlowQual": 
                componentRef.createSecondPage(doc,node1,"app-cash-flow");
                break;
                case "cashFlowNonQual": 
                componentRef.createSecondPage(doc,node1,"app-cash-flow");
                break;
                case "cashFlowReal": 
                componentRef.createSecondPage(doc,node1,"app-cash-flow");
                break;
                case "cashFlowRealQual": 
                componentRef.createSecondPage(doc,node1,"app-cash-flow");
                break;
                case "cashFlowRealNonQual": 
                componentRef.createSecondPage(doc,node1,"app-cash-flow");
                break;
                case "marketDownturn": 
                componentRef.createSecondPage(doc,node1,"app-market-downturn");
                break;
                case "cashSpending": 
                componentRef.createSecondPage(doc,node1,"app-cash-spending");
                break;
                case "taxCalcLinear": 
                componentRef.createSecondPage(doc,node1,"app-tax-calc-linear");
                break;
                case "taxCalcQualified": 
                componentRef.createSecondPage(doc,node1,"app-tax-calc-linear");
                break;
                case "taxCalcNonQualified": 
                componentRef.createSecondPage(doc,node1,"app-tax-calc-linear");
                break;
                case "taxCalcReal": 
                componentRef.createSecondPage(doc,node1,"app-tax-calc-linear");
                break;
                case "taxCalcRealQual": 
                componentRef.createSecondPage(doc,node1,"app-tax-calc-linear");
                break;
                case "taxCalcRealNonQual": 
                componentRef.createSecondPage(doc,node1,"app-tax-calc-linear");
                break;
          
            default:
                node1.style.height = "35vh";
                componentRef.loaderService.hide();
                doc.addImage(newImage, 'PNG',  10, 10, width-20, height-320);
                let blob = doc.output("blob");
                var url = window.URL.createObjectURL(blob);
                  window.open(url,'knskf');
                 setTimeout(function(){
                    window.URL.revokeObjectURL(url);
                  }, 100);
              break;
          }

          // filename = 'mypdf_' + '.pdf';
          // doc.save(filename);

        };


      })

    }, 500);
   
    
  }

  

  createForAllocaion(doc,mainGridNode){
    doc.addPage();
    var node1 = document.getElementById("allocation-chart-id");
    var img;
    var newImage;
    let componentRef = this;
    domtoimage.toPng(node1, { bgcolor: '#fff' })

      .then(function(dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        
        img.onload = function(){

        var pdfWidth = 1020;
        var pdfHeight = img.height;
        mainGridNode.style.height = "35vh";

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        componentRef.gridApi.sizeColumnsToFit();
          componentRef.loaderService.hide();
          doc.addImage(newImage, 'PNG',  10, 10, width-20, height-100);
          let blob = doc.output("blob");
          var url = window.URL.createObjectURL(blob);
              window.open(url,'knskf');
                setTimeout(function(){
                    window.URL.revokeObjectURL(url);
           }, 100);
        };
      })
  }

 

  createSecondPage(doc,mainGridNode,tagName){
    doc.addPage();
    var node1 = document.getElementsByTagName(tagName)[0];
    var img;
    var newImage;
    let componentRef = this;
    let decreaseHeightBy = 150;

    if(tagName != "app-allocation-charts"){
      node1 = document.getElementsByClassName("analysis-main-div-inner")[0];
    }

    if(tagName == "app-allocation-charts" && this.parentData.parentComponent=='cashFlowObjectives')
      decreaseHeightBy = 530;

    if(tagName == "app-allocation-charts" && this.parentData.parentComponent!='cashFlowObjectives')
      decreaseHeightBy = 410;

    if(tagName == "app-hypothetical-growth")
      decreaseHeightBy = 300;
    
    if(tagName == "app-effeicient-frontier")
      decreaseHeightBy = 350;
    
    var cashFlowTable;
    if(tagName == "app-cash-flow"){
      cashFlowTable = <HTMLElement>document.getElementsByClassName("analysis-table1")[0];
      cashFlowTable.style.height = "initial";
    }

    domtoimage.toPng(node1, { bgcolor: '#fff' })

      .then(function(dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        
        img.onload = function(){

        var pdfWidth = 1020;
        var pdfHeight = img.height;
        componentRef.printIconId.style.display = "block";
        if(mainGridNode)
          mainGridNode.style.height = "35vh";
        
        if(tagName == "app-cash-flow")
          cashFlowTable.style.height = "50vh";

        if(tagName != "app-allocation-charts"){
          if(pdfWidth > pdfHeight)
          {
            doc = new jsPDF('l', 'px', [pdfWidth , pdfHeight*2]);
          }
          else
          {
            doc = new jsPDF('p', 'px', [pdfWidth , pdfHeight]);
          }
        }
        

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();

          componentRef.loaderService.hide();
          componentRef.gridApi.sizeColumnsToFit();
          doc.addImage(newImage, 'PNG',  10, 10, width-20, height-decreaseHeightBy);
          let blob = doc.output("blob");
          var url = window.URL.createObjectURL(blob);
              window.open(url,'knskf');
                setTimeout(function(){
                    window.URL.revokeObjectURL(url);
           }, 100);
        };
      })
  }


  getSelectedStrategy() {
    let requestObj = {
      "processInstanceId": this.processInstanceId,
      "taskDefinitionKey": this.taskDefinitionKey
    };
    let portfolioId = this.selectedPortfolio["value"]
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData'].taskVariables && response['responseData'].taskVariables.strategySelectionAllocation) {
          this.portfoioVsProposedPortfolioInfo = response['responseData'].taskVariables.strategySelectionAllocation.portfoioVsProposedPortfolioInfo;
          this.parentData.selectedStrategy = this.portfoioVsProposedPortfolioInfo[portfolioId]['proposedAllocation']['name'];
        }
      }
    });
  }

}
