import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as $ from 'jquery';
import { CatalogService } from "../../../_services/catalog.service";
import { Variables } from "../../../constants";

declare var _: any;
@Component({
	selector: "app-product-compare-popup",
	templateUrl: "./product-compare-popup.component.html",
	styleUrls: ["./product-compare-popup.component.less"],
	encapsulation: ViewEncapsulation.None,
})

export class ProductComparePopup implements OnInit {
	@Input() compId: string;
	@Input() comparisonRequestData: {};

	comparisonCriterion: string = 'productDetails';

	productComparisonColumnDefs = [];
	productComparisonRowData = [];

	comparisonParametersExclusion = ['name','productName','productListVO','dataStoreId','createdBy','createdByName','modifiedBy','modifiedByName','createdDate','modifiedDate','systemOfRecordX','versionNumber','productTypeDataStoreId','styleDataStoreId','primaryBenchmarkDataStoreId','secondaryBenchmarkDataStoreId','issueCountryDataStoreId','riskCountryDataStoreId','statusDataStoreId','taxTypeDataStoreId','productListsDataStoreId','issueCountryDisplay','riskCountryDisplay'];

	gridApi: any;
	gridColumnApi: any;

	constructor(
		public ngxSmartModalService: NgxSmartModalService,
		private catalogService: CatalogService
	) { }

	ngOnInit() {
		this.onCreterionSelection(this.comparisonCriterion);
	}

	onCreterionSelection(comparisonCriterion) {
		this.productComparisonColumnDefs = [];
		if (comparisonCriterion == 'productDetails') {
			this.productComparisonColumnDefs = [
				{ headerName: "Product Details", field: "productInfoField", width: 250, suppressSizeToFit: true, pinned: "left", suppressResize: true, lockPosition: true, suppressSorting: true },
			];
		} else {
			this.productComparisonColumnDefs = [
				{ headerName: "Product Performance Details", field: "productInfoField", width: 250, suppressSizeToFit: true, pinned: "left", suppressResize: true, lockPosition: true, suppressSorting: true },
			];
		}
		this.getComparisonData(this.comparisonRequestData, comparisonCriterion);
	}

	getComparisonData(requestData, comparisonCriterion) {
		this.catalogService.getComparisonInfoData(requestData, comparisonCriterion)
			.subscribe(response => {
				if (response['responseType'] == Variables.statusSuccess) {
					this.prepareColumnDefsData(response['responseData']['result'],comparisonCriterion);
				}
			});
	}

	prepareColumnDefsData(data,comparisonCriterion) {
		setTimeout(() => {
			data.forEach(element => {
				let columnDef = {};
				if (comparisonCriterion == 'productDetails') {
					columnDef['headerName'] = element.name;
				} else{
					columnDef['headerName'] = element.productName;
				}
				columnDef['field'] = element.productId;
				columnDef['width'] = 150;
				columnDef['suppressSorting'] = true;
				this.productComparisonColumnDefs.push(columnDef);
			});
			this.gridApi.setColumnDefs(this.productComparisonColumnDefs);
			this.prepareRowData(data);
		});
	}

	prepareRowData(data) {
		var rowData = [];
		let dataProp = Object.keys(data[0]);
		dataProp.forEach(element => {			
			if(!this.comparisonParametersExclusion.includes(element)){
				let rowObj = {};
				let elementCopy = element;
				elementCopy = elementCopy.replace(/([A-Z])/g, ' $1').replace(/^./, function(elementCopy){ return elementCopy.toUpperCase(); });		
				rowObj['productInfoField'] = elementCopy;
				data.forEach(ele => {
					rowObj[ele.productId] = ele[element];
				});
				rowData.push(rowObj);
			}
		});		
		this.productComparisonRowData = rowData;
		setTimeout(() => {
			this.gridApi.sizeColumnsToFit();
		}, 100);
	}

	closePopup(): void {
		this.ngxSmartModalService.getModal(this.compId).close();
		$("#" + this.compId).closest("app-product-compare-popup").remove();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.ngxSmartModalService.open(this.compId);
		});
	}

	/* On grid ready */
	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

}
