import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { Variables } from '../../../../constants';

@Component({
  selector: 'product-performance-tab',
  templateUrl: './product-performance-tab.component.html',
  styleUrls: ['./product-performance-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductPerformanceTabComponent implements OnInit {
  productPerformanceData = {};
  productId: any;
  productRiskCharacteristicVO:any= {};

  productPerformanceColumnDefs = [];
  productPerformanceRowData = [];
  gridApi: any;
  gridColumnApi: any;


  constructor(
    private activeRoute: ActivatedRoute,
    private catalogProductInfoService: CatalogProductInfoService
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });

    this.productPerformanceColumnDefs = [
      {
        headerName: "Name",
        field: "productName",
        width: 100
      },
      {
        headerName: "MTD",
        field: "monthToDate",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "QTD",
        field: "quarterToDate",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "YTD",
        field: "yearToDate",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "1 Yr.",
        field: "oneYearReturn",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "2 Yr.",
        field: "twoYearReturn",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "3 Yr.",
        field: "threeYearReturn",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "5 Yr.",
        field: "fiveYearReturn",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "7 Yr.",
        field: "sevenYearReturn",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "10 Yr.",
        field: "tenYearReturn",
        valueFormatter: this.convertToPercSeperated,
        width: 100
      },
      {
        headerName: "Inception Date",
        field: "inceptionToDateReturn",
        width: 100
      }];

    this.getProductPerformanceData(this.productId);
  }

  getProductPerformanceData(productId) {
    this.catalogProductInfoService.getProductPerformanceInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productPerformanceData = response['responseData'];
          this.productPerformanceRowData = this.productPerformanceData['productPerformanceVO'];
          this.productRiskCharacteristicVO = this.productPerformanceData['productRiskCharacteristicVO'];
        }
      });
  }


  /* On grid ready */
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  
  convertToPercSeperated(params) {
    let value = params.value;
    if (value) {
      params.value = (value*100).toFixed(2)+ '%';
    }
    return params.value;
  }

}
