import { Component, OnInit, Input } from '@angular/core';
import { AnalysisService } from '../../../../../_services/workflow-services/analysis.service';
import { Variables } from '../../../../../constants';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-hypothetical-growth',
  templateUrl: './hypothetical-growth.component.html',
  styleUrls: ['./hypothetical-growth.component.less'],
  providers:[DatePipe]
})
export class HypotheticalGrowthComponent implements OnInit {
  dropDownData: any;
  public barchartYear=[];

  constructor(
    private analysisService: AnalysisService,
    private datePipe: DatePipe
  ) {}
 
  @Input() inputAnalysisData;
  @Input() inputRequestData;
  requestData :{};
  selectedYear:any={};
  public barChartOptions = {
    responsive: false,
    datasetFill : false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{

                      }]
            , yAxes: [{
                        ticks: {
                          callback: function(label, index, labels) {
                              return "$"+parseFloat(label).toLocaleString();
                          }
                          }
                      }] },
  };
  //public barChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartLabels=[];
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData = [];

  ngOnChanges(){
    if(this.inputAnalysisData){
     this.dropDownData=this.inputAnalysisData.yearsInfo
      this.selectedYear=this.inputAnalysisData.yearsInfo[0];
      this.drawChart();
      this.requestData=this.inputRequestData;
    }
  }

  drawChart(){
    this.barChartData = [];
      var storeRef=[];
      this.barchartYear=[];
      this.barChartLabels=[];
    setTimeout(() => {
      
      this.inputAnalysisData["seriesChartData"].forEach(element => {
      this.barChartData.push({
        label :element.seriesName,
        data : element.values,
        fill: false

      })
      storeRef.push(element.references);
    });
    storeRef[0].forEach(ele=>{
        var fullDate=new Date(ele).toUTCString();
     var formatedDate=this.datePipe.transform(fullDate, 'MM/yyyy');
     this.barchartYear.push(formatedDate);
    
    })
    this.barChartLabels = this.barchartYear;
    }, 1000);
  }

  ngOnInit(){
    
  };

  getDataBasedOnYear() {
    if(this.requestData){
      this.requestData["selectedYear"] = this.selectedYear.value;
      this.analysisService.getanalysisinfo(this.requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
           this.inputAnalysisData=response['responseData'];
           this.dropDownData=this.inputAnalysisData.yearsInfo;
           let j=this.inputAnalysisData.yearsInfo.findIndex(x => x.value ===this.selectedYear.value);
           this.selectedYear=this.inputAnalysisData.yearsInfo[j];
           this.drawChart();
        }
      });
    }
    
  }
   


}
