import { Pagination } from './pagination.model';

export class FilterModel {
    searchParamsList = [];
    paginationInfoVO = new Pagination();
    searchParamsInfoVO = [];
    result : {};
    isTotalRecordSelected : boolean = false;
    dataStoreIdList: any[];
}
