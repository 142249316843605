import {Component} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'progress-bar',
    template: '<div class="float-left w-100 progress-bar-container">'+
                '<div class="progress-border">'+
                    '<div class="progress" [style.width]="progressBarValue()">'+
                        '<p class="w-100 text-center">{{progressBarValue()}}</p>'+
                    '</div>'+
                '</div>'+
              '</div>',
    styles   : [
        `
        .progress-bar-container{
            margin-top:5px;
        }
        
        .progress-border{
            border: 1px solid #cecece;
            border-radius: 5px;
            width: 100%;
        }

        .progress{
            background-color: rgb(76, 151, 76);
            border-radius: 5px;
            font-size:13px;
            color: white;
            
        }
        .progress p{
            position:relative;
            top:-5px;
        }
        `
    ]
})
export class ProgressBarRowTemplate implements ICellRendererAngularComp {
    params:any;

    agInit(params:any):void {
        this.params = params;
    }

     progressBarValue():string {
        return this.params.value + '%';
    }

    refresh(): boolean {
        return false;
    }
}