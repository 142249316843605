import { Component, OnInit, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as $ from 'jquery';


declare var _: any;
@Component({
  selector: "add-edit-document-popup",
  templateUrl: "./add-edit-document-popup.component.html",
  styleUrls: ["./add-edit-document-popup.component.less"]
})

export class AddEditDocumentPopup implements OnInit {
 @Input() compId: string;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    setTimeout(() => {
    this.ngxSmartModalService.open(this.compId);
    });
  }

  closePopup()
  {
    this.ngxSmartModalService.getModal(this.compId).close();
    $("#"+this.compId).closest("add-edit-document-popup").remove();
  }

}
