import { Component, OnInit } from "@angular/core";
import { InvestorInfo } from "src/app/_models/investor-info.model";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";
import { WorkflowBaseService } from '../../../../_services/workflow-services/workflow-base.service';
import { RelationshipOverviewService } from "../../../../_services/workflow-services/relationship-overview.service";
import { Variables } from "../../../../constants";
import { RelationshipOverviewComponent } from "../relationship-overview.component";
import { BaseService } from "../../../../_services/base.service";
import { ProposalWorkflowComponent } from "../../proposal-workflow.component";
import { AuthMessageService } from "../../../../_services/auth-message.service";

@Component({
  selector: "app-investor-info",
  templateUrl: "./investor-info.component.html",
  styleUrls: ["./investor-info.component.less"]
})
export class InvestorInfoComponent implements OnInit {
  investorInfo = new InvestorInfo();
  isView: boolean = true;
  relationshipOverviewDropdowns: any;
  statesList: any;
  citiesList: any;
  processInstanceId: any;
  externalPartyInfoList: any;
  postalAddresses: any;
  internetAddresses: any;
  teleCommunicationsAddresses: any;
  primaryPostalAddressCode: any;
  primaryInternetAddressId: any;
  primaryTeleCommunicationAddressId: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private relationshipOverviewService: RelationshipOverviewService,
    private relationshipOverviewComponent: RelationshipOverviewComponent,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
    private workflowBaseService : WorkflowBaseService,
    private baseService: BaseService,
    private router : Router,
    private authMessageService: AuthMessageService
  ) { }

  ngOnInit() {

    setTimeout(() => {
      this.processInstanceId = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      this.getInvestorInfo(this.processInstanceId);
      this.relationshipOverviewDropdowns = this.relationshipOverviewComponent.relationshipOverviewDropdowns;
    }, 500);
  }

  toggleView() {
    if (this.isView) {
      this.isView = false;
      this.getExternalPartyData();
    } else {
      this.isView = true;
    }
  }

  getExternalPartyData() {
    this.relationshipOverviewService.getExternalPartyData(this.processInstanceId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.externalPartyInfoList = response['responseData'];          
          this.selectPrimaryParty(this.investorInfo.primary);
          this.selectSecondaryParty(this.investorInfo.secondary);
        }
      });
  }

  editRelationship(){
    var dataObj ={};
    dataObj['data']={};
      let requestObj = {
        "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
        "taskDefinitionKey": "relationshipSelection",
        "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    
      };
      this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          if (response['responseData'].taskVariables) {
            dataObj['data']['relationshipId']= response['responseData'].taskVariables.relationshipId;
            dataObj['isFrom']='/proposalWorkflow/relationshipOverview';
            var dataArray = [dataObj];
            var cache =[];
            var stringifiedData = JSON.stringify(dataArray, function(key, value) {
              if (typeof value === 'object' && value !== null) {
                  if (cache.indexOf(value) !== -1) {
                      // Duplicate reference found
                      try {
                          // If this value does not reference a parent it can be deduped
                          return JSON.parse(JSON.stringify(value));
                      } catch (error) {
                          // discard key if value cannot be deduped
                          return;
                      }
                  }
                  // Store value in our collection
                  cache.push(value);
              }
              return value;
          });
          cache = null; // Enable garbage collection
        
          localStorage.setItem('dataItem', stringifiedData);
          this.router.navigate(["/add-edit-relationship"]);
          }
        }
      });
  
  }

  selectPrimaryParty(primaryPartyName) {
    let partyInfo = this.externalPartyInfoList.find(function (element) {
      return element.name == primaryPartyName;
    });
    if (partyInfo) {
      this.investorInfo.primaryDataStoreId = partyInfo.dataStoreId;
      this.investorInfo.primaryEntityId = partyInfo.entityId;
      this.postalAddresses = partyInfo.communicationChannelVO.postalAddressVOList;
      this.internetAddresses = partyInfo.communicationChannelVO.internetAddressVOList;
      this.teleCommunicationsAddresses = partyInfo.communicationChannelVO.teleCommunicationsVOList;
      let primaryPostalAddresssInfo = this.postalAddresses.find(function (element) {
        return element.isPrimary == true;
      });
      if (primaryPostalAddresssInfo) {
        this.investorInfo.primaryPostalAddressVO = primaryPostalAddresssInfo;
        this.primaryPostalAddressCode = this.investorInfo.primaryPostalAddressVO.addressTypeCode;
      }

      let primaryInternetAddresssInfo = this.internetAddresses.find(function (element) {
        return element.isPrimary == true;
      });
      if (primaryInternetAddresssInfo) {
        this.investorInfo.primaryInternetAddressVO = primaryInternetAddresssInfo;
        this.primaryInternetAddressId = this.investorInfo.primaryInternetAddressVO.dataStoreId;
      }

      let primaryTeleCommunicationsAddressInfo = this.teleCommunicationsAddresses.find(function (element) {
        return element.isPrimary == true;
      });
      if (primaryTeleCommunicationsAddressInfo) {
        this.investorInfo.primaryTeleCommunicationsVO = primaryTeleCommunicationsAddressInfo;
        this.primaryTeleCommunicationAddressId = this.investorInfo.primaryTeleCommunicationsVO.dataStoreId;
      }
    }
    else{
      this.investorInfo.primaryEntityId=null;
    }
  }

  selectPrimaryAddress(primaryAddressCode) {
    let addressInfo = this.postalAddresses.find(function (element) {
      return element.addressTypeCode == primaryAddressCode;
    });
    if (addressInfo) {
      this.investorInfo.primaryPostalAddressVO = addressInfo;
      this.investorInfo.primaryPostalAddressVO.isPrimary = true;
    }
  }

  changePrimaryInternetAddress(primaryInternetAddressId){
    let internetAddressInfo = this.internetAddresses.find(function (element) {
      return element.dataStoreId == primaryInternetAddressId;
    });
    if (internetAddressInfo) {
      this.investorInfo.primaryInternetAddressVO = internetAddressInfo;
      this.investorInfo.primaryInternetAddressVO.isPrimary = true;
    }
  }


  changePrimaryTeleCommunicationAddress(primaryTeleCommunicationAddressId){
    let teleCommunicationAddressInfo = this.teleCommunicationsAddresses.find(function (element) {
      return element.dataStoreId == primaryTeleCommunicationAddressId;
    });
    if (teleCommunicationAddressInfo) {
      this.investorInfo.primaryTeleCommunicationsVO = teleCommunicationAddressInfo;
      this.investorInfo.primaryTeleCommunicationsVO.isPrimary = true;
    }
  }

  selectSecondaryParty(secondaryPartyName) {
    let partyInfo = this.externalPartyInfoList.find(function (element) {
      return element.name == secondaryPartyName;
    });
    if (partyInfo) {
      this.investorInfo.secondaryDataStoreId = partyInfo.dataStoreId;
      this.investorInfo.secondaryEntityId = partyInfo.entityId;
    }else{
      this.investorInfo.secondaryEntityId=null;
    }
  }

  saveInvestorInfo() {
    this.investorInfo["processInstanceId"]=this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
    this.relationshipOverviewService.updateInvestorInfo(this.investorInfo)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.getInvestorInfo(this.processInstanceId);
          this.toggleView();
        }
      });
  }

  cancelCheckPopUp() {
    this.toggleView();
  }

  confirmedCheckPopup() {
    this.toggleView();
    this.ngxSmartModalService.close("cancelConfirmation");
  }

  getInvestorInfo(processInstanceId) {
    this.relationshipOverviewService.getInvestorInfo(processInstanceId).subscribe(response => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.investorInfo = response['responseData'];
      }
    });
  }

  selectCountry() {
    this.baseService.getStatesInfoData(this.investorInfo.countryRegion).subscribe(response => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.statesList = response['responseData'].CountryStateInfo;
      }
    });
  }

  selectState() {
    this.baseService.getCitiesInfoData(this.investorInfo.stateProvince).subscribe(response => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.citiesList = response['responseData'].CountryStateCityInfo;
      }
    });
  }

}
