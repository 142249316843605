import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { ApiUrls } from '../constants';
import { User } from '../_models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
    public httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json',
            'subDomain'   : localStorage.getItem('subDomain'),
            'X-Auth-Token': localStorage.getItem('token') || ''
        })
    };

    constructor(private http: HttpClient) {}

    login(user: User) {
        return this.http.post(ApiUrls.loginAPI , user , this.httpOptions )
        .pipe(
            response => response
        );
    }

    logout() {
        return this.http.post(ApiUrls.logoutAPI , {} , this.httpOptions )
        .pipe(
            response => response
        );
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

}