import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiUrls } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PresentationService {

public httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('token') || ''
  })
};


constructor(private http: HttpClient) { }

getUserListData() {
  return this.http.get(ApiUrls.getUserListDataAPI, this.httpOptions )
  .pipe(
      response => response
  );
}

downloadReportData(apiParams){
  this.httpOptions['params'] = apiParams;
  return this.http.get(ApiUrls.dowloadReportAPI,this.httpOptions)
  .pipe(
    response => response
  );
}

deletePresentationService(apiParams){
//this.httpOptions['params'] = apiParams;
return this.http.post(ApiUrls.deleteReportAPI,apiParams,this.httpOptions)
.pipe(
  response => response
);
}

saveAssignTo(presentationDataStoreId:number,assignTo:number) {
  return this.http.post(ApiUrls.saveAssignToAPI+"?presentationDataStoreId="+presentationDataStoreId+"&assignTo="+assignTo, this.httpOptions )
  .pipe(
      response => response
  );
}

}
