import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Variables } from '../../../constants';
import { DatePipe } from '@angular/common';
import { CommonFunctions } from '../../../common';
import { EditIconRowTemplate } from '../../../shared/custom-grid-row-templates/edit-icon';
import { GridComponent } from '../../../shared/grid/grid.component';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ProposalWorkflowComponent } from '../proposal-workflow.component';
import { AuthMessageService } from '../../../_services/auth-message.service';
import { ClientFeeStructureModel,CalInvestmentManagementFeesModel } from '../../../_models/cal-workflow.models';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';
import { CashFlowObjectiveService } from '../../../_services/workflow-services/cash-flow-objective.service';
import { CashFlowObjectiveModel, PrimaryInfoModel, CashInflowModel, CashOutflowModel, SimulationParameterModel, InvestmentManagementFeesModel } from '../../../_models/cash-flow-objective.model';
import * as _ from "lodash";
import * as $ from 'jquery';
import { YearPickerComponent, BsDatepickerConfig } from 'ngx-bootstrap';

@Component({
  selector: 'app-cash-flow-objective',
  templateUrl: './cash-flow-objective.component.html',
  styleUrls: ['./cash-flow-objective.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class CashFlowObjectiveComponent implements OnInit {

  divStatus: boolean = true;
  isxlsx: boolean = true;
  componentReference: any;
  deleteType: string = null;
  simulatorDivStatus: boolean;
  isEditTemplateNameMode:boolean = false;
  @ViewChildren('cashFlowGrid') gridComponentData: QueryList<GridComponent>;
  inflowGridComponentReference: any;
  outflowGridComponentReference: any;
  gridApiMangagement: any;
  isSaveButtonClicked = false;
  commonFunction = new CommonFunctions();
  apiParams: {};
  managementGridColumnDefs: any;
  isLastManagementRow = false;
  frameworkComponents: any;
  managementGridRowData: any;
  investmentManagementFeesMode: string = 'add';
  minimumManagementFees: number;
  dropdownsData :any ={};
  strategyObj: any = {};
  analyticsData;
  stepInfo = {
    'cashFlowObjectiveModel': new CashFlowObjectiveModel()
  };
  managementDivStatus: boolean;
  cashFlowObjectiveModel = new CashFlowObjectiveModel();
  cashInflowModel:any = new CashInflowModel();
  cashOutflowModel:any = new CashOutflowModel();
  simulationParameterModel = new SimulationParameterModel();
  investmentManagementFeesModel = new InvestmentManagementFeesModel();
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  showExpandMsg : boolean;
  isSaveEntityInformation: boolean;

  editedTemplateName:String = "";
  templateName : String;
  feeTypeList:ClientFeeStructureModel[];
  taxabilityTypeList=[];
  isUserLevel:boolean = false;
  isIncludeFeeInAnalysis:boolean=false;
  isEffective:boolean =false;
  feeType:String;
  applyFeeType:string=null;
  taxabilityType;
  applyCurrentTier:boolean = false;
  taxTypeFeeTypeMap:{};

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private workflowBaseService: WorkflowBaseService,
    private authMessageService: AuthMessageService,
    private datePipe: DatePipe,
    private cashFlowObjectiveService: CashFlowObjectiveService,
    public proposalWorkflowComponent: ProposalWorkflowComponent) {
    this.componentReference = { componentParent: this };
    this.frameworkComponents = {
      EditIconRowTemplate: EditIconRowTemplate
    };
  }

  ngAfterViewInit() {
    this.inflowGridComponentReference = this.gridComponentData.toArray().find(function (item) {
      return item.gridName == 'workflow-inflow-list';
    });
    this.outflowGridComponentReference = this.gridComponentData.toArray().find(function (item) {
      return item.gridName == 'workflow-outflow-list';
    });
    $(".ag-body .ag-body-viewport").css("overflow-x","scroll")
    $(".ag-pinned-left-cols-viewport").css("overflow-x","scroll")
    $(".ag-pinned-left-cols-viewport").css("overflow-y","hidden")
  }


  ngOnInit() {
    this.feeType = null;
    this.dpConfig.containerClass = "theme-blue";
    this.analyticsData = {
      parentComponent: "cashFlowObjectives"
    }
    //this.isSaveEntityInformation=false;
   
    //this.showExpandMsg = false;
    this.apiParams = {
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId
    };

    let requestObj = {
      "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "relationshipSelection",
      "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData'].taskVariables) {
          this.cashFlowObjectiveModel.relationshipId = response['responseData'].taskVariables.relationshipId;
        }
      }
    });

    requestObj = {
      "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "cashFlowObjectives",
      "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData']) {
          if(response['responseData'].taskVariables.cashFlowObjectives.primaryInfo)
           this.cashFlowObjectiveModel.primaryInfo = response['responseData'].taskVariables.cashFlowObjectives.primaryInfo;
           
          if(response['responseData'].taskVariables.cashFlowObjectives.secondaryInfo)
            this.cashFlowObjectiveModel.secondaryInfo = response['responseData'].taskVariables.cashFlowObjectives.secondaryInfo;
          if(response['responseData'].taskVariables.cashFlowObjectives.planningTimeHorizon)
            this.cashFlowObjectiveModel.planningTimeHorizon = response['responseData'].taskVariables.cashFlowObjectives.planningTimeHorizon;
          if(!this.cashFlowObjectiveModel.primaryInfo["lifeExpectancy"] ||!this.cashFlowObjectiveModel.planningTimeHorizon){
            this.isSaveEntityInformation=false;
          }else{
            this.isSaveEntityInformation=true;
          }
          this.getSimulationParameter();
          if(response['responseData'].taskVariables.portfolioIdVsStrategies.portfoiloAnalysisMessage){
            this.strategyObj=response['responseData'].taskVariables.portfolioIdVsStrategies.portfoiloAnalysisMessage;
            this.showExpandMsg=true;
          }
          
          this.stepInfo.cashFlowObjectiveModel = JSON.parse(JSON.stringify(this.cashFlowObjectiveModel));
          this.workflowBaseService.setStepInfoVsStepName('cashFlowObjectives', this.stepInfo);
        }
      }
    });
    

    this.getCashFlowSelectionEnum();

    this.managementGridColumnDefs =
      [
        {
          "headerName": "",
          "checkboxSelection": true,
          "headerCheckboxSelection": true,
          "width": 30,
          "minWidth":15,
          "pinned": "left",
          "suppressResize": true,
          "lockPosition": true,
          "suppressSorting": true,
          "suppressMenu": true
        },
        { 
          "headerName": "",
          "width": 20,
          "minWidth":15,
          "pinned": "left",
          "suppressResize": true,
          "lockPosition": true,
          "suppressMenu": true,
          "suppressSorting": true,
          "cellRenderer": 'EditIconRowTemplate',
          "hide": false,
          "noHide": true
        },
        // {
        //   "headerName": "Schedule Id",
        //   "field": "scheduleId",
        //   "minWidth": 80,
        //   "backendFieldName": "scheduleId",
        //   "suppressSorting": true,
        //   "suppressMenu": true,
        //   "filter": "SelectFilterComponent",
        //   "hide": false
        // },
        {
          "headerName": "Begin Amount",
          "field": "beginAmountView",
          "minWidth": 70,
          "backendFieldName": "beginAmount",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "End Amount",
          "field": "endAmountView",
          "minWidth": 70,
          "backendFieldName": "endAmount",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "IM Fee (%)",
          "field": "imaFeePercentageView",
          "minWidth": 70,
          "backendFieldName": "imaFeePercentage",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Advisory Fee (%)",
          "field": "advisoryFeePercentageView",
          "minWidth": 70,
          "backendFieldName": "advisoryFeePercentage",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        },
        {
          "headerName": "Account Fee (%)",
          "field": "accountFeeView",
          "minWidth": 70,
          "backendFieldName": "accountFee",
          "suppressSorting": true,
          "suppressMenu": true,
          "filter": "SelectFilterComponent",
          "hide": false
        }
      ]
      var ele = document.getElementsByClassName('height-fix')[0];
      if(ele) {
        ele.classList.remove("app-hierarchy-view");
      }
  }

  ngOnDestroy() {
    var ele = document.getElementsByClassName('height-fix')[0];
    if(ele) {
      ele.classList.add("app-hierarchy-view");
    }
  }

  getSimulationParameter() {
    this.cashFlowObjectiveService.getSimulationParameter(this.apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.simulationParameterModel = response['responseData'];

      }
    });
  }

  getEntityInformation() {
    this.cashFlowObjectiveService.getEntityInformation(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.cashFlowObjectiveModel.primaryInfo = response['responseData'].primaryInfo;
        this.cashFlowObjectiveModel.secondaryInfo = response['responseData'].secondaryInfo;
        this.cashFlowObjectiveModel.planningTimeHorizon = response['responseData'].planningTimeHorizon;
      }
    });
  }

  getManagementFees() {
    this.cashFlowObjectiveService.getManagementFees(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response["responseData"] && response["responseData"].length) {
          this.managementGridRowData = response["responseData"];
          this.managementGridRowData.forEach(element => {
            element.gridName = 'workflow-management-fee-list'
          });
          this.minimumManagementFees = this.managementGridRowData[0].minimumFee;
          this.investmentManagementFeesMode = 'add';
          this.investmentManagementFeesModel = new InvestmentManagementFeesModel();
          this.investmentManagementFeesModel.beginAmount = this.managementGridRowData[this.managementGridRowData.length - 1].endAmount + 1;
        } else {
          this.managementGridRowData =[];
          this.investmentManagementFeesModel.beginAmount = 0;

        }
        this.isLastManagementRow = false;
        setTimeout(() => {
          this.gridApiMangagement.sizeColumnsToFit();
        },200);
      }
    });
  }

  saveDefaultManagementFees() {
    this.cashFlowObjectiveService.saveDefaultManagementFees(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response["responseData"] && response["responseData"].length) {
          this.managementGridRowData = response["responseData"];
          this.managementGridRowData.forEach(element => {
            element.gridName = 'workflow-management-fee-list'
          });
          this.minimumManagementFees = this.managementGridRowData[0].minimumFee;
          this.investmentManagementFeesMode = 'add';
          this.investmentManagementFeesModel = new InvestmentManagementFeesModel();
          this.investmentManagementFeesModel.beginAmount = this.managementGridRowData[this.managementGridRowData.length - 1].endAmount + 1;
        } else {
          this.investmentManagementFeesModel.beginAmount = 0;
        }

      }
    });
  }

  convertToCommaSeperated(row,key){
    let value = row[key];
    var partBeforeDecimal = '',partAfterDecimal='';
    var index = value.indexOf('.')
    if(index>-1){
      partBeforeDecimal = value.substr(0,index);
      partAfterDecimal = value.substr(index,value.length-index);
    }else {
      partBeforeDecimal = value;
    }

    if(partBeforeDecimal){
      partBeforeDecimal = partBeforeDecimal.replace( /,/g, "" );
      row[key] = (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
    }
    if(row['isPercentBased']){
      row[key]=row[key]+"%";
    }

  }

  saveMinimumFee() {
    if (!this.minimumManagementFees) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter minimum fee';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      // this.cashFlowObjectiveService.saveMinimumFee(this.minimumManagementFees, this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.taxabilityType).subscribe((response) => {
      //   if (response['responseType'] == Variables.statusSuccess) {
      //     if (response["responseData"]) {
      //       this.authMessageService.showSuccessPopup(response);

      //     }

      //   }
      // });
      let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
      if(!feeTypeModel.advisorFee.length){
        let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please create at least one fee tier';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
      }
    feeTypeModel.advisorFee[0].minimumFee =this.minimumManagementFees;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
    }

  }

  getCashFlowSelectionEnum() {
     this.apiParams={
       processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
       stepName: "cashFlowObjectives"
     }
    this.workflowBaseService.getEnumDataBasedOnStep(this.apiParams).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.dropdownsData = {};
        this.dropdownsData["cashInflowTypeList"] = response['responseData'].cashInFlowTypeSubTypeList;
        this.dropdownsData["cashOutflowTypeList"] = response['responseData'].cashOutFlowTypeSubTypeList;
        this.dropdownsData["cashFlowFrequency"] = response['responseData'].cashFlowFrequency;
        this.dropdownsData["portfolioList"] = response['responseData'].portfolioAccountMap;
        this.dropdownsData["portfolioAccountList"] = [];
        if (this.dropdownsData["portfolioList"]) {
          this.dropdownsData["portfolioList"].forEach(element => {
            var node = {};
            node["isHeader"] = true;
            node["code"] = element.portfolioId;
            node["display"] = element.portfolioName;
            this.dropdownsData["portfolioAccountList"].push(node);
            if (element.accountList) {
              element.accountList.forEach(item => {
                var subNode = {};
                subNode["isHeader"] = false;
                subNode["code"] = item.accountId;
                subNode["display"] = '--' + item.accName;
                this.dropdownsData["portfolioAccountList"].push(subNode);
              });
            }
          });
        }

      }
    });
  }

  onChangeTypeInflow(value: any,shouldMakeNull) {
    var accountType = _.find(this.dropdownsData["cashInflowTypeList"], { 'code': value });
    if (!_.isUndefined(accountType)) {
      this.dropdownsData["cashInflowSubTypeList"] = accountType.subType;
    }
    else {
      this.dropdownsData["cashInflowSubTypeList"] = [];
    }
    if(shouldMakeNull)
     this.cashInflowModel.subType = null;
  }

  onChangeTypeOutflow(value: any,shouldMakeNull) {
    var accountType = _.find(this.dropdownsData["cashOutflowTypeList"], { 'code': value });
    if (!_.isUndefined(accountType)) {
      this.dropdownsData["cashOutflowSubTypeList"] = accountType.subType;
    }
    else {
      this.dropdownsData["cashOutflowSubTypeList"] = [];
    }
    if(shouldMakeNull)
      this.cashOutflowModel.subType = null;
  }

  onChangePortfolioOutflow(value: any) {
    var accountType = _.find(this.dropdownsData["portfolioList"], { 'portfolioId': value });
    if (!_.isUndefined(accountType)) {
      //this.dropdownsData["accountList"] = accountType.accountList;
      if (this.cashOutflowModel.accountIds && this.cashOutflowModel.accountIds.length) {
        this.cashOutflowModel.accountVOList = [];
        this.cashOutflowModel.accountIds.forEach(element => {
          var acc = _.find(accountType.accountList, { 'accountId': element });
          if (acc) {
            this.cashOutflowModel.accountVOList.push({
              "accountId": element,
              "accName": acc.accName
            })
          }
        });
      } else {
        this.cashOutflowModel.accountVOList = accountType.accountList;
      }

    }
    else {
      this.cashOutflowModel.accountVOList = [];
    }
  }

  downloadCashflowTemplate() {
    this.cashFlowObjectiveService.downloadCashflowTemplate(this.proposalWorkflowComponent.workflowInfoData.processInstanceId, this.isxlsx).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        var fileData = response['responseData'].fileData;
        var fileName = response['responseData'].fileName;
        if (fileData && fileName) {
          CommonFunctions.downloadFileUsingByteCode(fileName, fileData);
        }
        this.isxlsx = true;
      }
    });
  }

  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {

    if (rowData['gridName'] == "workflow-inflow-list") {
      this.editInflowAccount(Object.assign({}, rowData));
    } else if (rowData['gridName'] == "workflow-outflow-list") {
      this.editOutflowAccount(Object.assign({}, rowData));
    } else if (rowData['gridName'] == "workflow-management-fee-list") {
      this.investmentManagementFeesModel = rowData;
      this.investmentManagementFeesMode = 'edit';
      this.ngxSmartModalService.open('addFee');
    }
  }

  saveInflow() {
    let requestObj = JSON.parse(JSON.stringify(this.cashInflowModel));
    if(requestObj.amount)
      requestObj.amount = requestObj.amount.replace( /,/g, "" );
    if (this.validateInflowData(requestObj)) {
      let startDate = this.cashInflowModel.startDate;
      requestObj.startDate = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      let endDate = this.cashInflowModel.endDate;
      requestObj.endDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      this.cashFlowObjectiveService.saveInflow(requestObj, this.apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.inflowGridComponentReference.getGridDataAfterUpdatingRecords();
          this.ngxSmartModalService.getModal('inflowModal').close();
          this.stepInfo.cashFlowObjectiveModel = JSON.parse(JSON.stringify(this.cashFlowObjectiveModel));
          this.workflowBaseService.setStepInfoVsStepName('cashFlowObjectives', this.stepInfo);
        }
      });
    }

  }

  saveSimulationParameter() {
    if (this.validateSimulationParameter(this.simulationParameterModel)) {
      this.cashFlowObjectiveService.saveSimulationParameter(this.simulationParameterModel, this.apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.close();
        }
      });
    }

  }

  saveManagementFees() {
    if (!this.managementGridRowData)
      this.managementGridRowData = [];
    let lastRow = this.managementGridRowData[this.managementGridRowData.length - 1];
    let lastMin = null;
    if (lastRow)
      lastMin = this.managementGridRowData[this.managementGridRowData.length - 1].endAmount;
    // if (!this.minimumManagementFees) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter minimum management fee';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    // }
    if (!this.investmentManagementFeesModel.feePercentage) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter fee percentage';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (lastRow && !lastMin) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Management fee cannot be saved as end amount of previous record is not entered';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (this.investmentManagementFeesModel.endAmount && this.investmentManagementFeesModel.endAmount <= this.investmentManagementFeesModel.beginAmount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'End amount should be greater than current amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      this.cashFlowObjectiveService.saveManagementFees(this.investmentManagementFeesModel, this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.investmentManagementFeesModel = new InvestmentManagementFeesModel();
          this.investmentManagementFeesMode = 'add';
          this.getManagementFees();
        }
      });
    }

  }

  editManagementFees() {
    if (!this.managementGridRowData)
      this.managementGridRowData = [];
    let lastRow = this.managementGridRowData[this.managementGridRowData.length - 1];
    let lastMin = null;
    if (lastRow)
      lastMin = this.managementGridRowData[this.managementGridRowData.length - 1].endAmount;
    if (!this.minimumManagementFees) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter minimum  fee';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (!this.investmentManagementFeesModel.feePercentage) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter fee percentage';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (lastRow && !lastMin) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Management fee cannot be saved as minimum fee of previous record is not entered';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if (this.investmentManagementFeesModel.endAmount && this.investmentManagementFeesModel.endAmount <= this.investmentManagementFeesModel.beginAmount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'End amount should be greater than current amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }else{
      this.cashFlowObjectiveService.editManagementFees(this.investmentManagementFeesModel, this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.investmentManagementFeesModel = new InvestmentManagementFeesModel();
          this.investmentManagementFeesMode = 'add';
          this.getManagementFees();
        }
      });
    }
    
  }


  saveOutflow() {
    let requestObj = JSON.parse(JSON.stringify(this.cashOutflowModel));
    if(requestObj.amount)
      requestObj.amount = requestObj.amount.replace( /,/g, "" );
    if(requestObj.amount && requestObj.amount.slice(-1)=="%"){
      requestObj.amount=requestObj.amount.replace(/%/g,"");
    }

    if (this.validateOutFlowData(requestObj)) {
      requestObj.accountIds = [];
      requestObj.accountVOList.forEach(element => {
        requestObj.accountIds.push(element.accountId);
      });
      let startDate = requestObj.startDate;
      requestObj.startDate = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      let endDate = requestObj.endDate;
      requestObj.endDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      this.cashFlowObjectiveService.saveOutflow(requestObj, this.apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.outflowGridComponentReference.getGridDataAfterUpdatingRecords();
          this.ngxSmartModalService.getModal('outflowModal').close();
        }
      });
    }

  }

  saveButtonClicked(){
    this.isSaveButtonClicked = true;
  }

  saveEntityInformation() {
    if (true) {
      this.cashFlowObjectiveService.saveEntityInformation(this.cashFlowObjectiveModel, this.apiParams).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          if(this.cashFlowObjectiveModel.primaryInfo["lifeExpectancy"] && this.cashFlowObjectiveModel.planningTimeHorizon){
            this.isSaveEntityInformation=true;
          }else{
            this.isSaveEntityInformation=false;
          }
          this.stepInfo.cashFlowObjectiveModel = this.cashFlowObjectiveModel;
          this.workflowBaseService.setStepInfoVsStepName('cashFlowObjectives', this.stepInfo);
          if(this.isSaveButtonClicked){
            this.isSaveButtonClicked = false;
            this.portfolioAnalyticsFn();
          }
        }
      });
    }

  }

  addNewInflowAccount() {
    this.cashInflowModel = new CashInflowModel();
    this.cashInflowModel.inflationRate = this.simulationParameterModel.inflationRate;
   // this.calculateEndDataInflow()
    this.ngxSmartModalService.open('inflowModal');
  }

  calculateEndDataInflow() {
    let dob = "01/01/1970";
    if (this.cashFlowObjectiveModel.primaryInfo['dateOfBirth']) {
      dob = this.cashFlowObjectiveModel.primaryInfo['dateOfBirth'];
    }
    let dobInArray = dob.split('/');
    let year = Number(dobInArray[2]);
    let retirementAge = 0;
    if (this.cashFlowObjectiveModel.primaryInfo['retirementAge']) {
      retirementAge = Number(this.cashFlowObjectiveModel.primaryInfo['retirementAge']);
    }
    year += retirementAge;
    this.cashInflowModel.endDate = dobInArray[0] + '/' + dobInArray[1] + '/' + year;
    let currentDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    if (new Date(currentDate).getTime() > new Date(this.cashInflowModel.endDate).getTime()) {
      this.cashInflowModel.endDate = currentDate;
    }

  }

  editInflowAccount(row) {
    this.cashInflowModel = row;
    this.cashInflowModel.amount = this.cashInflowModel.amount.toLocaleString();
    this.onChangeTypeInflow(row.type,false);
    this.ngxSmartModalService.open('inflowModal');
  }

  editOutflowAccount(row) {
    this.cashOutflowModel = row;
    this.cashOutflowModel.amount = this.cashOutflowModel.amount.toLocaleString();
    this.onChangeTypeOutflow(row.type,false);
    this.onChangePortfolioOutflow(row.portfolioId);
    this.ngxSmartModalService.open('outflowModal');
  }

  addNewOutflowAccount() {
    this.cashOutflowModel = new CashOutflowModel();
    this.cashOutflowModel.inflationRate = this.simulationParameterModel.inflationRate;
    //this.calculateEndDataOutflow();
    this.ngxSmartModalService.open('outflowModal');
  }

  calculateEndDataOutflow() {
    let dob = "01/01/1970";
    if (this.cashFlowObjectiveModel.primaryInfo['dateOfBirth']) {
      dob = this.cashFlowObjectiveModel.primaryInfo['dateOfBirth'];
    }
    let dobInArray = dob.split('/');
    let year = Number(dobInArray[2]);
    let lifeExpectancy = 0;
    if (this.cashFlowObjectiveModel.primaryInfo['lifeExpectancy']) {
      lifeExpectancy = Number(this.cashFlowObjectiveModel.primaryInfo['lifeExpectancy']);
    }
    year += lifeExpectancy;
    this.cashOutflowModel.endDate = dobInArray[0] + '/' + dobInArray[1] + '/' + year;
    let currentDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    if (new Date(currentDate).getTime() > new Date(this.cashOutflowModel.endDate).getTime()) {
      this.cashOutflowModel.endDate = currentDate;
    }
  }

  deleteInflowAccount(): void {
    var accountList = this.inflowGridComponentReference.getSelectedRows();
    if (accountList && accountList.length != 0) {
      this.deleteType = "inflowAccount";
      this.ngxSmartModalService.open('deleteConfirmationModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  openDownloadConfirmation() {
    this.ngxSmartModalService.open('downloadConfirmationModal');
  }

  deleteOutflowAccount(): void {
    var accountList = this.outflowGridComponentReference.getSelectedRows();
    if (accountList && accountList.length != 0) {
      this.deleteType = "outflowAccount";
      this.ngxSmartModalService.open('deleteConfirmationModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteManagementFee(): void {
    var list = this.gridApiMangagement.getSelectedRows();
    if (list && list.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (list && list[0].scheduleId != this.managementGridRowData[this.managementGridRowData.length - 1].scheduleId) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Only last record can be deleted';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (list) {
      this.deleteType = "managementFee";
      this.ngxSmartModalService.open('deleteConfirmationModal');
    }
  }

  deleteAfterConfirmation() {
    if (this.deleteType == "inflowAccount") {
      this.deleteInflowAfterConfirmation();
    } else if (this.deleteType == "outflowAccount") {
      this.deleteOutflowAfterConfirmation();
    } else if (this.deleteType == "managementFee") {
      this.deleteManagementFeeAfterConfirmation();
    }

    this.deleteType = null;
  }

  deleteInflowAfterConfirmation() {
    var inflowAccountList = this.inflowGridComponentReference.getSelectedRows();
    let requestObj = []
    inflowAccountList.forEach(element => {
      requestObj.push(element.cashflowId)
    });
    this.cashFlowObjectiveService.deleteInflow(requestObj, this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.inflowGridComponentReference.getGridDataAfterUpdatingRecords();
        this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
      }
    });
  }

  deleteOutflowAfterConfirmation() {
    var outflowAccountList = this.outflowGridComponentReference.getSelectedRows();
    let requestObj = []
    outflowAccountList.forEach(element => {
      requestObj.push(element.cashflowId)
    });
    this.cashFlowObjectiveService.deleteOutflow(requestObj, this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.outflowGridComponentReference.getGridDataAfterUpdatingRecords();
        this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
      }
    });
  }

  deleteManagementFeeAfterConfirmation() { 
    var managementFeeList = this.gridApiMangagement.getSelectedRows();
    this.cashFlowObjectiveService.deleteManagementFees(managementFeeList[0].scheduleId, this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.taxabilityType).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
        this.getFeeTemplatesForUser();
      }
    });
  }

  uploadConfigFile(fileDataObj) {
    var file = $('#upload-file').val();
    var fileName = null;
    var fileExtension = '';
    if (fileDataObj.target[0].files[0]) {
      fileName = fileDataObj.target[0].files[0].name;
      fileExtension = fileName.split(".").pop(-1);
      fileExtension = fileExtension.toLowerCase();
    }
    if (!fileName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select a file to upload';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } if (fileExtension != 'xlsx' && fileExtension != 'xls') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Only excel file can be used for import';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else {
      let formData = new FormData();
      // Add your values in here
      var fileData = fileDataObj.target[0].files[0];
      var accountId = fileDataObj.target[1].value;
      if (fileData) {
        formData.append('configFile', fileData, fileData.name);
        formData.append('accountId', accountId);
        //formData.append('processInstanceId', this.proposalWorkflowComponent.workflowInfoData.processInstanceId);

        this.cashFlowObjectiveService.uploadData(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,formData)
          .subscribe((response) => {
            if (response['responseType'] == Variables.statusSuccess) {
              this.authMessageService.showSuccessPopup(response);
              this.inflowGridComponentReference.getGridDataAfterUpdatingRecords();
              this.outflowGridComponentReference.getGridDataAfterUpdatingRecords();
            }
          });
      }
    }
  }

  /* On grid ready */
  onGridReady(params) {
    this.gridApiMangagement = params.api;
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    },200);
    var wait = setInterval(()=>{
      if($('.app-grid-heading')[0].clientHeight){
        clearInterval(wait)
        this.adjustHeightGridViewport(0);  
      }
    })
  }

  divStatusClick(id) {

    if (id == "simulatorParameter") {
      this.simulatorDivStatus = true;
      this.managementDivStatus = false;
    } else {
      this.simulatorDivStatus = false;
      this.managementDivStatus = true;
      this.investmentManagementFeesModel = new InvestmentManagementFeesModel();
        
      this.getFeeTemplatesForUser();
      //this.getManagementFees();
    }

    if (this.divStatus) {
      this.divStatus = false;
    } else {
      this.divStatus = true;
    }
  }

  close() {
    this.divStatus = true;
  }

  portfolioAnalyticsFn() {
    this.isSaveButtonClicked = false;
    if(!this.isSaveEntityInformation){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Life Expectancy and Projection Period';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }else{
     let requestObj = {
      "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "cashFlowObjectives"
    };
    this.cashFlowObjectiveService.analyseWorkflow(requestObj).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        
        this.strategyObj = response['responseData'];
        this.authMessageService.showSuccessPopup(response);
        this.showExpandMsg = true;
        this.getCashFlowSelectionEnum();
      }
    });
  }
}


  openPortfolioAnalyticsPopup(){
   
    this.ngxSmartModalService.open('portfolioAnalytics');
 
  }

  validateEntityInfo() {
    if (!this.cashFlowObjectiveModel.primaryInfo["lifeExpectancy"]) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Life Expectancy';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!this.cashFlowObjectiveModel.planningTimeHorizon) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Planning Time Horizon';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }

  validateSimulationParameter(data) {
    if (!data.numberOfTrials) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Number Of Trials';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.inflationRate) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Inflation Rate';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }

  validateInflowData(data) {
    if (!data.type || data.type == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.subType || data.subType == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter SubType';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.inflationRate && !data.isPercentBased) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Inflation Rate';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.amount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.frequency || data.frequency == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Frequency';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.startDate) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Start Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let startDate = this.datePipe.transform(data.startDate, 'MM/dd/yyyy');
    let endDate = this.datePipe.transform(data.endDate, 'MM/dd/yyyy');
    if (data.startDate && data.endDate && new Date(startDate).getTime() > new Date(endDate).getTime()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Start Date  should not  be greater than End Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.endDate && data.frequency != 'ONE_TIME') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter End Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.clientHierarchyId || data.clientHierarchyId =="null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Portfolio Id';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }

  validateOutFlowData(data) {
    if (!data.type || data.type == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.subType || data.subType == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter SubType';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.inflationRate && !data.isPercentBased) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Inflation Rate';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.amount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.frequency || data.frequency == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Frequency';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.startDate) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Start Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let startDate = this.datePipe.transform(data.startDate, 'MM/dd/yyyy');
    let endDate = this.datePipe.transform(data.endDate, 'MM/dd/yyyy');
    if (data.startDate && data.endDate && new Date(startDate).getTime() > new Date(endDate).getTime()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Start Date  should not  be greater than End Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.endDate && data.frequency != 'ONE_TIME') {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter End Date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.portfolioId || data.portfolioId == "null") {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Portfolio Id';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }

  adjustHeightGridViewport(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let optionDiv = $('.optionDiv')[0].clientHeight;
    let importFormDiv = $('.importFormDiv')[0].clientHeight;
    let priSecInfoDiv = $('.priSecInfoDiv')[0].clientHeight;
    let gridHeader = $('.app-grid-heading')[0].clientHeight;
    let margin = 5+ extraMargin;
    var total = header+ pageheader+ margin+ stepHeader+ optionDiv+ importFormDiv+ 2*gridHeader+ priSecInfoDiv
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if(ele) {
      ele.setAttribute('style',"height: calc((100vh - "+total+"px)*0.5);")
    }
    var ele1 = document.getElementsByTagName('ag-grid-angular')[1];
    if(ele1) {
      ele1.setAttribute('style',"height: calc((100vh - "+total+"px)*0.5);")
    }
  }





  updateFeeType(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if(feeTypeModel){
      this.managementGridRowData = feeTypeModel["advisorFee"];
      this.managementGridRowData.forEach(element => {
        if(element.accountFee)
         element.accountFeeView = element.accountFee + "%";

        if(element.imaFeePercentage)
         element.imaFeePercentageView = element.imaFeePercentage + "%";

        if(element.advisoryFeePercentage)
         element.advisoryFeePercentageView = element.advisoryFeePercentage + "%";

         if(element.beginAmount || element.beginAmount == 0)
          element.beginAmountView = this.convertToCommaSeperatedSingleVal(element.beginAmount.toString()) ;

        if(element.endAmount || element.endAmount == 0)
          element.endAmountView = this.convertToCommaSeperatedSingleVal(element.endAmount.toString()) ;

      });
      if(feeTypeModel.advisorFee.length)
        this.minimumManagementFees = feeTypeModel.advisorFee[0].minimumFee;
      else
      this.minimumManagementFees = 0;
      this.isUserLevel = feeTypeModel.isUserLevel;
      this.isEffective = feeTypeModel.isEffective; 
      this.isIncludeFeeInAnalysis = feeTypeModel.includeFeeInAnalysis;
      }
    setTimeout(() => {
      this.gridApiMangagement.sizeColumnsToFit();
    },200);
    

  }

  updateApplyFeeType(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.applyFeeType });
    if(feeTypeModel){
      this.managementGridRowData = feeTypeModel["advisorFee"];
      this.managementGridRowData.forEach(element => {
        if(element.accountFee)
         element.accountFeeView = element.accountFee + "%";

        if(element.imaFeePercentage)
         element.imaFeePercentageView = element.imaFeePercentage + "%";

        if(element.advisoryFeePercentage)
         element.advisoryFeePercentageView = element.advisoryFeePercentage + "%";

        if(element.beginAmount || element.beginAmount == 0)
          element.beginAmountView = this.convertToCommaSeperatedSingleVal(element.beginAmount.toString()) ;

        if(element.endAmount || element.endAmount == 0)
          element.endAmountView = this.convertToCommaSeperatedSingleVal(element.endAmount.toString()) ;

      });
      if(feeTypeModel.advisorFee.length)
        this.minimumManagementFees = feeTypeModel.advisorFee[0].minimumFee;
      else
        this.minimumManagementFees = 0;
      this.isUserLevel = feeTypeModel.isUserLevel;
      this.isEffective = feeTypeModel.isEffective; 
    }
    
  }


  convertToCommaSeperatedSingleVal(row) {
    let value = row
    var partBeforeDecimal = '', partAfterDecimal = '';
    var index = value.indexOf('.')
    if (value == '$' || value == "") {
      row = '$0';
    } else {
      if (index > -1) {
        partBeforeDecimal = value.substr(0, index);
        partAfterDecimal = value.substr(index, value.length - index);
      } else {
        partBeforeDecimal = value;
      }

      if (partBeforeDecimal) {
        partBeforeDecimal = partBeforeDecimal.replace(/,/g, "").replace('$', "");
        row = '$' + (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
      }
    }
    return row;
  }

  updateTaxType(){
    this.feeTypeList = this.taxTypeFeeTypeMap[this.taxabilityType];
        if(this.feeTypeList.length){
          this.feeType = this.feeTypeList[0].objectId;
          this.updateFeeType();
        }
  }

  editName(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if(feeTypeModel){
      this.editedTemplateName = feeTypeModel.name ;
      this.isEditTemplateNameMode = true;
    }
    
  }

  getFeeTemplatesForUser() { 
    this.workflowBaseService.getFeeTemplatesForUser().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.taxTypeFeeTypeMap = response['responseData'];
        this.taxabilityTypeList = Object.keys(this.taxTypeFeeTypeMap);
        if(!this.taxabilityType)
         this.taxabilityType = this.taxabilityTypeList[0];
        this.feeTypeList = this.taxTypeFeeTypeMap[this.taxabilityType];
        if(this.feeTypeList.length){
          if(this.feeTypeList[0].advisorFee.length)
            this.minimumManagementFees = this.feeTypeList[0].advisorFee[0].minimumFee;
          if(!this.feeType)
           this.feeType = this.feeTypeList[0].objectId;
          this.updateFeeType();
        }
          
      }
    });
  }

  saveNewTemplate(){

    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.applyFeeType });
    let duplicateName = _.find(this.taxTypeFeeTypeMap[this.taxabilityTypeList[0]],(item)=>{
      return item.name.toLowerCase() == this.templateName.toLocaleLowerCase()
    });
    let duplicateName2 = _.find(this.taxTypeFeeTypeMap[this.taxabilityTypeList[1]] ,(item)=>{
      return item.name.toLowerCase()==this.templateName.toLocaleLowerCase();
    });
    if (!this.templateName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    if (!this.templateName.trim()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter a valid template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    // if (duplicateName || duplicateName2) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Template with this name already exists';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   this.isEditTemplateNameMode = false;
    //   return false;
    // }
    if (duplicateName) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with this name already exists in'+" "+duplicateName.taxablityType;
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    else if(duplicateName2)
    {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with this name already exists in'+" "+duplicateName2.taxablityType;
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;

    }
    if(!feeTypeModel)
      feeTypeModel =  {};
    feeTypeModel.objectId = null;
    feeTypeModel.taxablityType=this.taxabilityType
    feeTypeModel.name = this.templateName;
    feeTypeModel.includeFeeInAnalysis=this.isIncludeFeeInAnalysis;
    feeTypeModel.isUserLevel =this.isUserLevel;
    if(!this.applyFeeType ){
      feeTypeModel.advisorFee = []; 
    }
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('templatePopModal').close();
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveTemplateName(){

    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    let duplicateName = _.find(this.taxTypeFeeTypeMap[this.taxabilityTypeList[0]] , {'name' : this.templateName});
    let duplicateName2 = _.find(this.taxTypeFeeTypeMap[this.taxabilityTypeList[1]] , {'name' : this.templateName});
    if (!this.editedTemplateName.trim()) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter a valid template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    if (duplicateName || duplicateName2) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with this name already exists';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      this.isEditTemplateNameMode = false;
      return false;
    }
    feeTypeModel.name = this.editedTemplateName;
    feeTypeModel.isUserLevel =this.isUserLevel;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveUserLevel(){
    if(this.isUserLevel){
      let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
      feeTypeModel.isUserLevel =this.isUserLevel;
      this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
    }
    
  }

  saveIncludeFeeAnalysis(){
      let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
      feeTypeModel.includeFeeInAnalysis =this.isIncludeFeeInAnalysis;
      this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveIsEffective(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if(this.isEffective && !feeTypeModel["advisorFee"].length){
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Template with no fee tiers cannnot be made default';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      setTimeout(() => {
        
      this.isEffective = false;
      }, 500);
      
    }else{
      feeTypeModel.isEffective =this.isEffective;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.isEditTemplateNameMode = false;
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
    }
    
  }

  resetFeeTemplate(){
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    feeTypeModel.isEffective =this.isEffective;
    this.workflowBaseService.resetFeeTemplate(this.isUserLevel,this.feeType).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.getFeeTemplatesForUser();

      }
    });
  }

  saveFeeTemplate() {
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    let lastRow = feeTypeModel["advisorFee"][feeTypeModel["advisorFee"].length-1];
    if (!this.investmentManagementFeesModel.beginAmount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Begin Amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!feeTypeModel["advisorFee"].length && this.investmentManagementFeesModel.beginAmount != 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Begin amount of first tier should be zero';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (lastRow && this.investmentManagementFeesModel.beginAmount <= lastRow.beginAmount+1) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Begin Amount should be greater than begin amount of last fee tier';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (!this.investmentManagementFeesModel.endAmount) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter End Amount';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (!this.investmentManagementFeesModel.imaFeePercentage && !this.investmentManagementFeesModel.advisoryFeePercentage && !this.investmentManagementFeesModel.accountFee) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter either IM Fee % or Advisory Fee %  or Account Fee %';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    feeTypeModel.isUserLevel =this.isUserLevel;
    if(feeTypeModel["advisorFee"].length)
      feeTypeModel["advisorFee"][feeTypeModel["advisorFee"].length-1].endAmount = this.investmentManagementFeesModel.beginAmount -1;
    feeTypeModel["advisorFee"].push(this.investmentManagementFeesModel);
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('addFee').close();
        this.getFeeTemplatesForUser();

      }
    });
  }

  editFeeTemplate() {
    let feeTypeModel = _.find(this.feeTypeList, { 'objectId': this.feeType });
    if (!this.investmentManagementFeesModel.beginAmount) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter Begin Amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    // if (!this.investmentManagementFeesModel.endAmount) {
    //   let errorObj = {};
    //   errorObj['errorCode'] = 'ERR0107';
    //   errorObj['message'] = 'Please enter End Amount';
    //   errorObj['errorDescription'] = '';
    //   this.authMessageService.showErrorPopup(errorObj);
    //   return false;
    // }
    if (!this.investmentManagementFeesModel.imaFeePercentage && !this.investmentManagementFeesModel.advisoryFeePercentage && !this.investmentManagementFeesModel.accountFee) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter either IM Fee % or Advisory Fee %  or Account Fee %';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    feeTypeModel.isUserLevel =this.isUserLevel;
    this.workflowBaseService.saveFeeTemplate(feeTypeModel).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        console.log(response['responseData']);
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('addFee').close();
        this.isEditTemplateNameMode = false;
        this.getFeeTemplatesForUser();

      }
    });
  }


  

  addAdvisorFee(){
    this.investmentManagementFeesModel = new InvestmentManagementFeesModel();
    this.investmentManagementFeesMode = 'add';
    this.ngxSmartModalService.open('addFee');
  }

 

 



  deleteFeeSchedule(): void {
    var list = this.gridApiMangagement.getSelectedRows();
    if (list && list.length == 0) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (list && list[0].scheduleId != this.managementGridRowData[this.managementGridRowData.length - 1].scheduleId) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Only last record can be deleted';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else if (list) {
      this.deleteType = "managementFee";
      this.ngxSmartModalService.open('deleteConfirmationModal');
    }
  }

  deleteFeeScheduleAfterConfirmation() {
    var managementFeeList = this.gridApiMangagement.getSelectedRows();
    this.cashFlowObjectiveService.deleteManagementFees(managementFeeList[0].scheduleId, this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.taxabilityType).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
        this.getFeeTemplatesForUser();
      }
    });
  }

   /*delete fee template*/
   deleteFeeTemplate(){
    console.log("selected template:"+this.feeType);
    if(!this.feeType || this.feeType == "null")
    {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select template';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;

    }
    else{
      this.ngxSmartModalService.open('deleteTemplateConfirmationModal');
      
    }
    

  } 

  deleteTemplateAfterConfirmation()
  {
        let feeTypeModel=_.find(this.feeTypeList,{'objectId':this.feeType})
        feeTypeModel.isUserLevel=this.isUserLevel; 
        this.workflowBaseService.deleteSelectedFeeTemplate(this.isUserLevel,this.feeType).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
            this.getFeeTemplatesForUser();
    
          }
        });
        if(feeTypeModel.name=='IRA' || feeTypeModel.name=='IMA')
        {
          this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
        }
        else{
          feeTypeModel.advisorFee = []; 
         this.updateFeeType();
        }
  }
  
  /*add Template pop*/
  addTemplate(){
    this.templateName=null;
    this.applyFeeType=null;
    this.ngxSmartModalService.open('templatePopModal');
  }
 




}



