export class DocumentCenterModel {
  documentName:String;
  description:String;
  category:String;
  document:String;
  modifiedBy:String;
  modifiedDate:String;
  createdBy:String;
  createdDate:String;
  dataStoreId:string;
}
