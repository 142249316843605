import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Variables } from '../../../../constants';
import { CommonFunctions } from '../../../../common';
import { ErrorModel } from '../../../../_models/response.model';
import { AuthMessageService } from '../../../../_services/auth-message.service';
import { WorkflowBaseService } from '../../../../_services/workflow-services/workflow-base.service';
import { ProposalWorkflowComponent } from '../../proposal-workflow.component';
import { PortfolioConstructionComponent } from '../portfolio-construction.component';
import { PortfolioConstructionService } from '../../../../_services/workflow-services/portfolio-construction.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.less']
})
export class AccountsComponent implements OnInit {

  selectedPortfolio:string=null;
  selectedAccountId:string=null;
  selectedAccountId2:string=null;
  dropdownsData;
  accountModel :any= new Object();
  commonFunction=new CommonFunctions();
  accountDropDownList:any=[];
  accountProductList:any=[];
  unAssignedProductList:any=[];
  selectAllFlag:boolean=false;
  selectAllFlagUnass:boolean=false;
  isMasterCheckDisable:boolean=false;
  isMasterCheckDisableUnass:boolean=false;
  currentValue: any;
  //portfolioDataForAccount: any;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private workflowBaseService : WorkflowBaseService,
    private proposalWorkflowComponent :ProposalWorkflowComponent,
    private portfolioConstructionComponent : PortfolioConstructionComponent,
    private portfolioConstructionService : PortfolioConstructionService,
    private authMessageService : AuthMessageService
  ) { }
  public report:string;
  public disableAssignButton:boolean;
  ngOnInit() {
    // this.report = "allocationCharts";
    // this.reportChange();
    this.dropdownsData = this.portfolioConstructionComponent.portfolioContructionDropdown;
    //this.portfolioDataForAccount=this.dropdownsData.portfolioList;
    this.selectedPortfolio=this.portfolioConstructionComponent.selectedPortfolio;
    this.getAccountDropDownData();
    this.getUnAssignedPosition();

    var ele = document.getElementsByClassName('height-fix')[0];
    if(ele) {
      ele.classList.remove("app-hierarchy-view");
    }

    this.disableMasterCheckBox();
  }

  ngOnDestroy() {
    var ele = document.getElementsByClassName('height-fix')[0];
    if(ele) {
      ele.classList.add("app-hierarchy-view");
    }
  }
  reportChange(){
    // console.log(this.report);

  }

  getAccountDropDownData(){
    this.portfolioConstructionService.getAccountList(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          this.accountDropDownList = response['responseData'];
          if(this.accountDropDownList &&this.accountDropDownList.length){
            this.selectedAccountId = this.accountDropDownList[0].accountId;
            this.onChangeAccount();
          }
      }
    });
  }

  convertToCommaSeperated(row,key){
    let value = row[key];
    var partBeforeDecimal = '',partAfterDecimal='';
    var index = value.indexOf('.')
    if(index>-1){
      partBeforeDecimal = value.substr(0,index);
      partAfterDecimal = value.substr(index,value.length-index);
    }else {
      partBeforeDecimal = value;
    }

    if(partBeforeDecimal){
      partBeforeDecimal = partBeforeDecimal.replace( /,/g, "" );
      row[key] = (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
    }

  }

  assignProduct(){
    setTimeout(() => {
    if(!this.disableAssignButton){
      this.selectAllFlagUnass = false;
      this.disableMasterCheckBox();
      let requestDataCopy = [],requestData=[];
      this.unAssignedProductList.forEach(element => {
        if(element.isSelected)
        requestDataCopy.push(element);
        
      });
      requestData = JSON.parse(JSON.stringify(requestDataCopy));// Creating deep copy
      requestData.forEach(element => {
        if(element.amount)
           element.amount = element.amount.replace( /,/g, "" );
      });
      if(requestData.length == 0){
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select product';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }
      else if(!this.selectedAccountId2 || this.selectedAccountId2== "null"){
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select account';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }
      else{
        this.portfolioConstructionService.assignProduct(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio,this.selectedAccountId2,requestData).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
              this.authMessageService.showSuccessPopup(response);
              this.getUnAssignedPosition();
              this.onChangeAccount();
              
          }
        });
      }
    }else{
      this.disableAssignButton = false;
    }
     
    }, 500);
    
    
  }

  unAssignProduct(){
    this.selectAllFlag = false;
    this.disableMasterCheckBox();
    let requestData = [];
    this.accountProductList.forEach(element => {
      if(element.isSelected){
        element.amount =  element.targetAmount;
        requestData.push(element);
      }
    });
    requestData.forEach(element => {
      if(element.targetAmount){
        element.targetAmount = element.targetAmount.replace( /,/g, "" );
        element.amount =  element.targetAmount;
      }
    });
    if(requestData.length == 0){
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select product';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else if(!this.selectedAccountId || this.selectedAccountId== "null"){
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select account';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else{
      this.portfolioConstructionService.unAssignProduct(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio,this.selectedAccountId,requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            console.log(response);
            this.authMessageService.showSuccessPopup(response);
            this.getUnAssignedPosition();
            this.onChangeAccount();
        }
      });
    }
    
  }

  getUnAssignedPosition(){
    this.portfolioConstructionService.getUnAssignedPosition(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
          console.log(response);
          this.unAssignedProductList = response['responseData'];                    
          if(this.unAssignedProductList.length > 0){
            this.isMasterCheckDisableUnass=false;
          }
          this.unAssignedProductList.forEach(element => {
            element.originalAmount = element.amount;
            if(element.amount)
              element.amount = element.amount.toLocaleString();
            
          });
      }
    });
  }

  checkUnassignedAmount(account){
    //let before_account_Num = account.amount;
    this.disableAssignButton  = false;
    let account_Num = account.amount.replace( /,/g, "" );
    if(parseFloat(account_Num) > account.originalAmount){
      account.amount = account.originalAmount.toLocaleString();
      this.disableAssignButton  = true;
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Amount for unassigned position cannot be greater than original amount';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    
  }


  onChangeAccount(){
    if(this.selectedAccountId){
      this.portfolioConstructionService.getAccountProductList(this.proposalWorkflowComponent.workflowInfoData.processInstanceId,this.selectedPortfolio,this.selectedAccountId).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            console.log(response);
            this.accountProductList = response['responseData'];
            if(this.accountProductList.length > 0){
              this.disableMasterCheckBox();
            }
            this.accountProductList.forEach(element => {
              element.originalTargetAmount =  element.targetAmount;
              if(element.targetAmount)
               element.targetAmount = element.targetAmount.toLocaleString();
            });
        }
      });
    }
    
  }

  createOpenAccount(){
    if(this.validateOpenAccountData(this.accountModel)){
      this.portfolioConstructionService.createOpenAccount(this.accountModel).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.getAccountDropDownData();
          this.ngxSmartModalService.close("addProducts");
        }
      });
    }
    
  }
  onChangePortfolio(){
    if(this.selectedPortfolio!='null'){
      this.getAccountDropDownData();
      this.getUnAssignedPosition();

    }
  }

  validateOpenAccountData(data){
    if(!data.name){
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter account name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if(!data.taxStatus || data.taxStatus == "null" ){
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select tax status';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true
  }

  addProductsFn(){
    this.accountModel = new Object();
    this.accountModel["portfolioId"]=this.selectedPortfolio ;
    this.accountModel["accountType"]="Asset Account" ;
    this.accountModel["taxStatus"]= null;
    this.accountModel["processInstanceId"]=this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
    this.ngxSmartModalService.open("addProducts");
  }

selectAll(event,type){
  if(type == 'ass'){
        this.accountProductList.forEach(function(account){
      if(!account.isLocked){
        account.isSelected = event.target.checked;
      }        
    })
  }

  if(type == 'unass'){
      this.unAssignedProductList.forEach(function(account){
      if(!account.isLocked){
        account.isSelected = event.target.checked;
      }        
    })
  }

  }

  checkToSelectAll(event, type){
    var localThis = this;
    var event = event;
    var type = type;
    setTimeout(function(){
          if(type == 'ass'){
      if(!event.target.checked){
        localThis.selectAllFlag = false;
      }
      else{
        localThis.selectAllFlag = true;
        let that = localThis;
        localThis.accountProductList.forEach(function(product){
            if(product.isSelected){
              if(product.isSelected == false){
                that.selectAllFlag = false;
              }
            }
            else if(!product.isLocked && !product.isSelected){
              that.selectAllFlag = false;
            }
        })
      
    }
    }

    if(type == 'unass'){
      if(!event.target.checked){
        localThis.selectAllFlagUnass = false;
      }
      else{
        localThis.selectAllFlagUnass = true;
        let that = localThis;
        localThis.unAssignedProductList.forEach(function(product){  
          if(product.isSelected){          
              if(product.isSelected == false){
                that.selectAllFlagUnass = false;              
            }
          }
          else{
            that.selectAllFlagUnass = false;
          }
        })
      }    
  }
    },100);
}

  disableMasterCheckBox(){
    if(this.accountProductList && this.accountProductList.length > 0){
      //if(this.accountProductList.find(x =>!x.isLocked) != undefined){
        this.isMasterCheckDisable = false;      
    }
    else{
      this.isMasterCheckDisable = true;
    }

    if(this.unAssignedProductList && this.unAssignedProductList.length > 0){
      this.isMasterCheckDisableUnass = false;      
    }
    else{
      this.isMasterCheckDisableUnass = true;
    }

  }
  getInputCurrentValue(evt,value){
    this.currentValue=value;
  }

 onPaste(event,fixedCurrentValue,currentVal){

        // this.allowRangeOnly(evt,pastedText);
        var Text = event.clipboardData.getData('text');
        if (isNaN(Text)) {
             event.preventDefault();
            var errorObj = new ErrorModel();
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = 'Invalid Input';
            errorObj['errorDescription'] = '';
            this.authMessageService.showErrorPopup(errorObj);
            //alert("Invalid Input")
        } else {
            
          event.preventDefault();
          var errorObj = new ErrorModel();
          errorObj['errorCode'] = 'ERR0107';
          errorObj['message'] = 'You are not allowed to copy/paste in target amount';
          errorObj['errorDescription'] = '';
          this.authMessageService.showErrorPopup(errorObj);

     }
  }
}
