import { Component, OnInit, ApplicationRef, ViewEncapsulation, ViewChild, QueryList } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap';
import { CustomCompileService } from 'src/app/_services/custom-compile.service';
import { AddEditProductListPopup } from 'src/app/shared/popups/add-edit-product-list-popup/add-edit-product-list-popup.component';
import { ProductComparePopup } from 'src/app/shared/popups/product-compare-popup/product-compare-popup.component';
import { AddEditProductPopup } from 'src/app/shared/popups/add-edit-product-popup/add-edit-product-popup.component';
import { EventEmitter } from 'events';
import { CatalogService } from '../../_services/catalog.service';
import { Variables } from '../../constants';
import { AuthMessageService } from '../../_services/auth-message.service';
import { GridComponent } from '../../shared/grid/grid.component';
import { FilterModel } from "../../_models/filter.model";
import { NgxSmartModalService } from "ngx-smart-modal";
import { BaseService } from '../../_services/base.service';
import { CatalogDropdownData } from '../../_models/catalog.model';
import { Router } from '@angular/router';
import { LeftNavService } from 'src/app/_services/left-nav.service';
import * as _ from "lodash";
import * as $ from 'jquery';
import { style } from '@angular/animations';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.less']
})
export class CatalogComponent implements OnInit {
  showProductsList: boolean = true;
  moduleList = [];
  listSearchBy: string;
  listSearchText: string;
  selectedRows: any[];
  filterVO = new FilterModel();
  componentReference: {};
  apiParams: {};
  message: boolean = true;
  mainContent: boolean = true;
  modulePermissionProduct;
  modulePermissionProductList;

  catalogDropdowns = new CatalogDropdownData();

  @ViewChild(GridComponent) gridComponentData: GridComponent;

  constructor(
    private compileService: CustomCompileService,
    private appRef: ApplicationRef,
    private catalogService: CatalogService,
    private authMessageService: AuthMessageService,
    public ngxSmartModalService: NgxSmartModalService,
    private baseService: BaseService,
    private router: Router,
    private leftNavData: LeftNavService
  ) {
    //this.listSearchText="";
    compileService.configure(this.appRef);
    this.componentReference = { componentParent: this };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $(".ag-body .ag-body-viewport").css("overflow-x", "scroll")
      $(".ag-pinned-left-cols-viewport").css("overflow-x", "scroll")
      $(".ag-pinned-left-cols-viewport").css("overflow-y", "hidden")
    }, 100
    );
  }

  ngOnInit() {

    this.modulePermissionProductList = {};
    this.modulePermissionProduct = {};
    this.moduleList = JSON.parse(localStorage.getItem('moduleList'));
    let catalogModule = _.find(this.moduleList , {moduleId : "catalog"});
    if(catalogModule){
      let subModuleProductList = _.find(catalogModule.childModules , {moduleId : "productList"});
      let subModuleProduct = _.find(catalogModule.childModules , {moduleId : "product"});
      if(subModuleProductList){
          this.modulePermissionProductList = subModuleProductList.modulePermission;
      }
      if(subModuleProduct){
          this.modulePermissionProduct = subModuleProduct.modulePermission;
      }
    }

    this.getDropdownsData();
    this.leftNavData.currentMessage.subscribe(message => {
      this.message = message;
      this.changeContainerSize();
    });
  }

  changeContainerSize() {
    if (this.message) {
      this.mainContent = true;
    } else {
      this.mainContent = false;
    }
  }

  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {
    if (this.showProductsList) {
      this.editNewProductList(rowData); //For Catalog - Product List
    } else {
      this.editNewProduct(rowData); //For Catalog - Product
    }

  }

  onTabSelect(id): void {
    if (id == "catalogListTab") {
      this.showProductsList = true;
      this.ngAfterViewInit();
    }
    else {
      this.apiParams = {
        ListName: null
      };
      this.showProductsList = false;
      this.ngAfterViewInit();
    }
  }

  gridLinkClicked(param) {
    this.apiParams = {
      ListName: param.name
    };
    this.showProductsList = false;
    this.ngAfterViewInit();

  }

  addNewProductList(): void {
    this.compileService.compile(AddEditProductListPopup, (c) => {
      c.instance.compId = "productListPopup",
        c.instance.saveNewProductListData = this.saveNewProductList.bind(this),
        c.instance.catalogDropdowns = this.catalogDropdowns
    })
  }

  editNewProductList(rowData) {
    this.compileService.compile(AddEditProductListPopup, (c) => {
      c.instance.compId = "productListPopup",
        c.instance.rowData = rowData,
        c.instance.saveNewProductListData = this.saveNewProductList.bind(this)
      c.instance.catalogDropdowns = this.catalogDropdowns
    })
  }

  deleteProductList(): void {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length != 0) {
      this.ngxSmartModalService.open('deleteProductListModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteProductListAfterConfirmation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    this.filterVO = this.gridComponentData.filterVO;
    this.filterVO.dataStoreIdList = [];
    this.selectedRows.forEach(element => {
      this.filterVO.dataStoreIdList.push(element.dataStoreId);
    });
    this.catalogService.deleteProductLists(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          if (response['message'] != "") {
            this.authMessageService.showSuccessPopup(response);
            
          }
          else if (response['errMessage'] != "") {
            let errorObj = {};
            errorObj['errorCode'] = 'ERR0107';
            errorObj['message'] = response['errMessage'];
            errorObj['errorDescription'] = '';
            this.authMessageService.showErrorPopup(errorObj);  
                
          }

          this.ngxSmartModalService.getModal('deleteProductListModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }

  addNewProduct(): void {
    this.compileService.compile(AddEditProductPopup, (c) => {
      c.instance.compId = "productPopup",
        c.instance.catalogDropdowns = this.catalogDropdowns,
        c.instance.saveNewProductData = this.saveNewProduct.bind(this)
    });
  }

  editNewProduct(rowData) {
    //rowData['displayName']="--"+rowData.productClass;
    this.compileService.compile(AddEditProductPopup, (c) => {
      c.instance.compId = "productListPopup",
        c.instance.rowData = rowData,
        c.instance.saveNewProductData = this.saveNewProduct.bind(this)
      c.instance.catalogDropdowns = this.catalogDropdowns
    })
  }

  deleteProduct(): void {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length != 0) {
      this.ngxSmartModalService.open('deleteProductModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteProductAfterConfirmation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    this.filterVO = this.gridComponentData.filterVO;
    this.filterVO.dataStoreIdList = [];
    this.selectedRows.forEach(element => {
      this.filterVO.dataStoreIdList.push(element.dataStoreId);
    });
    this.catalogService.deleteProducts(this.filterVO)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteProductModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }

  compareProducts(): void {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length > 1 && this.selectedRows.length <= 5) {
      this.filterVO = this.gridComponentData.filterVO;
      this.filterVO.dataStoreIdList = [];
      this.selectedRows.forEach(element => {
        this.filterVO.dataStoreIdList.push(element.dataStoreId);
      });
      let comparisonRequestData = this.filterVO;
      this.compileService.compile(ProductComparePopup, (c) => {
        c.instance.compId = "productComparePopup",
          c.instance.comparisonRequestData = comparisonRequestData
      });
    } else if (this.selectedRows && this.selectedRows.length > 1 && this.selectedRows.length > 5) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select atmost 5 records for comparison';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select atleast 2 records for comparison';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  /* Save new product list */
  saveNewProductList(data, popupReference) {
    this.catalogService.saveNewProductListData(data).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.gridComponentData.getGridDataAfterUpdatingRecords();
        popupReference.closePopup();
      }
    });
  }

  saveNewProduct(data, popupReference) {
    this.catalogService.saveNewProductData(data).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.gridComponentData.getGridDataAfterUpdatingRecords();
        popupReference.closePopup();
      }
    });
  }

  /* Goto product detailed view */
  openProductDetailedInfo(node) {
    this.router.navigate(['/productInfo/' + node.dataStoreId]);
  }

  /* Get dropdowns data */
  getDropdownsData() {
    this.baseService.getEnumDataBasedOnModule("catalog").subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.catalogDropdowns.productListType = data.productListType;
        this.catalogDropdowns.productStatus = data.productStatus;
        this.catalogDropdowns.productType = data.productType;
        this.catalogDropdowns.taxType = data.taxType;
        this.catalogDropdowns.primaryBenchmark = data.primaryBenchmark;
        this.catalogDropdowns.productStyle = data.productStyle;
        this.catalogDropdowns.secondaryBenchmark = data.secondaryBenchmark;
        this.catalogDropdowns.issueCountry = data.issueCountry;
        this.catalogDropdowns.riskCountry = data.riskCountry;
        this.catalogDropdowns.assetClass = data.assetClass;

        this.catalogDropdowns['assetClassDataForDropdown'] = [];
        if (this.catalogDropdowns.assetClass) {
          this.catalogDropdowns.assetClass.forEach(element => {
            element.isHeader = true;
            element.displayName = element.name;
            this.catalogDropdowns['assetClassDataForDropdown'].push(element);
            if (element.assetSubClassVO) {
              element.assetSubClassVO.forEach(item => {
                item.isHeader = false;
                item.displayName = '- - ' + item.name;
                this.catalogDropdowns['assetClassDataForDropdown'].push(item);
              });
            }
            if (element.objectId == 'BLENDED') {
              let obj = {
                isHeader: false,
                displayName: '- - Blended',
                objectId: 'BLENDED'
              };
              this.catalogDropdowns['assetClassDataForDropdown'].push(obj);
            }
          });
        }

      }
    });
  }



}
