import { Component } from '@angular/core';

import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'hierarchy-row',
    template: '<div class="float-left w-100"  >' +
        '<span *ngIf="level==0" style="font-weight:bold">{{params.node.data.name}}</span>' +
        '<span *ngIf="level==1">&nbsp;&nbsp;&nbsp;{{params.node.data.name}}</span>' +
        '<span *ngIf="level==2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span *ngIf="levelWithIcon"><i class="fa fa-lock" aria-hidden="true" style="color: orangered">&nbsp;&nbsp;</i></span>{{params.node.data.name}}</span>' +
        '</div>',
    styles: []
})
export class HierarchyRowTemplate implements ICellRendererAngularComp {
    params: any;
    level: any;
    levelWithIcon: any = "";

    agInit(params: any): void {
        this.params = params;
        if (this.params.node.data) {
            if (this.params.node.data.level !== 2) {
                this.level = this.params.node.data.level;
            } else {
                this.level = this.params.node.data.level;
                if (this.params.node.data.isLocked) {
                    this.levelWithIcon = true;
                }
            }
        }
        // if(this.params.node.data.gridName=="user-listing" && this.params.node.data.isTerminated){
        //     this.disableIcon = true;
        // }
    }



    refresh(): boolean {
        return false;
    }
}