import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogProductInfoService } from '../../../../_services/catalog-product-info-service';
import { Variables } from '../../../../constants';

@Component({
  selector: 'product-characterstics-tab',
  templateUrl: './product-characterstics-tab.component.html',
  styleUrls: ['./product-characterstics-tab.component.less', './../../product-info/product-info.component.less']
})
export class ProductCharactersticsTabComponent implements OnInit {

  productId: string;
  productCharactersticsData:any = {};
  productFinancialCharacteristicVO:any = {};
  productInvestmentPurposeVO:any = {};
  productRiskCharacteristicVO:any = {};

  constructor(
    private activeRoute: ActivatedRoute,
    private catalogProductInfoService: CatalogProductInfoService
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => { this.productId = params.id });
    this.getProductCharactersticsData(this.productId);
  }

  getProductCharactersticsData(productId) {
    this.catalogProductInfoService.getProductCharactersticsInfoData(productId)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.productCharactersticsData = response['responseData'];          
          this.productFinancialCharacteristicVO = this.productCharactersticsData['productFinancialCharacteristicVO'];
          this.productInvestmentPurposeVO = this.productCharactersticsData['productInvestmentPurposeVO'];
          this.productRiskCharacteristicVO = this.productCharactersticsData['productRiskCharacteristicVO'];
        }
      });
  }

}
