import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthMessageState } from '../shared/auth-message/auth-message';

@Injectable({
  providedIn: 'root'
})

export class AuthMessageService {
  static instance: AuthMessageService;// this is use to access out of root scope
  private AuthMessageSubject = new Subject<AuthMessageState>();
  authMessageState = this.AuthMessageSubject.asObservable();
  constructor() { 
    AuthMessageService.instance = this;//this is only for the accessing provide out of root scope
  }
  showErrorPopup(responseDataObj) {
    this.AuthMessageSubject.next(<AuthMessageState>{ showErrorPopup : true , showSuccessPopup :false , responseDataObj });
  }

  hideErrorPopup() {
    this.AuthMessageSubject.next(<AuthMessageState>{ showErrorPopup : false ,  showSuccessPopup : false});
  }

  showSuccessPopup(responseDataObj) {
    this.AuthMessageSubject.next(<AuthMessageState>{  showErrorPopup : false , showSuccessPopup : true , responseDataObj});
  }

  hideSuccessPopup() {
    this.AuthMessageSubject.next(<AuthMessageState>{ showErrorPopup : false , showSuccessPopup : false });
  }
}