import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiUrls } from '../../constants';
@Injectable({
  providedIn: 'root'
})
export class SlideSelectionService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };
  constructor(private http: HttpClient) { }

  getSlideSelectionDetails(processInstanceId) {
    return this.http.get(ApiUrls.getSlideSelectionDetailsAPI+"?processInstanceId="+processInstanceId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  saveSlideSelection(processInstanceId , slideSelectionModel) {
    return this.http.post(ApiUrls.saveSlideSelectionAPI+"?processInstanceId="+processInstanceId, slideSelectionModel,this.httpOptions)
        .pipe(
            response => response
        );
  }

  getStrategiesOneTwo(processInstanceId,portfolioId) {
    return this.http.get(ApiUrls.strategiesOneTwoAPI+"?processInstanceId="+processInstanceId+"&portfolioId="+portfolioId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  setStrategiesOneTwo(request) {
    return this.http.post(ApiUrls.strategiesOneTwoAPI, request,this.httpOptions)
        .pipe(
            response => response
        );
  }

  saveTemplate(processInstanceId , templateModel) {
    return this.http.post(ApiUrls.saveTemplateAPI+"?processInstanceId="+processInstanceId, templateModel,this.httpOptions)
        .pipe(
            response => response
        );
  }

  getExistingTemplates(processInstanceId) {
    return this.http.get(ApiUrls.getExistingTemplatesAPI+"?processInstanceId="+processInstanceId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  getPresentationSlide(processInstanceId) {
    return this.http.get(ApiUrls.getPresentationSlideAPI+"?processInstanceId="+processInstanceId, this.httpOptions)
        .pipe(
            response => response
        );
  }

  getTemplateDetails(templateId) {
    return this.http.get(ApiUrls.getTemplateDetailsAPI+"?templateId="+templateId, this.httpOptions)
        .pipe(
            response => response
        );
  }
}
