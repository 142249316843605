import { Component, OnInit, ApplicationRef,ViewChild } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
//import { CustomCompileService } from 'src/app/_services/custom-compile.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Relationship, RelationshipDropdownData } from '../../../_models/relationship.model';
import { Variables } from '../../../constants';
import { GridFilterSortingService } from 'src/app/_services/grid-filter-sorting.service';
import { BaseService } from '../../../_services/base.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { RelationshipsService } from '../../../_services/relationships.service';
import { AuthMessageService } from '../../../_services/auth-message.service';
import { GridComponent } from '../../../shared/grid/grid.component';
import { ErrorModel } from '../../../_models/response.model';
import { ProposalWorkflowComponent } from '../proposal-workflow.component';
import { WorkflowBaseService } from '../../../_services/workflow-services/workflow-base.service';

@Component({
  selector: 'app-relationship-selection',
  templateUrl: './relationship-selection.component.html',
  styleUrls: ['./relationship-selection.component.less'],

  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class RelationshipSelectionComponent implements OnInit {
  relationship = new Relationship();
  relationShipDropdowns = new RelationshipDropdownData();
  componentReference: any;
  apiParams: {};
  @ViewChild(GridComponent) gridComponentData: GridComponent;
  constructor(
    // private compileService: CustomCompileService,
    // private appRef: ApplicationRef,
    private ngxSmartModalService: NgxSmartModalService,
    private baseService: BaseService,
    private gridFilterSortingService:GridFilterSortingService,
    private relationshipsService: RelationshipsService,
    private datePipe: DatePipe,
    private authMessageService: AuthMessageService,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
    private workflowBaseService: WorkflowBaseService
  ) {
    // compileService.configure(this.appRef);
    // this.componentReference = { componentParent: this };
   }

  dataObj:any = new Object();
  defaultName: String;
  defaultSearchingData : any={};
  searchName :string= null;
  searchByName:boolean=true;
  searchId :string= null; 
  today = new Date();
  stepInfo = {
    'customProcessName': null,
    'selectedRelationshipData': {}
  };

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

 

  ngOnInit() {
    this.getTaskDetails();
    this.workflowBaseService.setStepInfoVsStepName('relationshipSelection', this.stepInfo);
    this.dpConfig.containerClass = "theme-blue";
    this.dataObj['nameType'] = 'default';
    setTimeout(() => {
      this.defaultName = this.proposalWorkflowComponent.workflowInfoData.processInstanceName;
    }, 500);
    this.getDropdownsData();
    var workflowRelationShipId = localStorage.getItem('workflowInfoDataRelationId');
    if(workflowRelationShipId)
    {
      this.defaultSearchingData={};
      this.defaultSearchingData["colId"] = "clientHierarchyId";
      this.defaultSearchingData["colValue"]= workflowRelationShipId;
      localStorage.removeItem('workflowInfoDataRelationId');
    }
  }

  getTaskDetails() {
    let requestObj = {
      "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": 'relationshipSelection',
      "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.apiParams={
          RelationShipName: response['responseData'].taskVariables.relationshipName}
          ;
        if (response['responseData'].taskVariables.processName) {
          
          this.dataObj['nameType'] = 'custom';
          this.stepInfo.customProcessName = response['responseData'].taskVariables.processName;
        }
        let count = 0;
        let ifRelationshipId = setInterval(() => {
            
          count++; 
          this.gridComponentData.gridApi.forEachNode(function (node) {         
            if (node.data.clientId == response['responseData'].taskVariables.relationshipId) {
              clearInterval(ifRelationshipId);
              node.setSelected(true);
            }
          });
          if(count > 10){
            clearInterval(ifRelationshipId);
          }
        }, 500);
      }
    });
  }

  addNewRelation() {
    this.ngxSmartModalService.getModal("addRelation").open();
  }

  searchGrid(){
    this.defaultSearchingData = [];
    let temp = {}
    this.searchByName=true;
    //temp["colId"] = "name";
    temp["fieldValue"]= this.searchName;
    temp['searchByName']=this.searchByName;
    //this.columnFilterData = this.params.column.colDef.searchSortData;
    //this.gridFilterSortingService.changeInSortingData
    this.gridFilterSortingService.setSortingData(temp);
    // this.defaultSearchingData.push(temp);
    // // temp={};
    // // temp["colId"] = "clientHierarchyId";
    // // temp["colValue"]= this.searchId;
    // this.defaultSearchingData.push(temp);
    //this.gridComponentData.getGridDataAfterUpdatingRecords();
  }

  clearSearchColumn(){
    this.searchName=null;
    this.searchByName=false;
    let temp = {}
    //this.searchByName=true;
    //temp["colId"] = "name";
    temp["fieldValue"]= null;
    temp['searchByName']=this.searchByName;
    //this.searchId=null;
    this.gridFilterSortingService.setSortingData(temp);
    //this.searchGrid();
  }
  
  saveNewRelationship() {
    this.relationship.shortName = this.relationship.name;
    if (this.validateFormData(this.relationship)) {
      let tempDate = this.relationship.inceptionDate;
      this.relationship.inceptionDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
      let toCreateOwnership = true;
      this.relationshipsService.setRelationshipsData(this.relationship, toCreateOwnership).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.ngxSmartModalService.getModal('addRelation').close();
          this.authMessageService.showSuccessPopup(response);
          this.relationship = new Relationship();
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
    }
  }

  validateFormData(data) {
    if (!data.name) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.shortName) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter short name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.clientType) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter client type';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    if (!data.inceptionDate) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter inception date';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } if (!data.currency) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter currency';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    return true;
  }


  getDropdownsData() {
    this.baseService.getEnumDataBasedOnModule("relationships").subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var retData = response['responseData'];
        this.relationShipDropdowns.accountType = retData.accountType;
        this.relationShipDropdowns.taxStatus = retData.accountTaxStatus;
        this.relationShipDropdowns.ownerType = retData.ownerType;
        this.relationShipDropdowns.relationType = retData.relationshipType;
        this.relationShipDropdowns.accountStatus = retData.accountStatus;
        this.relationShipDropdowns.investmentObjective = retData.investmentObjective;
      }
    });
  }


  onRowSelectionChanged(selectedNodes) {
    let selectedRelationshipData = selectedNodes[0].data;
    this.stepInfo['selectedRelationshipData'] = selectedRelationshipData;
    this.workflowBaseService.setStepInfoVsStepName('relationshipSelection', this.stepInfo);
  }

  changeProcessNameType() {
    if (this.dataObj['nameType'] == 'custom') {
      this.stepInfo['customProcessName'] = '';
    } else {
      this.stepInfo['customProcessName'] = null;
    }
  }


}
