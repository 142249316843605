import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Variables } from '../../../../constants';
import { CommonFunctions } from '../../../../common';
import { ErrorModel } from '../../../../_models/response.model';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AuthMessageService } from '../../../../_services/auth-message.service';
import { GridComponent } from '../../../../shared/grid/grid.component';
import { PortfolioConstructionService } from '../../../../_services/workflow-services/portfolio-construction.service'
import { ProposalWorkflowComponent } from '../../proposal-workflow.component';
import { WorkflowBaseService } from '../../../../_services/workflow-services/workflow-base.service';
import { PortfolioConstructionComponent } from '../portfolio-construction.component';
import { PortfolioOverviewService } from '../../../../_services/workflow-services/portfolio-overview-service';

import { timeout } from 'q';
import * as _ from "lodash";
import * as $ from 'jquery';
//import { request } from 'http';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.less'],
  providers: [DatePipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class InvestmentsComponent implements OnInit {
  componentReference: any;
  selectedPortfolio: string = null;
  decimalPoints: number = 2;
  accountHoldingList = [];
  analyticsData;
  dropdownsData: any;
  feeSummaryVO;
  feeIncludedVO;
  blendedList: any = [];
  commonFunction = new CommonFunctions();
  assetClassHeirarchy: any = [];
  templatePopupMode = "add";
  originalAssetClassHeirarchy: any = [];
  assetClassHeirarchyTotals: any = {};
  isChangeAfterSave: boolean = false;
  stepData = {
    isChangeAfterSave: false
  }
  confirmationType;
  isViewMessage = false;
  selectedTemplate;
  templateName;
  messageForAddSecurityConfirmation:String;
  investmentTemplateVO = {};
  investmentTemplateList = [];
  productComponentReference;
  securityComponentReference
  @ViewChildren('investmentGrid') popUpGridComponentData: QueryList<GridComponent>;
  @ViewChild(GridComponent) gridComponentData: GridComponent;
  accountDropDownList: any;
  selectedAccountId: any;
  isFirstLoading: Boolean = true;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private portfolioConstructionService: PortfolioConstructionService,
    private authMessageService: AuthMessageService,
    private datePipe: DatePipe,
    private workflowBaseService: WorkflowBaseService,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
    private portfolioConstructionComponent: PortfolioConstructionComponent,
    private portfolioOverviewService: PortfolioOverviewService
  ) { }

  ngAfterViewInit() {

    //console.log("viewChanged");

  }
  ngOnInit() {
    this.feeIncludedVO = {};
    this.feeSummaryVO = {};
    this.analyticsData = {
      parentComponent: "portfolioConstruction",
      selectedStrategy: ''
    }
    this.componentReference = { componentParent: this };
    let callCount = 1;
    let ifData = setInterval(() => {
      if (this.portfolioConstructionComponent && this.portfolioConstructionComponent.portfolioContructionDropdown) {
        this.dropdownsData = this.portfolioConstructionComponent.portfolioContructionDropdown;
        this.selectedPortfolio = this.portfolioConstructionComponent.selectedPortfolio;
        if (!this.selectedPortfolio && this.dropdownsData.portfolioList.length)
          this.selectedPortfolio = this.dropdownsData.portfolioList[0].portfolioId;
        //this.getFeeDetails();
        this.getInvestmentTemplate(null);
        this.onChangePortfolio('false');
        clearInterval(ifData);
      } else if (callCount > 10) {
        clearInterval(ifData);
      }
      callCount++;
    }, 500);

    var wait = setInterval(() => {
      if ($('.investments-total')[0].clientHeight) {
        clearInterval(wait)
        this.adjustHeightViewport(0);
      }
    })

  }
  onChangeValuePosition() {

  }

  getFeeDetails() {
    var apiParams = {
      processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      portfolioId: this.selectedPortfolio,
      isPopUpView: false
    };

    this.workflowBaseService.getFeeDetails(apiParams).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        console.log(data);

        this.feeSummaryVO = data.feeSummaryVO;
        this.feeIncludedVO = data.feeIncludedVO;
        this.isViewMessage = data.isViewMessage;
      }
    });
  }

  updateFeeIncluded() {

    this.workflowBaseService.updateFeeIncluded(this.selectedPortfolio, this.feeIncludedVO).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        var data = response['responseData'];
        this.ngxSmartModalService.getModal('feeInclude').close();
        this.getFeeDetails();
      }
    });
  }

  openFeeSummaryPopup() {
    this.ngxSmartModalService.open("feeSummary");
  }

  openFeeIncludePopup() {
    this.ngxSmartModalService.open("feeInclude");
  }

  openEditLockedHoldingPopup() {
    this.getAccountDropDownData();
    this.ngxSmartModalService.open("editHoldingModal");
  }

  onChangeAccountOnEditHoldingPopup() {
    this.portfolioOverviewService.getEditHoldingsList(this.selectedAccountId).subscribe(response => {
      if (response["responseType"] == Variables.statusSuccess) {
        this.accountHoldingList = response['responseData'];
      }
    });

  }

  saveLockedHoldingData() {
    let requestData = {
      accountId: this.selectedAccountId,
      currency: "USD",
      sysCurrency: "USD",
      holdingsInfoVOs: this.accountHoldingList
    }
    requestData["holdingsInfoVOs"].forEach(element => {
      if (element.asOfDate.length > 10) {
        element.asOfDate = element.asOfDate.substring(0, 10);
      }
      let tempDate = element.asOfDate;
      //let tempDate1= element.acquisitionDate.toLocaleDateString();
      //element.acquisitionDate=this.datePipe.transform(tempDate1,'MM/dd/yyyy');
      element.asOfDate = this.datePipe.transform(tempDate, 'MM/dd/yyyy');
    });
    this.portfolioOverviewService.saveHoldings(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.toUpdateLockedHolding();
        this.ngxSmartModalService.getModal('editHoldingModal').close();
      }
    })
  }

  toUpdateLockedHolding() {
    this.portfolioConstructionService.saveLockedHoldinData(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        //this.getInvestmentTemplate(null);
        this.onChangePortfolio(false);
      }
    })
  }

  getAccountDropDownData() {
    this.portfolioConstructionService.getAccountList(this.proposalWorkflowComponent.workflowInfoData.processInstanceId, this.selectedPortfolio).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.accountDropDownList = response['responseData'];
        if (this.accountDropDownList && this.accountDropDownList.length) {
          this.selectedAccountId = this.accountDropDownList[0].accountId;
          this.onChangeAccountOnEditHoldingPopup();
        }
      }
    });
  }


  saveAssetHierarchy() {
    if (parseFloat(this.assetClassHeirarchyTotals['proposedAmountCent']) > 100) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Total Proposed percentage should not be greater than 100%';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    } else {
      let assetClassHeirarchyCopy = JSON.parse(JSON.stringify(this.assetClassHeirarchy));
      assetClassHeirarchyCopy.forEach(element => {
        element.proposedAmountValue = parseFloat(element.proposedAmountValue).toFixed(this.decimalPoints);
        element.offTargetAmount = (element.proposedAmountValue - element.targetAmountValue).toFixed(this.decimalPoints);
        if (element.proposedAmountPercentage)
          element.proposedAmountPercentage = parseFloat(element.proposedAmountPercentage).toFixed(this.decimalPoints);
        if (element.currentAmountValue)
          element.currentAmountValue = parseFloat(element.currentAmountValue).toFixed(this.decimalPoints);
        if (element.currentAmountPercentage)
          element.currentAmountPercentage = parseFloat(element.currentAmountPercentage).toFixed(this.decimalPoints);



      });
      let refinedData = this.convertToOriginalVo(assetClassHeirarchyCopy);
      let requestData = {};
      requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      requestData["portfolioId"] = this.selectedPortfolio;
      requestData["strategyTacticalInfoVOList"] = refinedData;
      requestData["blendedInvestors"] = this.blendedList;
      this.portfolioConstructionService.saveUpdatedTacticalStrategies(requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          console.log(response);
          this.authMessageService.showSuccessPopup(response);
          this.isChangeAfterSave = false;
          this.stepData.isChangeAfterSave = false;
          this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
          this.getFeeDetails();

        }
      });
    }
  }

  onChangePortfolio(value) {
    let template = value ? null : this.selectedTemplate;//On Change we need to reset template 
    this.selectedTemplate = template;
    this.portfolioConstructionComponent.selectedPortfolio = this.selectedPortfolio;
    if (this.selectedPortfolio && this.selectedPortfolio != "null") {
      this.portfolioConstructionService.getTacticalStrategies(this.proposalWorkflowComponent.workflowInfoData.processInstanceId, this.selectedPortfolio, this.selectedTemplate).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          console.log(response);
          if (response['responseData']) {
            this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO, true);
            this.blendedList = response['responseData'].blendedInvestors;
            this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
            this.getFeeDetails();
            if (this.isFirstLoading || value) {
              this.isFirstLoading = false;
              this.selectedTemplate = response['responseData'].templateId;
              this.onChangePortfolio(false);
            }
          }

        }
      });
    }

  }

  removeInvestment() {
    let investmentIds = [];
    this.assetClassHeirarchy.forEach(element => {
      if (element.isSelected)
        investmentIds.push(element.investmentId);
    });
    this.blendedList.forEach(element => {
      if (element.isSelected)
        investmentIds.push(element.investmentId);
    });
    if (!investmentIds.length) {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select product/strategy';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
    else {
      this.portfolioConstructionService.removeInvestment(this.proposalWorkflowComponent.workflowInfoData.processInstanceId, this.selectedPortfolio, investmentIds).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          //this.selectedTemplate = null;
          if (response['responseData']) {
            this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO, true);
            this.blendedList = response['responseData'].blendedInvestors;
            this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
            this.isChangeAfterSave = false;
            this.stepData.isChangeAfterSave = false;
            this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
          }
        }
      });
    }

  }

  totalCalculation(data) {
    this.assetClassHeirarchyTotals["currentAmount"] = 0;
    this.assetClassHeirarchyTotals["currentAmountCent"] = 0;
    this.assetClassHeirarchyTotals["proposedAmount"] = 0;
    this.assetClassHeirarchyTotals["proposedAmountCent"] = 0;
    this.assetClassHeirarchyTotals["targetAmount"] = 0;
    this.assetClassHeirarchyTotals["targetAmountCent"] = 0;
    this.assetClassHeirarchyTotals["offTarget"] = 0;
    this.assetClassHeirarchyTotals["offTargetCent"] = 0;
    data.forEach(element => {
      this.assetClassHeirarchyTotals["currentAmount"] = (parseFloat(this.assetClassHeirarchyTotals["currentAmount"]) + parseFloat(element.currentAmountValue)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["currentAmountCent"] = (parseFloat(this.assetClassHeirarchyTotals["currentAmountCent"]) + parseFloat(element.currentAmountPercentage)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["proposedAmount"] = (parseFloat(this.assetClassHeirarchyTotals["proposedAmount"]) + parseFloat(element.proposedAmountValue)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["proposedAmountCent"] = (parseFloat(this.assetClassHeirarchyTotals["proposedAmountCent"]) + parseFloat(element.proposedAmountPercentage)).toFixed(this.decimalPoints);
      this.assetClassHeirarchyTotals["targetAmount"] = (parseFloat(this.assetClassHeirarchyTotals["targetAmount"]) + parseFloat(element.targetAmountValue));
      this.assetClassHeirarchyTotals["targetAmountCent"] = (parseFloat(this.assetClassHeirarchyTotals["targetAmountCent"]) + parseFloat(element.targetAmountPercentage)).toFixed(this.decimalPoints);
      element.offTargetAmount = parseFloat(element.proposedAmountValue) - parseFloat(element.targetAmountValue);
      if (element.targetAmountValue && parseFloat(element.targetAmountValue) != 0)
        element.offTargetPercentage = (element.offTargetAmount / element.targetAmountValue) * 100;
      this.assetClassHeirarchyTotals["offTarget"] = (parseFloat(this.assetClassHeirarchyTotals["offTarget"]) + parseFloat(element.offTargetAmount));

    });
    if (this.assetClassHeirarchyTotals["targetAmount"] && this.assetClassHeirarchyTotals["targetAmount"] != 0)
      this.assetClassHeirarchyTotals["offTargetCent"] = ((parseFloat(this.assetClassHeirarchyTotals["offTarget"]) / parseFloat(this.assetClassHeirarchyTotals["targetAmount"])) * 100).toFixed(this.decimalPoints);

    if (this.assetClassHeirarchyTotals["targetAmountCent"] == "100.00") {
      this.assetClassHeirarchyTotals["targetAmount"] = this.assetClassHeirarchyTotals["currentAmount"];
    }
    if (this.assetClassHeirarchyTotals["offTargetCent"] == "-100.00") {
      this.assetClassHeirarchyTotals["offTarget"] = -this.assetClassHeirarchyTotals["currentAmount"];
    }

  }

  refineVo(data, isFirstTime) {
    let refinedData = [];
    data.forEach(element => {
      element.hierarchyId = 1;
      element.offTargetAmount = element.proposedAmountValue;
      element.offTargetPercent = element.proposedAmountPercentage;

      element.proposedAmountValue = parseFloat(element.proposedAmountValue).toFixed(this.decimalPoints)
      element.offTargetAmount = (element.proposedAmountValue - element.targetAmountValue).toFixed(this.decimalPoints);
      // if(element.targetAmountValue && element.targetAmountValue!=0)
      // element.offTargetPercent = ((element.proposedAmountValue - element.targetAmountValue)/element.targetAmountValue).toFixed(this.decimalPoints);
      element.offTargetPercent = (parseFloat(element.proposedAmountPercentage) - parseFloat(element.targetAmountPercentage)).toFixed(this.decimalPoints);


      if (element.proposedAmountPercentage)
        element.proposedAmountPercentage = parseFloat(element.proposedAmountPercentage).toFixed(this.decimalPoints);
      if (element.currentAmountValue)
        element.currentAmountValue = parseFloat(element.currentAmountValue).toFixed(this.decimalPoints);
      if (element.currentAmountPercentage)
        element.currentAmountPercentage = parseFloat(element.currentAmountPercentage).toFixed(this.decimalPoints);
      if (isFirstTime)
        element.originalProposedAmountValue = element.proposedAmountValue;
      refinedData.push(element);
      element["strategyTacticalInfoVOChild"].forEach(element2 => {
        element2.hierarchyId = 2;
        element2.offTargetAmount = element2.proposedAmountValue;
        element2.offTargetPercent = element2.proposedAmountPercentage;

        element2.proposedAmountValue = parseFloat(element2.proposedAmountValue).toFixed(this.decimalPoints);
        element2.offTargetAmount = (element2.proposedAmountValue - element2.targetAmountValue).toFixed(this.decimalPoints);
        // if(element2.targetAmountValue && element2.targetAmountValue!=0)
        // element2.offTargetPercent = ((element2.proposedAmountValue - element2.targetAmountValue)/element2.targetAmountValue).toFixed(this.decimalPoints);
        element2.offTargetPercent = (parseFloat(element2.proposedAmountPercentage) - parseFloat(element2.targetAmountPercentage)).toFixed(this.decimalPoints);

        if (element2.proposedAmountPercentage)
          element2.proposedAmountPercentage = parseFloat(element2.proposedAmountPercentage).toFixed(this.decimalPoints);
        if (element2.currentAmountValue)
          element2.currentAmountValue = parseFloat(element2.currentAmountValue).toFixed(this.decimalPoints);
        if (element2.currentAmountPercentage)
          element2.currentAmountPercentage = parseFloat(element2.currentAmountPercentage).toFixed(this.decimalPoints);
        if (isFirstTime)
          element2.originalProposedAmountValue = element2.proposedAmountValue;
        refinedData.push(element2);
        if (!element2["strategyTacticalInfoVOChild"])
          element2["strategyTacticalInfoVOChild"] = [];
        element2["strategyTacticalInfoVOChild"].forEach(element3 => {
          if (element3.currentAmountValue)
            element3.currentAmountValue = parseFloat(element3.currentAmountValue).toFixed(this.decimalPoints);
          if (element3.currentAmountPercentage)
            element3.currentAmountPercentage = parseFloat(element3.currentAmountPercentage).toFixed(this.decimalPoints);
          if (element3.proposedAmountValue) {
            element3.proposedAmountValue = parseFloat(element3.proposedAmountValue).toFixed(this.decimalPoints);
            element3.proposedAmountValueStr = this.convertToCommaSeperated(element3.proposedAmountValue);
          } else {
            element3.proposedAmountValueStr = "$0.00"
          }

          if (element3.proposedAmountPercentage)
            element3.proposedAmountPercentage = parseFloat(element3.proposedAmountPercentage).toFixed(this.decimalPoints);
          if (isFirstTime)
            element3.originalProposedAmountValue = element3.proposedAmountValue;
          element3.hierarchyId = 3;
          element3.parentClassId = element2.assetClassId;
          refinedData.push(element3);
        });
      });
    });
    return refinedData;
  }

  convertToOriginalVo(data) {
    let refinedData = [];
    data.forEach(element => {
      if (element.hierarchyId == 1) {
        refinedData.push(element);
        element.strategyTacticalInfoVOChild = [];
      }

      else if (element.hierarchyId == 2) {
        element.strategyTacticalInfoVOChild = [];
        refinedData[refinedData.length - 1].strategyTacticalInfoVOChild.push(element);
      }
      else if (element.hierarchyId == 3) {
        element.strategyTacticalInfoVOChild = [];
        if (element.proposedAmountValue)
          element.proposedAmountValue = parseFloat(element.proposedAmountValue);
        if (element.proposedAmountPercentage)
          element.proposedAmountPercentage = parseFloat(element.proposedAmountPercentage);
        refinedData[refinedData.length - 1].strategyTacticalInfoVOChild[refinedData[refinedData.length - 1].strategyTacticalInfoVOChild.length - 1].strategyTacticalInfoVOChild.push(element);
      }
    });

    return refinedData;
  }

  calculateProposedPercentageBlended(product) {
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let investableValue = portfolioObj["investableAssetsDecimalValue"];
      if (product.proposedAmountValue == "") {
        product.proposedAmountValue = 0
        product.proposedAmountPercentage = 0.00;
      } else {
        product.proposedAmountPercentage = ((product.proposedAmountValue / investableValue) * 100).toFixed(this.decimalPoints);

        product.blendedInvestors.forEach(element => {
          let assetClass = _.find(this.assetClassHeirarchy, { assetClassId: element.assetClassId });
          if (assetClass) {
            assetClass.proposedAmountValue = assetClass.originalProposedAmountValue + product.proposedAmountValue * element.percentageOwnership;
            let blendedProduct = _.find(this.assetClassHeirarchy, { investmentId: product.investmentId, parentClassId: element.assetClassId });
            if (blendedProduct) {
              blendedProduct.proposedAmountValue = (product.proposedAmountValue * element.percentageOwnership) / 100;
            }
          }
        });
      }
      this.calculateProposedPercentages();
    }, 100);
  }

  convertToCommaSeperated(row) {
    let value = row
    var partBeforeDecimal = '', partAfterDecimal = '';
    var index = value.indexOf('.')
    if (value == '$' || value == "") {
      row = '$0';
    } else {
      if (index > -1) {
        partBeforeDecimal = value.substr(0, index);
        partAfterDecimal = value.substr(index, value.length - index);
      } else {
        partBeforeDecimal = value;
      }

      if (partBeforeDecimal) {
        partBeforeDecimal = partBeforeDecimal.replace(/,/g, "").replace('$', "");
        row = '$' + (parseFloat(partBeforeDecimal)).toLocaleString() + partAfterDecimal;
      }
    }
    return row;
  }
  valueBlurHandler() {
    this.assetClassHeirarchy.forEach(element => {
      if (element.hierarchyId == 3) {
        element.proposedAmountValue = element.proposedAmountValueStr.replace(/,/g, "").replace('$', "");
      }
    });
    this.calculateProposedPercentages();
    //this.saveAssetHierarchy()
  }

  percentBlurHandler(asset) {
    this.calculateProposedValue(asset);
    //this.saveAssetHierarchy()
  }

  blendedValueBlurHandler(asset) {
    this.calculateProposedPercentageBlended(asset);
    // this.saveAssetHierarchy()
  }

  blendedPercentBlurHandler(asset) {
    this.calculateProposedValueBlended(asset);
    //this.saveAssetHierarchy()
  }

  calculateProposedValueBlended(product) {
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let investableValue = portfolioObj["investableAssetsDecimalValue"];
      if (product.proposedAmountPercentage == "") {
        product.proposedAmountPercentage = 0.00;
        product.proposedAmountValue = 0;
      } else {
        product.proposedAmountValue = ((product.proposedAmountPercentage * investableValue) / 100).toFixed(2);

        product.blendedInvestors.forEach(element => {
          let assetClass = _.find(this.assetClassHeirarchy, { assetClassId: element.assetClassId });
          if (assetClass) {
            assetClass.proposedAmountValue = assetClass.originalProposedAmountValue + product.proposedAmountValue * element.percentageOwnership
            let blendedProduct = _.find(this.assetClassHeirarchy, { investmentId: product.investmentId, parentClassId: element.assetClassId });
            if (blendedProduct) {
              blendedProduct.proposedAmountValue = (product.proposedAmountValue * element.percentageOwnership) / 100;
            }
          }
        });
      }
      this.calculateProposedPercentages();
    }, 100);
  }

  calculateProposedValue(product) {
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let investableValue = portfolioObj["investableAssetsDecimalValue"];
      if (product.proposedAmountPercentage == "") {
        product.proposedAmountValue = 0;
        product.proposedAmountPercentage = 0.00;
      } else {
        product.proposedAmountValue = (product.proposedAmountPercentage * investableValue) / 100;
      }
      this.calculateProposedPercentages();
    }, 100);
  }

  calculateProposedPercentages() {
    setTimeout(() => {
      let portfolioObj = _.find(this.dropdownsData.portfolioList, { portfolioId: this.selectedPortfolio });
      let refinedData = this.convertToOriginalVo(this.assetClassHeirarchy);
      var investableValue = portfolioObj["investableAssetsDecimalValue"];
      if (investableValue) {
        refinedData.forEach(element => {
          let sumProposedValue2 = 0;
          element["strategyTacticalInfoVOChild"].forEach(element2 => {
            if (!element2["strategyTacticalInfoVOChild"])
              element2["strategyTacticalInfoVOChild"] = [];
            let sumProposedValue = 0;
            element2["strategyTacticalInfoVOChild"].forEach(element3 => {
              if (element3.proposedAmountValue == "") {
                element3.proposedAmountValue = 0;
              }
              element3.proposedAmountPercentage = ((element3.proposedAmountValue / investableValue) * 100).toFixed(this.decimalPoints);
              sumProposedValue = sumProposedValue + parseFloat(element3.proposedAmountValue) - parseFloat(element3.originalProposedAmountValue);
            });
            element2.proposedAmountValue = parseFloat(element2.originalProposedAmountValue) + sumProposedValue;
            element2.proposedAmountPercentage = ((element2.proposedAmountValue / investableValue) * 100).toFixed(this.decimalPoints);
            sumProposedValue2 = sumProposedValue2 + parseFloat(element2.proposedAmountValue);
          });
          element.proposedAmountValue = sumProposedValue2;
          element.proposedAmountPercentage = ((element.proposedAmountValue / investableValue) * 100).toFixed(this.decimalPoints);
        });

      }
      this.totalCalculation(refinedData);
      this.assetClassHeirarchy = this.refineVo(refinedData, false);

    }
      , 100);
  }


  saveProduct() {
    let selectedRows = this.gridComponentData.getSelectedRows();
    if (selectedRows && selectedRows.length) {
      let requestData = {};
      requestData["portfolioId"] = this.selectedPortfolio;
      requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      requestData["productIds"] = [];
      selectedRows.forEach(element => {
        requestData["productIds"].push(element.productId);
      });
      if (this.selectedPortfolio) {
        this.portfolioConstructionService.addProductInAsset(requestData).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.ngxSmartModalService.getModal('addProducts').close();
            if (response['responseData']) {
              this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO, true);
              this.blendedList = response['responseData'].blendedInvestors;
              this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
              this.isChangeAfterSave = false;
              this.stepData.isChangeAfterSave = false;
              this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
            }

          }
        });
      } else {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select portfolio';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }


    } else {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select product';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }

  }
  addSecurityConfirmation(){
    this.ngxSmartModalService.getModal("addSecurityConfirmationModal").close();
    this.saveSecurities(false);
  }

  saveSecurities(value) {
    let selectedRows = this.gridComponentData.getSelectedRows();
    this.messageForAddSecurityConfirmation="";
    var checkSecurityExistence = false;
    var filterId =[];
    if (value) {
       this.assetClassHeirarchy.forEach(function (ele) {
        if (ele.isLocked) {
          filterId.push(ele.ticker);
        }
      });
      selectedRows.forEach(element => {
        if (filterId.includes(element.ticker)) {
          this.messageForAddSecurityConfirmation+=element.ticker+",";
          checkSecurityExistence = true;
        }
      });
      if(checkSecurityExistence){
        this.ngxSmartModalService.open("addSecurityConfirmationModal");
      }else{
        value=false;
      }
    }
    if(!value){
    if (selectedRows && selectedRows.length) {
      let requestData = {};
      requestData["portfolioId"] = this.selectedPortfolio;
      requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
      requestData["securityIds"] = [];
      selectedRows.forEach(element => {
        requestData["securityIds"].push(element.securityId);
      });
      if (this.selectedPortfolio) {
        this.portfolioConstructionService.addProductInAsset(requestData).subscribe((response) => {
          if (response['responseType'] == Variables.statusSuccess) {
            this.authMessageService.showSuccessPopup(response);
            this.ngxSmartModalService.getModal('addSecurities').close();
            if (response['responseData']) {
              this.assetClassHeirarchy = this.refineVo(response['responseData'].strategyTacticalAllocationVO, true);
              this.blendedList = response['responseData'].blendedInvestors;
              this.totalCalculation(response['responseData'].strategyTacticalAllocationVO);
              this.isChangeAfterSave = false;
              this.stepData.isChangeAfterSave = false;
              this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
            }

          }
        });
      } else {
        var errorObj = new ErrorModel();
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select portfolio';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
      }


    } else {
      var errorObj = new ErrorModel();
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select security';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  }

  analysis() {
    this.analyticsData.selectedStrategy = ''
    this.ngxSmartModalService.open("portfolioAnalytics");
  }
  addProductsFn() {
    this.ngxSmartModalService.open("addProducts");
    setTimeout(() => {
      $(".ag-body .ag-body-viewport").css("overflow-x", "scroll")
      $(" .ag-pinned-left-cols-viewport").css("overflow-x", "scroll")
      $(" .ag-pinned-left-cols-viewport").css("overflow-y", "hidden")
    }, 100);
  }
  addSecuritiesFn() {
    this.ngxSmartModalService.open("addSecurities");
    setTimeout(() => {
      $(".ag-body .ag-body-viewport").css("overflow-x", "hidden")
      $(" .ag-pinned-left-cols-viewport").css("overflow-x", "hidden")
      $(" .ag-pinned-left-cols-viewport").css("overflow-y", "hidden")
    }, 100);
  }

  adjustHeightViewport(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let tab = $('.relationship-overview-tabset')[0].firstElementChild.clientHeight;
    let options = $('.investment-tab-button')[0].clientHeight;
    let totalRow = $('.investments-total')[0].clientHeight;
    let margin = 155 + extraMargin;
    var total = header + pageheader + margin + stepHeader + tab + options + totalRow
    var ele = document.getElementsByTagName('tbody')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  getInvestmentTemplate(template) {
    this.selectedTemplate = template;

    this.portfolioConstructionService.getInvestmentTemplate().subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.investmentTemplateList = response["responseData"];
        if (this.selectedTemplate)
          this.onChangePortfolio(false);

      }
    });
  }

  onChangeTemplate() {
    this.onChangePortfolio(false);
  }

  openDeleteTemplateConfirmation() {
    if (!this.selectedTemplate) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select template';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    } else {
      this.ngxSmartModalService.open("deleteTemplateConfirmationModal");
    }
  }

  openTemplateModal(mode) {
    if (mode == 'edit') {
      if (!this.selectedTemplate) {
        let errorObj = {};
        errorObj['errorCode'] = 'ERR0107';
        errorObj['message'] = 'Please select template';
        errorObj['errorDescription'] = '';
        this.authMessageService.showErrorPopup(errorObj);
        return false;
      }
      this.investmentTemplateVO = _.find(this.investmentTemplateList, { 'objectId': this.selectedTemplate });
      //this.templateName = this.investmentTemplateVO["name"];
    } else {
      this.investmentTemplateVO = {};
    }
    this.templatePopupMode = mode;

    this.ngxSmartModalService.open("investmentTemplatePopup");



    setTimeout(() => {
      this.productComponentReference = this.popUpGridComponentData.toArray().find(function (item) {
        return item.gridName == 'catalog-product-portfolio-construction';
      });
      this.securityComponentReference = this.popUpGridComponentData.toArray().find(function (item) {
        return item.gridName == 'workflow-security-grid';
      });
    });
  }

  removeInvestmentPopUp(index) {
    this.investmentTemplateVO['investments'].splice(index, 1)
  }

  saveInvestmentTemplate() {
    let duplicateInvestment = false;
    if (!this.investmentTemplateVO["name"]) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please enter template name';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }
    let selectedRows = this.productComponentReference.getSelectedRows();
    let selectedRows2 = this.securityComponentReference.getSelectedRows();
    let investmentList = [];
    if (this.templatePopupMode == "edit") {
      investmentList = this.investmentTemplateVO["investments"];
    }

    selectedRows.forEach(element => {
      let temp = _.find(investmentList, { 'investmentId': element.productId, 'investmentType': "product" });
      if (!temp) {
        investmentList.push(
          {
            investmentType: "product",
            investmentId: element.productId,
            proposedAmountPercentage: 0.0,
            name: element.name

          }
        )
      } else {
        duplicateInvestment = true;

      }

    });
    selectedRows2.forEach(element => {
      let temp = _.find(investmentList, { 'investmentId': element.productId, 'investmentType': "security" });
      if (!temp) {
        investmentList.push(
          {
            investmentType: "security",
            investmentId: element.securityId,
            proposedAmountPercentage: 0.0,
            name: element.name

          }
        )
      } else {
        duplicateInvestment = true;
      }

    });

    if (duplicateInvestment) {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Investment(s) already added';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
      return false;
    }

    let requestData = {
      name: this.investmentTemplateVO["name"],
      objectId: null,
      investments: investmentList

    }
    if (this.templatePopupMode == 'edit')
      requestData.objectId = this.investmentTemplateVO["objectId"]

    this.portfolioConstructionService.saveInvestmentTemplate(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('investmentTemplatePopup').close();
        this.getInvestmentTemplate(this.selectedTemplate);
      }
    });
  }

  deleteTemplateAfterConfirmation() {
    this.portfolioConstructionService.deleteInvestmentTemplate(this.selectedTemplate).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.ngxSmartModalService.getModal('deleteTemplateConfirmationModal').close();
        this.getInvestmentTemplate(this.selectedTemplate);
      }
    });
  }
  updateChangeFlag() {
    this.isChangeAfterSave = true;
    this.stepData.isChangeAfterSave = true;
    this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
  }



  openConfirmationModal(param) {
    this.confirmationType = param
    if (this.isChangeAfterSave) {
      this.ngxSmartModalService.open("deleteConfirmationModal");
    } else if (param == "Product") {
      this.addProductsFn();

    } else if (param == "Security") {
      this.addSecuritiesFn();

    } else if (param == "remove") {
      this.removeInvestment();

    }
  }

  deleteAfterConfirmation() {
    this.ngxSmartModalService.getModal('deleteConfirmationModal').close();
    if (this.confirmationType == "Product") {
      this.addProductsFn();

    } else if (this.confirmationType == "Security") {
      this.addSecuritiesFn();

    } else if (this.confirmationType == "remove") {
      this.removeInvestment();

    }
  }

  ngOnDestroy() {
    this.stepData.isChangeAfterSave = false;
    this.workflowBaseService.setStepInfoVsStepName('portfolioConstruction', this.stepData);
  }

}
