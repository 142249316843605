import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class GridFilterSortingService {


    constructor() { }

    @Output() changeInSortingData: EventEmitter<any> = new EventEmitter();
    @Output() changeInFilteringData: EventEmitter<any> = new EventEmitter();
    @Output() changeInSelectAllFlag: EventEmitter<any> = new EventEmitter();
   // @Output() sendEventToCheck: EventEmitter<any> = new EventEmitter();

    // sendDataToEnableCheck(data){
    //     this.sendEventToCheck.emit(data);
    //   }

    setSortingData(data){
        this.changeInSortingData.emit(data);
    }

    setFilteringData(data){
        this.changeInFilteringData.emit(data);
    }

    setSelectAllInfo(data){
        this.changeInSelectAllFlag.emit(data);
    }

}