import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';
import { CustomCompileService } from 'src/app/_services/custom-compile.service';
import { AddEditWorkflowBalanceSheetComponent } from 'src/app/shared/popups/add-edit-workflow-balance-sheet/add-edit-workflow-balance-sheet.component';
import { RelationshipOverviewService } from '../../../../_services/workflow-services/relationship-overview.service';
import { AuthMessageService } from '../../../../_services/auth-message.service';
import { Router } from "@angular/router";
import { BalanceSheetFreeFormModel } from 'src/app/_models/balance-sheet.model';
import { ProposalWorkflowComponent } from '../../proposal-workflow.component';
import { WorkflowBaseService } from '../../../../_services/workflow-services/workflow-base.service';
import { GridComponent } from '../../../../shared/grid/grid.component';
import { Variables } from '../../../../constants';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FilterModel } from '../../../../_models/filter.model';
import { RelationshipOverviewComponent } from '../relationship-overview.component';

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.less']
})
export class BalanceSheetComponent implements OnInit {
  componentReference: any;
  balanceSheetFreeFormModel = new BalanceSheetFreeFormModel();
  @ViewChild(GridComponent) gridComponentData: GridComponent;
  selectedRows: any[];
  filterVO: any;
  dropdownsData: any;
  apiParams = {};
  listOfMessage=[];
  //htmlContent:any;
  //ckeditorContent: string = '<p>Some html</p>';
  // noMessage:Boolean;
  // message:Boolean;

  // showContainer: boolean;
  messageObj={
    title:'',
    description:''
  };
 
  constructor(
    private compileService: CustomCompileService,
    private appRef: ApplicationRef,
    private relationshipOverviewService: RelationshipOverviewService,
    private authMessageService: AuthMessageService,
    private router: Router,
    private proposalWorkflowComponent: ProposalWorkflowComponent,
    private workflowBaseService: WorkflowBaseService,
    private ngxSmartModalService: NgxSmartModalService,
    private relationshipOverviewComponent: RelationshipOverviewComponent
  ) {
    compileService.configure(this.appRef);
    this.componentReference = { componentParent: this };
  }

  ngOnInit() {
    setTimeout(() => {
      let callCount = 1;
      this.apiParams = {
        processInstanceId: this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
        taskDefinitionKey: this.proposalWorkflowComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey,
        processDefinitionKey: this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey

      };
      /* THIS is done because data is not coming in category column in balance-sheet add/edit*/
      let ifData = setInterval(() => {
        if (this.relationshipOverviewComponent && this.relationshipOverviewComponent.relationshipOverviewDropdowns) {
          this.dropdownsData = this.relationshipOverviewComponent.relationshipOverviewDropdowns;
          clearInterval(ifData);
        } else if (callCount > 10) {
          clearInterval(ifData);
        }
        callCount++;
      }, 500);
      $(".ag-body .ag-body-viewport").css("overflow-x", "scroll");
      $(".ag-pinned-left-cols-viewport").css("overflow-x", "scroll");
      $(".ag-pinned-left-cols-viewport").css("overflow-y", "hidden");
    });
  }

  /* Edit Row called from grid - edit icon row template */
  editRowData(rowData) {
    this.editBalanceSheet(rowData);
  }

  editRelationship() {
    var dataObj = {};
    dataObj['data'] = {};
    let requestObj = {
      "processInstanceId": this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
      "taskDefinitionKey": "relationshipSelection",
      "processDefinitionKey" : this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey
    
    };
    this.workflowBaseService.getTaskDetails(requestObj, false).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        if (response['responseData'].taskVariables) {
          dataObj['data']['relationshipId'] = response['responseData'].taskVariables.relationshipId;
          dataObj['isFrom'] = '/proposalWorkflow/relationshipOverview';
          var dataArray = [dataObj];
          var cache = [];
          var stringifiedData = JSON.stringify(dataArray, function (key, value) {
            if (typeof value === 'object' && value !== null) {
              if (cache.indexOf(value) !== -1) {
                // Duplicate reference found
                try {
                  // If this value does not reference a parent it can be deduped
                  return JSON.parse(JSON.stringify(value));
                } catch (error) {
                  // discard key if value cannot be deduped
                  return;
                }
              }
              // Store value in our collection
              cache.push(value);
            }
            return value;
          });
          cache = null; // Enable garbage collection

          localStorage.setItem('dataItem', stringifiedData);
          this.router.navigate(["/add-edit-relationship"]);
        }
      }
    });

  }




  addNewBalanceSheet(): void {
    this.compileService.compile(AddEditWorkflowBalanceSheetComponent, (c) => {
      c.instance.compId = "addEditWorkflowBalanceSheetPopup",
        c.instance.saveBalanceSheetData = this.saveBalanceSheet.bind(this),
        c.instance.dropdownsData = this.dropdownsData
    });
  }

  editBalanceSheet(rowData): void {
    this.compileService.compile(AddEditWorkflowBalanceSheetComponent, (c) => {
      c.instance.compId = "addEditWorkflowBalanceSheetPopup",
        c.instance.rowData = Object.assign({}, rowData),
        c.instance.saveBalanceSheetData = this.saveBalanceSheet.bind(this),
        c.instance.dropdownsData = this.dropdownsData
    });
  }

  saveBalanceSheet(data, popupReference) {
    var requestData = {};
    requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
    requestData["taskDefinitionKey"] = this.proposalWorkflowComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey;
    requestData["processDefinitionKey"] = this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey;
    requestData["balanceSheetVO"] = data;
    this.relationshipOverviewService.saveBalanceSheetData(requestData).subscribe((response) => {
      if (response['responseType'] == Variables.statusSuccess) {
        this.authMessageService.showSuccessPopup(response);
        this.gridComponentData.getGridDataAfterUpdatingRecords();
        popupReference.closePopup();
      }
    });
  }

  deleteBalanceSheet(): void {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length != 0) {
      this.ngxSmartModalService.open('deleteBalanceSheetModal');
    } else {
      let errorObj = {};
      errorObj['errorCode'] = 'ERR0107';
      errorObj['message'] = 'Please select any record for deleting';
      errorObj['errorDescription'] = '';
      this.authMessageService.showErrorPopup(errorObj);
    }
  }

  deleteBalanceSheetAfterConfirmation() {
    this.selectedRows = this.gridComponentData.getSelectedRows();
    var requestData = {};
    requestData["processInstanceId"] = this.proposalWorkflowComponent.workflowInfoData.processInstanceId;
    requestData["taskDefinitionKey"] = this.proposalWorkflowComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey;
    requestData["processDefinitionKey"] = this.proposalWorkflowComponent.workflowInfoData.processDefinitionKey;
    requestData["balanceSheetIdList"] = [];
    this.selectedRows.forEach(element => {
      requestData["balanceSheetIdList"].push(element.balanceSheetId);
    });
    this.relationshipOverviewService.deleteBalanceSheets(requestData)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.authMessageService.showSuccessPopup(response);
          this.ngxSmartModalService.getModal('deleteBalanceSheetModal').close();
          this.selectedRows = [];
          this.gridComponentData.getGridDataAfterUpdatingRecords();
        }
      });
  }

  getFreeFormHtml(){
    this.relationshipOverviewService.getFreeFormData(this.proposalWorkflowComponent.workflowInfoData.processInstanceId).subscribe((response)=>{
      if(response['responseType']==Variables.statusSuccess){
        if(response['responseData']['freeFormDataVO']){
          this.listOfMessage=response['responseData']['freeFormDataVO'];
          this.listOfMessage.forEach((element)=>{
            if(element.title === "Annual Income needs"){
              this.balanceSheetFreeFormModel.annualIncomeNeeds=element.description;
            }else if(element.title === "Estate Plan"){
              this.balanceSheetFreeFormModel.estatePlan=element.description;
            }else if(element.title === "Gifting Plans"){
              this.balanceSheetFreeFormModel.giftingPlans=element.description;
            }else if(element.title === "Restriction( If any )"){
              this.balanceSheetFreeFormModel.restriction=element.description;
            }else if(element.title === "Time Horizon"){
              this.balanceSheetFreeFormModel.timeHorizon=element.description;
            }
          });
        }else{
          this.listOfMessage=[];
        }
        this.ngxSmartModalService.open("getFreeForm");
      }
    });
  }


  clearMessage(){
    this.balanceSheetFreeFormModel.annualIncomeNeeds="";
    this.balanceSheetFreeFormModel.estatePlan="";
    this.balanceSheetFreeFormModel.giftingPlans="";
    this.balanceSheetFreeFormModel.restriction="";
    this.balanceSheetFreeFormModel.timeHorizon="";
  }

  saveFreeForm(){
    let messageList=[];
    let msgObj1={
      title:"Annual Income needs",
      description:this.balanceSheetFreeFormModel.annualIncomeNeeds
    };
    messageList.push(msgObj1);
    let msgObj2={
      title:"Estate Plan",
      description:this.balanceSheetFreeFormModel.estatePlan
    };
    messageList.push(msgObj2);
    let msgObj3={
      title:"Gifting Plans",
      description:this.balanceSheetFreeFormModel.giftingPlans
    };
    messageList.push(msgObj3);
    let msgObj4={
      title: "Restriction( If any )",
      description:this.balanceSheetFreeFormModel.restriction
    };
    messageList.push(msgObj4);
    let msgObj5={
      title:"Time Horizon",
      description:this.balanceSheetFreeFormModel.timeHorizon
    };
    messageList.push(msgObj5);
    let requestObj={
            'formName':"Financial Objective",
            'processInstanceId':this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
            'processDefinitionKey':this.proposalWorkflowComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey,
            'freeFormDataVO': messageList
          };
          this.relationshipOverviewService.sendFreeFormDetail(requestObj).subscribe(response => {
            if (response['responseType'] == Variables.statusSuccess) {
              this.ngxSmartModalService.getModal('getFreeForm').close();
              this.authMessageService.showSuccessPopup(response);
             }
          });
        }

  }
  // getFreeFormHtml() {
  //   this.listOfMessage=[];
  //   this.balanceSheetFreeFormModel.title = "";
  //   this.balanceSheetFreeFormModel.description = "";
    
  //   //  this.messageObj.title='';
  //   //  this.messageObj.description='';
  //    this.listOfMessage.push(this.balanceSheetFreeFormModel);
  //    this.ngxSmartModalService.open("getFreeForm");
    
  // }

  // addToList() {
  //   //this.noMessage=false;
  //   //var messageObj = {};
  //   //messageObj['title'] = this.balanceSheetFreeFormModel.title;
  //   //messageObj['description'] = this.balanceSheetFreeFormModel.description;
  //   //Add message in list if there is more than one  title with description
  //   this.listOfMessage.push(new BalanceSheetFreeFormModel());
  //   //After clicking on ADD MORE button clear title and description text Area
  //   // this.balanceSheetFreeFormModel.title = "";
  //   // this.balanceSheetFreeFormModel.description = "";

  // }
  
  // deleteMessage(indexOfElement){
  // this.listOfMessage.forEach(element => {
  //     if(indexOfElement===this.listOfMessage.indexOf(element)){
  //       this.listOfMessage.splice(indexOfElement,1);
  //     }
  //   });
  // }

//   saveFreeForm() {
//     // if (this.balanceSheetFreeFormModel.title || this.balanceSheetFreeFormModel.description) {
//     //   var messageObj = {};
//     //   messageObj['title'] = this.balanceSheetFreeFormModel.title;
//     //   messageObj['description'] = this.balanceSheetFreeFormModel.description;
//     //   //Add message in list if there is more than one  title with description
//     //   this.listOfMessage.push(messageObj);
//     // }
//     if(this.listOfMessage.length==1 && (this.listOfMessage[0].title=="" && this.listOfMessage[0].description=="")){
//       let errorObj = {};
//       errorObj['errorCode'] = 'ERR0107';
//       errorObj['message'] = 'Please add message before save';
//       errorObj['errorDescription'] = '';
//       this.authMessageService.showErrorPopup(errorObj);
     
//     }else{
//     let requestObj={
//       'formName':"Financial Objective",
//       'processInstanceId':this.proposalWorkflowComponent.workflowInfoData.processInstanceId,
//       'processDefinitionKey':this.proposalWorkflowComponent.workflowInfoData.currentTaskDetails.taskDefinitionKey,
//       'freeFormDataVO': this.listOfMessage
//     };
//     this.relationshipOverviewService.sendFreeFormDetail(requestObj).subscribe(response => {
//       if (response['responseType'] == Variables.statusSuccess) {
//         this.ngxSmartModalService.getModal('getFreeForm').close();
//         this.authMessageService.showSuccessPopup(response);
//        }
//     });
//   }
// }



