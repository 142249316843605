import { BaseService } from "./_services/base.service";

/*Local Base Path */
//const basePath = 'http://hexaview.com:8085/foliomax/rest';

/*Prod Base Path */
 const basePath = 'https://app-server.foliomax.com/foliomax/rest';

/*QA Base Path */
// const basePath = 'https://qa-app-server.foliomax.com/foliomax/rest';

const loginPath='/login';

export class Variables{
    /* Status Constants */
    public static statusSuccess = "SUCCESS";
    public static statusError   = "ERROR";
    public static smsAuth       = "SMSAUTH";
    public static googleAuth    = "GOOGLEAUTH";
    public static itemsPerPageArr = [10,20,50,100];
    public static entityTypes = {
                                   relationship :'relationship',
                                   client       : 'client',
                                   account      : 'account'

                                 }
}



export class ApiUrls{
    public static loginAPI                        = basePath + '/user/doLogin';
    public static logoutAPI                       = basePath + '/user/doLogout';
    public static forgotPasswordAPI               = basePath + '/user/forgotPassword';
    public static resetPasswordAPI                = basePath + '/user/resetPassword';
    public static changePasswordAPI               = basePath + '/user/changePassword';
    public static activateMfaAPI                  = basePath + '/user/activateMFA';
    public static enableMfaAPI                    = basePath + '/user/enableMFA';
    public static deactivateMfaAPI                = basePath + '/user/deactivateMFA';
    public static getActivatedMFADevicesAPI       = basePath + '/user/getActivatedMFADevices';
    public static setRelationshipsDataAPI         = basePath + '/relationship/createClient';
    public static setClientDataAPI                = basePath + '/relationship/createLegalEntity';
    public static setAccountDataAPI               = basePath + '/relationship/createAccount';
    public static getRelationshpsDataAPI          = basePath + '/relationship/clientListing';
    public static getLegalEntityListDataAPI       = basePath + '/relationship/getLegalEntityList';
    public static getAccountListDataAPI           = basePath + '/relationship/getAccountList';
    public static deleteRelationshipDataAPI       = basePath + '/relationship/deleteClient';
    public static deleteClientDataAPI             = basePath + '/relationship/deleteLegalEntity';
    public static deleteAccountDataAPI            = basePath + '/relationship/deleteAccount';
    public static getDashBoardSummaryAPI          = basePath + '/relationship/getDashBoardSummary';
    //public static setDashboardDataAPI             = basePath + '/dashboard/getProcessList';
    public static setPresentationDataAPI          = basePath + '/dashboard/getProcessList';
    //public static getDocumentListDataAPI          = basePath + '/documents/getDocumentsList';
    public static getUserListDataAPI              = basePath + '/user/getAllUsers';
    public static createUserAPI                   = basePath + '/user/user';
    public static updateUserAPI                   = basePath + '/user/user';
    public static deleteUserAPI                   = basePath + '/user/user';
    public static confirmRegistrationAPI          = basePath + '/user/confirmRegistration';
    public static getAllRolesAPI                  = basePath + '/user/getAllRoles';
    public static tempPasswordAPI                 = basePath + '/user/tempPassword';
    public static userVarificationMailAPI         = basePath + '/user/userVarificationMail';
    public static saveDocumentDataAPI             = basePath + '/documents/uploadDocument';
    public static deleteDocumentsDataAPI          = basePath + '/documents/deleteDocuments';
    public static saveAssignToAPI                 = basePath + '/dashboard/saveAssignTo';
    public static downloadDocumentsDataAPI        = basePath + '/documents/downloadDocument';
    public static getAllDataloadConfigAPI         = basePath + '/dataloadconfig/getAllDataloadConfig';
    public static uploadDataAPI                   = basePath + '/dataloadconfig/uploadData';
    public static downloadDataAPI                 = basePath + '/dataloadconfig/downloadData';
    public static downloadTemplateAPI             = basePath + '/dataloadconfig/downloadTemplate';
    public static getEnumDataBasedOnModuleApi     = basePath + '/module/getEnumInfo';
    public static saveProductListDataAPI          = basePath + '/product/createProductList';
    public static deleteProductListDataAPI        = basePath + '/product/deleteProductList';
    public static getAllEntitledProductListAPI    = basePath + '/product/getProductListData';
    public static saveProductDataAPI              = basePath + '/product/createProductDetails';
    public static deleteProductDataAPI            = basePath + '/product/deleteProductDetails';
    public static getProductOveriewInfoDataAPI    = basePath + '/product/getProductOverview';
    public static getProductDetailsInfoDataAPI    = basePath + '/product/getProductDetails';
    public static getProductCharactersticsInfoDataAPI = basePath + '/product/getProductCharateristics';
    public static getProductManagementInfoDataAPI = basePath + '/product/getProductManagement';
    public static getProductPerformanceInfoDataAPI= basePath + '/product/getProductPerformance';
    public static getProductFeesInfoDataAPI       = basePath + '/product/getProductFees';
    public static getProductHoldingInfoDataAPI    = basePath + '/product/getProductHoldings';
    public static getProductComparisonInfoDataAPI = basePath + '/product/getProductCompare';
    public static getRightNavigationInfoDataAPI   = basePath + '/module/getEnumInfoByType';
    public static startNewProcessAPI              = basePath + '/workflow/startProcess';
    public static saveProcessNameAPI              = basePath + '/workflow/updateProcessName';
    public static getProcessInfoDataAPI           = basePath + '/workflow/getProcess';
    public static saveBalanceSheetDataAPI         = basePath + '/workflowOperations/createBalanceSheet';
    public static deleteBalanceSheetsAPI          = basePath + '/workflowOperations/deleteBalanceSheet';
    public static downloadCashflowTemplateAPI     = basePath + '/workflowOperations/downloadCashflowTemplate';
    public static getInvestorInfoAPI              = basePath + '/workflowOperations/getInvestorInfo';
    public static getEnumDataBasedOnWorkflowStepAPI = basePath + '/module/getWorkFlowEnumInfo';
    public static getStatesDataAPI                = basePath + '/module/getStateInfo';
    public static getCitiesDataAPI                = basePath + '/module/getCityInfo';
    public static gotoNextStepInWorkflowAPI       = basePath + '/workflow/nextTask';
    public static gotoPrevStepInWorkflowAPI       = basePath + '/workflow/previousTask';
    public static getTasksDetailsAPI              = basePath + '/workflow/getTaskDetails';
    public static getExternalPartyInfoAPI         = basePath + '/workflowOperations/getExternalPartyList';
    public static updateInvestorInfoAPI           = basePath + '/workflowOperations/updateInvestorInfo';
    public static getTaxInfoEnums                 = basePath + '/workflowOperations/getTaxEnumListing';
    public static saveTaxInfoAPI                  = basePath + '/workflowOperations/createTaxInfo';
    public static getTaxInfoLisitngAPI            = basePath + '/workflowOperations/getTaxInfoListing';
    public static deleteTaxInfoAPI                = basePath + '/workflowOperations/deleteTaxInfo';
    public static saveFreeFormAPI                 = basePath + '/workflowOperations/saveFreeFormData';
    public static getFreeFormAPI                  = basePath + '/workflowOperations/freeFormData';
    public static savePortfolioDataAPI            = basePath + '/workflowOperations/savePortfolio';
    public static getOwnerListForAccountAPI       = basePath + '/workflowOperations/getOwner';
    public static getConnectedPortfolioListAPI    = basePath + '/workflowOperations/getAccountInPf';
    public static saveConnectedPortfolioListAPI   = basePath + '/workflowOperations/connectAccountInPf';
    public static includePortfolioInWorkflowAPI   = basePath + '/workflowOperations/includeportinfpw';
	public static downloadTemplateWorkflowAPI     = basePath + '/workflowOperations/downloadTemplate';
    public static importHoldingsDataAPI           = basePath + '/workflowOperations/importHoldingsData';
    public static saveHoldingsAPI                 = basePath + '/relationship/saveHoldings';
    public static deleteHoldingsAPI               = basePath + '/relationship/deleteHoldings';
    public static getHoldingsListAPI              = basePath + '/relationship/getHoldingsList';
    public static getEditHoldingsListAPI          = basePath + '/relationship/getEditHoldingsList';
    public static resolveHoldingsAPI              = basePath + '/relationship/resolveHoldings';
    public static deletePortfoliosAPI             = basePath + '/workflowOperations/deletePortfolio';
    public static saveEntityInformationAPI        = basePath + '/workflowOperations/saveEntityInformation';
    public static getCashFlowSelectionEnumAPI     = basePath + '/workflowOperations/getCashFlowSelectionEnum';
    public static saveInflowAPI                   = basePath + '/workflowOperations/saveInflow';
    public static saveOutflowAPI                  = basePath + '/workflowOperations/saveOutflow';
    public static getEntityInformationAPI         = basePath + '/workflowOperations/getEntityInformation';
    public static deleteInflowAPI                 = basePath + '/workflowOperations/deleteInflow';
    public static deleteOutflowAPI                = basePath + '/workflowOperations/deleteOutflow';
    public static importCashflowDataAPI           = basePath + '/workflowOperations/importCashflowData';
    public static saveSimulationParameterAPI      = basePath + '/workflowOperations/saveSimulationParameter';
    public static getSimulationParameterAPI       = basePath + '/workflowOperations/getSimulationParameter';
    public static getManagementFeesAPI            = basePath + '/workflowOperations/getManagementFees';
    public static saveMinimumFeeAPI               = basePath + '/workflowOperations/saveMinimumFee';
    public static saveDefaultManagementFeesAPI    = basePath + '/workflowOperations/saveDefaultManagementFees';
    public static saveManagementFeesAPI           = basePath + '/workflowOperations/saveManagementFees';
    public static deleteManagementFeesAPI         = basePath + '/workflowOperations/deleteManagementFees';
    public static editManagementFeesAPI           = basePath + '/workflowOperations/editManagementFees';
    public static analyseWorkflowAPI              = basePath + '/workflowOperations/analyseWorkflow';
    public static getTacticalStrategiesAPI        = basePath + '/workflowOperations/getTacticalStrategies';
	public static getStrategyDataAPI              = basePath + '/workflowOperations/getProposedStrategies';
    public static saveUpdatedStrategyDataAPI      = basePath + '/workflowOperations/saveUpdatedProposedStrategies';
    public static getDataOnResetAPI               = basePath + '/workflowOperations/getDefaultProposedStrategies';
    public static addProductInAssetAPI            = basePath + '/workflowOperations/addInvestments';
    public static getAccountProductListAPI        = basePath + '/workflowOperations/getAccountProductList';
    public static getAccountListAPI               = basePath + '/workflowOperations/getAccountList';
    public static saveLockedHoldingDataAPI        = basePath + '/workflowOperations/saveUpdatedLockedData';
    public static validatePortfolioAllocation       = basePath + '/workflowOperations/validatePortfolioAllocation';
    public static getUnAssignedPositionAPI        = basePath + '/workflowOperations/getUnAssignedPosition';
    public static assignProductAPI                = basePath + '/workflowOperations/assignProduct';
    public static unAssignProductAPI              = basePath + '/workflowOperations/unAssignProduct';
    public static saveUpdatedTacticalStrategiesAPI= basePath + '/workflowOperations/saveUpdatedTacticalStrategies';
    public static createOpenAccountAPI            = basePath + '/workflowOperations/createOpenAccount';
    public static getanalysistypesAPI             = basePath + '/workflowOperations/getanalysistypes';
    public static getanalysisinfoAPI              = basePath + '/workflowOperations/getanalysisinfo';
    public static getanalysismetadataAPI          = basePath + '/workflowOperations/getanalysismetadata';
    public static getSlideSelectionDetailsAPI     = basePath + '/workflowOperations/getSlideSelectionDetails';
    public static saveSlideSelectionAPI           = basePath + '/workflowOperations/saveSlideSelection';
    public static saveTemplateAPI                 = basePath + '/workflowOperations/saveTemplate';
    public static strategiesOneTwoAPI             = basePath + '/workflowOperations/strategiesOneTwo';
    public static getExistingTemplatesAPI         = basePath + '/workflowOperations/getExistingTemplates';
    public static getTemplateDetailsAPI           = basePath + '/workflowOperations/getTemplateDetails';
    public static getPresentationSlideAPI         = basePath + '/workflowOperations/getPresentationSlide';
    public static saveGeneratePresentationDetailsAPI= basePath + '/workflowOperations/saveGeneratePresentationDetails';
    public static getGeneratePresentationDetailsAPI= basePath + '/workflowOperations/getGeneratePresentationDetails';
    public static generatereportAPI               = basePath + '/reporting/generateReport';
    public static dowloadReportAPI                = basePath + '/reporting/dowloadReport';
    public static deleteReportAPI                 = basePath + '/workflow/deleteProcess';
    public static getAssetClassExclusionInfoAPI   = basePath + '/workflowOperations/getAssetClassExclusionInfo';
    public static saveAssetClassExclusionInfoAPI   = basePath + '/workflowOperations/saveAssetClassExclusionInfo';
    public static updateProjectionPeriodAPI       = basePath + '/workflowOperations/updateProjectionPeriod';
    public static updateAccountTaxStatusAPI       = basePath + '/workflowOperations/updateAccountTaxStatus';
    public static getCurrentStrategiesAACAPI      = basePath + '/workflowOperations/getCurrentStrategiesAAC';
    public static getProposalStepAllocationDataAPI = basePath + '/workflowOperations/getProposalStepAllocationData';
    public static addCurrentStrategiesAPI         = basePath + '/workflowOperations/addCurrentStrategies';
    public static getFeeTemplatesForUserAPI       = basePath + '/workflowOperations/getFeeTemplatesForUser';
    public static saveFeeTemplateAPI              = basePath + '/workflowOperations/saveFeeTemplate';
    public static deleteFeeTemplateAPI            = basePath + '/workflowOperations/deleteFeeTemplate';
    public static resetFeeTemplateAPI             = basePath + '/workflowOperations/resetFeeTemplate';
    public static deleteCurrentStrategiesAPI      = basePath + '/workflowOperations/deleteCurrentStrategies';
    public static getFeeDetailsAPI                = basePath + '/workflowOperations/getFeeDetails';
    public static updateFeeIncludedAPI            = basePath + '/workflowOperations/updateFeeIncluded';
    public static saveInvestmentTemplateAPI       = basePath + '/workflowOperations/saveInvestmentTemplate';
    public static getInvestmentTemplateAPI        = basePath + '/workflowOperations/getInvestmentTemplate';
    public static investmentTemplateAPI           = basePath + '/workflowOperations/investmentTemplate';
    public static strategyTemplateAPI             = basePath + '/workflowOperations/strategyTemplate';
    public static getStrategyTemplateAPI          = basePath + '/workflowOperations/strategyTemplates';
    public static getBuySellSummarDataAPI          = basePath + '/workflowOperations/buyAndSellSummary';

    public static getSlideChartDataAPI            = basePath + '/reporting/getSlideChartData';
    public static uploadChartImgAPI               = basePath + '/reporting/uploadChartImg';  
    public static removeInvestmentAPI             = basePath + '/workflowOperations/removeInvestment';

    public static getGridConfigDataAPI            = basePath + '/gridconfig/getGridConfig'; //gridConfig.json
    public static getGridSearchSortDataAPI        = basePath + '/gridconfig/getSearchParamData';
    public static getGridDataAPI                  = {
        'document-center'          : basePath + '/documents/getDocumentsList',
        'dashboard'                : basePath + '/dashboard/getProcessList',
        'presentation'             : basePath + '/dashboard/getProcessList',
        'catalog-product-list'     : basePath + '/product/getProductListListing',
        'catalog-product'          : basePath + '/product/getProductListing',
        'catalog-product-portfolio-construction'          : basePath + '/product/getProductListing',
        'workflow-relationship-section': basePath + '/relationship/clientListing',
        'catalog-product-material': basePath + '/product/getProductMaterials',
        'workflow-balance-sheet'  : basePath + '/workflowOperations/getBalanceSheetListing',
        'workflow-portfolio-list' : basePath + '/workflowOperations/getPortfolios',
        'workflow-portfolio-account-list' : basePath + '/workflowOperations/getAccounts',
        'workflow-inflow-list' : basePath + '/workflowOperations/getInflows',
        'workflow-outflow-list' : basePath + '/workflowOperations/getOutFlows',
        'workflow-management-fee-list' : basePath + '/workflowOperations/getManagementFees',
        'workflow-security-grid'  : basePath + '/workflowOperations/getSecurityListing',
        'user-listing'            : basePath + '/user/getUserList'
    };
     //src/app/sampleJsons/gridData.json

    // public static getGridConfigDataAPI            = 'src/app/sampleJsons/'; //gridConfig.json
    // public static getGridSearchSortDataAPI        = 'src/app/sampleJsons/filterData.json';
    // public static getGridDataAPI                  = basePath + '/documents/getDocumentsList'; //src/app/sampleJsons/gridData.json

}
