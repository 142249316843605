import { Component, OnInit,Input } from '@angular/core';
import { Variables } from '../../../../../constants';
import { AnalysisService } from '../../../../../_services/workflow-services/analysis.service';

@Component({
  selector: 'app-tax-calc-linear',
  templateUrl: './tax-calc-linear.component.html',
  styleUrls: ['./tax-calc-linear.component.less']
})
export class TaxCalcLinearComponent implements OnInit {

  constructor(
    private analysisService : AnalysisService
  ) { }
  @Input() inputAnalysisData;
  @Input() inputRequestData;
  specificStrategy="";
  cashFlowList:any[];
  requestData:{};
  ngOnChanges() {
    if(this.inputAnalysisData){
      console.log(this.inputAnalysisData);
      
      this.cashFlowList = this.inputAnalysisData["taxCalculationSchedule"];
      this.specificStrategy=this.inputAnalysisData.strategyOptions[0];
    }
    this.requestData = this.inputRequestData;
  }

  ngOnInit(){

  }

  getDataBasedOnAnalysisType() {
    if(this.requestData){
      this.requestData["specificStrategyId"] = this.specificStrategy["value"];
      this.analysisService.getanalysisinfo(this.requestData).subscribe((response) => {
        if (response['responseType'] == Variables.statusSuccess) {
            this.cashFlowList = response["responseData"].taxCalculationSchedule;
        }
      });
    }
    
  }

}
