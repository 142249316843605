import { Component, OnInit, Input } from '@angular/core';
import { AnalysisStrategyComponent } from 'src/app/webPages/proposal-workflow/analysis-strategy/analysis-strategy.component';

@Component({
  selector: 'app-allocation-charts',
  templateUrl: './allocation-charts.component.html',
  styleUrls: ['./allocation-charts.component.less']
})
export class AllocationChartsComponent implements OnInit {

  chartVo;
  removeProposedGraph: boolean;
  strategy1: any;
  strategy2: any;

  constructor(
    private analysisStrategyComponent: AnalysisStrategyComponent
  ) {

  }
  @Input() public inputAnalysisData;
  ngOnChanges(): void {
    this.strategy1=this.analysisStrategyComponent.selectedStrategy1['key'];
    this.strategy2=this.analysisStrategyComponent.selectedStrategy2['key'];
    if (this.inputAnalysisData) {
      this.removeProposedGraph = this.analysisStrategyComponent.flagForRemovingProposedColumn

      //setTimeout(() => {
        this.chartVo = {
          current: {
            chartLabels: [],
            chartType: "pie",
            chartData: [],
            chartOptions: {
              //backgroundColor: ['rgba(100,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
              backgroundColor: [],
              legend: {
                display: true,
                position: 'right',
                //itemMaxWidth:10,
                labels:{
                  boxWidth:10,
                  boxHeight:10
                }
              },
              maintainAspectRatio: false
            }
          },
          proposed: {
            chartLabels: [],
            chartType: "pie",
            chartData: [],
            chartOptions: {
              //backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,200,0.3)'],
              backgroundColor: [],
              legend: {
                display: true,
                position: 'right',
                //itemMaxWidth:10,
                labels:{
                  boxWidth:10,
                  boxHeight:10
                }
              },
              maintainAspectRatio: false
            }
          },
          firstModelAllocation: {
            chartLabels: [],
            chartType: "pie",
            chartData: [],
            chartOptions: {
              //backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,200,0,0.3)', 'rgba(0,0,255,0.3)'],
              backgroundColor: [],
              legend: {
                display: true,
                position: 'right',
                //itemMaxWidth:10,
                labels:{
                  boxWidth:10,
                  boxHeight:10
                }
              },
              maintainAspectRatio: false
            }
          },
          secondModelAllocation: {
            chartLabels: [],
            chartType: "pie",
            chartData: [],
            chartOptions: {
              //backgroundColor: ['rgba(200,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
              backgroundColor: [],
              legend: {
                display: true,
                position: 'right',
                //itemMaxWidth:10,
                labels:{
                  boxWidth:10,
                  boxHeight:10
                }
              },
              maintainAspectRatio: false
            }
          }
        }

        this.inputAnalysisData.forEach(element => {
          if (element.currentAllocation) {
            this.chartVo.current.chartLabels.push(element.assetClassName);
            this.chartVo.current.chartData.push(parseInt(element.currentAllocation));
            this.chartVo.current.chartOptions.backgroundColor.push(element.color);
          }
          if (element.proposedAllocation) {
            this.chartVo.proposed.chartLabels.push(element.assetClassName);
            this.chartVo.proposed.chartData.push(parseInt(element.proposedAllocation));
            this.chartVo.proposed.chartOptions.backgroundColor.push(element.color);
          }
          if (element.firstModelAllocation) {
            this.chartVo.firstModelAllocation.chartLabels.push(element.assetClassName);
            this.chartVo.firstModelAllocation.chartData.push(parseInt(element.firstModelAllocation));
            this.chartVo.firstModelAllocation.chartOptions.backgroundColor.push(element.color);
          }
          if (element.secondModelAllocation) {
            this.chartVo.secondModelAllocation.chartLabels.push(element.assetClassName);
            this.chartVo.secondModelAllocation.chartData.push(parseInt(element.secondModelAllocation));
            this.chartVo.secondModelAllocation.chartOptions.backgroundColor.push(element.color);
          }
        });
        //this.analysisStrategyComponent.isAnalysisTypeData=false;

      //}
    //,1000);
  }
}
    ngOnInit() {
         
    }

  }
