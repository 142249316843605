import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiUrls } from '../constants';

/* API list of service */
const themeDataAPI   = 'http://localhost:4200/src/assets/sampleJson/theme.json';

@Injectable({
  providedIn: 'root'
})
export class BaseService{
  
  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };
  moduleInfoRightNav: any;


  constructor(
    private http: HttpClient
  ) {}

    /**
   * Get theme data
   */
  getThemeData(){
    return this.http.get(themeDataAPI).pipe(
          tap(ThemeCodes => ThemeCodes)
    );
  }

  getEnumDataBasedOnModule(value){
    
    return this.http.get(ApiUrls.getEnumDataBasedOnModuleApi+ "?module=" + value,this.httpOptions)
    .pipe(
        response => response
    );
  }

  getRightNavigationData(requestData){
    return this.http.get(ApiUrls.getRightNavigationInfoDataAPI+ "?enumType=" + requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  getStatesInfoData(requestData){
    return this.http.get(ApiUrls.getStatesDataAPI + "?countryCode=" + requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  getCitiesInfoData(requestData){
    return this.http.get(ApiUrls.getCitiesDataAPI + "?stateCode=" + requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  confirmRegistration(requestData){
    let httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    };
    return this.http.get(ApiUrls.confirmRegistrationAPI + "?userId=" + requestData.userId + "&token=" + requestData.token + "&subDomain=" + requestData.subDomain,httpOptions)
    .pipe(
        response => response
    );
  }

  getDashBoardSummary(){
    return this.http.get(ApiUrls.getDashBoardSummaryAPI ,this.httpOptions)
    .pipe(
        response => response
    );
  }

  generatereport(processInstaceId,requestData){
    return this.http.post(ApiUrls.generatereportAPI + "?processInstaceId="+processInstaceId,requestData ,this.httpOptions)
    .pipe(
        response => response
    );
  }


}
