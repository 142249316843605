import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FilterModel } from './../../_models/filter.model';
import { GridService } from '../../_services/grid.service';
import { Variables } from 'src/app/constants';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';

import { SelectFilterComponent } from './../custom-filters/select-filter/select-filter.component';
import { BooleanFilterComponent } from './../custom-filters/boolean-filter/boolean-filter.component';
import { LikeFilterComponent } from './../custom-filters/like-filter/like-filter.component';
import { DateFilterComponent } from './../custom-filters/date-filter/date-filter.component';
import { ProgressBarRowTemplate } from './../custom-grid-row-templates/progress-bar';
import { EditIconRowTemplate } from './../custom-grid-row-templates/edit-icon';
import { HierarchyRowTemplate } from './../custom-grid-row-templates/hierarchy-row';
import { LinkTypeRowTemplate } from '../custom-grid-row-templates/linkType-row';
import { CustomGridHeaderComponent } from './../custom-grid-header/custom-grid-header.component';
import { DownloadIconRowTemplate } from './../custom-grid-row-templates/download-icon';
import { PrintIconRowTemplate } from './../custom-grid-row-templates/printType-row';
import { ButtonTypeRowTemplate } from './../custom-grid-row-templates/buttonType-row';
import { GridFilterSortingService } from '../../_services/grid-filter-sorting.service';
import { SearchSort } from './../../_models/search-sort.model';
import { SelectAllHeaderComponent } from '../custom-grid-header/select-all-header/select-all-header.component';
import { from } from 'rxjs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from "@angular/router";
import { routerNgProbeToken } from '@angular/router/src/router_module';
declare var _, $: any;

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.less']
  
})
export class GridComponent implements OnInit {
  public gridApi;
  private gridColumnApi;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  isRowSelectable;
  columnDefs: Array<any>;
  rowData: Array<any>;
  private searchParamsData: Array<any>;
  private itemsPerPageArr = Variables.itemsPerPageArr;

  frameworkComponents;
  defaultColDef;
  public filterVO = new FilterModel();
  private results = [];
  public selectedRows = [];
  public sumTotal = null;
  private isEventSubscribed:boolean = false;
//@ViewChild(SelectFilterComponent) child;
  @Input() gridName: string;
  @Input() enableFilters: boolean;
  @Input() disablePagination: boolean;
  @Input() enableSorting: boolean;
  @Input() enableColResize: boolean;
  @Input() rowSelection: string;
  @Input() context;
  @Input() height: string;
  @Input() callRowDoubleClickEvent: string;
  @Input() defaultSearchingData: {};
  @Input() apiParams: {};
  @Input() hideSetColumnVisibilityOption: boolean;

  @Output() public onRowDoubleClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() public rowSelectionChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() message: any={};
  
  //@Output() checkEvent = new EventEmitter<string>();

  constructor(
    private location: Location,
   private router:Router,
    private gridService: GridService,
    private gridFilterSortingService: GridFilterSortingService
  ) {

    this.frameworkComponents = {
      DownloadIconRowTemplate: DownloadIconRowTemplate,
      PrintIconRowTemplate    : PrintIconRowTemplate,
      ButtonTypeRowTemplate   : ButtonTypeRowTemplate,
      SelectFilterComponent: SelectFilterComponent,
      BooleanFilterComponent:BooleanFilterComponent,
      LikeFilterComponent: LikeFilterComponent,
      DateFilterComponent: DateFilterComponent,
      ProgressBarRowTemplate: ProgressBarRowTemplate,
      LinkTypeRowTemplate  : LinkTypeRowTemplate,
      EditIconRowTemplate: EditIconRowTemplate,
      HierarchyRowTemplate:HierarchyRowTemplate,
      agColumnHeader: CustomGridHeaderComponent,
      selectAllHeaderComponent: SelectAllHeaderComponent
    };
  }

  ngOnInit() {
    this.getGridConfig();
    /*this is for row selection on a particular node or row selection */
    this.isRowSelectable = function(rowNode) {
        if(rowNode.data.name=='UnAllocated Assets'){
          return false;
        }else{
          return true;
        }
    };
    this.subscription = this.gridFilterSortingService.changeInSortingData.subscribe(data => {
          this.isEventSubscribed = true;
          if(!data.searchByName){
          this.filterVO.searchParamsInfoVO.forEach(element => {
            if (element.fieldName == data.fieldName) {
              element.searchedValue = data.searchedValue;
              element.sortingOrder = data.sortingOrder;
            }
          });
          this.getGridData(this.filterVO);
        }else{
          this.filterVO.searchParamsInfoVO[1].filterValue=data['fieldValue'];
          //this.filterVO.searchParamsList.push(data['fieldValue']);
          this.getGridData(this.filterVO);
          //this.filterVO.searchParamsList=[];
          this.filterVO.searchParamsInfoVO[1].filterValue="";
        }
          
      
      
      
    });
    this.subscription2= this.gridFilterSortingService.changeInFilteringData.subscribe(data => {
      this.getGridData(this.filterVO);
    });

    this.subscription3=this.gridFilterSortingService.changeInSelectAllFlag.subscribe(data => {
      if (data.grid == this.gridName) {
        if (data.type == 'TOTAL') {
          this.filterVO.isTotalRecordSelected = true;
          document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] =true;
          this.gridApi.forEachNode(function (node) {
            /* this is for disable  row on total/all selection*/
            // if(node.data.name!='UnAllocated Assets'){
            //   node.setSelected(true);
            // }
            node.setSelected(true);
          });
          /* this timeout for again select checkbox after all/total selection----reason to do this beacuse of disable a particular row*/
          setTimeout(()=>{
            document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] = true;
          },30);
        } else if (data.type == 'ALL') {
          this.filterVO.isTotalRecordSelected = false;
          document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] = true;
          this.gridApi.forEachNode(function (node) {
            /* this is for disable  row on total/all selection*/
            // if(node.data.name!='UnAllocated Assets'){
            //   node.setSelected(true);
            // }
            node.setSelected(true);
            
          });
          /* this timeout for again select checkbox after all/total selection----reason to do this beacuse of disable a particular row*/
          setTimeout(()=>{
            document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] = true;
          },30);
        } else {
          this.filterVO.isTotalRecordSelected = false;
          this.gridApi.forEachNode(function (node) {
            node.setSelected(false);
          });
        }
      }
    });

    $(document).ready(function () {
      $('.dropdown-menu').on('click', function (e) {
        if ($(this).hasClass('dropdown-menu')) {
          e.stopPropagation();
        }
      });
    });
  }

  /* On selecting rows in grid */
  onSelectionChanged(params) {
    
    this.gridApi = params.api;
    var selectedRows = this.gridApi.getSelectedRows();

    if(this.gridName == "workflow-portfolio-list")
     this.context.componentParent.onSeletionChanged(selectedRows);

    this.selectedRows = selectedRows;
    if (selectedRows.length < params.api.paginationProxy.pageSize) {
      this.frameworkComponents.selectAllHeaderComponent.selectAllCheckbox = false;
      this.frameworkComponents.selectAllHeaderComponent.selectAllOrTotal = null;
      this.unckeckMasterCheckBox();
    }
    else {
      this.frameworkComponents.selectAllHeaderComponent.selectAllCheckbox = true;
      this.frameworkComponents.selectAllHeaderComponent.selectAllOrTotal = 'ALL';
      if (document.getElementById('selectAllCheckbox-' + this.gridName + '-id'))
        document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] = true;
      if(document.getElementById('select-total-' + this.gridName + '-id') && document.getElementById('select-total-' + this.gridName + '-id')['checked'] == false)
        if (document.getElementById('select-all-' + this.gridName + '-id'))
          document.getElementById('select-all-' + this.gridName + '-id')['checked'] = true;
    }
    this.rowSelectionChangedEvent.emit(params.api.getSelectedNodes());
  }

  /* Get selected rows*/
  getSelectedRows() {
    return this.selectedRows;
  }

  

  /* Show/Hide columns */
  showHideColumn(item) {
    item.hide = !item.hide;
    if (item.hide) {
      this.gridColumnApi.setColumnsVisible([item.field], false);
    } else {
      this.gridColumnApi.setColumnsVisible([item.field], true);
    }
    this.gridApi.sizeColumnsToFit();
  }

  /* Get grid config */
  getGridConfig() {
    this.gridService
      .getGridConfig(this.gridName)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          let columnDefs = this.applyEditColumnPermission(JSON.parse(response['responseData']));
          if(this.location.path()=="/maxCalculator/proposalSetup")
          {
           if(this.gridName=="workflow-outflow-list")
           {
             columnDefs[10].hide=true;
           }

          }
          // if(columnDefs.length > 0 && columnDefs.length == 6){
          //   if(columnDefs[0].headerComponent == "selectAllHeaderComponent"){
          //       columnDefs[0].checkboxSelection = params=>params.data.name != 'UnAllocated Assets'
          //   }
          // }
          this.getGridSearchSortData(columnDefs, false);
        }
      });
  }

  applyEditColumnPermission(columnList){
    if(this.gridName  == "catalog-product-list"){
      let moduleList = JSON.parse(localStorage.getItem('moduleList'));
      let catalogModule = _.find(moduleList , {moduleId : "catalog"});
      if(catalogModule){
        let subModule = _.find(catalogModule.childModules , {moduleId : "productList"});
        if(subModule){
          if(!subModule.modulePermission.canUpdate){
            _.remove(columnList,{cellRenderer: "EditIconRowTemplate"})
          }
        }
      }
      return columnList;
    }else if(this.gridName  == "catalog-product"){
      let moduleList = JSON.parse(localStorage.getItem('moduleList'));
      let catalogModule = _.find(moduleList , {moduleId : "catalog"});
      if(catalogModule){
        let subModule = _.find(catalogModule.childModules , {moduleId : "product"});
        if(subModule){
          if(!subModule.modulePermission.canUpdate){
            _.remove(columnList,{cellRenderer: "EditIconRowTemplate"})
          }
        }
      }
      return columnList;

    }else{
      return columnList;
    }
  }

  /* Get grid search sort param data */
  getGridSearchSortData(columnDefs, shouldReloadParam) {
    this.gridService
      .getGridSearchSortData(this.gridName, this.apiParams)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {
          this.searchParamsData = response['responseData'];
          this.setSearchSortDataVO(this.searchParamsData, columnDefs, shouldReloadParam);
        }
      });
  }

  /* Set search sort data */
  setSearchSortDataVO(searchParamsData, columnDefs, shouldReloadParam) {
    setTimeout(() => {
      if (this.gridName == 'dashboard') {
        let searchSortColumn = new SearchSort();
        searchSortColumn.fieldName = 'progressStatus.display';
        searchSortColumn.searchedValue[0] = 'In Progress';
        this.filterVO.searchParamsInfoVO.push(searchSortColumn);
      }
      // if (this.gridName == 'presentation') {
      //   let searchSortColumn = new SearchSort();
      //   searchSortColumn.fieldName = 'progressStatus.code';
      //   searchSortColumn.searchedValue[0] = 'COMPLETED';
      //   this.filterVO.searchParamsInfoVO.push(searchSortColumn);
      // }
      // if (this.defaultSearchingData) {
      //   let searchSortColumn = new SearchSort();
      //   searchSortColumn.fieldName = this.defaultSearchingData["colId"];
      //   searchSortColumn.searchedValue[0] = this.defaultSearchingData["colValue"];
      //   this.filterVO.searchParamsInfoVO.push(searchSortColumn);
      // }
      this.columnDefs = columnDefs;
      for (let ele of columnDefs) {
        if (!ele['suppressMenu'] || !ele['suppressSorting']) {
          ele.searchSortData = new SearchSort;
          ele.searchSortData.fieldName = ele.backendFieldName;
          if (ele.backendFieldName == 'modifiedDate') {
            ele.searchSortData.sortingOrder = 'DESC';
          }
          if (this.gridName=="user-listing" && ele.backendFieldName == 'isTerminated') {
            ele.searchSortData.sortingOrder = 'ASC';
          }
          if (Array.isArray(this.defaultSearchingData)) {
            this.defaultSearchingData.forEach(item => {
              if (ele.searchSortData.fieldName == item["colId"]) {
                if (item["colValue"] && ele.searchSortData.searchedValue.indexOf(this.defaultSearchingData["colValue"]) == -1)
                  ele.searchSortData.searchedValue.push(item["colValue"]);
              }
            })
          } else {
            if (this.defaultSearchingData && ele.searchSortData.fieldName == this.defaultSearchingData["colId"]) {
              if (ele.searchSortData.searchedValue.indexOf(this.defaultSearchingData["colValue"]) == -1)
                ele.searchSortData.searchedValue.push(this.defaultSearchingData["colValue"]);
            }
          }

          this.filterVO.searchParamsInfoVO.push(ele.searchSortData);
          let columnSearchParamData = searchParamsData[ele.backendFieldName];
          if (columnSearchParamData) {
            ele.searchSortData.paramListData = columnSearchParamData;
            if (shouldReloadParam) {
              var colInstance = this.gridApi.getFilterInstance(ele.field);
              if (colInstance._params)
                colInstance._params.column.colDef.searchSortData.paramListData = columnSearchParamData;
            }
          }
        }
      };
      setTimeout(() => {
        this.getGridData(this.filterVO);
      });

    });
  }

  /* Get grid data */
  getGridData(gridRequestData) {
    /* if part logic is for when we redirect from list to product on catalog screen while clicking on link to enable filtering on Product List Membership*/
    if(!this.apiParams){
      
    }else{
      if(this.apiParams['ListName']!=null && this.gridName=='catalog-product'  ){
        //gridRequestData.searchParamsList.push(this.apiParams['ListName']);
        gridRequestData.searchParamsInfoVO[4].searchedValue.push(this.apiParams['ListName']);
        this.message=gridRequestData.searchParamsInfoVO[4];
        this.apiParams=null;
        this.gridService.changeMessage(this.message);
        
        // this.gridFilterSortingService.sendDataToEnableCheck(gridRequestData.searchParamsInfoVO[4]);
      }else if(this.apiParams['RelationShipName']){
        gridRequestData.searchParamsInfoVO[1].searchedValue.push(this.apiParams['RelationShipName']);
        this.apiParams=null;
      }
    }
    this.gridService
      .getGridData(gridRequestData, this.gridName, this.apiParams)
      .subscribe(response => {
        if (response['responseType'] == Variables.statusSuccess) {  
          this.results = [];
          if (this.disablePagination) {
            this.results = response['responseData'];
            this.filterVO.paginationInfoVO = null;
          } else {
            this.filterVO.paginationInfoVO = response["responseData"].paginationInfoVO;
            this.results = response['responseData'].result;
          }

          this.rowData = this.prepareGridData(this.results);
          console.log(this.rowData);
          
          this.isEventSubscribed = false;
          setTimeout(() => {
            this.gridApi.sizeColumnsToFit();
            if(gridRequestData.isTotalRecordSelected){
              document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] = true;
              this.gridApi.forEachNode(function (node) {
                node.setSelected(true);
              });
            }
            //this.gridApi.sizeColumnsToFit();
          },200);
          setTimeout(() => {
            this.adjustGridHeigtViewPort(this.gridName);
          }, 50);
        }
      });
  }

  /* On page change */
  changePage() {
    this.getGridData(this.filterVO);
    // Master Check box Fix - Unselect
   if(this.filterVO.isTotalRecordSelected){
         //nothing to do
   }else{
    this.frameworkComponents.selectAllHeaderComponent.selectAllCheckbox = false;
    this.frameworkComponents.selectAllHeaderComponent.selectAllOrTotal = null;
    this.unckeckMasterCheckBox()
   }
  }

  /* On changing page size */
  changePageSize() {
    this.filterVO.paginationInfoVO.currentPage = 1;
    this.getGridData(this.filterVO);
    this.unckeckMasterCheckBox()
  }

  /* Get grid data after any updation in records */
  getGridDataAfterUpdatingRecords() {
    if (this.filterVO.paginationInfoVO) {
      this.filterVO.paginationInfoVO.isTotalCountRequired = true;
    }
    this.filterVO = new FilterModel();
    this.selectedRows = [];
    this.getGridConfig();
    //this.getGridSearchSortData(this.columnDefs, true);
   // this.unckeckMasterCheckBox()
  }

  /* On  doubleclicking row */
  onRowDoubleClicked(params) {
    this.onRowDoubleClickEvent.emit(params.data);
  }

  /* Prepare grid data as per grid view requirement */
  prepareGridData(data) {
    switch (this.gridName) {
      case 'document-center':
        return this.prepareDocumentCenterGridData(data);
      case 'workflow-balance-sheet':
        return this.prepareBalanceSheetGridData(data);
      case 'catalog-product-portfolio-construction':
        return this.prepareProductListPortfolioContruction(data);
      case 'catalog-product':
        return this.prepareProductListPortfolioContruction(data);
      default:
        return this.addGridName(data);

    }
  }

  /* On grid ready */
  onGridReady(params) {
    this.defaultColDef = {
      headerComponentParams: { menuIcon: "fa-bars", gridName: this.gridName },
    };
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  /* Prepare data for different grid functions - Starts here */

  /* Document Centre Grid */
  prepareDocumentCenterGridData(data) {
    data.forEach(element => {
      element.category = element.extension;
    });
    return data;
  }

  /* Product Portfolio Contruction Grid */
  prepareProductListPortfolioContruction(data) {
    data.forEach(element => {
      if(element.productMin)
        element.productMin = "$" +element.productMin.toLocaleString();
      element.gridName = this.gridName;
    });
    return data;
  }

  /* Balance Sheet Grid */
  prepareBalanceSheetGridData(data) {
    this.sumTotal = data.totalMakVal;
    return data.balanceSheetVOList;
  }

  /* To add grid name for */
  addGridName(data) {
    data.forEach(element => {
      element.gridName = this.gridName;
    });
    return data;
  }

  /* Prepare data for different grid functions - Ends here */

  /*  Adjust Grih Height in ViewPort  */
  adjustGridHeigtViewPort(gridName) {
    if (gridName == 'catalog-product-list') {
      this.catalogGrid(10);
    }
    else if (gridName == 'catalog-product') {
      this.catalogGrid(-5);
    }else if(gridName=='catalog-product-portfolio-construction'){
      this.catalogGrid1();
    }
    else if (gridName == 'document-center') {
      this.documentsCenterGrid(0);
    }
    else if (gridName == 'dashboard') {
      this.dashboardGrid(0);
    }
    else if (gridName == 'workflow-relationship-section') {
      this.workflowRelationshipGrid(0);
    }
    else if (gridName == 'workflow-balance-sheet') {
      this.workflowBalancSheetGrid(0);
    }
    else if (gridName == 'workflow-portfolio-list') {
      this.portfolioOverviewGrid(0);
    }
    else if (gridName == 'presentation') {
      this.presentationGrid(0);
    }

  }
  catalogGrid1(){
    var ele=document.getElementsByTagName('ag-grid-angular')[0];
    if(ele){
      ele.setAttribute('style', "height: 300px");
    }
  }

  catalogGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let tab = $('.catalog-tabs-div')[0].clientHeight;
    let option = $('.optionDiv')[0].clientHeight;
    let margin = 200 + extraMargin;
    var total = header + pageheader + tab + option + margin
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  documentsCenterGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let margin = 185 + extraMargin;
    var total = header + pageheader + margin
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  relationshipTable(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let margin = 185 + extraMargin;
    var total = header + pageheader + margin
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  dashboardGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let dashboardSubHeading = $('.dashboardSubHeading')[0].clientHeight;
    let top10SubHeading = $('.top10SubHeading')[0].clientHeight;
    let margin = 185 + extraMargin;
    var total = header + pageheader + margin + dashboardSubHeading + top10SubHeading
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc((100vh - " + total + "px)*0.6);")
    }
    var ele1 = document.getElementsByTagName('ag-grid-angular')[1];
    if (ele1) {
      total = total + 20;
      ele1.setAttribute('style', "height: calc((100vh - " + total + "px)*0.4);")
    }
    var ele2 = document.getElementsByTagName('ag-grid-angular')[2];
    if (ele2) {
      total = total + 20;
      ele2.setAttribute('style', "height: calc((100vh - " + total + "px)*0.4);")
    }
  }

  workflowRelationshipGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let nameOption = $('.nameOption')[0].clientHeight;
    let gridHeader = $('.app-grid-heading')[0].clientHeight;
    let searchFilter = $('.relationship-selection-search-div')[0].clientHeight;
    let margin = 185 + extraMargin;
    var total = header + pageheader + margin + stepHeader + 2 * nameOption + gridHeader + searchFilter
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  workflowBalancSheetGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let tab = $('.relationship-overview-tabset')[0].firstElementChild.clientHeight;
    let gridHeadingOptions = $('.gridHeadingOptions')[0].clientHeight;
    let margin = 220 + extraMargin;
    var total = header + pageheader + margin + stepHeader + tab + gridHeadingOptions
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  portfolioOverviewGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let stepHeader = $('.stepHeader')[0].clientHeight;
    let gridHeadingOptions = $('.gridHeadingOptions')[0].clientHeight;
    // let chart =  $('.chart-container')[0].clientHeight;
    let margin = 80 + extraMargin;
    var total = header + pageheader + margin + 2 * stepHeader + 3 * gridHeadingOptions
    var ele = document.getElementsByTagName('ag-grid-angular')[1];
    if (ele) {
      ele.setAttribute('style', "height: calc((100vh - " + total + "px)*0.6);")
    }
  }

  presentationGrid(extraMargin) {
    let header = $('.mainHeader')[0].clientHeight;
    let pageheader = $('.app-pages-heading')[0].clientHeight;
    let margin = 185 + extraMargin;
    var total = header + pageheader + margin
    var ele = document.getElementsByTagName('ag-grid-angular')[0];
    if (ele) {
      ele.setAttribute('style', "height: calc(100vh - " + total + "px);")
    }
  }

  unckeckMasterCheckBox() {
    if (document.getElementById('selectAllCheckbox-' + this.gridName + '-id'))
      document.getElementById('selectAllCheckbox-' + this.gridName + '-id')['checked'] = false;
    if (document.getElementById('select-all-' + this.gridName + '-id'))
      document.getElementById('select-all-' + this.gridName + '-id')['checked'] = false;
    if (document.getElementById('select-total-' + this.gridName + '-id'))
      document.getElementById('select-total-' + this.gridName + '-id')['checked'] = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    
  }

}

