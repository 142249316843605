import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ProductFilterService } from "src/app/_services/product-filter.service";

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.less']
})
export class ProductFilterComponent implements OnInit {

  @Output() searchRecords = new EventEmitter<any>();
  
  constructor(public productFilterService:ProductFilterService
    ) {
     }

  ngOnInit() {
  }
  listName;

  private getCompInitialData():void
  {

  }


  submitSearchInfo():void
  {
   // this.searchRecords.emit();
  }

  resetSearchInfo():void
  {

  }

  private setFormData():void
  {

  }


}
