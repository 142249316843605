import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { ApiUrls } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class DocumentCenterService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('token') || ''
    })
  };

constructor(private http: HttpClient) { }

downloadDocumentData(dataStoreIdList: any){
  return this.http.post(ApiUrls.downloadDocumentsDataAPI , dataStoreIdList, this.httpOptions )
  .pipe(
      response => response
  );
}

saveDocumentsData(formData){
  const options = {} as any; // Set any options you like
  return this.http.post(ApiUrls.saveDocumentDataAPI, formData , options )
  .pipe(
      response => response
  );
}

deleteDocuments(filterInfoVO){
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('token') || ''
    }),
    body: filterInfoVO
  };
  return this.http.delete(ApiUrls.deleteDocumentsDataAPI, options)
  .pipe(
      response => response
  );
}


// downloadDocumentData(docId){

//   return this.http.get(ApiUrls.downloadDocumentsDataAPI + '?dataStoreId=' + docId + '&X-Auth-Token=' + localStorage.getItem('token'))
//   .pipe(
//       response => response
//   );
// }




}
