import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './../../app-routing.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MaxCalculatorMainComponent } from './max-calculator-main/max-calculator-main.component';
import { ProposalSetupComponent } from './proposal-setup/proposal-setup.component';
import { TabsModule } from 'ngx-bootstrap';
import { CalAllocationComponent } from './proposal-setup/cal-allocation/cal-allocation.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxEchartsModule } from 'ngx-echarts';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { EditIconRowTemplate } from '../../shared/custom-grid-row-templates/edit-icon';
import { HierarchyRowTemplate } from '../../shared/custom-grid-row-templates/hierarchy-row';
import { SelectAllHeaderComponent } from '../../shared/custom-grid-header/select-all-header/select-all-header.component';
import { DownloadIconRowTemplate } from '../../shared/custom-grid-row-templates/download-icon';
import { SelectFilterComponent } from '../../../app/shared/custom-filters/select-filter/select-filter.component';
import { DateFilterComponent } from '../../../app/shared/custom-filters/date-filter/date-filter.component';
import { LikeFilterComponent } from '../../../app/shared/custom-filters/like-filter/like-filter.component';
import { CustomGridHeaderComponent } from '../../../app/shared/custom-grid-header/custom-grid-header.component';
import { AgGridModule } from 'ag-grid-angular';
import { SortablejsModule } from 'angular-sortablejs'; 
import { SharedModule } from './../../shared/shared.module';
import { CalTaxInfoComponent } from './proposal-setup/cal-tax-info/cal-tax-info.component';
import { CalCashFlowComponent } from './proposal-setup/cal-cash-flow/cal-cash-flow.component';
import { CalFeeScheduleComponent } from './proposal-setup/cal-fee-schedule/cal-fee-schedule.component';
import { CalStrategySelectionComponent } from './proposal-setup/cal-strategy-selection/cal-strategy-selection.component';
import { CalPortfolioConstructionComponent } from './cal-portfolio-construction/cal-portfolio-construction.component';
import { CalSlideSelectionComponent } from './cal-slide-selection/cal-slide-selection.component';
import { CalCreatePresentationComponent } from './cal-create-presentation/cal-create-presentation.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularFontAwesomeModule,
    AppRoutingModule,
    SharedModule,
    TabsModule,
    NgbModule,
    ChartsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    SortablejsModule,
    BsDatepickerModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    AgGridModule.withComponents([SelectFilterComponent,LikeFilterComponent,DateFilterComponent,CustomGridHeaderComponent,EditIconRowTemplate,HierarchyRowTemplate,SelectAllHeaderComponent,DownloadIconRowTemplate]),
    NgxEchartsModule
  ],
  exports :[
  ],
  declarations: [
    MaxCalculatorMainComponent,
    ProposalSetupComponent,
    CalAllocationComponent,
    CalTaxInfoComponent,
    CalCashFlowComponent,
    CalFeeScheduleComponent,
    CalStrategySelectionComponent,
    CalPortfolioConstructionComponent,
    CalSlideSelectionComponent,
    CalCreatePresentationComponent]
})
export class MaxCalculatorModule { }
